import React, { Component } from 'react'
import './style.scss';
import { FaSearch } from "react-icons/fa";
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Add from './../../../Assets/icons/Group 1497.png';
import Back from './../../../Assets/icons/back.png';
//import { getsubsCategories } from '../../Website/WebServices/subsDetailAndSearch';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../redux/modules/app/actions';
import { domain, defaultHeaders } from '../../../Utils/domain';
import Pagination from "react-js-pagination";
import Loader from './../../../Components/Preloader';
import { getCookie } from "../../../Utils/cookies";

class index extends Component {

    state = {
        plansDetails : []
    }

    componentDidMount(){
        this.props.updateBreadcrumbs("Subscription");
        this.getData();
    }

    getData(){
        let token = getCookie("token");
        fetch( domain + '/api/subscription/adminlist_subscriptioncategory/',{
            method  : "GET",
            headers: {
                "Content-Type": "application/json",
                "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                Authorization: `Token ${token}`,
              },
            })
     .then(response => response.json())
     .then((responseJson) => { this.successResponse(responseJson) })
     .catch((error) => {  this.errorResponse(error) })
    }
    successResponse=(response)=>{
         
        this.setState({ 
            plansDetails : response
        
        })
       
    }
    render() {
        return (
            <div className="subs-details">
            <div className="subs-details__wrapper">
                <div className="subs-details__wrapper_block">
                    <div className="subs-details__wrapper_block_top_heading">
                        <p>Edit Subscription Plans</p>
                    </div>
                   
                </div>
                <div className="wrapper-style">
                    {
                        this.state.plansDetails && this.state.plansDetails.map(item => {
                            return (
                            <Link to={"/dashboard/edit-subscriptions/" +item.id}>
                                <p className="subs-paln-name">{item.subscription_category}</p>
                            </Link>
                            )
                        })
                    }
                </div>
                
             </div>
        </div>
        )
    }
}
export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      { updateBreadcrumbs }
    )(index)
  );