import React, { Component } from "react";
import "./style.scss";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Add from "./../../../Assets/icons/Group 1497.png";
import Back from "./../../../Assets/icons/back.png";
//import { getsubsCategories } from '../../Website/WebServices/subsDetailAndSearch';
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain, defaultHeaders } from "../../../Utils/domain";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";
import { getCookie } from "../../../Utils/cookies";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";

class index extends Component {
  state = {
    plansDetails: [],
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  componentDidMount() {
    this.getData();
    this.props.updateBreadcrumbs("Homepage");
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  getData() {
    let token = getCookie("token");
    fetch(domain + "/api/home/adminlist/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        this.successResponse(responseJson);
      })
      .catch(error => {
        this.errorResponse(error);
      });
  }
  successResponse = response => {
    this.setState({
      plansDetails: response
    });
  };
  render() {
    return (
      <div className="subs-details">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="subs-details__wrapper">
          <div className="subs-details__wrapper_block">
            <div className="subs-details__wrapper_block_top_heading">
              <p>Edit Home Page Static Content</p>
            </div>
          </div>
          <div className="wrapper-style">
            {this.state.plansDetails &&
              this.state.plansDetails.map(item => {
                return (
                  <Link to={"/dashboard/edit-home-page/content"}>
                    <p className="subs-paln-name">
                      Click to Edit Home Page Content
                    </p>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
