import React, { Component } from "react";
import Back from "./../../../../Assets/icons/back.png";
import Input from "@material-ui/core/Input";
import { Link } from "react-router-dom";
import { domain } from "../../../../Utils/domain";
import { getCookie } from "../../../../Utils/cookies";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../../../src/redux/modules/app/actions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "./style.scss";
import Loader from "../../../../Components/Preloader";
import Pagination from "react-js-pagination";
import Modal from "@material-ui/core/Modal";
import logo from "./../../../../Assets/images/logo2.jpeg";
import bgimgg from "./../../../../Assets/images/bgimg.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import MetaFile from "./../../../../Pages/MetaFile";

const ref = React.createRef();
class index extends Component {
  state = {
    orderDetails: [],
    assignBlock: false,
    lawyersList: [],
    lawyerName: "",
    lawyerId: null,
    final_doc: {},
    selectedFile: null,
    downloadPDfLink: "",
    downloadDocsLink: "",
    loader: false,
    lawyerUploadedLinks: [],
    group: getCookie("group"),
    client: false,
    activePage: 1,
    totalItem: null,
    maxCountDownload: 0,
    messageOfDownlaod: null,
    countofNumber: 0,
    numberOfOrder: 0,
    showNewModal: false,
    showLawyersPdf: false,
    pdfLink: false,
    showCustomersPdf: false,
    lanidigital_cost: 0,
    filling_cost: 0,
    coupon_discount: 0,
    coupon_persentage: 0,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };
  componentDidMount() {
    this.setState({
      loader: true
    });
    this.getData();
    if (this.state.group === "2") {
      this.setState({
        client: true
      });
    }
    this.getMeta();
  }
  getMeta = () => {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
    };
    fetch(domain + `/api/home/tags/order-details/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        this.getSucessMetaresponce(responseJson);
      })
      .catch(error => {
        this.getErrorMetaresponce(error);
      });
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getData = () => {
    let orderNumber = this.props.match.params.id;
    fetch(`${domain}/api/order/detail/${orderNumber}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
    fetch(`${domain}/api/auth/lawyer/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.lawyerSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  };
  fetchOrderCount = () => {
    fetch(`${domain}/api/order/download_order/${this.props.match.params.id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponseOrder(response))
      .catch(error => this.errorResponseDataOrder(error));
  };
  successResponseOrder = res => {
    this.setState({
      numberOfOrder: res.no_of_downloads,
      messageOfDownlaod: res.message
    });
  };
  errorResponseDataOrder = err => {};
  fetchMaxDownlaodCount = () => {
    fetch(
      `${domain}/api/order/download_counts/${this.state.orderDetails.user}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponseFetch(error));
  };
  successResponseFetch = res => {
    this.setState({ maxCountDownload: res[0].no_of_downloads }, () => {
      if (this.state.maxCountDownload > 0) {
        this.fetchOrderCount();
      } else {
        this.setState({
          numberOfOrder: res[0].no_of_downloads,
          messageOfDownlaod: "This user has reached maximum number of downloads"
        });
      }
    });
  };
  errorResponseFetch = err => {};
  getData = () => {
    let orderNumber = this.props.match.params.id;
    fetch(`${domain}/api/order/detail/${orderNumber}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
    this.getLawyerData();
  };
  getLawyerData = () => {
    fetch(domain + "/api/auth/lawyer/?page=1&&page_size=1000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.lawyerSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  };
  lawyerSuccessResponse = response => {
    this.setState({
      lawyersList: response.results,
      loader: false
    });
  };

  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].lawyersName === nameKey) {
        return myArray[i];
      }
    }
  }

  handleUpload = e => {
    this.setState({ selectedFile: e.target.files[0] }, () => {
      this.uploadDocuments();
    });
  };
  downloadOnClickPdf() {
    fetch(
      domain +
        "/api/order/download-pdf/?number=" +
        this.state.orderDetails.number,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(responsejson => this.downloadOnClickPdfSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  downloadOnClickPdfSuccess(res){
    const link = document.createElement('a')
    link.href = res.template_link
    link.target = "_blank"
    link.download="this.state.orderDetails.item"
    link.click()
  }
  downloadPdf() {
    fetch(
      domain +
        "/api/order/download-pdf/?number=" +
        this.state.orderDetails.number,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(responsejson => this.downloadPdfSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  downloadPdfSuccess(res) {
   
    this.setState(
      {
        downloadPDfLink: res.template_link
      },
      () => {
        this.downloadDocs();
      }
    );
    res.other_links.order_doc &&
      this.setState({
        lawyerUploadedLinks: res.other_links.order_doc
      });
  }
  downloadOnClickDocs() {
    fetch(
      domain +
        "/api/order/download-pdf/?number=" +
        this.state.orderDetails.number +
        "&doctype=doc&response-content-disposition=attachment",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(responsejson => this.downloadOnClickDocsSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  downloadOnClickDocsSuccess(res){
    const link = document.createElement('a')
    link.href = res.template_link
    link.target = "_blank"
    link.download="this.state.orderDetails.item"
    link.click()
  }

  downloadDocs() {
    fetch(
      domain +
        "/api/order/download-pdf/?number=" +
        this.state.orderDetails.number +
        "&doctype=doc&response-content-disposition=attachment",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(responsejson => this.downloadDocsSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  showLawyers = (e, ele) => {
    this.setState(
      { pdfLink: ele.uploaded_doc, showLawyersPdf: true },
      () => {}
    );
  };
  closeLawyers = e => {
    this.setState({ showLawyersPdf: false }, () => {});
  };
  openNewModal = e => {
    this.setState({ showNewModal: true }, () => {});
  };
  onCloseNewModal = e => {
    this.setState({ showNewModal: false }, () => {});
  };

  downloadDocsSuccess(res) {
    {
      res.template_link !== null &&
      res.template_link !== undefined &&
      res.template_link.length > 0
        ? this.setState({
            downloadDocsLink: res.template_link
          })
        : res.message === "Success"
        ? this.setState({
            downloadDocsLink: ""
          })
        : this.downloadDocs();
    }
  }
  uploadDocuments = () => {
    const formData = new FormData();
    formData.append(
      "final_doc_link",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    formData.append("number", this.state.orderDetails.number);

    fetch(domain + "/api/order/lawyer-upload/", {
      method: "POST",
      headers: {
        //"content-type": "multipart/form-data",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: formData
    })
      .then(response => response.json())
      .then(responsejson => this.uploadDocsResponse(responsejson))
      .catch(error => this.errorResponse(error));
  };
  uploadDocsResponse = res => {
    this.props.updateToasterConfig(
      {
        show: true,
        message: "Document Uploaded Successfully",
        color: "#D06900"
      },
      this.componentDidMount()
    );
  };
  assignLawyer = () => {
    let value = document.getElementById("selected-lawyer").value;
    let lawyer = this.search(value, this.state.lawyersList);

    let lawyerId = {
      number: this.state.orderDetails.number,
      lawyer: lawyer.id
    };

    fetch(domain + "/api/order/assignAnLawyerToAnOrder/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify(lawyerId)
    })
      .then(response => {
        this.props.updateToasterConfig({
          show: true,
          message: "Assigned a lawyer Successfully",
          color: "#D06900"
        });

        window.location.reload();
      })

      .catch(error => this.errorResponse(error));
  };

  successResponse = response => {
    this.setState({ orderDetails: response });
    this.downloadPdf();
  };

  errorResponse = error => {};
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getLawyerData());
  }
  assignBlock = () => {
    this.setState({
      assignBlock: !this.state.assignBlock
    });
  };
  deleteLink(id) {
    fetch(domain + "/api/order/delete_order_doc/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(responsejson => this.deletePdfSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  deletePdfSuccess(res) {
    this.props.updateToasterConfig(
      {
        show: true,
        message: "Document deleted successfully",
        color: "#D06900"
      },
      this.componentDidMount()
    );
  }
  additionalUpload(e) {
    const formData = new FormData();
    formData.append("uploaded_doc", e.target.files[0], e.target.files[0].name);
    formData.append("order_no", this.state.orderDetails.number);
    formData.append("doc_title", "additional docs");

    fetch(domain + "/api/order/upload_order_doc/", {
      method: "POST",
      headers: {
        //"content-type": "multipart/form-data",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: formData
    })
      .then(response => response.json())
      .then(responsejson => this.additionaluploadSuccess(responsejson))
      .catch(error => this.errorResponse(error));
  }
  additionaluploadSuccess(res) {
    this.props.updateToasterConfig(
      {
        show: true,
        message: "Document Uploaded Successfully",
        color: "#D06900"
      },
      this.componentDidMount()
    );
  }

  render() {
    let format_date = new Date(this.state.orderDetails.date);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let datestring =
      ("0" + format_date.getDate()).slice(-2) +
      " " +
      monthNames[format_date.getMonth()] +
      " " +
      format_date.getFullYear() +
      "   " +
      ("0" + format_date.getHours()).slice(-2) +
      ":" +
      ("0" + format_date.getMinutes()).slice(-2);
    format_date.toLocaleString().replace(/\//g, "-");
    return (
      <div className="orderdetails">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        {this.state.orderDetails["number"] === undefined ? (
          <Loader />
        ) : (
          <div className="orderdetails__wrapper">
            <div className="orderdetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                Order Details
              </p>
            </div>
            <div className="orderdetails__wrapper_content">
              <div className="orderdetails__wrapper_content_top">
                <p className="orderdetails__wrapper_content_top_orderid">
                  Order #{this.state.orderDetails.number}
                </p>
                <p className="orderdetails__wrapper_content_top_status">
                  <span className={this.state.orderDetails.status}>
                    {this.state.orderDetails.status}
                  </span>
                </p>
              </div>
              <p className="orderdetails__wrapper_content_service">
                {this.state.orderDetails.item}
              </p>
              <Container className="orderdetails__wrapper_content_block">
                <Grid className="leftBox" xs={12} sm={12} lg={6} md={12}>
                  <div className="orderdetails__wrapper_content_block_left">
                    <div className="orderdetails__wrapper_section">
                      <p className="left-side">Completed on</p>
                      <p className="right-side">{datestring}</p>
                    </div>
                    <div className="orderdetails__wrapper_section">
                      <p className="left-side">Payment Method</p>
                      <p className="right-side">
                        {this.state.orderDetails.paymentMethod}
                      </p>
                    </div>
                    <div className="orderdetails__wrapper_section-1">
                      <p className="left-side">Payment Summary</p>
                      <p className="right-side">{}</p>
                    </div>
                    <div className="orderdetails__wrapper_section-1">
                      <p className="right-side">
                        {this.state.orderDetails.filing_cost > 0
                          ? "Lanidigital Cost"
                          : "Cost"}
                      </p>
                      <p className="right-side">
                        &#8358; {this.state.orderDetails.lanidigital_cost}
                      </p>
                    </div>
                    {this.state.orderDetails.filing_cost > 0 && (
                      <div className="orderdetails__wrapper_section-1">
                        <p className="right-side">Filling Cost</p>
                        <p className="right-side">
                          &#8358; {this.state.orderDetails.filing_cost}
                        </p>
                      </div>
                    )}
                    {(this.state.orderDetails.coupon_final_amount > 0 ||
                      this.state.orderDetails.coupon_discount ===
                        "100.00% - Discount") && (
                      <div className="orderdetails__wrapper_section-1">
                        <p className="right-side">Coupon Code Discount</p>
                        <p className="right-side">
                          - &#8358;
                          {this.state.orderDetails.lanidigital_cost -
                            this.state.orderDetails.coupon_final_amount}
                        </p>
                      </div>
                    )}
                    {this.state.orderDetails.discount > 0 && (
                      <div className="orderdetails__wrapper_section-1">
                        <p className="right-side">Subscription Discount</p>
                        <p className="right-side">
                          - &#8358;
                          {this.state.orderDetails.discount
                            ? this.state.orderDetails.discount
                            : "00.00"}
                        </p>
                      </div>
                    )}
                    <div className="orderdetails__wrapper_section">
                      <p className="left-side">Grand total</p>
                      <p className="right-side">
                        &#8358;
                        {this.state.orderDetails.discount > 0
                          ? this.state.orderDetails.amount -
                            this.state.orderDetails.discount
                          : this.state.orderDetails.coupon_final_amount > 0 ||
                            this.state.orderDetails.coupon_discount ===
                              "100.00% - Discount"
                          ? this.state.orderDetails.amount -
                            (this.state.orderDetails.lanidigital_cost -
                              this.state.orderDetails.coupon_final_amount)
                          : this.state.orderDetails.amount}
                      </p>
                    </div>

                    {this.state.showNewModal && (
                      <Modal
                        open={this.state.showNewModal}
                        onClose={e => this.onCloseNewModal(e)}
                      >
                        {
                          <div
                            ref={el => (this.componentRef = el)}
                            id="printSection"
                            className="custom--subscription--popup payment--popup"
                          >
                            <p
                              className="custom--close"
                              onClick={e => this.onCloseNewModal(e)}
                            >
                              x
                            </p>
                            <div ref={ref}>
                              <TableContainer className="mobTable" style={{}}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="trLogo headDIv">
                                        <img src={logo} className="logoImahe" />
                                      </TableCell>
                                      <TableCell
                                        className="orderTH headDIv"
                                        align="right"
                                      >
                                        <h1>
                                          Order #
                                          {this.state.orderDetails.number}
                                        </h1>
                                        <p>
                                          <span
                                            style={{
                                              fontWeight: "600",
                                              color: "#141c49"
                                            }}
                                          >
                                            Date :
                                          </span>
                                          {datestring}
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        className="billAdd topBill"
                                        component="th"
                                        scope="row"
                                      >
                                        Bill to
                                      </TableCell>
                                      <TableCell
                                        className="billAdd topBill"
                                        align="right"
                                      >
                                        Ship to
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab"
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerName}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab"
                                        align="right"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerName}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab"
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails.address !==
                                          null &&
                                          this.state.orderDetails.address
                                            .addressLine1}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab"
                                        align="right"
                                      >
                                        {this.state.orderDetails.address !==
                                          null &&
                                          this.state.orderDetails.address
                                            .addressLine1}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab"
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails.address !==
                                          null &&
                                          this.state.orderDetails.address
                                            .addressLine2}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab"
                                        align="right"
                                      >
                                        {this.state.orderDetails.address !==
                                          null &&
                                          this.state.orderDetails.address
                                            .addressLine2}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab"
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerMobile}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab"
                                        align="right"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerMobile}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className="lastDIv">
                                      <TableCell
                                        className="nameTab padddiv"
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerEmail}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab padddiv"
                                        align="right"
                                      >
                                        {this.state.orderDetails
                                          .customerDetails !== null &&
                                          this.state.orderDetails
                                            .customerDetails.customerEmail}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="decriptIon padddiv"
                                        component="th"
                                        scope="row"
                                      >
                                        Description
                                      </TableCell>
                                      <TableCell
                                        className="grandT padddiv"
                                        align="right"
                                      >
                                        Amount
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab "
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails.item}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab "
                                        align="right"
                                      ></TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className="nameTab "
                                        component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails.filing_cost > 0
                                          ? "Lanidigital Cost"
                                          : "Cost"}
                                      </TableCell>
                                      <TableCell
                                        className="nameTab "
                                        align="right"
                                      >
                                        &#8358;
                                        {
                                          this.state.orderDetails
                                            .lanidigital_cost
                                        }
                                      </TableCell>
                                    </TableRow>
                                    {this.state.orderDetails.filing_cost >
                                      0 && (
                                      <TableRow>
                                        <TableCell
                                          className="nameTab "
                                          component="th"
                                          scope="row"
                                        >
                                          Filling Cost
                                        </TableCell>
                                        <TableCell
                                          className="nameTab "
                                          align="right"
                                        >
                                          &#8358;
                                          {this.state.orderDetails.filing_cost}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    {this.state.orderDetails
                                      .coupon_final_amount > 0 ||
                                      (this.state.orderDetails
                                        .coupon_discount ===
                                        "100.00% - Discount" && (
                                        <TableRow>
                                          <TableCell
                                            className="nameTab paddB"
                                            component="th"
                                            scope="row"
                                          >
                                            Coupon Code Discount
                                          </TableCell>
                                          <TableCell
                                            className="nameTab paddB"
                                            align="right"
                                          >
                                            - &#8358;
                                            {this.state.orderDetails
                                              .lanidigital_cost -
                                              this.state.orderDetails
                                                .coupon_final_amount}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    {this.state.orderDetails.discount > 0 && (
                                      <TableRow>
                                        <TableCell
                                          className="nameTab paddB subDiscount"
                                          component="th"
                                          scope="row"
                                        >
                                          Subscription Discount
                                        </TableCell>
                                        <TableCell
                                          className="nameTab paddB subDiscount"
                                          align="right"
                                        >
                                          - &#8358;
                                          {this.state.orderDetails.discount
                                            ? this.state.orderDetails.discount
                                            : "00.00"}
                                        </TableCell>
                                      </TableRow>
                                    )}

                                    <TableRow className="btop">
                                      <TableCell
                                        className="grantTotal topSpace "
                                        component="th"
                                        scope="row"
                                      >
                                        Grand Total
                                      </TableCell>
                                      <TableCell
                                        className="totalAmount topSpace"
                                        align="right"
                                      >
                                        &#8358;
                                        {this.state.orderDetails.discount > 0
                                          ? this.state.orderDetails.amount -
                                            this.state.orderDetails.discount
                                          : this.state.orderDetails
                                              .coupon_final_amount > 0 ||
                                            this.state.orderDetails
                                              .coupon_discount ===
                                              "100.00% - Discount"
                                          ? this.state.orderDetails.amount -
                                            (this.state.orderDetails
                                              .lanidigital_cost -
                                              this.state.orderDetails
                                                .coupon_final_amount)
                                          : this.state.orderDetails.amount}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className="notesOr">
                                      <TableCell
                                        className="notesRow  "
                                        // component="th"
                                        scope="row"
                                      >
                                        {this.state.orderDetails.status ===
                                          "Completed" ||
                                        this.state.orderDetails.status ===
                                          "Payment Done" ? (
                                          <div className="flexDis">
                                            Notes: Payment of &#8358;
                                            {this.state.orderDetails.discount >
                                            0
                                              ? this.state.orderDetails.amount -
                                                this.state.orderDetails.discount
                                              : this.state.orderDetails
                                                  .coupon_final_amount > 0 ||
                                                this.state.orderDetails
                                                  .coupon_discount ===
                                                  "100.00% - Discount"
                                              ? this.state.orderDetails.amount -
                                                (this.state.orderDetails
                                                  .lanidigital_cost -
                                                  this.state.orderDetails
                                                    .coupon_final_amount)
                                              : this.state.orderDetails.amount}
                                            &nbsp; made successfully. <br />
                                            Thank You.
                                          </div>
                                        ) : (
                                          <div className="flexDis">
                                            Notes: Payment of &#8358;
                                            {this.state.orderDetails.amount}
                                            is pending.
                                          </div>
                                        )}
                                      </TableCell>
                                      <TableCell className="NotesRow topSpace notabSp"></TableCell>
                                    </TableRow>

                                    <TableRow className="notesOr">
                                      <TableCell
                                        className="trLogo"
                                        // align="right"
                                      >
                                        <img src={logo} className="logoImahe" />
                                      </TableCell>
                                      <TableCell
                                        className="NotesRow topSpace"
                                        align="right"
                                      >
                                        The Adunola, Plot L2, 401 Close Banana
                                        Island, Ikoyi, Lagos, Nigeria
                                        CONTACT@LANIDIGITAL.COM +234-1-270-2551,
                                        0700-LANIDIGITAL
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>

                            <Pdf targetRef={ref} filename="receipt.pdf">
                              {({ toPdf }) => (
                                <button onClick={toPdf} className="downpdf">
                                  Download Receipt
                                </button>
                              )}
                            </Pdf>
                            {/* <ReactToPrint
                              trigger={() => (
                                <button className="downpdf">
                                  Download Receipt
                                </button>
                              )}
                              content={() => this.componentRef}
                            /> */}
                          </div>
                        }
                      </Modal>
                    )}
                    {this.state.showLawyersPdf && (
                      <Modal
                        ref={el => (this.componentRef = el)}
                        open={this.state.showLawyersPdf}
                        onClose={e => this.closeLawyers(e)}
                      >
                        {
                          <div
                            id="printSection"
                            className="custom--subscription--popup pdf--popup"
                          >
                            <p
                              className="custom--close"
                              onClick={e => this.closeLawyers(e)}
                            >
                              x
                            </p>
                            <div ref={ref}>
                              <iframe
                                src={this.state.pdfLink}
                                width="1100px"
                                height="1000px"
                              ></iframe>
                            </div>
                          </div>
                        }
                      </Modal>
                    )}

                    {getCookie("group") !== "3" && (
                      <p
                        className="orderdetails__wrapper_receipt"
                        onClick={e => this.openNewModal(e)}
                      >
                        View Payment Receipt
                      </p>
                    )}
                  </div>
                  {this.state.orderDetails.flow_type === "Type B" &&
                    getCookie("group") === "3" && (
                      <div>
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">
                            Lawyer's Docs upload section
                          </p>
                          <p className="right-side">{}</p>
                        </div>

                        {this.state.orderDetails.flow_type === "Type B" && (
                          <div className="orderdetails__wrapper_section">
                            <p className="left-side">Upload Final Agreement</p>
                            <p className="right-side">
                              <input
                                type="file"
                                id="upload-file"
                                onChange={e => this.handleUpload(e)}
                                style={{ display: "none" }}
                              />
                              <label
                                for="upload-file"
                                className="orderdetails__wrapper_content_button_download"
                              >
                                Upload
                              </label>
                            </p>
                          </div>
                        )}
                        {this.state.orderDetails.flow_type === "Type B" && (
                          <div className="orderdetails__wrapper_section">
                            <p className="left-side">Upload Additional Docs</p>
                            <p className="right-side">
                              <input
                                type="file"
                                id="upload-additional-file"
                                onChange={e => this.additionalUpload(e)}
                                style={{ display: "none" }}
                              />
                              <label
                                for="upload-additional-file"
                                className="orderdetails__wrapper_content_button_download"
                              >
                                Upload
                              </label>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                </Grid>
                <Grid className="rightBox" xs={12} sm={12} lg={6} md={12}>
                  <div className="orderdetails__wrapper_content_block_right">
                    {!this.state.client && (
                      <>
                        <h3 className="left-side">Customer Details</h3>
                        {this.state.orderDetails.customerDetails !== null ? (
                          <Link
                            to={{
                              pathname: "/dashboard/customers/customer-details",
                              customerId: this.state.orderDetails
                                .customerDetails.customerId
                            }}
                          >
                            <span className="view--profile">View Profile</span>
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: "/dashboard/customers/customer-details"
                            }}
                          >
                            <span className="view--profile">View Profile</span>
                          </Link>
                        )}
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">First Name</p>
                          <p className="right-side">
                            {this.state.orderDetails.customerDetails !== null &&
                              this.state.orderDetails.customerDetails.firstName}
                          </p>
                        </div>
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">Last Name</p>
                          <p className="right-side">
                            {this.state.orderDetails.customerDetails !== null &&
                              this.state.orderDetails.customerDetails.lastName}
                          </p>
                        </div>
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">User Id</p>
                          <p className="right-side">
                            {this.state.orderDetails.customerDetails !== null &&
                              this.state.orderDetails.customerDetails
                                .customerName}
                          </p>
                        </div>
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">Mobile</p>
                          <p className="right-side">
                            {this.state.orderDetails.customerDetails !== null &&
                              this.state.orderDetails.customerDetails
                                .customerMobile}
                          </p>
                        </div>
                        <div className="orderdetails__wrapper_section">
                          <p className="left-side">Email</p>
                          <p className="right-side">
                            {this.state.orderDetails.customerDetails !== null &&
                              this.state.orderDetails.customerDetails
                                .customerEmail}
                          </p>
                        </div>

                        {this.state.orderDetails.flow_type === "Type B" && (
                          <div className="orderdetails__wrapper_section">
                            <p className="left-side download--label">
                              Assigned Lawyer Name
                            </p>
                            <p className="right-side">
                              {this.state.orderDetails.assignLawyer
                                ? this.state.orderDetails.assignLawyer
                                : "NA"}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {this.state.orderDetails.flow_type === "Type B" && (
                      <div className="uploaded_links">
                        <div className="uploaded_links_customer">
                          <p>Customer's Document links</p>
                          <ol>
                            {this.state.orderDetails.agreement_doc.map(ele => {
                              return (
                                <li>
                                  {ele.uploaded_doc &&
                                  ele.uploaded_doc.includes("pdf") ? (
                                    <a
                                      onClick={e => this.showLawyers(e, ele)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Link
                                    </a>
                                  ) : (
                                    <a href={ele.uploaded_doc} download>
                                      Link
                                    </a>
                                  )}
                                </li>
                              );
                            })}
                          </ol>
                        </div>

                        <div className="uploaded_links_lawyers">
                          <p>Lawyer's Uploaded links</p>
                          <ol>
                            {this.state.lawyerUploadedLinks &&
                              this.state.lawyerUploadedLinks.map(ele => {
                                return (
                                  <li className="uploaded_links_lawyers_list">
                                    {ele.uploaded_doc.includes("pdf") ? (
                                      <a
                                        onClick={e => this.showLawyers(e, ele)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Link
                                      </a>
                                    ) : (
                                      <a href={ele.uploaded_doc} download>
                                        Link
                                      </a>
                                    )}
                                    {getCookie("group") === "3" && (
                                      <span
                                        onClick={() => this.deleteLink(ele.id)}
                                      >
                                        X
                                      </span>
                                    )}
                                  </li>
                                );
                              })}
                          </ol>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </Container>
             
              {this.state.downloadPDfLink == "" ?  <p className="loaderAlign">Loading Documents...</p> : 
              <p className="orderdetails__wrapper_content_button">
                  
                {this.state.orderDetails.status !== null &&
                this.state.orderDetails.status === "Payment Pending" && getCookie("group") === 1  ? (
                  <p>
                    {getCookie("group") == 2 &&
                      "Complete your Payment to Download"}
                  </p>
                ) : (
                  <span>
                    {this.state.downloadPDfLink &&
                      <span>
                        {/* <a
                          href={this.state.downloadPDfLink}
                          target="_blank"
                          download="this.state.orderDetails.item"
                        > */}
                          <button onClick={() => this.downloadOnClickPdf()}className="orderdetails__wrapper_content_button_download">
                            Download PDF
                          </button>
                      
                        {this.state.downloadDocsLink && 
                        // <a
                        //   href={this.state.downloadDocsLink}
                        //   download="this.state.orderDetails.item"
                        //   target="_blank"
                        // >
                          <button onClick={() => this.downloadOnClickDocs()} className="orderdetails__wrapper_content_button_download">
                            Download Word
                          </button>
                        // </a>
                        }
                       
                      </span>
                     }
                  </span>
                )}
                {getCookie("group") == 1 &&
                  this.state.orderDetails.status !== "Payment Pending" &&
                  this.state.orderDetails.flow_type === "Type B" && (
                    <button
                      onClick={this.assignBlock}
                      className="orderdetails__wrapper_content_button_download"
                    >
                      Assign a Lawyer
                    </button>
                  )}
              </p>
            }
              {this.state.assignBlock && (
                <div className="lawyerblock">
                  <select
                    id="selected-lawyer"
                    className="orderdetails__wrapper_content_button_download"
                    onChange={() => this.assignLawyer()}
                  >
                    <option>Select a lawyer</option>
                    {this.state.lawyersList.map(item => {
                      return <option>{item.lawyersName}</option>;
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
