import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Back from "../../../../../Assets/icons/back.png";
import { getCookie, setCookie } from "../../../../../Utils/cookies";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { domain } from "../../../../../Utils/domain";
import MetaFile from "../../../../../Pages/MetaFile";
import "./style.scss";

let id = null;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatLogDetail: [],
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  componentDidMount = () => {
    fetch(
      domain +
        `/api/chatbot/chat_conversations_details/${this.props.match.params.id}/?page=` +
        this.state.activePage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));

    this.getMeta();
  };
  getMeta = () => {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
    };
    fetch(domain + `/api/home/tags/chatbot-details/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        this.getSucessMetaresponce(responseJson);
      })
      .catch(error => {
        this.getErrorMetaresponce(error);
      });
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  seenMsg() {
    fetch(domain + "/api/chatbot/update_message_status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      },
      body: JSON.stringify({
        user_id: this.state.chatId,
        seen: true
      })
    })
      .then(response => response.json())
      .then(responsejson => this.seenSuccessResponse(responsejson))
      .catch(error => this.errorResponse(error));
  }
  seenSuccessResponse(res) {}
  successResponseFetch = response => {
    this.setState({
      botLogDetail: response.user_id,
      totalItem: response.paginationTotal,
      chatId: response.chatId
    });
    this.seenMsg();
  };
  errorResponse = error => {};

  render() {
    return (
      <Container className="orderdetails__wrapper_content_block chatDetailsp">
        {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <Grid xs={12} sm={12} lg={12} md={12}>
          <div className="orderdetails__wrapper_heading">
            <p>
              <span>
                <img
                  onClick={this.props.history.goBack}
                  src={Back}
                  alt="back button"
                ></img>
              </span>
              Bot Details
            </p>
          </div>
          <div>
            <Grid xs={12} sm={12}>
              {this.state.botLogDetail !== null &&
                this.state.botLogDetail !== undefined &&
                this.state.botLogDetail.map(botLogDetail => {
                  var newDate = new Date(botLogDetail.created_on);
                  return (
                    <div className="mainchatlogdiv" key={botLogDetail.id}>
                      <Grid className="orderNo" xs={12} sm={12} lg={12} md={12}>
                        <div className="chatLogcust">
                          <Grid className="chatlogFirstrow" xs={6} sm={6}>
                            <p>User Messages</p>

                            <p>
                              {botLogDetail.request &&
                              botLogDetail.request.replace(/(^"|"$)/g, "")
                                .length > 1
                                ? botLogDetail.request.replace(/(^"|"$)/g, "")
                                : "NA"}
                            </p>
                          </Grid>
                          <Grid className="chatlogSrow" xs={6} sm={6}>
                            <p>Email ID</p>
                            <p>
                              {botLogDetail.email_id &&
                              botLogDetail.email_id.replace(/(^"|"$)/g, "")
                                .length > 1
                                ? botLogDetail.email_id.replace(/(^"|"$)/g, "")
                                : "NA"}
                            </p>{" "}
                          </Grid>
                        </div>
                        <div className="chatLogsupport">
                          <Grid className="chatlogFirstrow" xs={6} sm={6}>
                            <p>Date</p>
                            <p>{`${newDate.getDate()}- ${newDate.getMonth() +
                              1} - ${newDate.getFullYear()}`}</p>
                          </Grid>
                          <Grid className="chatlogSrow" xs={6} sm={6}>
                            <p>Time</p>
                            <p>{`${newDate.getHours()}:${newDate.getMinutes() +
                              1}:${newDate.getSeconds()}`}</p>
                          </Grid>
                        </div>
                        <div className="chatLogsupport">
                          <Grid className="chatlogFirstrow" xs={6} sm={6}>
                            <p>First Name</p>
                            <p>
                              {botLogDetail.first_name &&
                              botLogDetail.first_name.replace(/(^"|"$)/g, "")
                                .length > 1
                                ? botLogDetail.first_name.replace(
                                    /(^"|"$)/g,
                                    ""
                                  )
                                : "NA"}
                            </p>
                          </Grid>
                          <Grid className="chatlogSrow" xs={6} sm={6}>
                            <p>Last Name</p>
                            <p>
                              {botLogDetail.last_name &&
                              botLogDetail.last_name.replace(/(^"|"$)/g, "")
                                .length > 1
                                ? botLogDetail.last_name.replace(/(^"|"$)/g, "")
                                : "NA"}
                            </p>
                          </Grid>
                        </div>{" "}
                        <div className="chatLogsupport">
                          <Grid className="chatlogFirstrow" xs={6} sm={6}>
                            <p>Phone Number</p>
                            <p>
                              {botLogDetail.phone_number &&
                              botLogDetail.phone_number.replace(/(^"|"$)/g, "")
                                .length > 1
                                ? botLogDetail.phone_number.replace(
                                    /(^"|"$)/g,
                                    ""
                                  )
                                : "NA"}
                            </p>
                          </Grid>
                        </div>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}
export default withRouter(index);
