import React, { Component } from 'react';
import './style.scss';
import Back from './../../../../Assets/icons/back.png';
import { withRouter } from "react-router-dom";
import {domain} from '../../../../Utils/domain';
import { getCookie } from '../../../../Utils/cookies';
import { updateToasterConfig } from '../../../../../src/redux/modules/app/actions';
import { connect } from "react-redux";
import axios from 'axios';

class index extends Component {

    state={
        personaId:'',
        expertiseArea:'',
        firstName:'',
        lastName:'',
        userName: '',
        email:'',
        phoneNumber:'',
        password:'',
        confirmPassword:'',
        userId:''
    }
   

    checkValidations(){
        if(!this.state.personaId && !this.state.userName && !this.state.firstName && !this.state.lastName && !this.state.email && !this.state.phoneNumber && !this.state.password && !this.state.expertiseArea ){
            this.props.updateToasterConfig({
                show: true,
                message:"Please fill all the inputs fields" ,
                color: '#D06900',
              })
        }else if(this.state.userName.includes("@") === true){
            this.props.updateToasterConfig({
              show: true,
              message: "Can not use @ in Username ",
              color: '#D06900',
            })
          }else if((this.state.password !== this.state.confirmPassword)){
            this.props.updateToasterConfig({
              show: true,
              message: "Password is not matching",
              color: '#D06900',
            })
          }else if(this.state.phoneNumber.length<=9 || this.state.phoneNumber.length>=13){
            this.props.updateToasterConfig({
              show: true,
              message: "Please enter a valid Phone number",
              color: '#D06900',
            })
          }else if(this.state.email.length >= 6 && this.state.email.includes("@") === false ){
            this.props.updateToasterConfig({
              show: true,
              message: "Please enter a valid Email address",
              color: '#D06900',
            })
          }
          else{
            this.creatingPersona( )
          }
    }
    creatingPersona(){

        let  groupsId    = this.state.personaId;
        let  firstname   = this.state.firstName;
        let  lastname    = this.state.lastName;
        let  gmail       = this.state.email;
        let  phoneNumber = this.state.phoneNumber;
        let  pwd         = this.state.password;
        let  expertiseIn = this.state.expertiseArea;
        let  userName    = this.state.userName;
        let token = getCookie("token");
        axios.post(domain + "/api/auth/create-profile/",
        JSON.stringify({
                "groups"     : groupsId,
                "username"   : userName,
                "first_name" : firstname,
                "last_name"  : lastname,
                "password"   : pwd,
                "phone"      : phoneNumber,
                "email"      : gmail,
                "expertise"  : expertiseIn,
                "lastUrl" : "/signin",
                "activationUrl" : window.location.protocol+"//"+window.location.host+'/email-verification'
        }),
        {
            headers :  {
                'Content-Type' : "application/json",
                'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                'Authorization': `Token ${token}`
           },
        })
        .then( responsejson => this.successResponse(responsejson))
        .catch(error => this.errorResponse(error))
    }

    successResponse=(response)=>{
         
        this.props.updateToasterConfig({
            show: true,
            message:"Persona has been created successfully. Please verified email address to activate." ,
            color: '#D06900',
          },window.location.reload())
    }

    errorResponse=(error)=>{
       
      if(error.response.data.email && error.response.data.email[0] === "user with this email already exists."){
        this.props.updateToasterConfig({
          show: true,
          message:"User with this email already exists. Please try with different email Id" ,
          color: '#D06900',
        })
      }else if(error.response.data.username && error.response.data.username[0] === "A user with that username already exists."){
        this.props.updateToasterConfig({
          show: true,
          message:"A user with that username already exists. Please try with different User name" ,
          color: '#D06900',
        })
      }else{
           
          this.props.updateToasterConfig({
              show: true,
              message:"Something went wrong. Try again after some time." ,
              color: '#D06900',
            })
        }

        
    }

    render() {
        return (
            <div className="create-lawyer">
                <h1><span onClick={this.props.history.goBack}><img src={Back} alt="back button" ></img></span>Creating New User</h1>
                <div className="create-lawyer__wrapper">
                   
                    <div className="create-lawyer__wrapper_block">
                        <div className="create-lawyer__wrapper_block_left">
                            <label>User Type</label>
                            <select id="Expertise" value={this.state.personaId} onChange={(e)=>this.setState({personaId:e.target.value},()=>console.log("persona_id",this.state.personaId))} className="select-input-type" name="Expertise">
                                <option value="">select</option>
                                <option value="3">Lawyer</option>
                                <option value="1">Admin</option>
                                <option value="4">Finance</option>
                                <option value="5">Customer Support</option>
                            </select>
                            <label>User Name</label>
                            <input type="text" placeholder="User Name"
                                   value={this.state.userName}
                                   onChange={(e)=>this.setState({ userName: e.target.value})}/>
                            <label>Email</label>
                            <input type="email" placeholder="Email"
                                   value={this.state.email}
                                   onChange={(e)=>this.setState({ email: e.target.value})}/>
                            <label>Password</label>
                            <input type="password" placeholder="Password"
                                   value={this.state.password}
                                   onChange={(e)=>this.setState({ password: e.target.value})}/>
                                   <label>Confirm Password</label>
                                   <input type="password" placeholder="Confirm Password"
                                          value={this.state.confirmPassword}
                                          onChange={(e)=>this.setState({ confirmPassword: e.target.value})}/>

                        </div>
                        <div className="create-lawyer__wrapper_block_right">
                        <label>First Name</label>
                            <input type="text" placeholder="First Name"
                                   value={this.state.firstName}
                                   onChange={(e)=>this.setState({ firstName: e.target.value})}/>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name"
                                   value={this.state.lastName}
                                   onChange={(e)=>this.setState({ lastName: e.target.value})}/>
                            <label>Phone</label>
                            <input type="phone" placeholder="Phone"
                                   value={this.state.phoneNumber}
                                   onChange={(e)=>{
                                     if(e.target.value.length<=11){
                                     this.setState({ phoneNumber: e.target.value})
                                     }
                                   }}/>
                                 {this.state.personaId==="3"&&<>  <label>Expertise</label>
                                   <select id="Expertise" value={this.state.expertiseArea} onChange={(e)=>this.setState({expertiseArea:e.target.value})} className="select-input" name="Expertise">
                                       <option value="">select</option>
                                       <option value="Personal">Personal</option>
                                       <option value="Business">Business</option>
                                   </select>
                                   </>}
                        </div>
                    </div>
                    {/* <button className="create-lawyer__wrapper_button_create">Generate Password</button> */}
                    <div className="create-lawyer__wrapper_button">
                        <button className="create-lawyer__wrapper_button_cancel">Cancel</button>
                        <button className="create-lawyer__wrapper_button_create" onClick={()=>this.checkValidations()}>Create</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      { updateToasterConfig }
    )(index)
  );


