import React, { useEffect, useState, useRef } from "react";
import { Avatar, Card, List } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  ServiceCategoriesAndLists,
  servicesBannercontent
} from "../../WebServices/Services";
import { withRouter } from "react-router-dom";
import Carousel, { consts } from "react-elastic-carousel";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import Estate from "../../../../Assets/images/estate.png";
import "../Personal/styles.scss";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import Breadcrumbs from "../../../../Components/Breadcrumbs";
import MetaFile from "../../../MetaFile";
import { Helmet } from 'react-helmet';
const BusinessServices = (props, ref) => {
  const [successResponseData, setsuccessResponseData] = useState([]);
  const [bannerContentData, setbannerContentData] = useState([]);
  const [text, setText] = useState("");
  const [activeIndex, setaActiveIndex] = useState(0);
  const [idValue, setIdvalue] = useState("");
  const [IdFromState, setIdFromState] = useState("");
  const scrollToId = useRef();

  function changeTabColor(index) {
    try {
      setaActiveIndex(index);

      var findTheTitle = document
        .getElementById(`titleOfScroll_${index}`)
        .scrollIntoView({ block: "center" });
      setTimeout(() => {
        props.history.push({
          state: undefined
        });
      }, 1000);
    } catch (error) {}
  }
  useEffect(() => {
    console.log(props.location.state);

    if (props.location.state !== undefined) {
      setTimeout(() => {
        props.location.state !== undefined &&
          props.location.state.id >= 0 &&
          changeTabColor(props.location.state.id);
      }, 1500);
    }
  }, [props]);

  // useEffect(() =>
  // {
  //   activeIndex
  // })
  useEffect(() => {
    if (props.location.id === undefined) {
      props.location.id = 3;
    }
    setsuccessResponseData([]);
    let serviceTypeId = props.location.id;
    let Id;
    if (serviceTypeId === 3) {
      if (props.location.id !== idValue) {
        setIdvalue(props.location.id);
      }
      setText("Business");
      props.updateBreadcrumbs("Business");
    } else {
      if (
        window.location.pathname === "/business" &&
        props.location.id === undefined
      ) {
        Id = 3;
        setIdvalue(3);
        setText("Business");
        props.updateBreadcrumbs("Business");
      } else {
        props.updateBreadcrumbs("Services");
      }
      serviceTypeId = Id;
    }
    ServiceCategoriesAndLists(successResponse, errorResponse, serviceTypeId);
    setbannerContentData([]);
    servicesBannercontent(successResponseContent, errorResponse);
  }, [idValue]);

  function successResponse(response) {
    setsuccessResponseData(response);
  }

  function errorResponse(error) {}

  function successResponseContent(response) {
    setbannerContentData(response);
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 750, itemsToShow: 2 },
    { width: 1000, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
    { width: 2000, itemsToShow: 5 }
  ];
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <Avatar
          src={require("../../../../Assets/images/right.png")}
          style={{ height: 50, width: 50, borderRadius: 0, marginRight: 50 }}
        />
      ) : (
        <Avatar
          src={require("../../../../Assets/images/left.png")}
          style={{ height: 50, width: 50, borderRadius: 0 }}
        />
      );
    return (
      <div
        className="arrowHeight"
        style={{ position: "absolute", right: 150, top: 650 }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-353797034/K9FBCI2Y6e4CEKqH2qgB'});`}</script>
      </Helmet>
      <div className="person--business--page  business--main--page">
        {bannerContentData !== undefined &&
          bannerContentData.map((item, index) => {
            if (idValue === item.id) {
              return (
                <div
                  key={index}
                  className="servicesPage "
                  style={{
                    backgroundImage: "url(" + item.service_type_banner + ")"
                  }}
                >
                  {item.meta_title !== "" &&  <MetaFile
                    meta_title={item.meta_title}
                    meta_description={item.meta_description}
                    meta_keywords={item.meta_keywords}
                    meta_image={item.meta_image}
                    other_meta_tags={item.json_meta_tags}
                  />
                }
                  <div className="services-breadcrumbs">
                    <Breadcrumbs />
                  </div>
                  <Container className="serviceContainer">
                    <div  
                      className="services--banner--wrapper"
                      dangerouslySetInnerHTML={{
                        __html: item.banner_content
                      }}
                    ></div>
                    {/* <div className="viewImg">
                      <Avatar
                        src={require("../../../../Assets/images/question.png")}
                        style={styles.questionImage}
                      />
                      <div
                        className="viewFaq"
                        onClick={() => props.history.push("/faq", { item })}
                        style={styles.faq}
                      >
                        View FAQs
                      </div>
                    </div> */}
                  </Container>
                </div>
              );
            }
          })}
        {/* <Avatar src={require('../../../../Assets/images/Category_Landing.png')} style={styles.banner} /> */}

        <Container className="personal--main--wrapper">
          <Grid xs="12" sm="12" lg="12" md="12">
            <Box paddingTop={2} paddingBottom={2} className="mobile-hidden">
              <h3></h3>
            </Box>
            <div className="mobile-hidden">
              <Carousel
                pagination={false}
                focusOnSelect={false}
                renderArrow={myArrow}
                breakPoints={breakPoints}
                autoTabIndexVisibleItems={false}
                tabItemContainerStyle={{ width: "100%" }}
              >
                {successResponseData.map((data, index) => (
                  <Tabs>
                    <Tab
                      key={index}
                      label={data.category}
                      id={data.id}
                      className={
                        activeIndex === index ? "tab active_item" : "tab"
                      }
                      onClick={() => changeTabColor(index)}
                    >
                      {data.category}
                    </Tab>
                  </Tabs>
                ))}
              </Carousel>
            </div>
            <hr className="mobile-hidden" style={{ marginTop: 0 }} />

            <List>
              {successResponseData.map((data, index) => (
                <Card
                  className="Services--content--wrapper"
                  key={index}
                  ref={scrollToId}
                  style={{
                    borderRadius: 0,
                    width: "100%",
                    opacity: 1,
                    backgroundColor: "white",
                    boxShadow: "0px 3px 35px #D4D4D480",
                    marginTop: "6%"
                  }}
                >
                  <Box padding={5} className="serContent">
                    <Grid
                      xs={12}
                      sm={12}
                      lg={4}
                      md={4}
                      className="mobile--image"
                      style={{ display: "none" }}
                    >
                      <div
                        className="estImg"
                        style={{
                          backgroundImage:
                            "url(" + data.service_category_banner + ")"
                        }}
                      ></div>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      lg={12}
                      md={12}
                      style={{
                        backgroundImage:
                          "linear-gradient(black, black),url(" +
                          data.service_category_banner +
                          ")"
                      }}
                      className="ser--bgimage"
                    >
                      <h1
                        key={data.category}
                        style={styles.title}
                        id={
                          window.innerWidth > 768
                            ? "simpleId"
                            : `titleOfScroll_${index}`
                        }
                      >
                        {data.category}
                      </h1>
                      <div
                        id={
                          window.innerWidth > 768
                            ? `titleOfScroll_${index}`
                            : "simpleId"
                        }
                        key={data.description}
                        style={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: data.description
                        }}
                      ></div>
                      <div key={data.category} style={styles.subheading}>
                        Services of {data.category}
                      </div>
                      <div className="serContent--inner">
                        {data.service_category.map((data, index) => (
                          <div className="service--inner" key={index}>
                            <div
                              key={data.service_title}
                              style={styles.listTitle}
                            >
                              {data.service_title}
                            </div>
                            <div
                              className="ser--knowmore"
                              style={styles.knowmore}
                            >
                              <Link
                                to={{
                                  pathname: "/productDetail/" + data.slug,
                                  // hash:"123",
                                  state: {
                                    id: data.id
                                  }
                                }}
                              >
                                Know more
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Box>
                </Card>
              ))}
            </List>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(BusinessServices)
);

let styles = {
  title: {
    fontFamily: "Lora",
    fontWeight: "medium",
    opacity: 1,
    color: "#141C49",
    fontStyle: "normal",
    fontSize: 40,
    padding: 0,
    margin: 0,
    marginBottom: 10
  },
  description: {
    color: "#585858",
    opacity: 1,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "Raleway",
    fontSize: 16,
    lineHeight: 1.4
  },
  subheading: {
    color: "#141C49",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Lora",
    fontSize: 26,
    opacity: 1,
    paddingTop: 22
  },
  listTitle: {
    color: "#585858",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "medium",
    fontSize: 16,
    paddingTop: 30
  },
  knowmore: {
    fontStyle: "normal",
    fontFamily: "Raleway",
    fontWeight: "medium",
    color: "#D06900",
    fontSize: 15,
    paddingTop: "10px",
    cursor: "pointer"
  },
  banner: {
    height: window.innerHeight,
    width: "100%",
    borderRadius: 0
  },
  questionImage: {
    borderRadius: 0,
    height: 18,
    width: 18
  },
  faq: {
    fontSize: 18,
    fontFamily: "Raleway",
    color: "#FFB300",
    fontStyle: "normal",
    fontWeight: "normal",
    paddingLeft: 10,
    cursor: "pointer"
  },
  select: {
    color: "#141C49",
    fontSize: 24,
    marginLeft: "17%",
    marginTop: 30,
    fontFamily: "Lora"
  },
  iamfxf: {
    width: "100%"
  }
};
