import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Add from "./../../../../Assets/icons/Group 1497.png";
import "./style.scss";
import Back from "./../../../../Assets/icons/back.png";
import PlusIcon from "./../../../../Assets/images/plus-icon.png";
import { withRouter } from "react-router-dom";
import { getCookie } from "../../../../Utils/cookies";
import { ifameDomain } from "./../../../../Utils/domain";
import { domain } from "./../../../../Utils/domain";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

export default class index extends Component {
  state = {
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  componentDidMount() {
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  render() {
    return (
      <div className="faq-details">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="faq-details__wrapper">
          <div className="faq-details__wrapper_block">
            <div className="faq-details__wrapper_block_top_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                Edit Home Page Content
              </p>
            </div>
          </div>
          <div style={{ paddingTop: "50px" }}>
            <iframe
              src={
                ifameDomain +
                "/admin/home/homestatic/1/change/?_to_field=id&_popup=1&auth=" +
                getCookie("token")
              }
              title="description"
              width="850"
              height="550"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}
