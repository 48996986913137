import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";

const Content = (props) => {
  const [getSignIn, setSignIn] = useState(false);

  useEffect(() => {
    if (props.location.pathname === "/signup") {
      setSignIn(false);
    } else {
      setSignIn(true);
    }
  }, []);
  const redirect = () => {
    props.history.push({
      pathname: "/signup",
      state: {
        redirect: props.redirect,
      },
    });
  };
  return (
    <div className="Login--left--wrapper">
      <Avatar
        src={require("./../../Assets/images/background.png")}
        alt=" "
        style={styles.image}
      />
      <div className="Login--left--content">
        <div style={styles.title}>Welcome!</div>
        <div style={styles.description}>
          Access easy to use and <br />
          customise legal document templates.
        </div>
        {getSignIn ? (
          <Button
            className="signupLogin"
            style={styles.button}
            variant="outlined"
            onClick={redirect}
          >
            Sign Up
          </Button>
        ) : (
          <Link to="/signin">
            <Button
              className="signupLogin"
              style={styles.button}
              variant="outlined"
            >
              Sign In
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default withRouter(Content);

let styles = {
  image: {
    height: window.innerHeight,
    width: "100%",
    borderRadius: 0,
  },
  content: {
    paddingLeft: 125,
    top: 200,
    bottom: 150,
    position: "absolute",
  },
  title: {
    fontSize: 30,
    fontFamily: "Raleway",
    color: "white",
  },
  description: {
    fontSize: 16,
    fontFamily: "Raleway",
    color: "white",
    textAlign: "inherit",
    marginTop: 30,
    lineHeight: 1.4,
  },
  button: {
    height: 56,
    width: 184,
    borderColor: "white",
    marginTop: 68,
    borderRadius: 0,
    fontFamily: "Raleway",
  },
  linkstyle: {
    textDecoration: "none",
    color: "white",
  },
};
