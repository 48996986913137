import React, { Component } from 'react'
import MetaFile from "./../../MetaFile";

export default class index extends Component {
    render() {
        return (
            <div>
                <MetaFile
                    meta_title="Test-Page"
                    meta_description="This is my test page to check"
                    meta_keywords="Test, Page"
                    other_meta_tags=""
                    />
                      <div className="Login--page" style={{marginTop : "150px"}}>
                         
                <p>Lorazepam, sold under the brand name Ativan among others, is a benzodiazepine medication. It is used to treat anxiety disorders, trouble sleeping, severe agitation, active seizures including status epilepticus, alcohol withdrawal, and chemotherapy-induced nausea and vomiting</p>
            </div>
            </div>
          
        )
    }
}
