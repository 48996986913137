import React, { Component } from "react";
import { domain } from "../../../Utils/domain";
import { getCookie } from "../../../Utils/cookies";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import "./styles.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "react-js-pagination";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
class BotLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      activePage: 1,
      totalItem: null,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  componentDidMount = () => {
    this.getChatData();
    this.props.updateBreadcrumbs("Bot Logs");
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getChatData() {
    fetch(
      domain +
        "/api/chatbot/chat_conversations_list/?page=" +
        this.state.activePage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }
  successResponse = response => {
    this.setState({
      chatList: response.results,
      totalItem: response.paginationTotal
    });
  };
  errorResponse = error => {};
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getChatData());
  }
  errorDetailsResponse = error => {};
  render() {
    return (
      <div>
        <Container>
          {this.state.meta_title !== "" && <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
        }
          <Grid xs={12} md={12} lg={12} sm={12} className="cblockBot">
            <div className="faq--breadcrumbs">
              <Breadcrumbs />
            </div>

            <Grid xs={12} sm={12} md={12} lg={12}>
              <h2 className="botLogHeading">Bot Logs</h2>
              <div className="customerCare__wrapper_block_orderlist">
                <TableContainer>
                  <Table className="orderTable" aria-label="simple table">
                    <TableHead>
                      <TableRow className="orderNo">
                        <TableCell>User Name </TableCell>
                        <TableCell>Email ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell> Time </TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>

                    <>
                      <TableBody>
                        {this.state.chatList &&
                          this.state.chatList.map((chatList, id) => {
                            var dateTime = new Date(chatList.created_on);
                            return (
                              <TableRow
                                key={id}
                                style={{
                                  background: chatList.seen ? "#f1f1f1" : "#fff"
                                }}
                              >
                                <TableCell>
                                  <p>
                                    {(chatList.first_name &&
                                      chatList.first_name.replace(
                                        /(^"|"$)/g,
                                        ""
                                      ).length > 1) ||
                                    (chatList.last_name &&
                                      chatList.last_name.replace(/(^"|"$)/g, "")
                                        .length > 1) ? (
                                      <div>
                                        {chatList.first_name.replace(
                                          /(^"|"$)/g,
                                          ""
                                        )}
                                        &nbsp;
                                        {chatList.last_name.replace(
                                          /(^"|"$)/g,
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      chatList.user_id.replace(/(^"|"$)/g, "")
                                    )}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <p>
                                    {chatList.email_id &&
                                    chatList.email_id.replace(/(^"|"$)/g, "")
                                      .length > 1 ? (
                                      <div>
                                        {chatList.email_id.replace(
                                          /(^"|"$)/g,
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </p>
                                </TableCell>
                                <TableCell>{`${dateTime.getDate()}- ${dateTime.getMonth() +
                                  1} - ${dateTime.getFullYear()}`}</TableCell>
                                <TableCell>{`${dateTime.getHours()}:${dateTime.getMinutes() +
                                  1}:${dateTime.getSeconds()}`}</TableCell>
                                <TableCell>
                                  <Link
                                    to={
                                      "/dashboard/BotLogs/BotChatDetails/" +
                                      chatList.user_id.replace(/(^"|"$)/g, "") +
                                      "/"
                                    }
                                  >
                                    <li>
                                      <span className="action">View</span>
                                    </li>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
                <Pagination
                  prevPageText="<"
                  nextPageText=">"
                  firstPageText=""
                  lastPageText=""
                  pageRangeDisplayed={5}
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalItem}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
export default withRouter(
  connect(state => ({}), { updateBreadcrumbs })(BotLogs)
);
