import React from "react";
import {
  Avatar,
  Button,
  Divider,
  InputBase,
  Paper,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Notification from "../../Assets/icons/nav/Path 32.png";
import { SearchApi } from "../../Pages/Website/WebServices/HomeApis";
// import { ServiceCategoriesAndLists } from '../../Pages/Website/WebServices/Services'
import { domain, defaultHeaders } from "../../Utils/domain";
import {
  AppBar,
  Toolbar,
  Typography,
  ListItem,
  List,
  Box
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import { ArrowBack, AssignmentInd, Home } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import { getCookie, setCookie } from "../../Utils/cookies";
import Ham from "../Menu/Ham";
import { updateProfileImg } from "./../../../src/redux/modules/app/actions";
import { updateNotificationCount } from "./../../../src/redux/modules/app/actions";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerColor: false,
      removeSignin: true,
      getSearchInput: false,
      getinput: false,
      ser_con_sug_com: "",
      ser_tit_sug_com: "",
      showList: false,
      searchListData: "",
      searchProductId: "",
      showModal: false,
      setName: false,
      services: [],
      services1: [],
      services2: [],
      services3: [],
      personalId: 2,
      businessId: 3,
      successResponseDetails: [],
      notifications: []
    };
    //  this.searchSuccessResponse = this.searchSuccessResponse.bind(this);
    //  this.serviceSuccessResponse = this.serviceSuccessResponse.bind(this);
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.getServices(2);
    this.getServices(3);
    this.getProfileDetails();
    this.getNotification();
    //this.getServices(4);
  };
  successDetails = responseDetails => {
    this.setState({ successResponseDetails: responseDetails });
  };

  getNotification = () => {
    fetch(domain + "/api/notification/list/?page=1&&page_size=10000000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.notiSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  };
  errorResponse = res => {};
  notiSuccessResponse = res => {
    if (res.results.length > 0) {
      var data = [];
      res.results.map((item, index) => {
        if (item.read === false) {
          data.push(item);
        }
      });
      this.props.updateNotificationCount(data.length);
    }
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (!this.state.headerColor) {
        this.setState({ headerColor: true });
      }
    } else {
      if (this.state.headerColor) {
        this.setState({ headerColor: false });
      }
    }
  };
  getProfileDetails() {
    let userId = getCookie("userId");
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successDetails(response))
      .catch(error => this.errorDetails(error));
  }
  getsearchresult(ser_tit_sug_com) {
    let scsc = this.state.ser_con_sug_com;
    let stsc = ser_tit_sug_com;
    scsc = stsc;
    // SearchApi(this.searchSuccessResponse,this.searchErrorResponse,scsc,stsc)
    fetch(
      domain +
        `/search/service/suggest/?service_content_suggest__completion=${scsc}&service_title_suggest__completion=${stsc}`,
      {
        method: "GET",
        headers: defaultHeaders
      }
    )
      .then(response => response.json())
      .then(responseJson => {
        this.searchSuccessResponse(responseJson);
      })
      .catch(error => {
        this.searchErrorResponse(error);
      });
  }

  searchSuccessResponse(response) {
    this.setState({
      searchListData: response,
      showList: true
    });
    if (response["service_title_suggest__completion"][0].options.length > 0) {
      this.setState({
        searchProductId:
          response["service_title_suggest__completion"][0].options[0]._source
            .slug
      });
    }
  }

  searchErrorResponse(error) {}

  goToProductDetail(e) {
    debugger;
    if (
      this.state.searchListData === "" ||
      this.state.searchListData["service_title_suggest__completion"][0].options
        .length <= 0
    ) {
      this.props.history.push("/search", {
        searchtext: this.state.ser_tit_sug_com
      });
    } else if (
      Object.keys(this.state.searchListData.service_content_suggest__completion)
        .length >= 1
    ) {
      this.props.history.push("/productDetail/" + this.state.searchProductId);
      // window.location.reload();
    }
  }

  getServices(id) {
    // ServiceCategoriesAndLists(this.serviceSuccessResponse,this.serviceErrorResponse,id);
    fetch(domain + `/api/services/categories-list2/${id}/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(response => this.serviceSuccessResponse(response, id))
      .catch(error => this.serviceErrorResponse(error));
  }

  serviceSuccessResponse(response, id) {
    if (id == 2) {
      this.setState({ services1: response });
    }
    if (id == 3) {
      this.setState({ services2: response });
    }
    if (id == 4) {
      this.setState({ services3: response });
    }
    return null;
  }

  serviceErrorResponse(error) {
    //alert(error);
  }
  closeModal = () => {
    this.setState({ showModal: false, getSearchInput: false });
  };
  errorDetails = error => {};

  render() {
    try {
      var searchElemet = document.getElementById("searchInput");
      searchElemet !== null &&
        searchElemet.addEventListener("keyup", event => {
          if (event.key === "Enter") {
            this.goToProductDetail();
          }
        });
    } catch (err) {}
    this.props.demo === false &&
      this.state.getSearchInput === true &&
      this.closeModal();
    this.props.demo === false &&
      this.state.showModal === true &&
      this.closeModal();
    if (
      this.props.location.pathname === "/testpage" ||
      this.props.location.pathname === "/signin" ||
      this.props.location.pathname === "/signup" ||
      this.props.location.pathname === "/forgotpassword" ||
      this.props.location.pathname === "/form" ||
      this.props.location.pathname === "/dashboard" ||
      this.props.location.pathname === "/dashboard/" ||
      this.props.location.pathname === "/dashboard/order-details" ||
      this.props.location.pathname === "/dashboard/orders" ||
      this.props.location.pathname === "/dashboard/customers" ||
      this.props.location.pathname ===
        "/dashboard/customers/customer-details" ||
      this.props.location.pathname === "/dashboard/chatLogsAdmin" ||
      this.props.location.pathname ===
        "/dashboard/chatLogsAdmin/chat-details" ||
      this.props.location.pathname === "/dashboard/reset-password" ||
      this.props.location.pathname === "/payment-status" ||
      this.props.location.pathname === "/dashboard/users" ||
      this.props.location.pathname === "/dashboard/users/create-users" ||
      this.props.location.pathname === "/dashboard/users/users-details" ||
      this.props.location.pathname === "/paymentDetails" ||
      this.props.location.pathname === "/dashboard/products/add-product" ||
      this.props.location.pathname === "/dashboard/products" ||
      this.props.location.pathname === "/dashboard/product/product-details" ||
      this.props.location.pathname === "/dashboard/chat-logs" ||
      this.props.location.pathname === "/dashboard/notifications" ||
      this.props.location.pathname === "/dashboard/faq-Details" ||
      this.props.location.pathname === "/dashboard/faqs" ||
      this.props.location.pathname === "/dashboard/profile" ||
      this.props.location.pathname === "/dashboard/subscription" ||
      this.props.location.pathname === "/PaymentSubscription" ||
      this.props.location.pathname === "/dashboard/edit-home-page" ||
      this.props.location.pathname === "/dashboard/edit-home-page/content" ||
      this.props.location.pathname === "/dashboard/edit-subscriptions" ||
      this.props.location.pathname === "/dashboard/faqs/que-ans" ||
      this.props.location.pathname === "/payments" ||
      this.props.location.pathname === "/privacy-policy" ||
      this.props.location.pathname === "/terms-conditions" ||
      this.props.location.pathname === "/userChat" ||
      window.location.href.includes("order-details") ||
      window.location.href.includes("paymentDetails") ||
      window.location.href.includes("PaymentSubscription") ||
      window.location.href.includes("chat-details") ||
      window.location.href.includes("BotChatDetails") ||
      window.location.href.includes("botlogs")
    ) {
      this.state.headerColor = true;
      this.state.removeSignin = false;
    } else {
      this.handleScroll();
      this.state.removeSignin = true;
    }
    try {
      return (
        <>
          <Box component="nav" onClick={this.closeModal}>
            <AppBar
              position="sticky"
              style={{
                backgroundColor:
                  this.state.headerColor === true ||
                  window.location.href.includes("agreement")
                    ? "white"
                    : "transparent",
                height: 125,
                width: "100%",
                position: "fixed",
                boxShadow:
                  this.state.headerColor === true ||
                  window.location.href.includes("agreement")
                    ? "0px 3px 6px #141C4912"
                    : "none",
                padding: "15px 0px"
              }}
            >
              <Container>
                <header padding="3">
                  <div className="fqaAndContact">
                    <div
                      className={
                        this.props.location.pathname === "/faq"
                          ? "faq active"
                          : "faq"
                      }
                    >
                      <Link
                        to="/faq"
                        style={{
                          textDecoration: "none",
                          color:
                            this.state.headerColor === true ||
                            window.location.href.includes("agreement") ||
                            window.location.href.includes("order-details") ||
                            window.location.href.includes("reset-password") ||
                            window.location.href.includes("dashboard")
                              ? "black"
                              : "white"
                        }}
                      >
                        FAQ
                      </Link>
                    </div>
                    <div
                      className={
                        this.props.location.pathname === "/contactus"
                          ? "contact active"
                          : "contact"
                      }
                    >
                      <Link
                        to="/contactus"
                        style={{
                          textDecoration: "none",
                          color:
                            this.state.headerColor === true ||
                            window.location.href.includes("agreement") ||
                            window.location.href.includes("order-details") ||
                            window.location.href.includes("reset-password") ||
                            window.location.href.includes("dashboard")
                              ? "black"
                              : "white"
                        }}
                      >
                        CONTACT
                      </Link>
                    </div>
                  </div>
                  <Box
                    display="flex"
                    component="secNav"
                    justifyContent="space-between"
                    className="headerW"
                  >
                    <IconButton className="logo">
                      <Link to="/">
                        {window.location.href.includes("agreement") ||
                        window.location.href.includes("order-details") ||
                        window.location.href.includes("reset-password") ||
                        window.location.href.includes("dashboard") ? (
                          <Avatar
                            src={require("./../../Assets/images/logo2.jpeg")}
                            className="Logo-main"
                          />
                        ) : (
                          <>
                            {this.state.headerColor === false ? (
                              <Avatar
                                src={require("./../../Assets/images/lani-logo.png")}
                                className="Logo-main Logo-mobile"
                              />
                            ) : (
                              <Avatar
                                src={require("./../../Assets/images/logo2.jpeg")}
                                className="Logo-main"
                              />
                            )}
                          </>
                        )}
                      </Link>
                    </IconButton>
                    <Ham dataProps={this.props.history} />

                    <Box className="headerNav" display="flex">
                      <ListItem
                        className={
                          this.props.location.pathname === "/personal"
                            ? "drop-down active"
                            : "drop-down"
                        }
                      >
                        <Link
                          to={{ pathname: "/personal", id: 2 }}
                          className="dropbtn"
                          style={{
                            color:
                              this.state.headerColor === true ||
                              window.location.href.includes("agreement") ||
                              window.location.href.includes("order-details") ||
                              window.location.href.includes("reset-password") ||
                              window.location.href.includes("dashboard")
                                ? "black"
                                : "white"
                          }}
                          onMouseEnter={e => {
                            e.stopPropagation();
                            this.setState({
                              showModal: false
                            });
                          }}
                        >
                          PERSONAL
                          <div class="dropdown-content">
                            {this.state.services1.map((data, index) => (
                              <div key={index} className="cardCol1">
                                <Link
                                  to={{
                                    pathname: "/personal/",
                                    state: {
                                      id: index
                                    }
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#141C49",
                                      fontWeight: 600,
                                      fontSize: 14,
                                      paddingTop: 12,
                                      marginBottom: 5,
                                      textTransform: "UPPERCASE",
                                      cursor: "pointer"
                                    }}
                                  >
                                    {data.category}
                                  </h5>
                                </Link>

                                <ul>
                                  {data.service_category.map(
                                    (products, index) => {
                                      return (
                                        <li>
                                          <Link
                                            key={index}
                                            to={{
                                              pathname:
                                                "/productDetail/" +
                                                products.slug,
                                              state: {
                                                id: products.id
                                              }

                                              //window.location.reload();
                                            }}
                                          >
                                            <a
                                              key={index}
                                              style={{
                                                fontSize: 14,
                                                marginBottom: 6,
                                                color: "#585858",
                                                display: "inline-block",
                                                fontFamily: "Raleway"
                                              }}
                                            >
                                              {products.service_title}
                                            </a>
                                          </Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </Link>
                        <div className="arrows">
                          <Avatar
                            src={require("./../../Assets/images/cheveron-down.png")}
                            style={styles.image}
                          />
                        </div>
                      </ListItem>
                      <ListItem
                        className={
                          this.props.location.pathname === "/business"
                            ? "drop-down active"
                            : "drop-down"
                        }
                      >
                        <Link
                          to={{ pathname: "/business", id: 3 }}
                          style={{
                            color:
                              this.state.headerColor === true ||
                              window.location.href.includes("agreement") ||
                              window.location.href.includes("order-details") ||
                              window.location.href.includes("reset-password") ||
                              window.location.href.includes("dashboard")
                                ? "black"
                                : "white"
                          }}
                          onMouseEnter={e => {
                            e.stopPropagation();
                            this.setState({
                              showModal: false
                            });
                          }}
                        >
                          BUSINESS
                          <div class="dropdown-content">
                            {this.state.services2.map((data, index) => (
                              <div key={index} className="cardCol1">
                                <Link
                                  to={{
                                    pathname: "/business/",
                                    state: {
                                      id: index
                                    }
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#141C49",
                                      fontWeight: 600,
                                      fontSize: 14,
                                      paddingTop: 12,
                                      marginBottom: 5,
                                      textTransform: "UPPERCASE"
                                    }}
                                  >
                                    {data.category}
                                  </h5>
                                </Link>
                                <ul>
                                  {data.service_category.map(
                                    (products, index) => (
                                      <li>
                                        <Link
                                          key={index}
                                          to={{
                                            pathname:
                                              "/productDetail/" + products.slug,
                                            state: {
                                              id: products.id
                                            }
                                          }}
                                        >
                                          <a
                                            key={index}
                                            style={{
                                              fontSize: 14,
                                              marginBottom: 6,
                                              color: "#585858",
                                              display: "inline-block",
                                              fontFamily: "Raleway"
                                            }}
                                          >
                                            {products.service_title}
                                          </a>
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </Link>
                        <div className="arrows">
                          <Avatar
                            src={require("./../../Assets/images/cheveron-down.png")}
                            style={styles.image}
                          />
                        </div>
                      </ListItem>
                      {/*<ListItem className={this.props.location.pathname === "/categoryDetail" ? "drop-down active" : "drop-down"}>
                      <Link
                        to={{ pathname: "/categoryDetail", id: 4 }}
                        style={{
                          color:
                            this.state.headerColor === true ||
                            window.location.href.includes("agreement") ||
                            window.location.href.includes("order-details") ||
                            window.location.href.includes("reset-password") ||
                            window.location.href.includes("dashboard")
                              ? "black"
                              : "white",
                        }}
                        onMouseEnter={(e) => {
                          e.stopPropagation();
                          this.setState(
                            {
                              showModal: false,
                            }
                          );
                        }}
                      >
                        CORPORATE FILINGS
                        <div class="dropdown-content">
                          {this.state.services3.map((data, index) => (
                            <div key={index} className="cardCol1">
                             <h5
                                style={{
                                  color: "#141C49",
                                  fontWeight: 600,
                                  fontSize: 14,
                                  paddingTop: 12,
                                  marginBottom: 5,
                                  textTransform: "UPPERCASE",
                                }}
                              >
                                {data.category}
                              </h5>
                              <ul>
                                {data.service_category.map(
                                  (products, index) => (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        this.props.history.push(
                                          `/productDetail:${products.id}`
                                        );

                                        window.location.reload();
                                      }}
                                    >
                                      <a
                                        key={index}
                                        style={{
                                          fontSize: 14,
                                          marginBottom: 6,
                                          color: "#585858",
                                          display: "inline-block",
                                          fontFamily: "Raleway",
                                        }}
                                      >
                                        {products.service_title}
                                      </a>
                                    </div>
                                  )
                                )}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </Link>
                      <div className="arrows">
                        <Avatar
                          src={require("./../../Assets/images/cheveron-down.png")}
                          style={styles.image}
                        />
                      </div>
                    </ListItem>*/}
                      <ListItem>
                        {getCookie("userId") === "" && this.state.removeSignin && (
                          <Button style={styles.button}>
                            <Link to="/signin" style={styles.linktext}>
                              SIGN IN
                            </Link>
                          </Button>
                        )}
                        {getCookie("userId") !== "" && (
                          <>
                            <Link
                              to="/dashboard/"
                              // onClick={() => {
                              //   window.location.href = "/dashboard";
                              // }}
                            >
                              <Avatar
                                src={this.props.profileImg}
                                className="profileImageIcon"
                              />
                            </Link>
                            <p
                              className="profileNameColor"
                              style={{
                                color:
                                  this.state.headerColor === true ||
                                  window.location.href.includes("agreement") ||
                                  window.location.href.includes(
                                    "order-details"
                                  ) ||
                                  window.location.href.includes("dashboard")
                                    ? "black"
                                    : "white"
                              }}
                            >
                              {getCookie("logedinUser").toUpperCase()}
                            </p>
                          </>
                        )}
                      </ListItem>
                      {/* <ListItem>
                      {this.state.getSearchInput && (
                        <Paper style={styles.paper} className="dropdownModel">
                          <InputBase
                            variant="outlined"
                            placeholder="Search legal services..."
                            style={{
                              flex: 1,
                              marginLeft: 14,
                              fontFamily: "Raleway",
                              fontSize: 12,
                            }}
                            value={this.state.ser_tit_sug_com}
                            onClick={(e)=>{e.stopPropagation()}}
                            onChange={(e) =>{
                              e.stopPropagation();
                              this.setState(
                                { ser_tit_sug_com: e.target.value },
                                () =>
                                  this.getsearchresult(
                                    this.state.ser_tit_sug_com
                                  )
                              )
                                  }
                            }
                          />
                          <Divider className="divider" orientation="vertical" />
                          <IconButton
                            onClick={() => this.goToProductDetail()}
                            style={styles.searchImageBackground}
                          >
                            <Avatar
                              src={require("./../../Assets/images/search.png")}
                              style={{ height: 16, width: 16, borderRadius: 0 }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => this.setState({getSearchInput:false})}

                          >
                           x
                          </IconButton>
                        </Paper>
                      )}
                      {this.state.headerColor === false ? (
                        this.state.getSearchInput === true ? (
                          ""
                        ) : (
                          <Avatar
                            src={require("./../../Assets/images/search.png")}
                            onClick={(e) =>{
                              e.stopPropagation();
                              this.setState({ getSearchInput: true });
                            }
                            }
                            style={styles.searchimage}
                          />
                        )
                      ) : this.state.getSearchInput === true ? (
                        ""
                      ) : (
                        <Avatar
                          src={require("./../../Assets/images/search2.svg")}
                          onClick={(e) =>{
                            e.stopPropagation();
                            this.setState({ getSearchInput: true })
                          }
                          }
                          style={styles.searchimage}
                        />

                      )}
                    </ListItem> */}
                      {getCookie("userId") !== "" && (
                        <IconButton className="notifications--btn--wrapper">
                          <Link
                            to="/dashboard/notifications"
                            // onClick={() => {
                            //   window.location.href = "/dashboard/notifications";
                            // }}
                          >
                            {window.location.href.includes("agreement") ||
                            window.location.href.includes("order-details") ||
                            window.location.href.includes("reset-password") ||
                            window.location.href.includes("dashboard") ? (
                              <Avatar
                                src={require("./../../Assets/icons/nav/Path 32.png")}
                                style={styles.searchimage}
                                className="notifications--img"
                              />
                            ) : (
                              <>
                                {this.state.headerColor === false ? (
                                  <Avatar
                                    src={require("./../../Assets/icons/nav/notification-white.png")}
                                    style={styles.searchimage}
                                    className="notifications--img"
                                  />
                                ) : (
                                  <Avatar
                                    src={require("./../../Assets/icons/nav/Path 32.png")}
                                    style={styles.searchimage}
                                    className="notifications--img"
                                  />
                                )}
                              </>
                            )}
                          </Link>
                          {this.props.notiCount !== "0" && (
                            <span className="notificationNumber" style={{display: this.props.notiCount == 0 ? 'none' : 'block' }}>
                              {this.props.notiCount == 0
                                ? ""
                                : this.props.notiCount}
                            </span>
                          )}
                        </IconButton>
                      )}
                      <ListItem
                        style={{
                          paddingLeft:
                            this.props.location.pathname === "/signin" ? 50 : ""
                        }}
                      >
                        {this.state.getSearchInput && (
                          <Paper style={styles.paper} className="dropdownModel">
                            <InputBase
                              variant="outlined"
                              placeholder="Search legal services..."
                              style={{
                                flex: 1,
                                marginLeft: 14,
                                fontFamily: "Raleway",
                                fontSize: 12
                              }}
                              id="searchInput"
                              value={this.state.ser_tit_sug_com}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              onChange={e => {
                                e.stopPropagation();
                                this.setState(
                                  { ser_tit_sug_com: e.target.value },
                                  () =>
                                    this.getsearchresult(
                                      this.state.ser_tit_sug_com
                                    )
                                );
                              }}
                            />
                            <Divider
                              className="divider"
                              orientation="vertical"
                            />
                            <IconButton
                              id="myBtn"
                              onClick={() => this.goToProductDetail()}
                              style={styles.searchImageBackground}
                            >
                              <Avatar
                                src={require("./../../Assets/images/search.png")}
                                style={{
                                  height: 16,
                                  width: 16,
                                  borderRadius: 0
                                }}
                              />
                            </IconButton>
                            {/*}<IconButton
                          onClick={() => this.setState({getSearchInput:false})}

                        >
                         x
                        </IconButton>*/}
                          </Paper>
                        )}
                        {this.state.headerColor === false ? (
                          this.state.getSearchInput === true ? (
                            ""
                          ) : (
                            <Avatar
                              src={require("./../../Assets/images/search.png")}
                              onClick={e => {
                                e.stopPropagation();
                                this.setState({ getSearchInput: true });
                              }}
                              style={styles.searchimage}
                            />
                          )
                        ) : this.state.getSearchInput === true ? (
                          ""
                        ) : (
                          <Avatar
                            src={require("./../../Assets/images/search2.svg")}
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({ getSearchInput: true });
                            }}
                            style={styles.searchimage1}
                          />
                        )}
                      </ListItem>
                    </Box>
                    {this.state.showModal && (
                      <Paper
                        className="modalPop"
                        open={this.state.showModal}
                        style={styles.modal}
                      >
                        <div
                          onClick={() => this.setState({ showModal: false })}
                        >
                          <Avatar
                            src={require("../../Assets/images/wrong.png")}
                            style={{
                              borderRadius: 0,
                              height: 14,
                              width: 14,
                              position: "absolute",
                              right: 30,
                              top: 10,
                              cursor: "pointer"
                            }}
                          />
                        </div>
                        <div
                          className="cardInner"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            padding: "25px 35px",
                            justifyContent: "space-between"
                          }}
                        >
                          {this.state.services.map((data, index) => (
                            <div key={index} className="cardCol">
                              <li>
                                <div
                                  onClick={() => {
                                    this.props.history.push("/productDetail", {
                                      id: data.id
                                    });

                                    window.location.reload();
                                  }}
                                >
                                  {data.category}
                                </div>
                              </li>
                            </div>
                          ))}
                        </div>
                      </Paper>
                    )}
                  </Box>
                </header>
              </Container>
            </AppBar>
          </Box>
        </>
      );
    } catch (err) {}
  }
}

export default withRouter(
  connect(
    state => ({
      profileImg: state.app.profileImg,
      notiCount: state.app.notificationCount
    }),
    { updateProfileImg, updateNotificationCount }
  )(Header)
);

let styles = {
  Logo: {
    // height: auto,
    width: 180,
    borderRadius: 0
  },
  data: {
    marginLeft: "70%",
    display: "flex"
  },
  sidebysidelist: {
    display: "flex"
  },
  image: {
    borderRadius: 0,
    width: 10,
    height: 6,
    marginLeft: 13,
    marginTop: 25
  },

  button: {
    backgroundColor: "#D06900",
    height: 40,
    width: 110,
    fontFamily: "Raleway",
    textTransform: "inherit",
    fontSize: 12,
    borderRadius: 0,
    marginLeft: 46,
    marginTop: 5
  },
  linktext: {
    textDecoration: "none",
    color: "white",
    fontSize: 14
  },
  searchimage: {
    borderRadius: 0,
    height: 24,
    width: 24,
    marginLeft: 150,
    marginTop: 15
  },
  searchimage1: {
    borderRadius: 0,
    height: 24,
    width: 24,
    opacity: 0.6
  },
  paper: {
    display: "flex",
    width: 240,
    height: 40,
    backgroundColor: "white",
    marginLeft: 20,
    borderRadius: 0,
    marginTop: 4,
    boxShadow: "0px 3px 10px #283891D1"
  },
  sidebysidelist: {
    display: "flex"
  },
  image: {
    borderRadius: 0,
    width: 10,
    height: 6,
    marginLeft: 13
  },

  button: {
    backgroundColor: "#D06900",
    height: 40,
    width: 110,
    fontFamily: "Raleway",
    textTransform: "inherit",
    fontSize: 12,
    borderRadius: 0
  },
  linktext: {
    textDecoration: "none",
    color: "white",
    fontSize: 14
  },
  searchimage: {
    borderRadius: 0,
    height: 24,
    width: 24
  },
  paper: {
    display: "flex",
    width: 240,
    height: 40,
    backgroundColor: "white",
    marginLeft: 20,
    borderRadius: 0,
    marginTop: 0,
    boxShadow: "0px 3px 10px #283891D1"
  },
  searchImageBackground: {
    backgroundColor: "#D06900",
    borderRadius: 0,
    width: 40
  },
  modal: {
    width: "60%",
    backgroundColor: "white",
    border: "1px solid #00000029",
    borderRadius: 0,
    right: 0,
    top: "94%",
    position: "absolute",
    height: "336px",
    overflowY: "scroll"
  }
};
