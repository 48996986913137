import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./style.scss";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class index extends Component {
  state = {
    orders: [],
    filterValue: [],
    resultArr: {},
    showOrders: false,
    storeOrders: []
  };
  componentDidMount() {
    this.props.updateBreadcrumbs("orders");
    let token = getCookie("token");
    // getOrdersList(this.successResponse,this.errorResponse)
    fetch(domain + "/api/order/list/" + this.props.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    // console.log(response,"response");
    this.setState(
      {
        orders: response.results,
        storeOrders: response.results
      },
      () => {
        console.log(this.state.orders, "orders");
        if (this.state.orders && this.state.orders !== "") {
          this.setState({ showOrders: true });
        }
        // else if( this.state.orders.length !== '' ){
        //     this.setState({ showOrders : true })
        // }
        // }
      }
    );
  };

  errorResponse = error => {
    // console.log(error, "eror");
  };
  ChangeDateFormat = orderDate => {
    let format_date = new Date(orderDate);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let datestring =
      ("0" + format_date.getDate()).slice(-2) +
      " " +
      monthNames[format_date.getMonth()] +
      " " +
      format_date.getFullYear();
    format_date.toLocaleString().replace(/\//g, "-");
    return datestring;
  };

  filterResult = (value, id) => {
    //
    const currentClass = document.getElementsByClassName("filter");
    // console.log(currentClass,"consoel")
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("filter-active");
    }
    const element = document.getElementById(value);
    element.classList.add("filter-active");

    if (value === "All") {
      this.setState({
        orders: this.state.storeOrders
      });
    } else {
      let filterValue = this.state.storeOrders.filter(
        item => item.status === value
      );
      // console.log(filterValue);
      this.setState({
        orders: filterValue
      });
    }
  };
  performSearch = e => {
    //  ;
    if (e.length >= 3) {
      let filtered = this.state.orders.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });
      this.setState({
        orders: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        orders: this.state.storeOrders
      });
    }
  };

  render() {
    return (
      <div>
        <div>
          <div className="orders__wrapper_block_top_search">
            <input
              onChange={event => this.performSearch(event.target.value)}
              name="search"
              placeholder="search..."
            />
            <span className="orders__wrapper_block_top_search_icon">
              <FaSearch />
            </span>
          </div>
        </div>
        <Container className="orderContainer">
          {this.state.showOrders === true && (
            <Grid xs={12} sm={12}>
              {this.state.orders.map(order => (
                <div className="ordeR" key={order.number}>
                  <div className="orderData">
                    <div className="orderNo">
                      <p>Order No.</p>

                      <p>{order.number}</p>
                    </div>
                    <div className="datE">
                      <p>Date</p>
                      <TableCell>{this.ChangeDateFormat(order.date)}</TableCell>
                      {/* <p>17-11-2020</p> */}
                    </div>
                    <div className="statuS">
                      <p>Status</p>
                      <p>{order.status}</p>
                    </div>
                  </div>
                  <div className="payData">
                    <div className="iteM">
                      <p>ITEM</p>
                      <p>{order.item}</p>
                    </div>
                    <div className="amounT">
                      <p>Amount</p>
                      <p>{order.amount}</p>
                    </div>
                  </div>
                  <div className="linkData">
                    <Link
                      to={
                        "/dashboard/order-details/" +
                        order.number +
                        "/" +
                        order.item
                      }
                    >
                      <li>
                        <span className="action">View</span>
                      </li>
                    </Link>
                  </div>
                </div>
              ))}
            </Grid>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
