import ChatComponent from "../../../Components/ChatComponent";
import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import "./style.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getCookie, setCookie } from "../../../Utils/cookies";
import { domain } from "../../../Utils/domain";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactStore: [],
      searchValue: null,
      chatData: [],
      responseOfUser: [],
      chatLogsData: [],
      selectValue: "",
      activePage: 1,
      totalItem: null,
      loader: false,
      queryName: "page",
      allOrdersData: []
    };
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Chats");
    this.getData();
    if (
      localStorage.getItem("allChatsData") === null ||
      localStorage.getItem("allChatsData") === undefined ||
      localStorage.getItem("allChatsData").length === 0
    ) {
      setTimeout(() => {
        this.state.totalItem !== null && this.getDataForSearch();
      }, 5000);
    } else {
      this.setState({
        allOrdersData: JSON.parse(localStorage.getItem("allChatsData"))
      });
    }
    window.scrollTo(0, 0);
  };
  getData() {
    fetch(
      domain +
        "/adminlist_chat/?" +
        this.state.queryName +
        "=" +
        this.state.activePage +"&page_size=20",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));
  }

  successResponseFetch = res => {
    var dataInput = [];
    for (var split in res.chatlog_conversations) {
      const lengthData = res.chatlog_conversations[split].messages;
      dataInput.push({
        id: res.chatlog_conversations[split].recipients[0].id,
        name: res.chatlog_conversations[split].recipients[0].name,
        sender: res.chatlog_conversations[split].messages[0].sender,
        senderName: res.chatlog_conversations[split].messages[0].senderName,
        date:
          res.chatlog_conversations[split].messages[lengthData.length - 1]
            .messageSentDateTime,
        status: res.chatlog_conversations[split].recipients[0].status
      });
    }

    this.setState({
      chatLogsData: res.results,
      chatStore: res.results,
      activePage: res.paginationCurrent,
      storeOrders: dataInput,
      totalItem: res.paginationTotal,
      loader: false
    });
  };
  ChangeDateFormat = orderDate => {
    if (orderDate != "" && orderDate != null) {
      let format_date = new Date(orderDate);
      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let datestring =
        ("0" + format_date.getDate()).slice(-2) +
        " " +
        monthNames[format_date.getMonth()] +
        " " +
        format_date.getFullYear();
      format_date.toLocaleString().replace(/\//g, "-");
      return datestring;
    }
  };
  errorResponse = error => {};

  filterResult(value, id) {
    this.setState(
      {
        queryName: "status",
        activePage: value
      },
      () => {
        const currentClass = document.getElementsByClassName("filter");

        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("filter-active");
        }
        const element = document.getElementById(value);
        element.classList.add("filter-active");
        this.getData();
      }
    );
  }
  getDataForSearch() {
    fetch(
      domain + `/adminlist_chat/?page=1&&page_size=${this.state.totalItem}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.searchSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  }
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allChatsData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        loader: false
      });
    }
  }
  clearFilterForSearch = value => {
    const currentClass = document.getElementsByClassName("filter");
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i].classList.remove("filter-active");
      }
      const element = document.getElementById(value);
      element.classList.add("filter-active");
  }
  performSearch = e => {
    if (e.length >= 3) {
      this.clearFilterForSearch("All"); 
      let filtered = this.state.allOrdersData.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });

      this.setState({
        chatLogsData: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        chatLogsData: this.state.chatStore
      },()=>{
        this.filterResult("All");
        this.clearFilterForSearch("All") 

      });
    }
  };

  changeRefresh = () => {
    this.state.chatLogsData.sort((a, b) => {
      return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
    });
    // this.setState({ refresh: true, orderData: true });
  };
  changeRefreshDate = () => {
    this.state.chatLogsData.sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
    //this.setState({ refresh: true, orderData: false });
  };
  refreshMethod = () => {
    this.state.chatLogsData.sort((a, b) => {
      var aDate = new Date(a.date);
      var bDate = new Date(b.date);
      return aDate < bDate ? 1 : -1;
    });
    this.state.storeOrders.sort((a, b) => {
      var aDate = new Date(a.date);
      var bDate = new Date(b.date);
      return aDate < bDate ? 1 : -1;
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getData());
  }
  render() {
    this.state.selectValue === "id" && this.changeRefresh();

    this.state.selectValue === "name" && this.changeRefreshDate();

    this.state.selectValue === "recent" && this.refreshMethod();

    return (
      <div className="customerCare">
        <div className="customerCare__wrapper">
          <div className="customerCare__wrapper_block">
            <div className="customerCare__wrapper_block_top">
              <div className="customerCare__wrapper_block_top_heading">
                <p>Chat Logs</p>
              </div>
              <div className="customerCare__wrapper_block_top_search">
                <input
                  onChange={event => this.performSearch(event.target.value)}
                  name="search"
                  placeholder="search..."
                />
                <span className="orders__wrapper_block_top_search_icon">
                  <FaSearch />
                </span>
              </div>
            </div>

            <ul className="customerCare__wrapper_block_top_filter">
              <li
                id="All"
                className=" filter filter-active"
                onClick={() => this.filterResult("All")}
              >
                All
              </li>
              <li
                id="incomplete"
                className="filter"
                onClick={() => this.filterResult("incomplete")}
              >
                Inprogress
              </li>
              <li
                id="escalate"
                className="filter"
                onClick={() => this.filterResult("escalate")}
              >
                Escalated
              </li>
              <li
                id="complete"
                className="filter"
                onClick={() => this.filterResult("complete")}
              >
                Completed
              </li>
              <li className="filter mobileView">
                <span className="sortLabel">Sortby</span>
                <select
                  className="selectDropdown"
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option className="optionValue" value="recent">
                    Recent
                  </option>
                  <option className="optionValue" value="name">
                    Name
                  </option>
                  <option className="optionValue" value="id">
                    Id
                  </option>
                </select>
              </li>
            </ul>

            <div className="customerCare__wrapper_block_orderlist">
              <TableContainer>
                <Table className="orderTable" aria-label="simple table">
                  <TableHead>
                    <TableRow className="orderNo">
                      <TableCell>Date & Time</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>User Id</TableCell>
                      <TableCell>Customer Email</TableCell>
                      <TableCell>Responder Name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <>
                    <TableBody>
                      {this.state.chatLogsData !== null &&
                        this.state.chatLogsData !== undefined &&
                        this.state.chatLogsData.map(order => {
                          // console.log(order)
                          var dateTime = new Date(order.date);
                          return (
                            <TableRow key={order.id}>
                              <TableCell>
                                {this.ChangeDateFormat(order.created)}
                              </TableCell>
                              <TableCell>
                                {order.first_name + " " + order.last_name}
                              </TableCell>
                              <TableCell>{order.owner}</TableCell>
                              <TableCell>{order.customer_email}</TableCell>
                              <TableCell>{order.opponent}</TableCell>
                              <TableCell>
                                <span className="completedClass">
                                  {order.complete
                                    ? "Completed"
                                    : order.escalate
                                    ? "Escalated"
                                    : order.incomplete
                                    ? "Inprogress"
                                    : "NA"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={
                                    "/dashboard/chatLogsAdmin/chat-details/" +
                                    order.id +
                                    "/"
                                  }
                                >
                                  <li>
                                    <span className="action">View</span>
                                  </li>
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
       {this.state.totalItem && <div className="pagination-box">
          <Pagination
            prevPageText="<"
            nextPageText=">"
            firstPageText=""
            lastPageText=""
            pageRangeDisplayed={5}
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.totalItem}
            onChange={this.handlePageChange.bind(this)}
          />
          <span className="total-page">Page No. {this.state.activePage} of {Math.ceil(this.state.totalItem/20)}</span>

        </div>
        }
        
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
