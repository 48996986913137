import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Link, withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { domain, defaultHeaders } from "../../Utils/domain";
import "./styles.scss";
import { responsiveFontSizes } from "@material-ui/core";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      services1: [],
      services2: [],
      services3: [],
      services4: [],
      discription : "",
      fb_link : " ",
      insta_link: " ",
      twit_link: " ",
      uTube_link: " ",
      linkd_link: " ",
      disclaimer : ""
    };
  }

  componentDidMount = () => {
    this.getServices(2);
    this.getServices(3);
    this.getSocialMedia()
  };

  getSocialMedia = () => {
    fetch(domain + `/api/home/footer/`, {
      method: "GET",
      headers: defaultHeaders,
    })
      .then((response) => response.json())
      .then((response) => this.successSocialResponse(response))
      .catch((error) => this.serviceErrorResponse(error));
  }
  successSocialResponse = response => {
    this.setState({
      discription : response[0].description,
      fb_link :response[0].fb_link,
      insta_link: response[0].instagram_link,
      twit_link: response[0].twitter_link,
      uTube_link: response[0].youtube_link,
      linkd_link: response[0].linkedn_link,
      disclaimer: response[0].footer_disclaimer
    })
  }
  getServices(id) {
    fetch(domain + `/api/services/footer_service/`, {
      method: "GET",
      headers: defaultHeaders,
    })
      .then((response) => response.json())
      .then((response) => this.serviceSuccessResponse(response, id))
      .catch((error) => this.serviceErrorResponse(error));
  }

  serviceSuccessResponse(response, id) {
     this.setState({
       services1:response[0]['services1'],
       services2:response[0]['services2'],
       services3:response[1]['services1'],
       services4:response[1]['services2']
     });
    return null;
  }

  serviceErrorResponse(error) {
    //alert(error);
  }

  render() {

    return (
      <div style={styles.container}>
        <Container className="Footer--wrapper">
          <div style={styles.content}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} className="Footer--Logo">
                <div>
                  <Link>
                    <Avatar
                      src={require("./../../Assets/images/lani-logo.png")}
                      style={styles.image}
                    />
                  </Link>
                  <div style={styles.description} dangerouslySetInnerHTML={{ __html: this.state.discription }}></div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <div className="disclaimer-block" dangerouslySetInnerHTML={{ __html: this.state.disclaimer }}>

                </div>

              </Grid>
              <Grid className="footerStatic" xs={12} sm={12} md={4} lg={4}>
                <div className="staticPages">
                  <Link style={styles.title5} to="/faq">
                    <div style={styles.title5}>FAQ</div>
                  </Link>

                  <Link style={styles.title5} to="/contactus">
                    <div style={styles.title5}>CONTACTS</div>
                  </Link>

                  {/* <Link style={styles.title5} to="/payments">
                    <div style={styles.title5}>PAYMENTS</div>
                  </Link> */}
                  <Link style={styles.title5} to="/privacy-policy" >
                    <div style={styles.title5}>PRIVACY POLICY</div>
                  </Link>

                  <Link style={styles.title5} to="/terms-conditions">
                    <div style={styles.title5}>TERMS AND CONDITIONS</div>
                  </Link>
                </div>
                <div style={styles.socialImagesInRow} className="social--icons">
                  <a  href={this.state.fb_link} target="_blank">
                    <Avatar
                      src={require("./../../Assets/images/facebook.png")}
                      style={styles.fbimage}
                    />
                  </a>
                  <a  href={this.state.twit_link} target="_blank">
                    <Avatar
                      src={require("./../../Assets/images/twitter.png")}
                      style={styles.Twi_Lin_image}
                    />
                  </a>

                  <a href={this.state.linkd_link} target="_blank">
                    <Avatar
                      src={require("./../../Assets/images/linkedin.png")}
                      style={styles.Twi_Lin_image}
                    />
                  </a>

                  <a  href={this.state.uTube_link} target="_blank">
                    <Avatar
                      src={require("./../../Assets/images/youtube.png")}
                      style={styles.Twi_Lin_image}
                    />
                  </a>

                  <a  href={this.state.insta_link} target="_blank">
                    <Avatar
                      src={require("./../../Assets/images/insta.png")}
                      style={styles.Twi_Lin_image}
                    />
                  </a>
                </div>
              </Grid>
            </Grid>

          </div>

                      <Grid container xs={12} sm={12} md={12} lg={12} className="Footer--new--categories">
                        <Grid item xs={12} sm={12} md={3} lg={3} className="divMainfooter" >
                          <div>
                            <Link
                              style={styles.title1}
                              to={{ pathname: "/categoryDetail", id: 2 }}
                            >
                              PERSONAL
                            </Link>
                            <Link>
                            {this.state.services1.map((data, index) => (
                                <span key={index}>

                                      <div
                                        className="footerDiv"
                                        key={index}
                                       
                                      >
                                        <Link to={{
                                          pathname : "/productDetail/" +(data.slug),
                                            state : {
                                              id : data.id
                                            }
                                           
                                          }}>
                                        <a key={index}>{data.service_title}</a>
                                        </Link>
                                      </div>

                                </span>
                              ))}
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} className="divMainfooter" style={{paddingTop:'20px'}} >
                          <div>
                            <Link
                              style={styles.title1}
                            >
                            </Link>
                            <Link>
                            {this.state.services2.map((data, index) => (
                                <span key={index}>

                                      <div
                                        className="footerDiv"
                                        key={index}
                                      >
                                         <Link to={{
                                          pathname : "/productDetail/" +(data.slug),
                                            state : {
                                              id : data.id
                                            }
                                           
                                          }}>
                                        <a key={index}>{data.service_title}</a>
                                        </Link>
                                      </div>

                                </span>
                              ))}
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} className="divMainfooter">
                          <div>
                            <Link className="mainHover"
                              style={styles.title1}
                              to={{ pathname: "/categoryDetail", id: 3 }}
                            >
                              BUSINESS

                            </Link>
                            <Link>
                            {this.state.services3.map((data, index) => (
                                <div key={index}>

                                      <div
                                        className="footerDiv"
                                        key={index}
                                       >
                                          <Link to={{
                                          pathname : "/productDetail/" +(data.slug),
                                            state : {
                                              id : data.id
                                            }
                                           
                                          }}>
                                        <a key={index}>{data.service_title}</a>
                                        </Link>
                                      </div>

                                </div>
                              ))}
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} className="divMainfooter" style={{paddingTop:'20px'}} >
                          <div>
                            <Link className="mainHover"
                              style={styles.title1}
                            >

                            </Link>
                            <Link>
                            {this.state.services4.map((data, index) => (
                                <div key={index}>

                                      <div
                                        className="footerDiv"
                                        key={index}
                                        
                                      >
                                        <Link to={{
                                          pathname : "/productDetail/" +(data.slug),
                                            state : {
                                              id : data.id
                                            }
                                           
                                          }}>
                                        <a key={index}>{data.service_title}</a>
                                        </Link>
                                      </div>

                                </div>
                              ))}
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
          <hr style={styles.horizontalLine} />
          <div style={styles.footercaption}>
            © Copyright 2021 All Rights Reserved
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Footer);

let styles = {
  container: {
    width: "100%",
    backgroundColor: "#2D314A",
    padding: "80px 0px 30px",
  },
  content: {
    flex: 1,
    justifyContent: "space-between",
    display: "flex",
  },
  footercaption: {
    fontSize: 13,
    fontFamily: "Raleway",
    opacity: 0.59,
    color: "white",
    textAlign: "center",
    marginTop: 30,
    letterSpacing:'1px'
  },
  image: {
    height: 50,
    width: 170,
    borderRadius: 0,
  },
  description: {
    color: "white",
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 18,
    opacity: 0.6,
    fontFamily: "Raleway",
  },
  socialImagesInRow: {
    display: "flex",
    marginTop: 20,
  },
  fbimage: {
    height: 35,
    width: 35,
  },
  Twi_Lin_image: {
    height: 35,
    width: 35,
    marginLeft: 13,
  },
  imageBackground: {
    height: 35,
    width: 35,
    backgroundColor: "white",
    borderRadius: 17.5,
    marginLeft: 13,
  },
  youtubeimage: {
    height: 16,
    width: 22,
    marginLeft: 7,
    marginTop: 10,
    borderRadius: 0,
  },
  instaImage: {
    height: 20,
    width: 20,
    margin: 8,
    borderRadius: 0,
  },
  horizontalLine: {
    opacity: 0.2,
    marginTop: "5%",
    color: "white",
  },
  title1: {
    fontFamily: "Raleway-Regular",
    fontSize: 15,
    opacity: 0.8,
    color: "white",
    display: "block",
    paddingBottom: 5,
  },
  title2: {
    fontFamily: "Raleway",
    fontSize: 12,
    opacity: 0.6,
    color: "white",
    marginTop: 20,
  },
  title3: {
    fontFamily: "Raleway",
    fontSize: 12,
    opacity: 0.6,
    color: "white",
    marginTop: 11,
  },
  title4: {
    fontFamily: "Raleway",
    fontSize: 15,
    opacity: 0.6,
    color: "white",
  },
  title5: {
    fontFamily: "Raleway",
    fontSize: 15,
    opacity: 0.9,
    color: "white",
    marginTop: 11,
  },
  faq: {
    fontFamily: "Raleway",
    fontSize: 14,
    opacity: 1,
    color: "white",
  },
  space: {
    marginTop: 20,
  },
  linkText: {
    fontFamily: "Raleway",
    fontSize: 14,
    opacity: 1,
    color: "white",
  },
  services: {
    marginTop: 20,
    fontFamily: "Raleway",
    fontSize: 14,
    opacity: 1,
    color: "white",
  },
};
