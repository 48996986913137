import React, { Component } from "react";
import { Link } from "react-router-dom";
import Orders from "./../../Assets/icons/nav/Group 1008.png";
import Customers from "./../../Assets/icons/nav/customer.png";
import Users from "./../../Assets/icons/nav/user.png";
import Notifications from "./../../Assets/icons/nav/Path 32.png";
import FAQ from "./../../Assets/icons/nav/question.png";
import Products from "./../../Assets/icons/nav/write.png";
import Chat from "./../../Assets/icons/nav/headset.png";
import Profile from "./../../Assets/icons/nav/Path 31.png";
import Subscription from "./../../Assets/icons/nav/subscription.png";
import OrdersWhite from "./../../Assets/icons/nav/Group 1007.png";
import CustomersWhite from "./../../Assets/icons/nav/customer-white.png";
import UsersWhite from "./../../Assets/icons/nav/user-white.png";
import NotificationsWhite from "./../../Assets/icons/nav/notification-white.png";
import FAQWhite from "./../../Assets/icons/nav/question-white.png";
import ProductsWhite from "./../../Assets/icons/nav/write-white.png";
import ChatWhite from "./../../Assets/icons/nav/headset-white.png";
import ProfileWhite from "./../../Assets/icons/nav/profile-white.png";
import SubscriptionWhite from "./../../Assets/icons/nav/subscription-white.png";
import Bot from "./../../Assets/icons/nav/boticon.png";
import BotW from "./../../Assets/icons/nav/boticonw.png";
import homeE from "./../../Assets/icons/nav/home.png";
import homeEW from "./../../Assets/icons/nav/homew.png";
import FAQcatA from "./../../Assets/icons/nav/faqcategory.png";
import FAQcatWhite from "./../../Assets/icons/nav/faqcategoryw.png";
import FAQQA from "./../../Assets/icons/nav/faqquestions.png";
import FAQQAWhite from "./../../Assets/icons/nav/faqquestionw.png";

import { getCookie, deleteCookie } from "./../../Utils/cookies";
import { domain } from "../../Utils/domain";
import { withRouter } from "react-router-dom";
import "./style.scss";

let adminNavbar = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "Orders",
    path: "/dashboard/orders"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "3",
    icon: Users,
    iconWhite: UsersWhite,
    name: "User Management",
    path: "/dashboard/users"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "Customers",
    path: "/dashboard/customers"
  },

  {
    id: "5",
    icon: Products,
    iconWhite: ProductsWhite,
    name: "Products",
    path: "/dashboard/products"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat Logs",
    path: "/dashboard/chatLogsAdmin"
  },
  {
    id: "9",
    icon: Bot,
    iconWhite: BotW,
    name: "Bot Logs",
    path: "/dashboard/botlogs"
  },

  {
    id: "10",
    icon: homeE,
    iconWhite: homeEW,
    name: "Home Page",
    path: "/dashboard/edit-home-page"
  },
  {
    id: "11",
    icon: Subscription,
    iconWhite: SubscriptionWhite,
    name: "Subscriptions",
    path: "/dashboard/edit-subscriptions"
  },
  {
    id: "8",
    icon: FAQcatA,
    iconWhite: FAQcatWhite,
    name: "FAQ Category",
    path: "/dashboard/faqs"
  },
  {
    id: "12",
    icon: FAQQA,
    iconWhite: FAQQAWhite,
    name: "FAQs Q & A",
    path: "/dashboard/faqs/que-ans"
  }
];

let clientNavbar = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "My Orders",
    path: "/dashboard/orders"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "9",
    icon: Subscription,
    iconWhite: SubscriptionWhite,
    name: "My Subscription",
    path: "/dashboard/subscription"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat with Support",
    path: "/dashboard/chat-logs"
  },
  {
    id: "8",
    icon: FAQ,
    iconWhite: FAQWhite,
    name: "View FAQs",
    path: "/faq"
  }
];

let financeNavbar = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "Orders",
    path: "/dashboard/orders"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "Customers",
    path: "/dashboard/customers"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  }
];
let lawyerNavbar = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "My Orders",
    path: "/dashboard/orders"
  },

  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "My Customers",
    path: "/dashboard/customers"
  },

  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  }
];
let supportNavbar = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "Orders",
    path: "/dashboard/orders"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "Customers",
    path: "/dashboard/customers"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat with Customers",
    path: "/dashboard/chat-logs"
  },
  {
    id: "10",
    icon: Bot,
    iconWhite: BotW,
    name: "Bot Logs",
    path: "/dashboard/botlogs"
  }
];

let adminNavbarMobile = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "Order",
    path: "/dashboard/order"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "CustomerList",
    path: "/dashboard/customer"
  },
  {
    id: "3",
    icon: Users,
    iconWhite: UsersWhite,
    name: "User Management",
    path: "/dashboard/user"
  },
  {
    id: "5",
    icon: Products,
    iconWhite: ProductsWhite,
    name: "Products",
    path: "/dashboard/product"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat Logs",
    path: "/dashboard/mob-chatlogs"
  },
  {
    id: "10",
    icon: Bot,
    iconWhite: BotW,
    name: "Bot Logs",
    path: "/dashboard/botlog"
  },
  {
    id: "8",
    icon: FAQ,
    iconWhite: FAQWhite,
    name: "FAQs",
    path: "/faq"
  }
];

let clientNavbarMobile = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "My Orders",
    path: "/dashboard/order"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "9",
    icon: Subscription,
    iconWhite: SubscriptionWhite,
    name: "My Subscription",
    path: "/dashboard/subscription"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat with Support",
    path: "/dashboard/chat-logs"
  },
  {
    id: "8",
    icon: FAQ,
    iconWhite: FAQWhite,
    name: "View FAQs",
    path: "/faq"
  }
];

let financeNavbarMobile = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "My Orders",
    path: "/dashboard/order"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "Customer",
    path: "/dashboard/customer"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  }
];
let lawyerNavbarMobile = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "My Orders",
    path: "/dashboard/order"
  },

  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "My Customer",
    path: "/dashboard/customer"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  }
];
let supportNavbarMobile = [
  {
    id: "1",
    icon: Orders,
    iconWhite: OrdersWhite,
    name: "Orders",
    path: "/dashboard/order"
  },
  {
    id: "2",
    icon: Profile,
    iconWhite: ProfileWhite,
    name: "My Profile",
    path: "/dashboard/profile"
  },
  {
    id: "4",
    icon: Customers,
    iconWhite: CustomersWhite,
    name: "Customer",
    path: "/dashboard/customer"
  },
  {
    id: "6",
    icon: Notifications,
    iconWhite: NotificationsWhite,
    name: "Notifications",
    path: "/dashboard/notifications"
  },
  {
    id: "7",
    icon: Chat,
    iconWhite: ChatWhite,
    name: "Chat with Customers",
    path: "/dashboard/chat-logs"
  },
  {
    id: "10",
    icon: Bot,
    iconWhite: BotW,
    name: "Bot Logs",
    path: "/dashboard/ botlog"
  }
];

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: getCookie("group"), //getCookie("group")
      navBar: [],
      openMenu: false,
      fetchData: false,
      navBarMobile: [],
      whiteColor: window.location.href.includes("profile")
        ? "2"
        : window.location.href.includes("users")
        ? "3"
        : window.location.href.includes("customers")
        ? "4"
        : window.location.href.includes("products")
        ? "5"
        : window.location.href.includes("notifications")
        ? "6"
        : window.location.href.includes("chat-logs") ||
          window.location.href.includes("chatLogsAdmin")
        ? "7"
        : window.location.href.includes("faqs")
        ? "8"
        : window.location.href.includes("subscription")
        ? "9"
        : window.location.href.includes("botlogs")
        ? "10"
        : "1"
    };
  }
  getData = () => {
    fetch(domain + "/api/order/list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  };

  successResponse = response => {
    if (response.detail === undefined) {
    } else if (
      (response.detail &&
        response.detail === "Authentication credentials were not provided.") ||
      "Invalid token"
    ) {
      localStorage.clear();
      this.props.history.push("/");
    }
  };
  errorResponse = error => {};

  componentDidMount = () => {
    //this.getData()

    if (this.state.group === "1") {
      this.setState({
        //navBar : lawyerNavbar //3
        navBar: adminNavbar,
        navBarMobile: adminNavbarMobile
        //navBar : clientNavbar
      });
    } else if (this.state.group === "2") {
      this.setState({
        navBar: clientNavbar, //3,
        navBarMobile: clientNavbarMobile
        //navBar : adminNavbar
      });
    } else if (this.state.group === "4") {
      this.setState({
        navBar: financeNavbar,
        navBarMobile: financeNavbarMobile
      });
    } else if (this.state.group === "3") {
      this.setState({
        navBar: lawyerNavbar,
        navBarMobile: lawyerNavbarMobile //3
      });
    } else if (this.state.group === "5") {
      this.setState({
        navBar: supportNavbar,
        navBarMobile: supportNavbarMobile //3
      });
    }

    window.addEventListener("load", function() {
      try {
        const currentClass = document.getElementsByClassName("side_nav_item");

        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("active_item");
        }
        if (window.location.href.includes("orders")) {
          const element = document.getElementById("1");
          element.classList.add("active_item");
        } else if (window.location.href.includes("profile")) {
          const element = document.getElementById("2");
          element.classList.add("active_item");
        } else if (window.location.href.includes("users")) {
          const element = document.getElementById("3");
          element.classList.add("active_item");
        } else if (window.location.href.includes("customers")) {
          const element = document.getElementById("4");
          element.classList.add("active_item");
        } else if (window.location.href.includes("products")) {
          const element = document.getElementById("5");
          element.classList.add("active_item");
        } else if (window.location.href.includes("notifications")) {
          const element = document.getElementById("6");
          element.classList.add("active_item");
        } else if (
          window.location.href.includes("chat-logs") ||
          window.location.href.includes("chatLogsAdmin")
        ) {
          const element = document.getElementById("7");
          element.classList.add("active_item");
        } else if (window.location.href.includes("faqs")) {
          const element = document.getElementById("8");
          element.classList.add("active_item");
        } else if (window.location.href.includes("subscription")) {
          const element = document.getElementById("9");
          element.classList.add("active_item");
        } else if (window.location.href.includes("botlogs")) {
          const element = document.getElementById("10");
          element.classList.add("active_item");
        } else {
          const element = document.getElementById("1");
          element.classList.add("active_item");
        }
      } catch (err) {
        if (err) {
        }
      }
    });
  };
  clicked = () => {
    const element = document.getElementById("1");
    element.classList.add("active_item");
  };

  handleScroll = () => {
    if (window.pageYOffset > 200 && window.pageYOffset < 700) {
      this.setState({ sticky: true });
    } else {
      this.setState({ sticky: false });
    }
  };

  handleClick = id => {
    this.setState({ whiteColor: id });
    this.changeShow();
    const currentClass = document.getElementsByClassName("side_nav_item");

    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("active_item");
    }
    const element = document.getElementById(id);
    element.classList.add("active_item");
    this.setState({ navbarClicked: true });
  };
  logout = () => {
    var data = JSON.parse(localStorage.getItem("storeDb"));

    if (data !== null) {
      fetch(domain + "/api/chat/save_chat_conversations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify({
          chatlog_conversations: data
        })
      })
        .then(response => response.json())
        .then(response => this.successResponseSaveChat(response))
        .catch(error => this.errorResponse(error));
    }
    if (data === null) {
      localStorage.clear();
      sessionStorage.clear();
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var key = cookies[i].split("=");
        deleteCookie(key[0]);
        //document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
      this.props.history.push("/");
    }
  };

  successResponseSaveChat = res => {
    if (res) {
      localStorage.clear();
      sessionStorage.clear();
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var key = cookies[i].split("=");
        deleteCookie(key[0]);
        // document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }

      this.props.history.push("/");
    }
  };

  errorResponse = error => {
    localStorage.clear();
    sessionStorage.clear();
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var key = cookies[i].split("=");
      deleteCookie(key[0]);
      // document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }

    this.props.history.push("/");
  };

  activeClass = () => {
    let elem = document.getElementById("1");
  };
  storeData = e => {
    this.setState({ openMenu: e.showNavber, fetchData: false });
  };

  changeShow = e => {
    this.props.changeArrowFalse();
    this.setState({ fetchData: true });
  };
  changeMethod = e => {
    this.props.changeArrow();
    this.setState({ fetchData: true });
  };
  render() {
    if (this.state.fetchData === true) {
      this.props && this.storeData(this.props);
    }

    return (
      <>
        {window.innerWidth > 768 ? (
          <div className="navbar">
            <div className="navbar__wrapper">
              <ul>
                {this.state.navBar.map(nav => (
                  <Link to={nav.path} key={nav.id}>
                    <li
                      style={{
                        background:
                          this.state.whiteColor === nav.id
                            ? "#00A5E6"
                            : "white",
                        color:
                          this.state.whiteColor === nav.id
                            ? "#ffffff"
                            : "#000000"
                      }}
                      id={nav.id}
                      onClick={() => this.handleClick(nav.id)}
                      className="side_nav_item"
                    >
                      {this.state.whiteColor === nav.id ? (
                        <img src={nav.iconWhite} alt={nav.name} />
                      ) : (
                        <img src={nav.icon} alt={nav.name} />
                      )}
                      {nav.name}
                    </li>
                  </Link>
                ))}
                {this.state.navBar.length && this.activeClass()}
              </ul>

              <p className="navbar__wrapper_signout">
                <p
                  className="reset_password"
                  onClick={() =>
                    this.props.history.push("/dashboard/reset-password")
                  }
                >
                  Reset Password
                </p>
                Not {getCookie("logedinUser")}?
                <span onClick={this.logout}>Sign out</span>
              </p>
            </div>
          </div>
        ) : (
          <>
            <div
              className={this.state.openMenu ? "transArrow" : "parentDivArrow"}
            >
              {!this.state.openMenu && (
                <div className="arrowsMark" onClick={this.changeMethod}></div>
              )}
              <div>
                <div>
                  {this.state.openMenu && (
                    <div className="navbar ">
                      <div className="navbar__wrapper mobileViewCss">
                        <ul>
                          {this.state.openMenu && (
                            <div
                              className="arrowsMarkBlue"
                              onClick={this.changeShow}
                            ></div>
                          )}
                        </ul>
                        <ul>
                          {this.state.navBarMobile.map(nav => (
                            <Link
                              params={{ showNavber: this.state.openMenu }}
                              to={nav.path}
                              key={nav.id}
                            >
                              <li
                                id={nav.id}
                                onClick={() => this.handleClick(nav.id)}
                                className="side_nav_item"
                              >
                                <img src={nav.icon} alt={nav.name} />
                                {nav.name}
                              </li>
                            </Link>
                          ))}
                          {this.state.navBarMobile.length && this.activeClass()}
                        </ul>

                        <p className="navbar__wrapper_signout">
                          <p className="reset_password">
                            <Link to="/dashboard/reset-password">
                              {" "}
                              Reset Password{" "}
                            </Link>
                          </p>
                          Not {getCookie("logedinUser")}?
                          <span onClick={this.logout}>Sign out</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(index);
