import React, { Component } from 'react';
import { getCookie } from '../../../../Utils/cookies';
import {ifameDomain} from "./../../../../Utils/domain";
import Back from './../../../../Assets/icons/back.png';


export default class index extends Component {
    render() {
        return (
            <div className="faq-details">
            <div className="faq-details__wrapper">
                <div className="faq-details__wrapper_block">
                    <div className="faq-details__wrapper_block_top_heading">
                        <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span> Add FAQs Categories</p>
                    </div>
                   
                </div>
                <div style={{paddingTop:"50px"}}>
                <iframe src={ifameDomain+"/admin/home/faqcategory/add/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="850" height="550"></iframe>
                </div>
                
             </div>
        </div>
        )
    }
}
