export const breadcrumbs = {
  orders: [
    { name: 'HOME', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'ORDERS' },
  ],
  customers: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'customers' },
  ],
  Contact:[
    { name: 'Home', link: '/' },
    { name: 'Contact Us' },
  ],
  Payment:[
    { name: 'Home', link: '/' },
    { name: 'Payments' },
  ],
  Terms:[
    { name: 'Home', link: '/' },
    { name: 'Terms and Conditions' },
  ],
  Privacy:[
    { name: 'Home', link: '/' },
    { name: 'Privacy Policy' },
  ],
  Faq:[
    { name: 'Home', link: '/' },
    { name: 'Faq' },
  ],

  users: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'users' },
  ],
  Products: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'Products' },
],
Notifications: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'Notifications' },
],
ResetPassword: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'Reset Password' },
],
Chats: [
    { name: 'Home', link: '/' },
    { name: 'MY ACCOUNT', link: '/dashboard' },
    { name: 'Chats' },
],
Profile: [
  { name: 'Home', link: '/' },
  { name: 'MY ACCOUNT', link: '/dashboard' },
  { name: 'Profile' },
],
Subscriptions: [
{ name: 'Home', link: '/' },
{ name: 'MY ACCOUNT', link: '/dashboard' },
{ name: 'Subscriptions' },
],
Faqs: [
  { name: 'Home', link: '/' },
  { name: 'MY ACCOUNT', link: '/dashboard' },
  { name: 'FAQ\'s' },
],
Homepage:[
  { name: 'Home', link: '/' },
  { name: 'MY ACCOUNT', link: '/dashboard' },
  { name: 'Home Page' },
],
Subscription:[
  { name: 'Home', link: '/' },
  { name: 'MY ACCOUNT', link: '/dashboard' },
  { name: 'Subscription' },
],

Personal: [
  { name: 'Home', link: '/' },
{ name: 'Personal' },
],
Business: [
  { name: 'Home', link: '/' },
{ name: 'Business' },
],
Services: [
  { name: 'Home', link: '/' },
{ name: 'Services' },
],
Corporate: [
  { name: 'Home', link: '/' },
{ name: 'Corporate Filings' },
],
  form: [
    { name: 'Home', link: '/' },
    { name: 'personal Services', link: '/dashboard' },
    { name: 'Agreement' },
],
}
