import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { deleteCookie } from "../../../../Utils/cookies";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";
import Pagination from "react-js-pagination";
import Loader from "../../../../Components/Preloader";
import "./style.scss";
import xlsExport from "../../../../Components/ExcelExport/XlsExport";
import DatePicker from "react-datepicker";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

class Order extends Component {
  state = {
    orders: [],
    filterValue: [],
    resultArr: {},
    showOrders: false,
    storeOrders: [],
    selectValue: "",
    activePage: 1,
    totalItem: null,
    loader: false,
    allOrdersData: [],
    dateFilter: false,
    startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
    endDate: new Date(),
    startDateValue: "",
    endDateValue: "",
    dateFilterData: [],
    dynamicSelect: "All",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };
  componentDidMount() {
    this.setState({
      loader: true
    });
    window.scrollTo(0, 0);
    this.props.updateBreadcrumbs("orders");
    let token = getCookie("token");
    this.getData();
    // if (
    //   localStorage.getItem("allOrderDetails") === null ||
    //   localStorage.getItem("allOrderDetails") === undefined ||
    //   localStorage.getItem("allOrderDetails").length === 0
    // ) {
    //   setTimeout(() => {
    //     this.state.totalItem !== null && this.getDataForSearch();
    //   }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allOrderDetails"))
    //   });
    // }

    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  getData() {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          "/api/order/list/?created_on__date__gte=" +
          this.state.startDateValue +
          "&created_on__date__lte=" +
          this.state.endDateValue +
          "&page=" +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/order/list/?page=" + this.state.activePage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }

  successResponse = response => {
    this.setState(
      {
        orders: response.results,
        storeOrders: response.results,
        loader: this.state.totalItem === null ? true : false,
        totalItem: response.paginationTotal
      },
      () => {
        if (this.state.orders && this.state.orders !== "") {
          this.setState({ showOrders: true });
          this.state.totalItem !== null && this.getDataForSearch();
        }
      }
    );

    // var data = localStorage.getItem("storeDb");
    // fetch(domain + "/api/chat/save_chat_conversations/", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
    //     Authorization: "Token " + getCookie("token")
    //   },
    //   body: JSON.stringify({
    //     chatlog_conversations: JSON.parse(data)
    //   })
    // })
    //   .then(response => response.json())
    //   .then(response => this.successResponseSaveChat(response))
    //   .catch(error => this.errorResponse(error));
  };
  // successResponseSaveChat = res => {
  //   if (res) {
  //     localStorage.clear();
  //   }
  // };

  errorResponse = error => {};

  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getData());
    window.scrollTo(0, 0);
  }
  filterResult = e => {
    if (e.target !== undefined && e.target.value !== undefined) {
      var Valuee = e.target.value;
      if (Valuee === "All") {
        this.setState({
          orders: this.state.dateFilter
            ? this.state.dateFilterData
            : this.state.storeOrders,
          dynamicSelect: Valuee
        });
      } else {
        let filterValue = this.state.dateFilter
          ? this.state.dateFilterData.filter(item => item.status === Valuee)
          : this.state.storeOrders.filter(item => item.status === Valuee);

        let exportFilter = this.state.dateFilter
          ? this.state.dateFilterData.filter(item => item.status === Valuee)
          : this.state.allOrdersData.filter(item => item.status === Valuee);
        console.log(exportFilter);
        this.setState({
          orders: filterValue,
          exportOrder: exportFilter,
          dynamicSelect: Valuee
        });
      }
    }
  };
  getDataForSearch() {
    fetch(
      domain + `/api/order/list/?page=1&&page_size=${this.state.totalItem}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.searchSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  }
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allOrderDetails", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results
      });
    }
  }
  performSearch = e => {
    try {
      if (e.length >= 3) {
        let filtered = this.state.allOrdersData.filter(entry => {
          return Object.values(entry).some(val => {
            if (
              typeof val === "string" &&
              val.toLowerCase().includes(e.toLowerCase())
            ) {
              return true;
            }
          });
        });
        this.setState({
          orders: filtered
        });
      }
      if (e.length === 0) {
        this.setState({
          orders: this.state.storeOrders
        });
      }
    } catch (err) {}
  };

  logout = () => {
    localStorage.clear();
    deleteCookie("notification-length");
    deleteCookie("phone");
    deleteCookie("logedinUser");
    deleteCookie("email");
    deleteCookie("userId");
    deleteCookie("token");
    deleteCookie("group");
    this.props.history.push("/");
  };
  changeRefresh = () => {
    this.state.orders.sort((a, b) =>
      a.item > b.item ? 1 : b.item > a.item ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.item > b.item ? 1 : b.item > a.item ? -1 : 0
    );
  };
  changeRefreshDate = () => {
    this.state.orders.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
  };

  refreshMethod = () => {
    this.state.orders.sort((a, b) => {
      return a.number > b.number ? 1 : b.number > a.number ? -1 : 0;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.number > b.number ? 1 : b.number > a.number ? -1 : 0;
    });
  };
  exportexcel = e => {
    try {
      const xls = new xlsExport(
        this.state.dynamicSelect === "All" && !this.state.dateFilter
          ? this.state.allOrdersData
          : this.state.dynamicSelect === "All" && this.state.dateFilter
          ? this.state.dateFilterData
          : this.state.exportOrder,
        "Orders"
      );
      xls.exportToXLS("export.xls");
    } catch (err) {}
  };
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },

            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };

  callApiOfSpecificGroup = valueFromFilter => {
    if (this.state.dateFilter) {
      let token = getCookie("token");
      if (
        getCookie("group") === "2" ||
        getCookie("group") === "3" ||
        getCookie("group") === "4" ||
        getCookie("group") === "5"
      ) {
        fetch(
          domain +
            `/api/order/order_based_payment_client/${valueFromFilter}/?created_on__date__gte=` +
            this.state.startDateValue +
            "&created_on__date__lte=" +
            this.state.endDateValue +
            "&page=" +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      } else {
        fetch(
          domain +
            `/api/order/order_based_payment/${valueFromFilter}/?created_on__date__gte=` +
            this.state.startDateValue +
            "&created_on__date__lte=" +
            this.state.endDateValue +
            "&page=" +
            this.state.activePage,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      }
    } else {
      let token = getCookie("token");
      if (
        getCookie("group") === "2" ||
        getCookie("group") === "3" ||
        getCookie("group") === "4" ||
        getCookie("group") === "5"
      ) {
        fetch(
          domain +
            `/api/order/order_based_payment_client/${valueFromFilter}/?page=` +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      } else {
        fetch(
          domain +
            `/api/order/order_based_payment/${valueFromFilter}/?page=` +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      }
    }
  };
  successResponseSelected = response => {
    this.setState({
      totalItem: response.paginationTotal,
      orders: response.results,
      storeOrders: response.results,
      loader: false
    });
  };
  errorResponseSelected = err => {};
  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  render() {
    this.state.orders.detail === "Invalid token." && this.logout();
    this.state.selectValue === "item" && this.changeRefresh();

    this.state.selectValue === "date" && this.changeRefreshDate();

    this.state.selectValue === "number" && this.refreshMethod();
    return (
      <div>
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div>
          <ul className="orders__wrapper_block_top_filter">
            <li className="filter mobileView filter-active">
              <select className="selectDropdown" onChange={this.filterResult}>
                <option className="optionValue" value="All">
                  All
                </option>
                <option className="optionValue" value="Completed">
                  Completed
                </option>
                <option className="optionValue" value="Payment Pending">
                  Payment Pending
                </option>
                <option className="optionValue" value="Payment Done">
                  Payment Done
                </option>
              </select>
            </li>
            <li className="filter mobileView">
              <select
                className="selectDropdown"
                value={this.state.selectValue}
                onChange={this.handleChange}
              >
                <option className="optionValue" value="item">
                  Item
                </option>
                <option className="optionValue" value="number">
                  Order No
                </option>
                <option className="optionValue" value="date">
                  Date
                </option>
              </select>
            </li>
          </ul>
          <div>
            {getCookie("group") === "1" &&
              this.state.allOrdersData.length >= 1 && (
                <span className="buttonExport">
                  <Button onClick={e => this.exportexcel(e)}>
                    Export Order Details
                  </Button>
                </span>
              )}
          </div>

          <div className="orders__wrapper_block_top_search">
            {this.state.allOrdersData.length >= 1 && (
              <>
                <input
                  onChange={event => this.performSearch(event.target.value)}
                  name="search"
                  placeholder="search..."
                />
                <span className="orders__wrapper_block_top_search_icon">
                  <FaSearch />
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="date_filter">
            <div className="date_input">
              <span
                style={{
                  color: this.state.dateFilter ? "#00a5e6" : "",
                  borderBottom: "1px"
                }}
              >
                Start Date
              </span>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <span>---</span>
            <div className="date_input">
              <span style={{ color: this.state.dateFilter ? "#00a5e6" : "" }}>
                End Date
              </span>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <button
              className={this.state.dateFilter ? "show_active_btn" : "show_btn"}
              onClick={e => this.dateFilter(e)}
            >
              {this.state.dateFilter ? "Clear" : "Show"}
            </button>
          </div>
        </div>
        <Container className="orderContainer">
          {this.state.showOrders === true && (
            <Grid xs={12} sm={12}>
              {this.state.orders.map(order => (
                <div className="ordeR" key={order.number}>
                  <div className="orderData">
                    <div className="orderNo">
                      <p>Order No.</p>

                      <p>{order.number}</p>
                    </div>
                    <div className="datE">
                      <p>Date</p>
                      {/* <p>   {order.date}</p> */}
                      <p>{order.date.slice(0, 10)}</p>
                    </div>
                    <div className="statuS">
                      <p>Status</p>
                      <p>{order.status}</p>
                    </div>
                  </div>
                  <div className="payData">
                    <div className="iteM">
                      <p>ITEM</p>
                      <p>{order.item}</p>
                    </div>
                    <div className="amounT">
                      <p>Amount</p>
                      <p>{order.amount}</p>
                    </div>
                    <div className="amounT">
                      <p>Laywer</p>
                      <p>{order.laywerAssigned}</p>
                    </div>
                  </div>
                  <div className="linkData">
                    <Link
                      to={
                        "/dashboard/order-details/" +
                        order.number +
                        "/" +
                        order.item
                      }
                    >
                      <li>
                        <span className="action">View</span>
                      </li>
                    </Link>
                  </div>
                </div>
              ))}
            </Grid>
          )}
        </Container>
        <Pagination
          prevPageText="<"
          nextPageText=">"
          firstPageText=""
          lastPageText=""
          pageRangeDisplayed={3}
          activePage={this.state.activePage}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItem}
          //pageRangeDisplayed={Math.ceil(this.state.totalItem/10)}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}
export default withRouter(connect(state => ({}), { updateBreadcrumbs })(Order));
