import React, { Component } from "react";
import { Avatar, Container, Paper } from "@material-ui/core";
import { FlutterWaveButton } from "flutterwave-react-v3";
import Grid from "@material-ui/core/Grid";
import { getCookie, setCookie } from "../../../../Utils/cookies";
import FormLabel from "@material-ui/core/FormLabel";
import { connect } from "react-redux";
import { domain } from "../../../../Utils/domain";
import { withRouter } from "react-router-dom";
import { updateToasterConfig } from "../../../../redux/modules/app/actions";
import { flutterwaveKey } from "./../../../../Utils/domain";
import MetaFile from "../../../../Pages/MetaFile";
import "./style.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      pin: "",
      state: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      lstStatus: "",
      lstTrans: "",
      lstTx_ref: "",
      finalAmount: "",
      orderNumber: "",
      documentName: "",
      showPopup: false,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  getProfileDetails() {
    let userId = getCookie("userId");
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successDetails(response))
      .catch(error => this.errorDetails(error));
  }
  successDetails = response => {
    this.setState({
      name: response.username,
      email: response.email,
      phone: response.phone,
      pin: response.pin,
      state: response.state,
      city: response.city,
      addressLine1: response.addressLine1,
      addressLine2: response.addressLine2
    });
  };

  setupCookies = () => {
    // setCookie("user_subscription", this.props.location.state===undefined &&  this.props.location.state.user_subscription)
    // setCookie('plan',this.props.location.state===undefined &&  this.props.location.state.plan);
    // setCookie('total_amount',this.props.location.state===undefined && this.props.location.state.total_amount )
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState(
      {
        orderNumber:
          this.props.location.state === undefined
            ? getCookie("user_subscription")
            : this.props.location.state.user_subscription,
        documentName:
          this.props.location.state === undefined
            ? getCookie("plan")
            : this.props.location.state.plan,
        finalAmount:
          this.props.location.state === undefined
            ? getCookie("total_amount")
            : this.props.location.state.total_amount
      },
      () => this.setupCookies()
    );
    this.getProfileDetails();
    this.getMeta();
  };
  getMeta = () => {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
    };
    fetch(domain + `/api/home/tags/payment/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        this.getSucessMetaresponce(responseJson);
      })
      .catch(error => {
        this.getErrorMetaresponce(error);
      });
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  handleChange = (e, id) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  SuccessMsg = () => {
    this.props.updateToasterConfig({
      show: true,
      message: "Subscription Plan has been activated",
      color: "#D06900"
    });
    this.props.history.push("/dashboard/subscription");
  };

  config = {
    public_key: flutterwaveKey,
    tx_ref: Date.now(),
    amount:
      this.props.location.state === undefined
        ? getCookie("total_amount")
        : this.props.location.state.total_amount,
    currency: "NGN",
    redirect_url: "/payment-status",
    payment_options: "Debit/Credit Card",
    customer: {
      email: getCookie("email"),
      phonenumber: getCookie("phone"),
      name: getCookie("logedinUser")
    },
    customizations: {
      title: "LANIDIGITAL",
      description: getCookie("plan"),
      logo:
        "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg"
    }
  };

  fwConfig = {
    ...this.config,
    text: "Proceed",
    callback: response => {
      this.updateBackend();
    },
    onClose: () => {}
  };

  render() {
    return (
      <div>
        <Container>
        {this.state.meta_title !== "" &&  <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
    }

          <div className="payment">Payment</div>
          <div style={{ display: "flex" }}>
            <Avatar
              src={require("../../../../Assets/images/lock.png")}
              className="lock"
            />
            <div className="paymentCaption">
              All transactions are safe and secure
            </div>
          </div>
          <div className="paymentInfo">
            <Grid xs={12} sm={12} lg={8} md={8}>
              <Paper className="paper1">
                <div className="paper1Padding">
                  <div className="paper1Title">Billing Information</div>

                  <div className="paper1TextSaved">
                    Please Enter billing details below
                  </div>
                  <div className="paper1RemainingText"></div>
                  <FormLabel>Name*</FormLabel>
                  <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    value={this.state.name}
                    onChange={e => this.handleChange(e, "name")}
                  />
                  <FormLabel>Email*</FormLabel>
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    value={this.state.email}
                    onChange={e => this.handleChange(e, "email")}
                  />
                  <FormLabel>Phone*</FormLabel>
                  <input
                    type="number"
                    placeholder="Phone"
                    id="phone"
                    value={this.state.phone}
                    onChange={e => this.handleChange(e, "phone")}
                  />
                  <FormLabel>Address Line 1</FormLabel>
                  <input type="text" placeholder="Address Line 1" />
                  <FormLabel>Address Line 2</FormLabel>
                  <input type="text" placeholder="Address Line 2" />
                  <FormLabel>City*</FormLabel>
                  <input
                    type="city"
                    placeholder="city"
                    id="city"
                    value={this.state.city}
                    onChange={e => this.handleChange(e, "city")}
                  />
                  <FormLabel>State*</FormLabel>
                  <input
                    type="state"
                    placeholder="state"
                    id="state"
                    value={this.state.state}
                    onChange={e => this.handleChange(e, "state")}
                  />
                  <FormLabel>Enter Zip code*</FormLabel>
                  <input
                    type="number"
                    placeholder="Enter Zip code"
                    id="pin"
                    value={this.state.pin}
                    onChange={e => this.handleChange(e, "pin")}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4}>
              <Paper className="paper2">
                <div
                  style={{
                    paddingTop: 30,
                    paddingLeft: 40,
                    paddingBottom: 30,
                    paddingRight: 30
                  }}
                >
                  <Grid xs={12} sm={12} lg={12} md={12}>
                    <div className="orderSum">Order Summary</div>
                    <div className="orderSummary">
                      <b> {this.state.documentName}</b>
                      <b> &#8358; {this.state.finalAmount}</b>
                    </div>
                    <div
                      style={{
                        color: "#D06900",
                        fontWeight: 600,
                        paddingTop: 23,
                        fontSize: 14
                      }}
                    ></div>
                    <hr style={{ marginTop: 14 }} />
                    <div
                      style={{
                        color: "#141C49",
                        fontSize: 20,
                        fontFamily: "Raleway-Bold",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      Total : <b> &#8358; {this.state.finalAmount}</b>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </div>
          <div></div>
          {getCookie("total_amount") > 0 &&
            this.state.name &&
            this.state.email &&
            this.state.phone && (
              <div className="proceed-button">
                <FlutterWaveButton {...this.fwConfig} />
              </div>
            )}
          {getCookie("total_amount") == 0 &&
            this.state.name &&
            this.state.email &&
            this.state.phone && (
              <div className="proceed-button">
                <button onClick={() => this.SuccessMsg()}>Proceed</button>
              </div>
            )}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig }
  )(index)
);
