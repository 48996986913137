import React, { useState, useEffect } from "react";
import {
  Paper,
  InputBase,
  IconButton,
  Avatar} from "@material-ui/core";
import { withRouter } from "react-router";
import { domain, defaultHeaders } from "../../../Utils/domain";
import { Scrollbars } from "react-custom-scrollbars";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
import "./styles.scss";

const Search = props => {
  const [text, setText] = useState("");
  const [changeAllColor, setchangeAllColor] = useState(true);
  const [personalColor, setPersonalColor] = useState(false);
  const [businessColor, setBusinessColor] = useState(false);
  const [category, setcategory] = useState("");
  const [searchtext, setsearchtext] = useState("");
  const [filtereddata, setfiltereddata] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [meta_title, setmeta_title] = useState("");
  const [meta_description, setmeta_description] = useState("");
  const [meta_keywords, setmeta_keywords] = useState("");
  const [other_meta_tags, seteother_meta_tags] = useState("");
  const [json_meta_tags, setjson_meta_tags] = useState("");

  function searchResult(e) {
    setText(e.target.value);
    fetch(
      `${domain}/search/service/suggest/?service_content_suggest__completion=""&service_title_suggest__completion=${e.target.value}`,
      {
        method: "GET",
        headers: defaultHeaders
      }
    )
      .then(response => response.json())
      .then(responseJson => {
        searchSuccess(responseJson);
      })
      .catch(error => {
        errorResponse(error);
      });
  }
  function searchSuccess(res) {
    setSearchData(res.service_title_suggest__completion[0].options);
  }
  useEffect(() => {
    defaultFilter();

    if (typeof props.location.state !== "undefined") {
      if (props.location.state.searchtext != "") {
        setsearchtext(props.location.state.searchtext);
        setText(props.location.state.searchtext);
      }
    }
    getMeta(getMetaResponse, getMetaError);
  }, []);

  const getMetaResponse = response => {
    setmeta_title(response.meta_title);
    setmeta_description(response.meta_description);
    setmeta_keywords(response.meta_keywords);
    seteother_meta_tags(response.other_meta_tags);
    setjson_meta_tags(response.json_meta_tags);
  };
  const getMetaError = error => {
    console.log(error);
  };

  function defaultFilter() {
    debugger;
    //   SearchFilter(successResponse, errorResponse,category,searchtext)
    fetch(domain + `/search/service`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => successResponse(responseJson))
      .catch(error => {
        errorResponse(error);
      });
  }

  function successResponse(response) {
    setfiltereddata(response);
  }

  function errorResponse(error) {}

  function allColor() {
    setchangeAllColor(true);
    setPersonalColor(false);
    setBusinessColor(false);
    defaultFilter();
  }
  function goBack() {
    props.history.push("/");
  }
  function PersonalandBusinessFilter(service_type, searchtext) {
    debugger;
    fetch(domain + `/search/service/?service_type=${service_type}`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => personalSuccessResponse(responseJson))
      .catch(error => {
        personaleErrorResponse(error);
      });
  }

  function changePersonalColor() {
    let service_type = "PERSONAL";
    setPersonalColor(true);
    setchangeAllColor(false);
    setBusinessColor(false);
    PersonalandBusinessFilter(service_type, searchtext);
  }

  function personalSuccessResponse(response) {
    setfiltereddata(response);
  }

  function personaleErrorResponse(error) {}

  function changeBusinessColor() {
    let service_type = "BUSINESS";
    setPersonalColor(false);
    setchangeAllColor(false);
    setBusinessColor(true);
    PersonalandBusinessFilter(service_type, searchtext);
  }

  return (
    <div
      className="search--overlay"
      style={{ backgroundColor: "#97979738", height: window.innerHeight }}
    >
      {this.state.meta_title !== "" &&  <MetaFile
        meta_title={meta_title}
        meta_description={meta_description}
        meta_keywords={meta_keywords}
        other_meta_tags={json_meta_tags}
      />
  }
      <div onClick={() => goBack()}>
        <Avatar
          src={require("../../../Assets/images/back.png")}
          style={{
            marginLeft: "10%",
            borderRadius: 0,
            height: 24,
            width: 32,
            paddingTop: 30
          }}
        />
      </div>
      <Paper style={styles.paper}>
        <InputBase
          variant="outlined"
          placeholder="Insert keywords"
          style={styles.input}
          value={text}
          onChange={e => searchResult(e)}
        />
        <IconButton style={styles.searchImageBackground}>
          <Avatar
            src={require("../../../Assets/images/search2.svg")}
            style={styles.image}
          />
        </IconButton>
      </Paper>
      <div className="search--results--wrapper">
        <div className="search--results--left">
          <div
            className="searchCatRes"
            style={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontStyle: "normal",
              fontSize: 24
            }}
          >
            Category
          </div>

          <li
            style={{
              paddingTop: 14,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14,
              color: changeAllColor === true ? "#D06900" : "#585858"
            }}
            onClick={() => allColor()}
          >
            All
          </li>

          <li
            style={{
              paddingTop: 10,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14,
              color: personalColor === false ? "#585858" : "#D06900"
            }}
            onClick={() => changePersonalColor()}
          >
            Personal
          </li>

          <li
            style={{
              paddingTop: 10,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14,
              color: businessColor === false ? "#585858" : "#D06900"
            }}
            onClick={() => changeBusinessColor()}
          >
            Business
          </li>

          {/* <div
            className="docType"
            style={{
              fontFamily: "Raleway",
              fontWeight: 600,
              fontStyle: "normal",
              fontSize: 24,
              paddingTop: 17
            }}
          >
            Document Type
          </div>
          <li
            style={{
              paddingTop: 14,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14
            }}
          >
            All
          </li>
          <li
            style={{
              paddingTop: 10,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14
            }}
          >
            Forms
          </li>
          <li
            style={{
              paddingTop: 10,
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 14
            }}
          >
            PDF's
          </li> */}
        </div>
        <div className="search--results--right">
          <div
            style={{
              fontFamily: "Raleway",
              fontWeight: "normal",
              fontStyle: "normal",
              fontSize: 16
            }}
          >
            Showing results
          </div>
          <Scrollbars
            style={{ width: 770, height: 380 }}
            className="Search--results--list"
          >
            <div style={{ scrollBehavior: "smooth", paddingTop: 40 }}>
              {searchData.length > 0 && (
                <div>
                  {searchData.map((data, index) => (
                    <div>
                      <div
                        key={index}
                        className="search--item"
                        style={{ paddingTop: 12, paddingLeft: 0 }}
                      >
                        <div
                          style={{
                            color: "#141C49",
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                            fontStyle: "normal",
                            fontSize: 14,
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            props.history.push(
                              "/productDetail/" + data._source.slug
                            )
                          }
                          key={index}
                        >
                          {data.text}
                        </div>
                        <div
                          style={{
                            color: "#707070",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontSize: 14,
                            paddingTop: 8,
                            lineHeight: 1.5
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data._source.service_content
                            }}
                          ></div>
                        </div>
                      </div>
                      <hr style={{ color: "gray", marginTop: 10 }} />
                    </div>
                  ))}
                </div>
              )}

              {filtereddata.length > 0 &&
                filtereddata
                  .filter(data => {
                    if (text === null) {
                      return data;
                    } else if (
                      data.service_title
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .map((data, index) => (
                    <div>
                      <div
                        key={index}
                        className="search--item"
                        style={{ paddingTop: 12, paddingLeft: 0 }}
                      >
                        <div
                          style={{
                            color: "#141C49",
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                            fontStyle: "normal",
                            fontSize: 14,
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            window.open("/productDetail/" + data.slug)
                          }
                          key={index}
                        >
                          {data.service_title}
                        </div>
                        <div
                          style={{
                            color: "#707070",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontSize: 14,
                            paddingTop: 8,
                            lineHeight: 1.5
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.service_content
                            }}
                          ></div>
                        </div>
                      </div>
                      <hr style={{ color: "gray", marginTop: 10 }} />
                    </div>
                  ))}
              {filtereddata.length > 0 && (
                <div
                  style={{
                    color: "#141C49",
                    fontFamily: "Raleway",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontSize: 16,
                    cursor: "pointer"
                  }}
                ></div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Search);

let styles = {
  input: {
    flex: 1,
    marginLeft: 19,
    fontSize: 16,
    fontFamily: "Raleway"
  },
  searchImageBackground: {
    borderRadius: 0,
    width: 90
  },
  image: {
    borderRadius: 0,
    height: 24,
    width: 24
  },
  paper: {
    display: "flex",
    width: "80%",
    height: 60,
    backgroundColor: "white",
    position: "absolute",
    top: 80,
    marginLeft: "10%",
    marginRight: "10%",
    borderRadius: 0,
    boxShadow: "0px 0px 8px #28389140"
  }
};
