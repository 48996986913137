import React from 'react'
import Sidebar from './Sidebar';

export default function Dashboard({ id , performSearch,filterValue, filterId}) {
  
  return (
    <div className="d-flex" style={{ height: '750px' }}>
      <Sidebar id={id} performSearch={performSearch} filterValue={filterValue} filterId={filterId}/>
    </div>
  )
}
