import React, { Component } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getCookie, setCookie } from "../../../Utils/cookies";
import { domain } from "../../../Utils/domain";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { updateNotificationCount } from "./../../../../src/redux/modules/app/actions";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";

import { w3cwebsocket as W3CWebSocket } from "websocket";
var socketPath = "wss://legalecomm.beta.webenza.net/ws/notifications/";

const token = getCookie("token");
var client = null;
token !== ""
  ? (client = new WebSocket(socketPath, ["Token", token]))
  : (client = new WebSocket(socketPath));
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      notificationsStore: [],
      showNotifications: false,
      notificationMsg: "No data found",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: "",
      totalNoti : ""
    };
  }

  componentDidMount() {
    client.onopen = message => {};
    client.onmessage = message => {};
    this.props.updateBreadcrumbs("Notifications");
    this.getNotification();
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getNotification = () => {
    let token = getCookie("token");
    fetch(domain + "/api/notification/list/?page=1&&page_size=10000000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  };

  successResponse = response => {
    this.setState(
      {
        notifications: response.results,
        notificationsStore: response.results,
        showNotifications: true,
        totalNoti : response.paginationTotal
      },
      () => {
        console.log(this.state.notifications, "notifications");
        if (this.state.notifications && this.state.notifications !== "") {
        }
      }
    );
  };

  errorResponse = error => {
    this.setState({
      showNotifications: false
    });
    this.props.updateNotificationCount(0);
  };

  performSearch = e => {
    if (e.length >= 3) {
      let filtered = this.state.notifications.filter(
        entry =>
          typeof entry.notification.message === "string" &&
          entry.notification.message.toLowerCase().includes(e)
      );

      this.setState({
        notifications: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        notifications: this.state.notificationsStore
      });
    }
  };
  clearAllNotification = () => {
    //alert("All cleared");
    fetch(domain + "/api/notification/readall/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
    })
      .then(response => response.json())
      .then(responsejson => this.seenAllResponse(responsejson))
      .catch(error => this.errorResponse(error));
  }
  seenAllResponse = (res) => {
    this.componentDidMount();
  }
  seenNotification = id => {
    fetch(domain + "/api/notification/read/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify({
        notification_id: id.id
      })
    })
      .then(response => response.json())
      .then(responsejson => this.seenSuccessResponse(responsejson))
      .catch(error => this.errorResponse(error));
  };
  seenSuccessResponse(res) {
    this.componentDidMount();
  }

  render() {
    if (this.state.notifications.length > 0) {
      var data = [];
      this.state.notifications.map((item, index) => {
        if (item.read === false) {
          data.push(item);
        }
      });
      this.props.updateNotificationCount(this.state.totalNoti);
      //setCookie('notification-length', data.length);
    }

    this.state.notifications.sort((a, b) =>
      a.notification.massage > b.notification.massage
        ? 1
        : b.notification.massage > a.notification.massage
        ? -1
        : 0
    );
    this.state.notificationsStore.sort((a, b) =>
      a.notification.massage > b.notification.massage
        ? 1
        : b.notification.massage > a.notification.massage
        ? -1
        : 0
    );

    return (
      <div className="notification">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="notification__wrapper">
          <div className="notification__wrapper_block">
            <div className="notification__wrapper_block_top_heading">
              <p>Notifications</p>
            </div>
            <div className="notification__wrapper_block_top_search">
              <input
                onChange={event => this.performSearch(event.target.value)}
                name="search"
                placeholder="search..."
              />
              <span className="customer__wrapper_block_top_search_icon">
                <FaSearch />
              </span>
            </div>
          </div>
          
          <div className="notification__wrapper_block_box">
           {this.state.showNotifications && <div className="clear-all">
            <button className="clear-all-btn" onClick={() => this.clearAllNotification()}>Clear All</button>
            </div>
          }
            {this.state.showNotifications ? (
              this.state.notifications.map(item => {
                return (
                  <div
                    key={item.id}
                    className="notification__wrapper_block_box_value"
                  >
                    {item.notification.event_type === "Account" ||
                    item.notification.event_type === "Order" ||
                    item.notification.event_type === "Lawyer" ? (
                      <p className="LinkOfOrders">
                        {" "}
                        <Link
                          to="/dashboard/orders"
                          target="_blank"
                          className="mainOrder"
                        >
                          {item.notification.message}
                        </Link>
                      </p>
                    ) : (
                      <p>{item.notification.message}</p>
                    )}
                    <span>
                      {" "}
                      {item.created_on.slice(0, 10)}{" "}
                      <span
                        className="cross-btn"
                        onClick={() => this.seenNotification(item)}
                      >
                        {" "}
                      X
                      </span>
                    </span>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>You don't have any new notifications</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateNotificationCount }
  )(index)
);
