import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Back from "./../../../../Assets/icons/back.png";
import Input from "@material-ui/core/Input";
// import { getOrderDetails } from '../../../Website/WebServices/ordersList';
import { domain } from "../../../../Utils/domain";
import { getCookie, setCookie } from "../../../../Utils/cookies";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "./style.scss";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewNotifications: [],
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  componentDidMount() {
    let token = getCookie("token");
    // getOrdersList(this.successResponse,this.errorResponse)
    fetch(domain + "/api/notification/list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  successResponse = response => {
    this.setState(
      {
        viewNotifications: response.results
      },
      () => {
        console.log(this.state.viewNotifications, "notifications");
        if (
          this.state.viewNotifications &&
          this.state.viewNotifications !== ""
        ) {
          this.setState({ showNotifications: true });
        }
      }
    );

    fetch(domain + "/api/notification/read/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify({
        notification_id: JSON.parse(this.props.match.params.id)
      })
    })
      .then(response => response.json())
      .then(response => this.successResponseRead(response))
      .catch(error => this.errorResponse(error));
  };
  successResponseRead = res => {};
  errorResponse = error => {};

  render() {
    return (
      <div className="orderdetails">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="orderdetails__wrapper_heading">
          <p>
            <span>
              <img
                onClick={this.props.history.goBack}
                src={Back}
                alt="back button"
              ></img>
            </span>{" "}
            Notifications Details
          </p>
        </div>
        {this.state.viewNotifications.map((item, index) => {
          if (item.id == this.props.match.params.id) {
            return (
              <div className="orderdetails__wrapper_content_block_left">
                <div className="orderdetails__wrapper_content">
                  <p className="left-side">User Id : {item.extra_data.user}</p>

                  <p className="left-side">
                    user Name : {item.extra_data.username}
                  </p>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}
export default withRouter(index);
