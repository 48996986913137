import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from './../../../../Assets/icons/Group 1497.png';
import './style.scss'
import Back from './../../../../Assets/icons/back.png';
import PlusIcon from './../../../../Assets/images/plus-icon.png';
import { withRouter } from "react-router-dom";
import { getCookie } from '../../../../Utils/cookies';
import {domain,ifameDomain} from './../../../../Utils/domain';
let productId =  null
class index extends Component {
    state={
        addFaq:false,
        question:'',
        answer:''
    }

    componentDidMount(){
         
        productId = this.props.match.params.id;
   }
    // AddFaqInServer(){
    //     let token = getCookie("token");
    //     fetch(domain +"/api/auth/add-faq-data/",{
    //         method:"POST",
    //         headers :{
    //             'Content-Type' : "application/json",
    //             'Api-Key': 'sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79',
    //             'Authorization' : `Token ${token}`
    //            },
    //         body: JSON.stringify({
    //                 "faq_category" : this.props.location.state.faqid,
    //                 "faq_title" : this.state.question,
    //                 "faq_description" : this.state.answer
    //             })
    //     })
    //     .then(response=>response.json())
    //     .then(responseJson=>this.successResponse(responseJson))
    //     .catch(error=>this.errorResponse(error))
    // }

    // successResponse=(response)=>{

    //     this.setState({ addFaq:false })
    // }

    // errorResponse=(error)=>{

    // }



    render() {

        //let faqDetail = this.props.location.state.faq_detail;
        return (
            <div className="faq-details">
                <div className="faq-details__wrapper">
                    <div className="faq-details__wrapper_block">
                        <div className="faq-details__wrapper_block_top_heading">
                            <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span>FAQs Categories</p>
                        </div>
                       
                    </div>
                    <div style={{paddingTop:"50px"}}>
                    <iframe src={ifameDomain+"/admin/home/faqcategory/"+productId +"/change/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="850" height="550"></iframe>
                    </div>
                    
                 </div>
            </div>

            
        )
    }
}
export default withRouter(index);
