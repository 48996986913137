import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import './style.scss';

export default class index extends Component {
    render() {
        return (
            <div className="notfound">
                <h1 > 404 - Page not found!</h1>
                <p>Click for <Link to="/">Homepage</Link></p>
            </div>
        )
    }
}
