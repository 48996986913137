import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import "./style.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "./../../../../redux/modules/app/actions";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { getCookie, setCookie } from "./../../../../Utils/cookies";
import { domain } from "./../../../../Utils/domain";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactStore: [],
      searchValue: null,
      chatData: [],
      responseOfUser: [],
      chatLogsData: [],
      selectValue: "recent",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Bot Logs");
    fetch(domain + "/api/chatbot/chat_conversations_list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));

    window.scrollTo(0, 0);
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  successResponseFetch = res => {
    var dataInput = [];
    for (var split in res.results) {
      dataInput.push({
        id: res.results[split].user_id,
        fname: res.results[split].first_name,
        lname: res.results[split].last_name,
        dateTime: res.results[split].created_on,
        email: res.results[split].email_id
      });
    }
    this.setState({ chatLogsData: dataInput, storeOrders: dataInput });
  };

  errorResponse = error => {};

  filterResult = (value, id) => {
    const currentClass = document.getElementsByClassName("filter");
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("filter-active");
    }
    const element = document.getElementById(value);
    element.classList.add("filter-active");

    if (value === "All") {
    }
  };

  performSearch = e => {
    if (e.length >= 2) {
      let filtered = this.state.chatLogsData.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });
      this.setState({
        chatLogsData: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        chatLogsData: this.state.storeOrders
      });
    }
  };

  changeRefresh = () => {
    this.state.chatLogsData.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );
    this.setState({ refresh: true, orderData: true });
  };
  changeRefreshDate = () => {
    this.state.chatLogsData.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.setState({ refresh: true, orderData: false });
  };
  refreshMethod = () => {
    window.location.reload();
  };
  render() {
    if (this.state.selectValue === "id" && !this.state.orderData) {
      this.changeRefresh();
    }
    if (this.state.selectValue === "name" && this.state.orderData) {
      this.changeRefreshDate();
    }
    this.state.selectValue === "recent" &&
      this.state.refresh &&
      this.refreshMethod();
    return (
      <div className="customerCare">
        {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="customerCare__wrapper">
          <div className="customerCare__wrapper_block">
            <div className="customerCare__wrapper_block_top">
              <div className="customerCare__wrapper_block_top_heading">
                <p>Bot Logs</p>
              </div>
              <div className="customerCare__wrapper_block_top_search">
                <input
                  onChange={event => this.performSearch(event.target.value)}
                  name="search"
                  placeholder="search..."
                />
                <span className="orders__wrapper_block_top_search_icon">
                  <FaSearch />
                </span>
              </div>
            </div>

            <ul className="customerCare__wrapper_block_top_filter">
              <li
                id="All"
                className=" filter filter-active"
                onClick={() => this.filterResult("All")}
              >
                All
              </li>
              <li
                id="Inprogress"
                className="filter"
                onClick={() => this.filterResult("Inprogress")}
              >
                Inprogress
              </li>
              <li
                id="Completed"
                className="filter"
                onClick={() => this.filterResult("Completed")}
              >
                Completed
              </li>
              <li className="filter mobileView">
                <span className="sortLabel">Sortby</span>
                <select
                  className="selectDropdown"
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option className="optionValue" value="recent">
                    Recent
                  </option>
                  <option className="optionValue" value="name">
                    Name
                  </option>
                  <option className="optionValue" value="id">
                    Id
                  </option>
                </select>
              </li>
            </ul>

            <div>
              <Grid xs={12} sm={12}>
                {this.state.chatLogsData !== null &&
                  this.state.chatLogsData !== undefined &&
                  this.state.chatLogsData.map(order => {
                    var newDate = new Date(order.dateTime);
                    return (
                      <div className="mainchatlogdiv" key={order.id}>
                        <Grid
                          className="orderNo"
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                        >
                          <div className="chatLogcust">
                            <Grid className="chatlogFirstrow" xs={6} sm={6}>
                              <p>User Name</p>

                              <p>
                                {(order.fname &&
                                  order.fname.replace(/(^"|"$)/g, "").length >
                                    1) ||
                                (order.lname &&
                                  order.lname.replace(/(^"|"$)/g, "").length >
                                    1) ? (
                                  <div>
                                    {order.fname.replace(/(^"|"$)/g, "")}&nbsp;
                                    {order.lname.replace(/(^"|"$)/g, "")}
                                  </div>
                                ) : (
                                  order.id.replace(/(^"|"$)/g, "")
                                )}
                              </p>
                            </Grid>
                            <Grid className="chatlogSrow" xs={6} sm={6}>
                              <p>Email ID</p>
                              <p>
                                {order.email &&
                                order.email.replace(/(^"|"$)/g, "").length >
                                  1 ? (
                                  <div>
                                    {order.email.replace(/(^"|"$)/g, "")}
                                  </div>
                                ) : (
                                  "NA"
                                )}
                              </p>
                            </Grid>
                          </div>
                          <div className="chatLogsupport">
                            <Grid className="chatlogFirstrow" xs={6} sm={6}>
                              <p>Date</p>
                              <p>{`${newDate.getDate()}- ${newDate.getMonth() +
                                1} - ${newDate.getFullYear()}`}</p>
                            </Grid>
                            <Grid className="chatlogSrow" xs={6} sm={6}>
                              <p>Time</p>
                              <p>{`${newDate.getHours()}:${newDate.getMinutes() +
                                1}:${newDate.getSeconds()}`}</p>
                            </Grid>
                          </div>

                          <div className="routeAdminchatlog">
                            <Link
                              to={
                                "/dashboard/botlog/botDetails/" + order.id + "/"
                              }
                            >
                              <li>
                                <span className="action">View</span>
                              </li>
                            </Link>
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
