import React, { Component } from "react";
import "./style.scss";
import Profile from "./../../../../Assets/images/profile-img.png";
import CustomOrders from "./../../Orders/customOrders";
import CustomOrdersMobile from "./../../Orders/CustomOrdersMobile";
import Back from "./../../../../Assets/icons/back.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../../../src/redux/modules/app/actions";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import SubscriptionPlan from "./../../SubscriptionPlans/SubscriptionPlans";
class index extends Component {
  state = {
    customerDetails: [],
    customerId: [],
    viewOrder: null,
    showOrder: false,
    showModal: false,
    lawyersList: [],
    hours: null,
    minuts: null,
    notes: "",
    assignBlock: false,
    consultationList: [],
    consultationId: null,
    consultation_id: null,
    lawyersConsultationList: []
  };

  componentDidMount() {
    this.props.updateBreadcrumbs("customers");
    //let id = 90;
    let id = this.props.location.state.customerId;
    let token = getCookie("token");
    fetch(`${domain}/api/subscription/detail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }
  lawyersConsultationList = () => {
    //let id = 90;
    let id = this.props.location.state.customerId;
    let token = getCookie("token");
    fetch(`${domain}/api/subscription/lawyer_cosultation_list/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponselawyersConsultationList(response))
      .catch(error => this.errorResponse(error));
  };
  successResponselawyersConsultationList = response => {
    this.setState({
      lawyersConsultationList: response
    });
  };
  successResponse = response => {
    response.detail === "Not found."
      ? this.setState({ customerDetails: response.detail })
      : this.setState(
          { customerDetails: response, subscriptionPlanId: response.plan },
          () => this.consultation()
        );
    this.lawyersConsultationList();
  };

  showTimeBlock = id => {
    this.setState({
      showModal: true,
      consultation_id: id
    });
  };
  changeHours = e => {
    this.setState({
      hours: e.target.value
    });
  };
  changeMinuts = e => {
    this.setState({
      minuts: e.target.value
    });
  };
  updateNotes = e => {
    this.setState({
      notes: e.target.value
    });
  };

  consultation = () => {
    let id = this.props.location.state.customerId;
    let token = getCookie("token");
    fetch(`${domain}//api/subscription/subscription_coosultation_list_ad/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponseConsultation(response))
      .catch(error => this.errorResponse(error));
  };
  successResponseConsultation = response => {
    this.setState({
      consultationList: response
    });
  };

  errorResponse = error => {};
  viewOrderNumber = (e, item) => {
    this.setState({ viewOrder: item, showOrder: true });
  };

  assignLawyer = id => {
    let ele = document.getElementById("selected-lawyer-" + id);
    ele.style.display = "block";
    this.setState({
      consultationId: id
    });
    fetch(`${domain}/api/auth/lawyer/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.lawyerSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  };

  lawyerSuccessResponse = response => {
    this.setState({
      lawyersList: response.results,
      assignBlock: true
    });
  };
  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].lawyersName === nameKey) {
        return myArray[i];
      }
    }
  }
  assignThisLawyer = id => {
    let value = document.getElementById("selected-lawyer-" + id).value;
    let lawyerId = this.search(value, this.state.lawyersList);
    let data = {
      consultation_id: this.state.consultationId,
      lawyer: lawyerId.id
    };

    fetch(domain + "/api/subscription/assignAnLawyerToConsultation/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responsejson => this.postLawyerResponse(responsejson))
      .catch(error => this.errorResponse(error));
  };
  updateTimeDuration = () => {
    let data = {
      consultation_id: this.state.consultation_id,
      duration_hr_actual: this.state.hours,
      duration_min_actual: this.state.minuts
    };
    fetch(domain + "/api/subscription/updateLawyerConsultationTime/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responsejson => this.successTimeUpdate(responsejson))
      .catch(error => this.errorResponse(error));
  };
  successTimeUpdate = response => {
    this.setState({
      showModal: false
    });
    this.props.updateToasterConfig({
      show: true,
      message: "Time has been updated Successfully",
      color: "#D06900"
    });
  };

  postLawyerResponse = response => {
    this.props.updateToasterConfig({
      show: true,
      message: "Assigned a lawyer Successfully",
      color: "#D06900"
    });
    this.setState({
      assignBlock: false
    });
  };
  lawyerResponse = (id, res) => {
    let api = "";
    if (res == true) {
      api = "lawyer_accept_subscription/";
    } else {
      api = "deny_consultation_subscription/";
    }
    let data = {
      consultation_id: id
    };
    fetch(domain + "/api/subscription/" + api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responsejson => this.SuccesslawyerResponse(responsejson))
      .catch(error => this.errorResponse(error));
  };
  SuccesslawyerResponse = response => {
    this.props.updateToasterConfig({
      show: true,
      message: response.message,
      color: "#D06900"
    });
  };

  render() {
    var splicedDate =
      this.state.customerDetails.valid_till !== undefined &&
      this.state.customerDetails.valid_till.slice(0, 10);
    var present_date = new Date();
    var validateDay = new Date(splicedDate);
    var one_day = 1000 * 60 * 60 * 24;
    if (present_date.getMonth() == 11 && present_date.getDate() > 25)
      validateDay.setFullYear(validateDay.getFullYear() + 1);
    var Result =
      Math.round(validateDay.getTime() - present_date.getTime()) / one_day;

    var Final_Result = Result.toFixed(0);
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const futureDate = moment(currentDate).add(Final_Result, "days");
    const diff = moment.duration(futureDate.diff(currentDate));

    return (
      <Container>
        <div className="customerDetails">
          <div className="customerDetails__wrapper">
            <div className="customerDetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                {this.props.location.customerName} Subscription
              </p>
            </div>
            <div className="customerDetails__wrapper_content">
              <div className="customerDetails__wrapper_content_block">
                {this.state.customerDetails === "Not found." ? (
                  <Grid xs={12} sm={12} md={6} lg={6}>
                    <p className="discountHeadplan">
                      No Subscription data found.
                    </p>
                  </Grid>
                ) : (
                  <Grid xs={12} lg={6} md={6} sm={6}>
                    <div className="coloumFlex">
                      {this.state.customerDetails.active ? (
                        <p className="activePlan">
                          CURRENT ACTIVE PLAN <br />
                          <b> {this.state.customerDetails.plan_details.plan}</b>
                        </p>
                      ) : (
                        <p>NOT CURRENT ACTIVE PLAN</p>
                      )}
                      <div className="recuRing">
                        Recurs in {diff.months()} Months {diff.days()} Days
                      </div>

                      <div className="remainingPlan">
                        {/* 20 templates (14/20 remaining) */}
                      </div>
                      {this.state.customerDetails.sub_usage !== undefined &&
                        this.state.customerDetails.sub_usage.map(
                          (item, index) => {
                            if (item.template_type === "TEMPLATE") {
                              return (
                                <div key={index} className="servicesTemplatesC">
                                  <div className="planName">
                                    {item.service_name}
                                  </div>
                                  {/* <div>{item.service_type}</div>
                                  <div>{item.service_category}</div> */}

                                  {item.order_no !== null && item.availed && (
                                    <button
                                      className="viewOrderT"
                                      onClick={e =>
                                        this.viewOrderNumber(
                                          e,
                                          item.service_name
                                        )
                                      }
                                    >
                                      View order
                                    </button>
                                  )}
                                  {this.state.showOrder &&
                                    item.service_name ===
                                      this.state.viewOrder && (
                                      <div>Order Number:{item.order_no}</div>
                                    )}
                                </div>
                              );
                            }
                          }
                        )}
                    </div>
                  </Grid>
                )}
                <Grid xs={12} lg={6} md={6} sm={12}>
                  <div className="discountHeadplan">
                    {/* 7% discount on 7 add-on templates (5/7 remaining)   */}
                  </div>
                  {this.state.customerDetails.sub_usage !== undefined &&
                    this.state.customerDetails.sub_usage.map((item, index) => {
                      if (item.template_type === "ADDON") {
                        return (
                          <div key={index} className="servicesTemplatesC">
                            <div className="planName">{item.service_name}</div>
                            {/* <div>{item.service_type}</div>
                                <div>{item.service_category}</div> */}

                            {item.order_no !== null && item.availed && (
                              <button
                                className="viewOrderT"
                                onClick={e =>
                                  this.viewOrderNumber(e, item.service_name)
                                }
                              >
                                View order
                              </button>
                            )}
                            {this.state.showOrder &&
                              item.service_name === this.state.viewOrder && (
                                <div>Order Number:{item.order_no}</div>
                              )}
                          </div>
                        );
                      }
                    })}

                  <div className="discountHeadplan spaceDIv">
                    {/* 7% discount on 10 legal services (8/10 remaining) */}
                  </div>

                  {this.state.customerDetails.sub_usage !== undefined &&
                    this.state.customerDetails.sub_usage.map((item, index) => {
                      if (item.template_type === "LEGALSERVICE") {
                        return (
                          <div key={index} className="servicesTemplatesC">
                            <div className="planName">{item.service_name}</div>
                            {/* <div>{item.service_type}</div>
                                <div>{item.service_category}</div> */}

                            {item.order_no !== null && item.availed && (
                              <button
                                className="viewOrderT"
                                onClick={e =>
                                  this.viewOrderNumber(e, item.service_name)
                                }
                              >
                                View order
                              </button>
                            )}
                            {this.state.showOrder &&
                              item.service_name === this.state.viewOrder && (
                                <div>Order Number:{item.order_no}</div>
                              )}
                          </div>
                        );
                      }
                    })}

                  <div className=" topDissec">
                    <div className="flexDIv">
                      <div>
                        <div className="discountH">
                          <h3>New consultation requests</h3>
                        </div>
                        {getCookie("group") == 1 &&
                          this.state.customerDetails &&
                          this.state.customerDetails.sub_consultation && (
                            <Grid>
                              {this.state.customerDetails.sub_consultation.map(
                                item => {
                                  return (
                                    <div>
                                      <div>
                                        <span>{item.consultatin_id} - </span>
                                        <p>
                                          <b>Assign Lawyer:</b>{" "}
                                          {item.lawyer_name === undefined
                                            ? "Not assigned yet"
                                            : item.lawyer_name}
                                        </p>
                                        <p>
                                          <b>Total Duration:</b>{" "}
                                          {item.legal_consultation_per_month}{" "}
                                        </p>
                                        <p>
                                          <b>Remaining Duration :</b>{" "}
                                          {item.remaining_total_quota}{" "}
                                        </p>
                                      </div>
                                      <div className="assign_lawyer">
                                        <select
                                          id={
                                            "selected-lawyer-" +
                                            item.consultatin_id
                                          }
                                          className="selected-lawyer"
                                          onChange={() =>
                                            this.assignThisLawyer(
                                              item.consultatin_id
                                            )
                                          }
                                        >
                                          <option>Select a lawyer</option>
                                          {this.state.lawyersList.map(item => {
                                            return (
                                              <option>
                                                {item.lawyersName}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <button
                                          onClick={() =>
                                            this.assignLawyer(
                                              item.consultatin_id
                                            )
                                          }
                                          className="bookNow"
                                        >
                                          Assign a Lawyer
                                        </button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Grid>
                          )}
                        {getCookie("group") == 3 &&
                          this.state.lawyersConsultationList && (
                            <div>
                              {this.state.lawyersConsultationList.map(item => {
                                return (
                                  <div>
                                    <p>Id: {item.consultatin_id}</p>
                                    <p>Time: {item.scheduled_datetime}</p>
                                    <p>
                                      Duration of call:{" "}
                                      {item.duration_min_requested} mins
                                    </p>
                                    <div className="accept_reject">
                                      <p>Action: </p>
                                      <button
                                        onClick={() =>
                                          this.lawyerResponse(
                                            item.consultatin_id,
                                            false
                                          )
                                        }
                                        className="bookNow"
                                      >
                                        Reject
                                      </button>
                                      <button
                                        onClick={() =>
                                          this.lawyerResponse(
                                            item.consultatin_id,
                                            true
                                          )
                                        }
                                        className="bookNow"
                                      >
                                        Accept
                                      </button>
                                    </div>
                                    <div className="update-time">
                                      <p> Update call duration:</p>{" "}
                                      <button
                                        onClick={() =>
                                          this.showTimeBlock(
                                            item.consultatin_id
                                          )
                                        }
                                        className="bookNow"
                                      >
                                        Enter Details
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                        {this.state.showModal && (
                          <Modal
                            open={this.state.showModal}
                            onClose={e => this.setState({ showModal: false })}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            <div className="lawyer-subscription-popup">
                              <h1>Consultation Details</h1>
                              <div className="consult-time-wrapper">
                                <div className="consult-inner">
                                  <p>Enter Time taken</p>
                                  <input
                                    type="number"
                                    placeholder="Hours"
                                    value={this.state.hours}
                                    onChange={this.changeHours}
                                  />
                                  <input
                                    type="number"
                                    placeholder="Mins"
                                    value={this.state.minuts}
                                    onChange={this.changeMinuts}
                                  />
                                </div>
                                <div className="consult-inner">
                                  <p>Notes</p>
                                  <input
                                    type="textarea"
                                    placeholder="Enter Notes"
                                    value={this.state.notes}
                                    onChange={this.updateNotes}
                                  />
                                </div>
                              </div>

                              <button
                                className="btn-cancel"
                                onClick={e =>
                                  this.setState({ showModal: false })
                                }
                              >
                                cancel
                              </button>
                              <button
                                className="btn-save"
                                onClick={this.updateTimeDuration}
                              >
                                Save
                              </button>
                            </div>
                          </Modal>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <SubscriptionPlan />
      </Container>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
