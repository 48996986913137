import React, { useState, useEffect } from "react";
import { ServiceListDetails } from "../WebServices/Services";
import { setCookie } from "../../../Utils/cookies";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ServieImage from "../../../Assets/images/services.png";
import { getCookie } from "../../../Utils/cookies";
import "./styles.scss";
import SamplePdf from "../../../Assets/images/sample.pdf";
import { faqsdetailsPage } from "../WebServices/faqDetailAndSearch";
import { numberWithCommas } from "./../Form/currencyConvertor";
import MetaFile from "../../MetaFile";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'


const trackingId = "UA-198795830-2"; // UA-198795830-2  Google Analytics tracking ID
const tagManagerArgs = {
  gtmId: 'GTM-MT2Q5GF'  // GTM  tracking ID
}



const SubCategoryDetails = props => {
  const [successResponseData, setsuccessResponseData] = useState([]);
  const [bool, setBool] = useState(false);
  const [displayId, setdisplayId] = useState(null);
  const [questionColor, setquestionColor] = useState(false);
  const [faqsD, setfaqsD] = useState([]);
  const [urlId, setUrlId] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setdata] = useState([
    {
      id: "134",
      name: "Memorandum of Understanding",
      slug: "memorandum-of-understanding-corporate-to-corporate",
      description:
        "Short info, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
    },
    {
      id: "216",
      name: "Partnership Agreement",
      slug: "partnership-agreement-corporate-to-corporate",
      description:
        "Short info, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
    },
    {
      id: "21",
      name: "Tenancy Agreement",
      slug: "tenancy-agreement-corporate-to-corporate",
      description:
        "Short info, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
    }
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if(window.location.host === "lanidigital.com" || window.location.host === "www.lanidigital.com"){
      successResponseData.meta_title !== "" && setTimeout(function(){
        TagManager.initialize(tagManagerArgs)
        ReactGA.initialize(trackingId);
        //to report page view
        ReactGA.pageview(window.location.pathname)
        console.log("GA code triggered");
      },4000)
    }
    
    
    let url = window.location.pathname.split("/");
    if (url[2]) {
      const listId = url[2].toLowerCase();
      ServiceListDetails(successResponse, errorResponse, listId);
      faqsdetailsPage(successResponsefaq, errorResponsefaq, listId);
    } else {
      props.history.push("/personal");
    }
  }, [window.location.pathname]);

  function successResponse(response) {
    setsuccessResponseData(response);
  }

  function errorResponse(error) {}

  function changeBool(data) {
    if (bool == false && questionColor == false) {
      setBool(true);
      setquestionColor(true);
      setdisplayId(data);
    } else if (bool === true && questionColor == true) {
      setBool(false);
      setquestionColor(false);
      setdisplayId(null);
    }
  }
  function successResponsefaq(response) {
    setfaqsD(response);
  }
  function errorResponsefaq(error) {}

  const redirect = response => {
    try {
      setCookie("subscriptionData", false);
      setCookie("user_subscription", null);
      props.history.push({
        pathname: "/agreement/" + response.slug,
        state: {
          id: successResponseData.id
        }
      });
    } catch (error) {}
  };
  const templateRedirect = (id, item) => {
    props.history.push({
      pathname:
        "/productDetail/" +item
    });
    //window.location.reload();
  };

  return (
    <div className="product--details--pages">
      {successResponseData.meta_title !== "" &&  <MetaFile
        meta_title={successResponseData.meta_title}
        meta_description={successResponseData.meta_description}
        meta_keywords={successResponseData.meta_keywords}
        meta_image={successResponseData.meta_image}
        other_meta_tags={successResponseData.json_meta_tags}
      />
  }
      <div className="backgroundImages">
        <Container>
          <Grid lg={12} md={12} sm={12} xs={12}>
            <div style={styles.listTitle}></div>
            <Paper className="serviceHeading">
              <Grid lg={5} md={5} sm={12} xs={12}>
                <div
                  className="details--bgimage"
                  style={{
                    backgroundImage:
                      "url(" + successResponseData.service_image + ")"
                  }}
                ></div>
              </Grid>
              <Grid lg={7} md={7} sm={12} xs={12}>
                <div style={styles.title}>
                  {successResponseData.service_title}
                </div>
                <div
                  style={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: successResponseData.service_content
                  }}
                ></div>
                <div className="catCreate">
                  <Button
                    className="create--btn"
                    onClick={() => redirect(successResponseData)}
                  >
                    Create
                  </Button>
                  <Button
                    href={successResponseData.service_file}
                    target="_blank"
                    className="view--sample--btn"
                  >
                    View Sample
                  </Button>
                </div>
                {getCookie("sub-plan") &&
                  !(getCookie("sub-plan") === "FREEMIUM PLAN") && (
                    <Button
                      className="create--btn sub-btn"
                      onClick={() => redirect(successResponseData)}
                    >
                      Download with {getCookie("sub-plan")}
                    </Button>
                  )}
                <div
                  className="details_faq"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30
                  }}
                >
                  {successResponseData.amount && (
                    <span
                      style={{
                        fontSize: "20px",
                        paddingLeft: "3px",
                        marginTop: "-2px"
                      }}
                    >
                      {successResponseData.filing_cost > 0
                        ? successResponseData.lanidigital_cost_text
                        : "Price"}
                      : &#8358;
                      {numberWithCommas(successResponseData.lanidigital_cost)}
                    </span>
                  )}
                  {successResponseData.filing_cost && (
                    <span
                      style={{
                        fontSize: "20px",
                        paddingLeft: "3px",
                        marginTop: "-2px",
                        display:
                          successResponseData.filing_cost == 0
                            ? "none"
                            : "block"
                      }}
                    >
                      {successResponseData.filing_cost_text}: &#8358;
                      {numberWithCommas(successResponseData.filing_cost)}
                    </span>
                  )}
                </div>

                <div
                  className="details_faq"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Avatar
                    src={require("../../../Assets/images/question2.png")}
                    style={styles.question}
                  />
                  <span
                    onClick={() => props.history.push("/faq", { id: 2 })}
                    style={styles.faqtext}
                  >
                    View FAQ
                  </span>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </div>

      <Container className="contentforDetails">
        <Grid xs={12} sm={12} lg={4} md={4}>
          <Paper
            className="catDetails"
            style={{
              height: 600,
              width: 350,
              backgroundColor: "#F6F9FC",
              borderRadius: 0,
              boxShadow: "none"
            }}
          >
            <div
              style={{
                color: "#141C49",
                fontFamily: "Lora",
                fontWeight: "medium",
                fontStyle: "normal",
                fontSize: 30,
                textAlign: "center",
                paddingTop: 40
              }}
            >
              The Process
            </div>
            <div style={{ marginLeft: 15, marginRight: 15 }}>
              <div style={{ display: "flex", paddingTop: 45 }}>
                <div
                  style={{
                    height: 71,
                    width: 71,
                    borderRadius: 35,
                    backgroundColor: "white",
                    border: "1px solid #00A5E63D"
                  }}
                >
                  <Avatar
                    src={require("../../../Assets/images/list.svg")}
                    style={{
                      borderRadius: 0,
                      height: 35,
                      width: 26,
                      marginTop: 14,
                      marginLeft: 22
                    }}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 21,
                    display: "flex",
                    alignSelf: "center",
                    flexFlow: "column"
                  }}
                >
                  <div
                    className="process--heading"
                    style={{
                      color: "#141C49",
                      fontStyle: "normal",
                      fontSize: 18
                    }}
                  >
                    Choose template
                  </div>
                  <div
                    style={{
                      paddingTop: 4,
                      lineHeight: 1.4,
                      color: "#585858",
                      fontFamily: "Raleway",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      fontSize: 14
                    }}
                  >
                    Choose from any of our
                    <br /> available documents.
                  </div>
                </div>
              </div>
              <div style={{ color: "#00A5E63D", marginLeft: 33 }}>
                |<br />
                |<br />
                |<br />|
              </div>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 71,
                    width: 71,
                    borderRadius: 35,
                    backgroundColor: "white",
                    border: "1px solid #00A5E63D"
                  }}
                >
                  <Avatar
                    src={require("../../../Assets/images/pencil.svg")}
                    style={{
                      borderRadius: 0,
                      height: 34,
                      width: 34,
                      marginTop: 16,
                      marginLeft: 18
                    }}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 21,
                    display: "flex",
                    alignSelf: "center",
                    flexFlow: "column"
                  }}
                >
                  <div
                    className="process--heading"
                    style={{
                      color: "#141C49",
                      fontStyle: "normal",
                      fontSize: 18
                    }}
                  >
                    Complete the document
                  </div>
                  <div
                    style={{
                      paddingTop: 4,
                      lineHeight: 1.4,
                      color: "#585858",
                      fontFamily: "Raleway",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      fontSize: 14
                    }}
                  >
                    Answer a few simple questions
                  </div>
                </div>
              </div>
              <div style={{ color: "#00A5E63D", marginLeft: 33 }}>
                |<br />
                |<br />
                |<br />|
              </div>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 71,
                    width: 71,
                    borderRadius: 35,
                    backgroundColor: "white",
                    border: "1px solid #00A5E63D"
                  }}
                >
                  <Avatar
                    src={require("../../../Assets/images/print.svg")}
                    style={{
                      borderRadius: 0,
                      height: 31,
                      width: 34,
                      margin: 18
                    }}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 21,
                    display: "flex",
                    alignSelf: "center",
                    flexFlow: "column"
                  }}
                >
                  <div
                    className="process--heading"
                    style={{
                      color: "#141C49",
                      fontStyle: "normal",
                      fontSize: 18
                    }}
                  >
                    Save / print
                  </div>
                  <div
                    style={{
                      paddingTop: 4,
                      lineHeight: 1.4,
                      color: "#585858",
                      fontFamily: "Raleway",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      fontSize: 14
                    }}
                  >
                    Your document is ready!
                    <br /> Use it however you wish
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} lg={8} md={8}>
          <div className="contentofDetails">
            {/* <div className="contentofdetailsHead">Lorem ipsum dolor</div> */}
            <div className="textbodyContent">
              {successResponseData.service_main_content === "" ? (
                <div>
                  <p>
                    The “last will and testament” is a document in which an
                    individual called the “Testator” declares how his/her
                    property should be dealt with and distributed upon his/her
                    death. The Testator must be at least 18 years old or 21
                    years old (in Lagos State only).
                  </p>
                  <p>
                    The testator is expected to appoint certain individuals
                    (called the “Executors”) to carry out these wishes upon
                    his/her death.
                  </p>
                  <p>
                    The will is made for persons known as “Beneficiaries”, who
                    ought to be described with certainty in the body of the
                    will.
                  </p>
                  <p>
                    The signing of the will by the testator must be witnessed by
                    two independent witnesses. The two witnesses must also sign
                    the will in the presence of the testator, but not
                    necessarily in each other’s presence The will must be
                    written with the free will of a testator possessing sound
                    mind and free of any coercion or undue influence.
                  </p>
                  <p>
                    The properties to be bequeathed in the will must be clearly
                    identified with certainty and must form part of the
                    testator’s estate at the time of the testator’s death.
                  </p>
                  dangerouslySetInnerHTML={{ __html: this.state.formData }}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: successResponseData.service_main_content
                  }}
                ></div>
              )}
            </div>

            
          </div>
          {/* <div style={{ marginTop:270 }}>
            </div> */}
        </Grid>
      </Container>
      <Container>
        <Grid lg={12} md={12} xs={12} sm={12}>
          {faqsD.length > 0 && (
            <Paper className="faqSection">
              <div style={{ paddingBottom: 35 }}>
                <h3
                  style={{
                    color: "#141C49",
                    fontFamily: "Lora",
                    fontWeight: 100,
                    fontStyle: "normal",
                    fontSize: 40,
                    textAlign: "center",
                    paddingTop: 40
                  }}
                >
                  Frequently Asked Questions
                </h3>
                {faqsD.length > 0 &&
                  faqsD.map((data, index) => (
                    <div
                      style={{
                        width: "75%",
                        height: "auto",
                        backgroundColor: "#FFFFFF",
                        marginLeft: "12%",
                        marginRight: "12%",
                        marginTop: 0,
                        marginBottom: 30
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: 45,
                          paddingRight: 38,
                          paddingTop: 25,
                          paddingBottom: 25,
                          cursor: "pointer"
                        }}
                        onClick={() => changeBool(data.id)}
                      >
                        <div
                          style={{
                            fontFamily: "Raleway",
                            color:
                              questionColor === true && displayId === data.id
                                ? "#00A5E6"
                                : "#141C49",
                            fontSize: 18,
                            fontWeight:
                              questionColor === true && displayId === data.id
                                ? 600
                                : "normal"
                          }}
                        >
                          {data.faq_title}
                        </div>
                        <div
                          style={{
                            height: 25,
                            width: 25,
                            borderRadius: 12,
                            backgroundColor: "#F3F3F3"
                          }}
                        >
                          {bool === true && displayId === data.id ? (
                            <Avatar
                              src={require("../../../Assets/images/up.png")}
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 12
                              }}
                            />
                          ) : (
                            <Avatar
                              src={require("../../../Assets/images/down.png")}
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 12
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {bool === true && displayId === data.id && (
                        <div
                          style={{
                            lineHeight: 1.6,
                            color: "#585858",
                            fontFamily: "Raleway",
                            fontSize: 14,
                            paddingLeft: 45,
                            paddingRight: 38,
                            paddingBottom: questionColor === true ? 35 : 0
                          }}
                          dangerouslySetInnerHTML={{
                            __html: data.faq_description
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
              </div>
            </Paper>
          )}
          <div className="related--documents--wrapper">
            <h1
              style={{
                color: "#141C49",
                fontFamily: "Lora",
                fontWeight: 100,
                fontStyle: "normal",
                fontSize: 40,
                textAlign: "center",
                marginTop: 70
              }}
            >
              Related Documents
            </h1>
            {/*<div
              className="related--desc"
              style={{
                lineHeight: 1.6,
                color: "#585858",
                fontFamily: "Raleway",
                fontSize: 16,
                marginTop: 37,
                marginLeft: "16%",
                marginRight: "16%",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>*/}
            <Grid className="textboxDetails">
              {successResponseData.related_doc &&
              successResponseData.related_doc.length >= 1
                ? successResponseData.related_doc[0].related_doc.map(
                    (items, index) => (
                      <Grid xs={12} sm={12} lg={4} md={4}>
                        <div className="related--inner">
                          <div>
                            <Avatar
                              src={require("../../../Assets/images/form.png")}
                              style={{
                                borderRadius: 0,
                                height: 60,
                                width: 60,
                                marginLeft: 14,
                                marginTop: 26
                              }}
                            />
                          </div>
                          <div style={{ paddingLeft: 19 }}>
                            <h2
                              onClick={() =>
                                templateRedirect(items.id, items.slug)
                              }
                              style={{
                                fontFamily: "Raleway",
                                fontWeight: "normal",
                                fontStyle: "normal",
                                fontSize: 19,
                                color: "#0C0C0DBF"
                              }}
                            >
                              {items.service_title}
                            </h2>
                            {/*<p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontSize: 14,
                          color: "#0C0C0DBF",
                          lineHeight: 1.4,
                        }}
                      >
                        {items.description}
                      </p>*/}
                          </div>
                        </div>
                      </Grid>
                    )
                  )
                : data.map((items, index) => (
                    <Grid xs={12} sm={12} lg={4} md={4}>
                      <div className="related--inner">
                        <div>
                          <Avatar
                            src={require("../../../Assets/images/form.png")}
                            style={{
                              borderRadius: 0,
                              height: 60,
                              width: 60,
                              marginLeft: 14,
                              marginTop: 26
                            }}
                          />
                        </div>
                        <div style={{ paddingLeft: 19 }}>
                          <h2
                            onClick={() =>
                              templateRedirect(items.id, items.slug)
                            }
                            style={{
                              fontFamily: "Raleway",
                              fontWeight: "normal",
                              fontStyle: "normal",
                              fontSize: 19,
                              color: "#0C0C0DBF"
                            }}
                          >
                            {items.name}
                          </h2>
                          {/*<p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontSize: 14,
                          color: "#0C0C0DBF",
                          lineHeight: 1.4,
                        }}
                      >
                        {items.description}
                      </p>*/}
                        </div>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default withRouter(SubCategoryDetails);

let styles = {
  title: {
    fontFamily: "Lora",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 35,
    marginTop: 30,
    color: "#141C49"
  },
  description: {
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: "Raleway",
    fontStyle: "normal",
    lineHeight: 1.4,
    marginTop: 10
  },
  listTitle: {
    position: "absolute",
    top: "22%",
    left: "12%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Raleway",
    fontSize: 14,
    color: "white"
  },
  button: {
    textTransform: "initial",
    borderRadius: 0,
    height: 60,
    width: 200,
    backgroundColor: "#D06900",
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: 18
  },
  button2: {
    textTransform: "initial",
    borderRadius: 0,
    height: 60,
    width: 200,
    backgroundColor: "#F9F9F9",
    color: "#D06900",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: 18,
    border: "1px solid #D06900",
    marginLeft: 20
  },
  question: {
    width: 20,
    height: 20
  },
  faqtext: {
    fontSize: 18,
    fontFamily: "Raleway",
    color: "#D06900",
    fontStyle: "normal",
    fontWeight: "normal",
    paddingLeft: 10,
    cursor: "pointer"
  }
};
