import React, { Component, useState } from 'react';

import {
    Switch,
    Route,withRouter
  } from "react-router-dom";
import Home from './../Pages/Website/Home';
//import Templates from "../Pages/Website/templates"
import Personal from './../Pages/Website/Services/Personal';
import { Redirect } from 'react-router';
import Footer from './../Components/Footer';
import Pages from './../Pages';
import Header from './../Components/Header';
import SignIn from './../Pages/login';
import SignUp from './../Pages/login/Signup';
import Faq from '../Pages/Website/FAQ';
import ContactUs from '../Pages/Website/Contact';
import PrivacyPolicy from '../Pages/Website/PrivacyPolicy';
import Payments from '../Pages/Website/Payments';
import TermsConditions from '../Pages/Website/TermsConditions';
import ProductDetail from '../Pages/Website/Services/subCategoryDetails';
import Form from '../Pages/Website/Form';
import Search from '../Pages/Website/Home/Search';
import ForgotPassword from '../Pages/login/Forgotpassword';
import PaymentDetails from '../Pages/Website/Services/Payment';
import PaymentSubscription from '../Pages/Website/Services/PaymentSubscription';
import NotFound from './../Pages/Website/Services/NotFound';
import EmailVerification from './../Pages/login/emailVerification';
import PaymentStatus from './../Pages/Website/Services/PaymentStatus';
import ResetPassword from '../Components/Resetpassword/ResetPassword';
import ChatDeatilsUser from './../Pages/UserChatWithCustomer/Chat-logs';
import BusinessPage from '../Pages/Website/Services/Business';
import RedirectLog from './../Components/Redirection';
import Test from './../Pages/Website/Test';
import { Payment } from '@material-ui/icons';
const excludeFooter =[ "/signin","/signup","/forgotpassword","/search","/socketapi"]
const excludeHeader =[ "/search","/socketapi"]

const Routes =({location})=> {

    const [demo, setDemo] = useState(true)
const closeAll=()=>{
    setDemo(false)
    setTimeout(() => {
        setDemo(true)
    }, 1000);
}

        return (
            <div onClick={closeAll}>
               { excludeHeader.indexOf(location.pathname) < 0 && <Header demo={demo}/> }
               
                    <Switch>
                        <Route path="/home" exact component={() => {
                            return <Redirect to="/"></Redirect>;
                            }} />
                        <Route path="/" exact component={Home} />
                        {/* <Route path="/templates" exact component={Templates} /> */}
                        <Route path="/signin" exact component={SignIn} />
                        <Route path="/signup" exact component={SignUp} />
                        <Route path="/forgotpassword" exacct component={ForgotPassword}/>
                        <Route path="/faq" exact component={Faq} />
                        <Route path="/paymentDetails/*" exact component={PaymentDetails} />
                        <Route path="/PaymentSubscription/*" exact component={PaymentSubscription} />
                        <Route path="/payment-status" exact component={PaymentStatus} />
                        <Route path="/contactus" exact component={ContactUs} />
                        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                        <Route path="/payments" exact component={Payments} />
                        <Route path="/terms-conditions" exact component={TermsConditions} />
                        <Route path="/testpage" exact component={Test} />
                        <Route path="/personal" exact component={Personal} />
                        <Route path="/business" exact component={BusinessPage} />
                        <Route path="/productDetail" exact component={ProductDetail} />
                        <Route path="/productDetail/:aggrement" exact component={ProductDetail} />
                        <Route path="/productDetail/:myId/:aggrement" exact component={ProductDetail} />
                        <Route path="/customer-support" exact component={RedirectLog} />
                        <Route path="/email-verification" exact component={EmailVerification} />
                        <Route path="/search" exact component={Search}/>
                        <Route path="/agreement/:name" exact component={Form} />
                        <Route path="/agreement/:id/:name" exact component={Form} />
                        <Route path="/userChat" exact component={ChatDeatilsUser} />
                        <Route path="/dashboard" exact component={Pages} />
                        <Route path="/dashboard/*" exact component={Pages} />


                        <Route component={NotFound} />
                    </Switch>
                { excludeFooter.indexOf(location.pathname) < 0 &&  <Footer /> }

            </div>
        )
    }


export default  withRouter(Routes)
