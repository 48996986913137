import React, { Component } from 'react';
import './style.scss';
import Back from './../../../../Assets/icons/back.png';
import { withRouter } from "react-router-dom";


class index extends Component {
    render() {
        return (
            <div className="create-lawyer">
                <h1><span onClick={this.props.history.goBack}><img src={Back} alt="back button" ></img></span>Add New Product</h1>
                <div className="create-lawyer__wrapper">

                    <div className="create-lawyer__wrapper_block">
                        <div className="create-lawyer__wrapper_block_left">
                        <label>Product Name</label>
                        <input type="text" placeholder="Document Name"></input>
                            <label>Product Category</label>
                            <select id="Expertise" className="select-input-type" name="Expertise">
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                                <option value="Legal">Legal</option>
                            </select>

                            <label>Add Images</label>
                            <textarea type="email" placeholder="Images"></textarea>
                            <label>Password</label>
                            <input type="password" placeholder="Password"></input>
                            <label>Expertise</label>
                            <select id="Expertise" className="select-input" name="Expertise">
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                                <option value="Legal">Legal</option>
                            </select>
                        </div>
                        <div className="create-lawyer__wrapper_block_right">
                        <label>Product sub-Category</label>
                            <select id="Expertise" className="select-input-type" name="Expertise">
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                                <option value="Legal">Legal</option>
                            </select>
                            <label>Product Type</label>
                            <input type="radio" id="male" name="" value="" />Immediate download after payment<br />
                            <input type="radio" id="male" name="" value="" />Sent for registration and processing

                            <label>Confirm Password</label>
                            <input type="password" placeholder="Confirm Password"></input>
                        </div>
                    </div>
                    <button className="create-lawyer__wrapper_button_create">Generate Password</button>
                    <div className="create-lawyer__wrapper_button">
                        <button className="create-lawyer__wrapper_button_cancel">Cancel</button>
                        <button className="create-lawyer__wrapper_button_create">Create</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(index);
