// import React, { Component } from 'react';
// import { FlutterWaveButton } from 'flutterwave-react-v3';
// import {getCookie, setCookie, deleteCookie} from '../../../../Utils/cookies';

// import './styles.scss';

// export default class test extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//             subClicked : false
//             }
//         }
//         componentDidMount(){

//             debugger
//             console.log(this.props.total)
//         }
//         config = {
//             public_key: flutterwaveKey,
//             tx_ref: Date.now(),
//             amount:this.props.total,
//             currency: 'NGN',
//             redirect_url:"/payment-status",
//             payment_options: 'Debit/Credit Card',
//             customer: {
//               email: getCookie('email'),
//               phonenumber: getCookie('phone'),
//               name: getCookie('logedinUser'),
//             },
//             customizations: {
//               title: 'LANIDIGITAL',
//               description: getCookie('docs_name'),
//               logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
//             },
//           };
//           fwConfig = {
//             ...this.config,
//             text: 'Proceed',
//             callback: (response) => {
//                 this.updateBackend();
//             },
//             onClose: () => {
//                  ;
//             },

//           };
//     render() {
//         return (
//             <div>
//                 <span>{this.props.total}</span>
//               <FlutterWaveButton {...this.fwConfig} />
//             </div>
//         )
//     }
// }

import React from "react";
import { flutterwaveKey } from "./../../../../Utils/domain";
import { useFlutterwave } from "flutterwave-react-v3";
import { getCookie, setCookie, deleteCookie } from "../../../../Utils/cookies";

export default function App(props) {
  const config = {
    public_key: flutterwaveKey,
    tx_ref: Date.now(),
    amount: props.total,
    currency: "NGN",
    redirect_url: "/payment-status",
    payment_options: "Debit/Credit Card",
    customer: {
      email: getCookie("email"),
      phonenumber: getCookie("phone"),
      name: getCookie("logedinUser")
    },
    customizations: {
      title: "LANIDIGITAL",
      description: getCookie("docs_name"),
      logo:
        "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg"
    }
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className="App">
      {/* <span>{props.total}</span> */}
      <button
        onClick={() => {
          handleFlutterPayment({
            callback: response => {},
            onClose: () => {}
          });
        }}
      >
        Pay Now
      </button>
    </div>
  );
}
