import React from 'react';
import './App.css';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import Routes from  './Routes/Routes';
import { withRouter } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Toaster from '../src/Components/Toster';
import ScrollToTop from '../src/Components/ScrollToTop/ScrollToTop';


const trackingId = "UA-198795830-2"; // UA-198795830-2  Google Analytics tracking ID
const tagManagerArgs = {
  gtmId: 'GTM-MT2Q5GF'  // GTM  tracking ID
}


class App extends React.Component {

    componentDidMount(){
      console.log("Timeout out");
      // if(window.location.host === "lanidigital.com" || window.location.host === "www.lanidigital.com"){
      //   setTimeout(function(){
      //     TagManager.initialize(tagManagerArgs)
      //     ReactGA.initialize(trackingId);
      //     //to report page view
      //     ReactGA.pageview(window.location.pathname)
      //     console.log("Timeout");
      //   },9000)
      // }
      }
    
    
  render() {  
    return(
      <div className="App">
        <BrowserRouter>
        <ScrollToTop />
          <Routes />
            {this.props.showToaster && <Toaster></Toaster>}
        </BrowserRouter>
      </div>
    )
  }
}



export default connect(
  (state) => ({
    showToaster: state.app.toasterConfig.show,
  }),
  {},
)(App);
