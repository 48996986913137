import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Pagination from "react-js-pagination";
import Loader from "../../../../Components/Preloader";
import xlsExport from "../../../../Components/ExcelExport/XlsExport";
import { Button } from "@material-ui/core";
import "./styles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

class CustomerList extends Component {
  state = {
    customerList: [],
    customerListStore: [],
    activePage: 1,
    totalItem: null,
    loader: false,
    filterValue: [],
    selectValue: "",
    allOrdersData: [],
    dateFilter: false,
    startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
    endDate: new Date(),
    startDateValue: "",
    endDateValue: "",
    dateFilterData: [],
    dynamicSelect: "All",
    exportOrder: [],
    allValue: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  componentDidMount() {
    this.setState({
      loader: true
    });
    this.getData();
    this.props.updateBreadcrumbs("customers");
    // if (
    //   localStorage.getItem("allCustomerData") === null ||
    //   localStorage.getItem("allCustomerData") === undefined ||
    //   localStorage.getItem("allCustomerData").length === 0
    // ) {
    //   setTimeout(() => {
    //     this.state.totalItem !== null && this.getDataForSearch();
    //   }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allCustomerData"))
    //   });
    // }
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getDataForSearch() {
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/client/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(
        domain + `/api/auth/client/?page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allCustomerData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results,
        loader: false
      });
    }
  }
  callApiOfSpecificGroup = valueFromFilter => {
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/status_based_clients/${valueFromFilter}/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    } else {
      fetch(
        domain +
          `/api/auth/status_based_clients/${valueFromFilter}/?page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    }
  };
  successResponseSelected = response => {
    this.setState({
      totalItem: response.paginationTotal,
      customerList: response.results,
      customerListStore: response.results,
      loader: false
    });
  };
  errorResponseSelected = err => {};

  getData() {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/client/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/auth/client/?page=" + this.state.activePage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    console.log(this.state.dateFilter);
    this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        console.log(this.state.dateFilter);
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },
            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };
  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  successResponse = response => {
    this.setState(
      {
        totalItem: response.paginationTotal,
        customerList: response.results,
        customerListStore: response.results,
        loader: false
      },
      () => {
        this.getDataForSearch();
      }
    );
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () =>
      this.filterResult(this.state.dynamicSelect)
    );
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  errorResponse = error => {};
  performSearch = e => {
    if (e.length >= 3) {
      let filtered = this.state.allOrdersData.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });
      this.setState({
        customerList: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        customerList: this.state.customerListStore
      });
    }
  };
  exportexcel = e => {
    const xls = new xlsExport(
      this.state.dynamicSelect === "All" && !this.state.dateFilter
        ? this.state.allOrdersData
        : this.state.dynamicSelect === "All" && this.state.dateFilter
        ? this.state.dateFilterData
        : this.state.exportOrder,
      "Customers"
    );
    xls.exportToXLS("export.xls");
  };

  filterResult = (value, id) => {
    try {
      if (value === "All") {
        this.getData();
      }

      if (value === this.state.dynamicSelect) {
        this.setState({ dynamicSelect: value });
        const currentClass = document.getElementsByClassName("filter");

        this.setState({ dynamicSelect: value });
        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("filter-active");
        }
        const element = document.getElementById(value);
        element.classList.add("filter-active");

        if (value === "All") {
          this.setState({
            customerList: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.customerListStore
          });
        } else {
          var orderItem = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => item.status === value)
            : this.state.allOrdersData.filter(item => {
                return item.status === value;
              });
          var filterValue = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => item.status === value)
            : this.state.customerListStore.filter(item => {
                return item.status === value;
              });

          this.setState(
            {
              customerList: filterValue,
              exportOrder: orderItem
            },
            () => {
              var selectedArray = this.state.allOrdersData.find(
                x => x.status === value
              );
              selectedArray !== undefined &&
                this.callApiOfSpecificGroup(selectedArray.status);
            }
          );
        }
      } else {
        this.setState({ dynamicSelect: value }, () => {
          this.getData();
        });
        setTimeout(() => {
          this.filterResult(this.state.dynamicSelect);
        }, 1000);
      }
    } catch (err) {}
  };

  usernameSort = () => {
    this.state.customerList.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
    this.state.customerListStore.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
  };
  changeRefreshDate = () => {
    this.state.customerList.sort((a, b) =>
      a.lastorderdate < b.lastorderdate ? 1 : -1
    );
    this.state.customerListStore.sort((a, b) =>
      a.lastorderdate < b.lastorderdate ? 1 : -1
    );
  };

  ChangeDateFormat = orderDate => {
    if (orderDate != "" && orderDate != null) {
      let format_date = new Date(orderDate);
      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let datestring =
        ("0" + format_date.getDate()).slice(-2) +
        " " +
        monthNames[format_date.getMonth()] +
        " " +
        format_date.getFullYear();
      format_date.toLocaleString().replace(/\//g, "-");
      return datestring;
    }
  };
  handleChangeAll = e => {
    this.setState(
      {
        allValue: e.target.value
      },
      () => {
        this.state.allValue === "All" && this.filterResult(this.state.allValue);
        this.state.allValue === "Active" &&
          this.filterResult(this.state.allValue);
        this.state.allValue === "Inactive" &&
          this.filterResult(this.state.allValue);
        this.state.allValue === "Cancelled" &&
          this.filterResult(this.state.allValue);
      }
    );
  };
  render() {
    this.state.selectValue === "name" && this.usernameSort();
    this.state.selectValue === "lastOrder" && this.changeRefreshDate();

    return (
      <div>
        <ul className="customer__wrapper_block_top_filter">
        {this.state.meta_title !== "" &&  <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
    }
          <li className="filter mobileView filter-active">
            <select
              className="selectDropdown"
              value={this.state.allValue}
              onChange={e => {
                this.handleChangeAll(e);
              }}
            >
              <option id="All" className=" filter filter-active" value="All">
                All
              </option>
              <option id="Active" className="filter" value="Active">
                Active
              </option>
              <option id="Inactive" className="filter" value="Inactive">
                Inactive
              </option>
              <option id="Cancelled" className="filter" value="Cancelled">
                Cancelled
              </option>
            </select>
          </li>
          <li className="filter mobileView">
            <select
              className="selectDropdown"
              value={this.state.selectValue}
              onChange={e => {
                this.handleChange(e);
              }}
            >
              <option className="optionValue" value="name">
                Name
              </option>
              <option className="optionValue" value="lastOrder">
                Last Order On
              </option>
            </select>
          </li>
        </ul>
        <div>
          {getCookie("group") === "1" && this.state.allOrdersData.length >= 1 && (
            <span className="buttonExport">
              <Button onClick={e => this.exportexcel(e)}>
                Export Order Details
              </Button>
            </span>
          )}
        </div>
        <div className="orders__wrapper_block_top_search">
          {this.state.allOrdersData.length >= 1 && (
            <>
              <input
                onChange={event => this.performSearch(event.target.value)}
                name="search"
                placeholder="search..."
              />
              <span className="orders__wrapper_block_top_search_icon">
                <FaSearch />
              </span>
            </>
          )}
        </div>
        {/* Date Button start */}
        <div className="date_filter">
          <div className="date_input">
            <span
              style={{
                color: this.state.dateFilter ? "#00A5E6" : "",
                borderBottom: "1px"
              }}
            >
              Start Date
            </span>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleStartDateChange}
              name="startDate"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <span>---</span>
          <div className="date_input">
            <span style={{ color: this.state.dateFilter ? "#00A5E6" : "" }}>
              End Date
            </span>
            <DatePicker
              selected={this.state.endDate}
              onChange={this.handleEndDateChange}
              name="startDate"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <button
            className={this.state.dateFilter ? "show_active_btn" : "show_btn"}
            onClick={e => this.dateFilter(e)}
          >
            {this.state.dateFilter ? "Clear" : "Show"}
          </button>
        </div>

        {/* Date button end  */}
        <div>
          <Container>
            {this.state.customerList.map(order => (
              <Grid xs={12} sm={12}>
                <div key={order.id}>
                  <div className="customerList">
                    <div className="custorderRow">
                      <Grid xs={6} sm={6}>
                        <div className="nameCustom">
                          <p>NAME</p>
                          <p>{order.username}</p>
                        </div>
                      </Grid>
                      <Grid xs={6} sm={6}>
                        <div className="nameCustom">
                          <p>Last Order On.</p>
                          <p>
                            {order.lastorderdate != null
                              ? this.ChangeDateFormat(order.lastorderdate)
                              : " "}
                          </p>
                        </div>
                      </Grid>
                    </div>

                    <div className="phorderRow">
                      <Grid xs={6} sm={6}>
                        <div className="phoneCustom">
                          <p>Phone</p>
                          <p>{order.phone}</p>
                        </div>
                      </Grid>
                      <Grid xs={6} sm={6}>
                        <div className="planOrder">
                          <p>Subscription Plan</p>
                          <p>{order.plan}</p>
                        </div>
                      </Grid>
                    </div>
                    <div className="phorderRow">
                      <Grid xs={6} sm={6}>
                        <div className="planOrder">
                          <p>Satus</p>
                          <p>{order.status}</p>
                        </div>
                      </Grid>
                    </div>
                    <div className="emorderRow">
                      <div class="emailCustom">
                        <p>Email ID</p>
                        <p>{order.email}</p>
                      </div>
                    </div>
                    <div className="linkData">
                      <Link
                        to={{
                          pathname: "/dashboard/customers/customer-details",
                          customerId: order.id
                        }}
                      >
                        <li>
                          <span className="action">View</span>
                        </li>
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Container>
        </div>
        <Pagination
          prevPageText="<"
          nextPageText=">"
          firstPageText=""
          lastPageText=""
          pageRangeDisplayed={3}
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.state.totalItem}
          //pageRangeDisplayed={Math.ceil(this.state.totalItem/10)}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(CustomerList)
);
