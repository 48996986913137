
import ChatComponent from "../../../Components/ChatComponent"

import React, { Component } from 'react';
import { FaSearch } from "react-icons/fa";

import './style.scss';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getCookie, setCookie } from '../../../Utils/cookies';
import { domain } from '../../../Utils/domain';
import { updateBreadcrumbs } from '../../../redux/modules/app/actions';



class index extends Component {
    constructor(props){
        super(props);
    this.state={
        contacts: [],contactStore:[], searchValue:null,
        chatData:[], 
        responseOfUser:[],
        filterValue:null, filterId:null,
    }
    
    }

        render() {
         
          return (
            <div className="customerCare userChat">
                
                   
                        <div className="customerCare__wrapper_block_orderlist">
                           
                           <ChatComponent />

                        </div>
                   
            </div>
        )
    }
}

export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      {updateBreadcrumbs },
    )(index),
  );
  
