import React from 'react';
import Burger from './Nav/Burger'

function Ham({dataProps}) {
  return (
    <div className="Ham">
       <div className="menu--mobile">
         <Burger dataProps={dataProps} />
        </div>
    </div>
  );
}

export default Ham;
