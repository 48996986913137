import {
    UPDATE_AUTH,
    UPDATE_MODAL,
    UPDATE_BREADCRUMBS,
    UPDATE_TOASTER_CONFIG,
    UPDATE_PROFILE_Image,
    NOTIFICATION_COUNT 
  } from './types';
  
  export function updateAuth(data) {
    return (dispatch) => {
      dispatch(setState(UPDATE_AUTH, data));
    };
  }
  
  export function updateShowModal(boolVal) {
    return (dispatch) => {
      dispatch(setState(UPDATE_MODAL, boolVal));
    };
  }
  export function updateToasterConfig(obj) {
    return (dispatch) => {
      dispatch(setState(UPDATE_TOASTER_CONFIG, obj));
    };
  }
  export function updateBreadcrumbs(key) {
    return (dispatch) => {
      dispatch(setState(UPDATE_BREADCRUMBS, key));
    };
  }
  export function updateProfileImg(key) {
    return (dispatch) => {
      dispatch(setState(UPDATE_PROFILE_Image, key));
    };
  }
    export function updateNotificationCount(key) {
      return (dispatch) => {
        dispatch(setState(NOTIFICATION_COUNT, key));
      };
  }

  
  function setState(type, response) {
    return { type, response };
  }
  