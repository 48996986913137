import React, { Component } from 'react';
import Breadcrumbs from './../Components/Breadcrumbs'
import Sidebar from './../Components/Sidebar';
import Dashboard from './Dashboard'
import './styles.scss';
export default class index extends Component {
    constructor(props){
        super(props);
        this.state={
            showNavber:false
        }
    }
    componentDidCatch(){
    //      
    //    this.props.history.push("/");
    //    window.location.reload();
      }
    changeArrow=()=>{
       this.setState({
            showNavber:true
        })
    }
    changeArrowFalse=()=>{
        this.setState({
             showNavber:false
         })
     }
    render() {
    
        return (
            <div className="main-page">
                <div className="main-page__wrapper">
                    <Breadcrumbs />
                    <div className="main-page__wrapper_blocks">
                        <div className="main-page__wrapper_blocks_navbar">
                            <Sidebar  showNavber={this.state.showNavber} changeArrow={this.changeArrow} changeArrowFalse={this.changeArrowFalse}/>
                        </div>
                        <div className="main-page__wrapper_blocks_dynamic-content">
                            <Dashboard showNavber={this.state.showNavber} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
