import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from './../../../../Assets/icons/Group 1497.png';
import './style.scss'
import Back from './../../../../Assets/icons/back.png';
import PlusIcon from './../../../../Assets/images/plus-icon.png';
import { withRouter } from "react-router-dom";
import { getCookie } from '../../../../Utils/cookies';
import {domain,ifameDomain} from './../../../../Utils/domain';


let planId =  null

export default class index extends Component {

    componentDidMount(){
         
        planId = this.props.match.params.id;
   }

    render() {
        return (
            <div className="faq-details">
            <div className="faq-details__wrapper">
                <div className="faq-details__wrapper_block">
                    <div className="faq-details__wrapper_block_top_heading">
                        <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span>Edit Subscription Details</p>
                    </div>
                   
                </div>
                <div style={{paddingTop:"50px"}}>
                <iframe src={ifameDomain+"/admin/subscription/subscriptioncategory/"+planId +"/change/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="850" height="550"></iframe>
                </div>
                
             </div>
        </div>
        )
    }
}
