import React, { Component } from 'react';
import {setCookie} from './../../Utils/cookies';
import {defaultHeaders,domain} from '../../Utils/domain';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateToasterConfig } from '../../../src/redux/modules/app/actions';

class emailVerification extends Component {

    state = {
        activationKey : '',
        redirect : '',
        userName : ''
    }

    userSuccessResponse = (response) => {
        if(response.status == "Activation link has expired. Please create your account again"){
            this.props.updateToasterConfig({
                show: true,
                message: "Activation link has been expired. Please create your account again",
                color: '#D06900',
              }) 
            this.props.history.push('/signup');
        }else if(response.status == "User is already active"){
            this.props.updateToasterConfig({
                show: true,
                message: "You have already activated the account.",
                color: '#D06900',
              }) 
            this.props.history.push('/signin');
        }else if(response.user.groups){
            setCookie("group", response.user.groups)
            setCookie("token", response.token)
            setCookie("userId", response.user.id)
            setCookie("email", response.user.email)
            setCookie("phone", response.user.phone)
            setCookie("logedinUser", response.user.username)
            this.state.redirect ===  "/" ? this.props.history.push('/dashboard') : this.props.history.push(this.state.redirect);
        }else{
            this.props.updateToasterConfig({
                show: true,
                message: "Somthing went wrong Please try after some time.",
                color: '#D06900',
              }) 
            this.props.history.push('/signin');
        }
    }
    errorResponse = (error) => {
        this.props.updateToasterConfig({
            show: true,
            message: "Somthing went wrong Please try after some time.",
            color: '#D06900',
          }) 
        this.props.history.push('/signin');
    }

    validateUser = () => {
        
        let data = {
            "user" : this.state.userName,
            "activation_key" : this.state.activationKey
        }

        fetch(domain+"/api/auth/activate/",{
            method  : "POST", 
            headers :{
                 'Content-Type' : "application/json",
                 'Api-Key': 'sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79'
                },
            body    : JSON.stringify(data)    
    })
        .then(response => response.json())
        .then( responsejson => this.userSuccessResponse(responsejson))
        .catch(error => this.errorResponse(error))
    }
  
    componentDidMount = () => {
        this.props.location.search && console.log(this.props);
        let url = this.props.location.search
        let split = url.split("&");
        let activationKeyUrl = split[0].split("?activation_key=");
        let redirectUrl = split[2].split("url=");
        let userNameUrl = split[1].split("user=");

        this.setState({
            activationKey : activationKeyUrl[1],
            redirect : redirectUrl[1],
            userName : userNameUrl[1]

        }, () =>  this.validateUser())
       
    }
    render() {
        return (
            <div style={{minHeight:"500px"}}>
                <h1>Verifying Account </h1>
            </div>
        )
    }
}
export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      { updateToasterConfig }
    )(emailVerification)
  );