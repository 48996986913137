import React, { Component } from 'react'
import './style.scss'

export default class index extends Component {
    render() {
        return (
           
                <div className="pz-loader">
                    <div className="div-spread"></div>
                    <div className="div-spread"></div>
                    <div className="div-spread"></div>
                </div>
          
        )
    }
}





