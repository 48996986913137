// import React, { Component } from 'react'
import {domain} from './../../../Utils/domain';
     
let defaultHeaders =  { 
    // 'Content-Type' : "application/json",
    'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
}

 export const ServiceCategoriesAndLists = (successResponse, errorResponse,serviceTypeId) =>{
 
    fetch(`${domain}/api/services/categories-list2/${serviceTypeId}/`,{
        method  : "GET",
         headers : defaultHeaders 
    })
    .then(response => response.json())
    .then(response => successResponse(response))
    .catch(error => errorResponse(error))

}

 export const ServiceListDetails = (successResponse, errorResponse,listId) =>{
     fetch(`${domain}/api/services/list/${listId}/`,{
         method  : "GET",
         headers : defaultHeaders  
     })
     .then(response => response.json())
     .then((responseJson) => { successResponse(responseJson) })
     .catch((error) => {  errorResponse(error) })
 }

 export const ServiceProducts = (successLists,errorLists,categoryId) =>{
    fetch(`${domain}/api/services/service_details/${categoryId}/`,{
        method  : "GET",
        headers : defaultHeaders  
    })
    .then(response => response.json())
    .then((responseJson) => { successLists(responseJson) })
    .catch((error) => {  errorLists(error) })
}

export const servicesBannercontent = (successLists,errorLists) =>{
    fetch(`${domain}/api/services/types/`,{
        method  : "GET",
        headers : defaultHeaders  
    })
    .then(response => response.json())
    .then((responseJson) => { successLists(responseJson) })
    .catch((error) => {  errorLists(error) })
}
