import React, { Component } from "react";
import "./style.scss";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Add from "./../../../Assets/icons/Group 1497.png";
import Back from "./../../../Assets/icons/back.png";
//import { getFaqCategories } from '../../Website/WebServices/faqDetailAndSearch';
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain, defaultHeaders } from "../../../Utils/domain";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";
import { getCookie } from "../../../Utils/cookies";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
class index extends Component {
  state = {
    faqsCategories: [],
    faqCategoryList: [],
    faqId: "",
    faqsCategoryListstore: [],
    activePage: 1,
    totalItem: null,
    loader: false,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  componentDidMount() {
    this.props.updateBreadcrumbs("Faqs");
    this.setState({
      loader: true
    });
    this.getData();
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  getData() {
    let token = getCookie("token");
    fetch(domain + "/api/home/adminlist_homefaqcategory/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        this.successResponse(responseJson);
      })
      .catch(error => {
        this.errorResponse(error);
      });
  }
  successResponse = response => {
    this.setState({
      totalItem: response.paginationTotal,
      faqsCategories: response,
      faqsCategoryListstore: response.results,
      loader: false
    });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getData());
  }

  errorResponse = error => {};

  goToList(id, list) {
    this.setState({ faqId: id, faqCategoryList: list }, () =>
      this.props.history.push("/dashboard/faq-Details", {
        faqid: this.state.faqId,
        faq_detail: this.state.faqCategoryList
      })
    );
  }
  performSearch = e => {
    if (e.length >= 3) {
      let filtered = this.state.faqsCategories.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });

      this.setState({
        faqsCategories: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        faqsCategories: this.state.faqsCategoryListstore
      });
    }
  };

  render() {
    // this.state.faqsCategories.sort((a,b) => (a.faq_category_name > b.faq_category_name) ? 1 : ((b.faq_category_name > a.faq_category_name) ? -1 : 0));
    // this.state.faqsCategoryListstore.sort((a,b) => (a.faq_category_name > b.faq_category_name) ? 1 : ((b.faq_category_name > a.faq_category_name) ? -1 : 0) );
    return (
      <div className="faqs">
        {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="faqs__wrapper">
          {this.state.loader ? (
            <Loader />
          ) : (
            <div>
              <div className="faqs__wrapper_block">
                <div className="faqs__wrapper_block_top_heading">
                  {/* <span><img src={Back} alt="back button" ></img></span> */}
                  <p>FAQs</p>
                </div>
                <div className="customer__wrapper_block_top_search">
                  <input
                    onChange={event => this.performSearch(event.target.value)}
                    name="search"
                    placeholder="search..."
                  />
                  <span className="customer__wrapper_block_top_search_icon">
                    <FaSearch />
                  </span>
                </div>
              </div>
              <div className="faqs__wrapper_block_box">
                <div className="faqs__wrapper_block_box_section">
                  <Grid container spacing={2}>
                    {this.state.faqsCategories.map(item => {
                      return (
                        <Grid key={item.id} item xs={6}>
                          <Link to={"/dashboard/faq-Details/" + item.id}>
                            <Paper>{item.faq_category_name}</Paper>
                          </Link>
                        </Grid>
                      );
                    })}
                    <Grid item xs={6}>
                      <Link to="/dashboard/faqs/add-faqs">
                        <Paper
                          className="faq--add--group"
                          style={{ display: "flex" }}
                        >
                          <img
                            style={{ width: "20px", marginRight: "10px" }}
                            src={Add}
                            alt="add"
                          />
                          Add New Group
                        </Paper>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          prevPageText="<"
          nextPageText=">"
          firstPageText=""
          lastPageText=""
          pageRangeDisplayed={5}
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={this.state.totalItem}
          //pageRangeDisplayed={Math.ceil(this.state.totalItem/10)}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
