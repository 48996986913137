import react from "react";
export function LeftSideLoop(k, data, totalCount, addedData) {
  if (totalCount === 2) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
  } else if (totalCount === 3) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 4) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 5) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 6) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 7) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 8) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 9) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 10) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 11) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 12) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[10].children[0].innerHTML = `${data.children[10].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 13) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[10].children[0].innerHTML = `${data.children[10].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[11].children[0].innerHTML = `${data.children[11].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 14) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[10].children[0].innerHTML = `${data.children[10].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[11].children[0].innerHTML = `${data.children[11].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[12].children[0].innerHTML = `${data.children[12].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else if (totalCount === 15) {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[10].children[0].innerHTML = `${data.children[10].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[11].children[0].innerHTML = `${data.children[11].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[12].children[0].innerHTML = `${data.children[12].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[13].children[0].innerHTML = `${data.children[13].children[0].innerHTML.concat(
      k + 1
    )}?`;
  } else {
    data.children[0].children[0].innerHTML = `${addedData.concat(k + 1)}?`;
    data.children[1].children[0].innerHTML = `${data.children[1].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[2].children[0].innerHTML = `${data.children[2].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[3].children[0].innerHTML = `${data.children[3].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[4].children[0].innerHTML = `${data.children[4].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[5].children[0].innerHTML = `${data.children[5].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[6].children[0].innerHTML = `${data.children[6].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[7].children[0].innerHTML = `${data.children[7].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[8].children[0].innerHTML = `${data.children[8].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[9].children[0].innerHTML = `${data.children[9].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[10].children[0].innerHTML = `${data.children[10].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[11].children[0].innerHTML = `${data.children[11].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[12].children[0].innerHTML = `${data.children[12].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[13].children[0].innerHTML = `${data.children[13].children[0].innerHTML.concat(
      k + 1
    )}?`;
    data.children[14].children[0].innerHTML = `${data.children[14].children[0].innerHTML.concat(
      k + 1
    )}?`;
  }
}
