import React, { Component } from 'react';
import './style.scss';
import Profile from './../../../../Assets/images/profile-img.png';
import Orders from './../../MobileView/Order/Order'
import Back from './../../../../Assets/icons/back.png';
import { withRouter } from "react-router-dom";
// import { getLawyerDetails } from '../../../Website/WebServices/LawyersList'
// import { saveProfileDetails } from '../../../Website/WebServices/editProfile';
import Edit_pencil from '../../../../Assets/images/edit-pencil.svg';
import { domain } from '../../../../Utils/domain';
import { getCookie } from '../../../../Utils/cookies';
// let lawyerDetails = {

//         id : '1236',
//         experties : 'Business',
//         cases: '100',
//         status: 'Active',
//         lawyerDetails : {
//             customerName: 'Wone Peo',
//             customerEmail: 'johndoe@email.com',
//             customerMobile: '77XXXXXXXX',
//         },
//         address : {
//             addressLine1 : 'Apartment #1234, A block',
//             addressLine2 : 'Apartment Name',
//             city : 'India',
//             state : 'Karnataka',
//             pincode : '12345'
//         }

//     }
class index extends Component {

    state={
        lawyerProfile:'',
        uFname:'',
        uEmail: '',
        uMobile:'',
        uExpertise:'',
        cases:'',
        getInputs:false
    }

    saveProfile(){
        let userId = this.props.location.lawyerId
        let params = {
            "id": userId,
            "first_name":this.state.uFname,
            "last_name": this.state.uLname,
            "email": this.state.uEmail,
            "customerMobile": this.state.uMobile,
            "expertise":this.state.uExpertise,
            "cases": this.state.cases
        }
        this.saveProfileDetails(params);
        this.getLawyerDetails();
    }

    saveProfileDetails(params){
        let token = getCookie("token")
        fetch( domain + '/api/auth/edit-profile/',{
                method:"POST",
                headers: {
                            'Content-Type' : "application/json",
                            'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                            'Authorization': `Token ${token}`
                        },
                body: JSON.stringify(params)
            })
        .then(response=>response.json())
        .then((response)=> this.successResponse(response))
        .catch(error=> this.errorResponse(error))
    }

    successResponse=(response)=>{
       this.setState({getInputs:false})
    }

    errorResponse=(error)=>{

    }

    componentDidMount(){
        this.getLawyerDetails()
    }

    getLawyerDetails(){
        let id = this.props.location.lawyerId;
        let token = getCookie("token");
        fetch( domain + `/api/auth/lawyer/${id}`,{
               headers: {
                            'Content-Type' : "application/json",
                            'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                            'Authorization': `Token ${token}`
                         }
            })
        .then(response=>response.json())
        .then((response)=> this.successDetails(response))
        .catch(error=> this.errorDetails(error))
    }

    successDetails=(response)=>{

        this.setState({ lawyerProfile: response },
            ()=>{
                if (this.state.lawyerProfile.lawyerDetails && this.state.lawyerProfile.lawyerDetails.customerName && this.state.lawyerProfile.lawyerDetails.customerName !== '' ){
                this.setState({ uFname : this.state.lawyerProfile.lawyerDetails.customerName })

              }
               if (this.state.lawyerProfile.lawyerDetails && this.state.lawyerProfile.lawyerDetails.customerEmail && this.state.lawyerProfile.lawyerDetails.customerEmail !== '' ){
                this.setState({ uEmail : this.state.lawyerProfile.lawyerDetails.customerEmail })

              }
              if (this.state.lawyerProfile.lawyerDetails && this.state.lawyerProfile.lawyerDetails.customerMobile && this.state.lawyerProfile.lawyerDetails.customerMobile !== '' ){
                this.setState({ uMobile : this.state.lawyerProfile.lawyerDetails.customerMobile })

              }
              if (this.state.lawyerProfile.lawyerDetails && this.state.lawyerProfile.expertise && this.state.lawyerProfile.expertise !== '' ){
                this.setState({ uExpertise : this.state.lawyerProfile.expertise })

              }
            //   if (this.state.lawyerProfile.lawyerDetails &&this.state.lawyerProfile.cases && this.state.lawyerProfile.cases !== '' ){
            //     this.setState({ uExpertise : this.state.lawyerProfile.expertise })

            //   }
        })
    }

    errorDetails=(error)=>{

    }

    render() {
        return (
            <>
            <div className="lawyerDetails">
                <div className="lawyerDetails__wrapper">
                    <div className="lawyerDetails__wrapper_heading">
                        <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span> User Profile</p>
                        <div style={{ display:'flex',paddingLeft:540,marginTop:20 }}>
                            <img src={Edit_pencil} style={{ height:18,width:18 }}/>
                            <div className="editText" onClick={()=>this.setState({ getInputs: true })}>Edit</div>
                        </div>
                    </div>
                    <div className="lawyerDetails__wrapper_content">
                        {/* <div className="lawyerDetails__wrapper_content_top">
                            <p className="lawyerDetails__wrapper_content_top_orderid">Order #{lawyerDetails.id}</p>
                            <p className="lawyerDetails__wrapper_content_top_status">{lawyerDetails.status}</p>
                        </div> */}

                        <div className="lawyerDetails__wrapper_content_block">
                            <div className="lawyerDetails__wrapper_content_block_left">
                            { this.state.getInputs === true ?
                          <input value={this.state.uFname}
                                 className="inputText"
                                 onChange={(e)=>this.setState({ uFname:e.target.value })}/>

                         :
                        <p className="lawyerDetails__wrapper_content_service">{this.state.uFname}</p>
                       }
                       { this.state.getInputs === true &&
                          <input value={this.state.uLname}
                                 className="inputText"
                                 onChange={(e)=>this.setState({ uLname:e.target.value })}/>
                       }
                                <img src={Profile} alt="profile" />
                            </div>
                            <div className="lawyerDetails__wrapper_content_block_right">
                                <div className="lawyerDetails__wrapper_section">
                                    <p className="left-side">Email ID</p>
                                    { this.state.getInputs === true ?
                                        <input value={this.state.uEmail}
                                                className="inputText"
                                                onChange={(e)=>this.setState({ uEmail:e.target.value })}/>
                                        :
                                    <p className="right-side">{this.state.uEmail}</p>
                                    }
                                </div>
                                <div className="lawyerDetails__wrapper_section">
                                    <p className="left-side">Phone number</p>
                                    { this.state.getInputs === true ?
                                        <input value={this.state.uMobile}
                                                className="inputText"
                                                onChange={(e)=>this.setState({ uMobile:e.target.value })}/>
                                        :
                                    <p className="right-side">{this.state.uMobile}</p>
                                    }
                                </div>
                                <div className="lawyerDetails__wrapper_section">
                                    <p className="left-side">Expertise In</p>
                                    { this.state.getInputs === true ?
                                        <input value={this.state.uExpertise}
                                                className="inputText"
                                                onChange={(e)=>this.setState({ uExpertise:e.target.value })}/>
                                        :
                                    <p className="right-side">{this.state.uExpertise}</p>
                                    }
                                </div>
                                <div className="lawyerDetails__wrapper_section">
                                    <p className="left-side">No. of Cases</p>
                                    { this.state.getInputs === true ?
                                        <input value={this.state.cases}
                                                className="inputText"
                                                onChange={(e)=>this.setState({ cases:e.target.value })}/>
                                        :
                                     <p className="right-side">{this.state.cases}</p>
                                   }
                                </div>
                                <div className="lawyerDetails__wrapper_section">
                                    <p className="left-side">Deactivate Lawyer Account</p>
                                    {/* <p className="right-side">{this.state.lawyerDetails.status}</p> */}
                                </div>



                                </div>

                            </div>
                            { this.state.getInputs &&
                            <div style={{ marginTop:26,marginLeft:'40%', }}>
                            <button className="cancel" onClick={()=>this.setState({ getInputs: false,uFname:this.state.uFname })}>cancel</button>
                            <button className="save" onClick={()=>this.saveProfile()}>save</button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="lawyerDetails_orders-block">
                    <Orders />
                </div>
                </>
        )
    }
}
export default withRouter(index);
