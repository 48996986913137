import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Avatar, ListItem } from "@material-ui/core";
import styled from "styled-components";
import { getCookie } from "../../../Utils/cookies";
import { domain, defaultHeaders } from "../../../Utils/domain";
import "./style.scss";
import { SettingsInputHdmiSharp } from "@material-ui/icons";
import { deleteCookie } from "../../../Utils/cookies";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 15px 30px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: rgb(255, 255, 255);
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    padding-left: 0px;
    padding-bottom: 30px;
    padding-top: 30px;
    transition: transform 0.3s ease-in-out;

    li {
      color: rgb(20, 28, 73);
      a {
        color: rgb(20, 28, 73);
        font-size: 15px;
        font-family: "Raleway";
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        letter-spacing: 1.08px;
      }
      li {
        padding: 15px 10px;
        .colorHead {
          color: rgb(20, 28, 73);
          font-size: 15px;
          font-family: "Raleway";
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          letter-spacing: 1.08px;
        }
        .smallData {
          a {
            color: rgb(20, 28, 73);
            font-size: 14px;
            font-family: "Raleway";
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            letter-spacing: 1.08px;
          }
        }
      }
    }
  }
`;

const RightNav = props => {
  const [displayId, setId] = useState(null);
  const [displayIdP, setIdP] = useState(null);
  const [displayIdC, setIdC] = useState(null);
  const [showModalP, setMethodModalP] = useState(false);
  const [showModalB, setMethodModalB] = useState(false);
  const [showModalC, setMethodModalC] = useState(false);
  const [showDataPData, setshowDataPData] = useState(false);
  const [showDataBData, setshowDataBData] = useState(false);
  const [showDataCData, setshowDataCData] = useState(false);
  const [Personalservices, setServicesP] = useState([]);
  const [Buisnessservices, setServicesB] = useState([]);
  const [Corporatefilings, setServicesC] = useState([]);

  useEffect(() => {
    fetch(domain + `/api/services/categories-list2/${2}/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(response => serviceSuccessResponse(response))
      .catch(error => serviceErrorResponse(error));
  }, []);
  useEffect(() => {
    fetch(domain + `/api/services/categories-list2/${3}/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(response => serviceSuccessResponseB(response))
      .catch(error => serviceErrorResponseB(error));
  }, []);
  useEffect(() => {
    fetch(domain + `/api/services/categories-list2/${4}/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(response => serviceSuccessResponseC(response))
      .catch(error => serviceErrorResponseC(error));
  }, []);

  const serviceSuccessResponseB = response => {
    setServicesB(response);
  };

  const serviceErrorResponseB = error => {};
  const serviceSuccessResponseC = response => {
    setServicesC(response);
  };

  const serviceErrorResponseC = error => {};
  const serviceSuccessResponse = response => {
    setServicesP(response);
  };

  const serviceErrorResponse = error => {};
  const showDataP = () => {
    setMethodModalP(!showModalP);
  };
  const showDataB = () => {
    setMethodModalB(!showModalB);
  };
  const showDataC = () => {
    setMethodModalC(!showModalC);
  };
  const showDataPDataOpen = (e, data) => {
    setshowDataPData(!showDataPData);
    setIdP(data);
  };
  const showDataBDataOpen = (e, data) => {
    setshowDataBData(!showDataBData);
    setId(data);
  };
  const showDataCDataOpen = (e, data) => {
    setshowDataCData(!showDataCData);
    setIdC(data);
  };

  const logout = () => {
    var data = JSON.parse(localStorage.getItem("storeDb"));

    if (data !== null) {
      fetch(domain + "/api/chat/save_chat_conversations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify({
          chatlog_conversations: data
        })
      })
        .then(response => response.json())
        .then(response => successResponseSaveChat(response))
        .catch(error => errorResponse(error));
    }
    if (data === null) {
      localStorage.clear();
      sessionStorage.clear();
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var key = cookies[i].split("=");
        deleteCookie(key[0]);
        //document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
      props.history.push("/");
    }
  };

  const successResponseSaveChat = res => {
    if (res) {
      localStorage.clear();
      sessionStorage.clear();
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var key = cookies[i].split("=");
        deleteCookie(key[0]);
        // document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }

      props.history.push("/");
    }
  };

  const errorResponse = error => {
    localStorage.clear();
    sessionStorage.clear();
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var key = cookies[i].split("=");
      deleteCookie(key[0]);
    }
    props.history.push("/");
  };

  return (
    <Ul open={props.open} className="scrollNavBar">
      <span className="exploreBtn">Explore</span>
      <li onClick={props.close}>
        <Link to="/"> HOME </Link>
      </li>
      <div className="displayFlex">
        <li onClick={props.close}>
          <Link to={{ pathname: "/personal", id: 2 }}>PERSONAL SERVICES</Link>
        </li>
        <span className="arrows" onClick={() => showDataP()}>
          <Avatar
            src={require("./../../../Assets/images/cheveron-down.png")}
            style={styles.image}
          />
        </span>
      </div>
      <li className={showModalP ? "displayData" : "toggleData"}>
        {Personalservices.map((item, index) => {
          return (
            <>
              <div key={index} className="displayFlex">
                <li>
                  <Link
                    to={{
                      pathname: "/personal/",
                      state: {
                        id: index
                      }
                    }}
                  >
                    <span className="colorHead" onClick={props.close}>
                      {item.category}
                    </span>
                  </Link>
                </li>
                <span
                  className="arrows"
                  onClick={e => showDataPDataOpen(e, item.id)}
                >
                  <Avatar
                    src={require("./../../../Assets/images/cheveron-down.png")}
                    style={styles.image}
                  />
                </span>
              </div>
              {displayIdP === item.id && (
                <li className={showDataPData ? "displayData" : "toggleData"}>
                  {item.service_category.map((item, index) => {
                    return (
                      <div key={index}>
                        <li className="smallData">
                          <Link
                            onClick={props.close}
                            key={index}
                            to={{
                              pathname: "/productDetail/" + item.slug,
                              state: {
                                id: item.id
                              }
                            }}
                          >
                            <a
                              key={index}
                              style={{
                                fontSize: 14,
                                marginBottom: 6,
                                color: "#585858",
                                display: "inline-block",
                                fontFamily: "Raleway"
                              }}
                            >
                              {item.service_title}
                            </a>
                          </Link>
                        </li>
                      </div>
                    );
                  })}
                </li>
              )}
            </>
          );
        })}
      </li>
      <div className="displayFlex">
        <li onClick={props.close}>
          <Link to={{ pathname: "/business", id: 3 }}>BUSINESS SERVICES</Link>
        </li>
        <span className="arrows" onClick={() => showDataB()}>
          <Avatar
            src={require("./../../../Assets/images/cheveron-down.png")}
            style={styles.image}
          />
        </span>
      </div>
      <li className={showModalB ? "displayData" : "toggleData"}>
        {Buisnessservices.map((item, index) => {
          return (
            <>
              <div key={index} className="displayFlex">
                <li>
                  <Link
                    to={{
                      pathname: "/business/",
                      state: {
                        id: index
                      }
                    }}
                  >
                    <span className="colorHead" onClick={props.close}>
                      {item.category}
                    </span>
                  </Link>
                </li>
                <span
                  className="arrows"
                  onClick={e => showDataBDataOpen(e, item.id)}
                >
                  <Avatar
                    src={require("./../../../Assets/images/cheveron-down.png")}
                    style={styles.image}
                  />
                </span>
              </div>
              {displayId === item.id && (
                <li className={showDataBData ? "displayData" : "toggleData"}>
                  {item.service_category.map((item, index) => {
                    return (
                      <div key={index}>
                        <li className="smallData">
                          <Link
                            onClick={props.close}
                            key={index}
                            to={{
                              pathname: "/productDetail/" + item.slug,
                              state: {
                                id: item.id
                              }
                            }}
                          >
                            <a
                              key={index}
                              style={{
                                fontSize: 14,
                                marginBottom: 6,
                                color: "#585858",
                                display: "inline-block",
                                fontFamily: "Raleway"
                              }}
                            >
                              {item.service_title}
                            </a>
                          </Link>
                        </li>
                      </div>
                    );
                  })}
                </li>
              )}
            </>
          );
        })}
      </li>

      {/*<div className="displayFlex">
        <li onClick={props.close}>
          <Link to={{ pathname: "/categoryDetail", id: 4 }}>
            CORPORATE FILINGS
          </Link>
        </li>
        <span className="arrows" onClick={() => showDataC()}>
          <Avatar
            src={require("./../../../Assets/images/cheveron-down.png")}
            style={styles.image}
          />
        </span>
      </div>
      <li className={showModalC ? "displayData" : "toggleData"}>
        {Corporatefilings.map((item, index) => {
          return (
            <>
              <div key={index} className="displayFlex">
                <li>
                  <span className="colorHead">{item.category}</span>
                </li>
                <span
                  className="arrows"
                  onClick={(e) => showDataCDataOpen(e, item.id)}
                >
                  <Avatar
                    src={require("./../../../Assets/images/cheveron-down.png")}
                    style={styles.image}
                  />
                </span>
              </div>
              {displayIdC === item.id && (
                <li className={showDataCData ? "displayData" : "toggleData"}>
                  {item.service_category.map((item, index) => {
                    return (
                      <div key={index}>
                        <li>
                            
                          <Link to="/productDetail" onClick={props.close}>
                            {item.service_title}
                          </Link>
                        </li>
                      </div>
                    );
                  })}
                </li>
              )}
            </>
          );
        })}
      </li>*/}

      <li onClick={props.close}>
        <Link to="/contactus"> CONTACT </Link>
      </li>
      <li onClick={props.close}>
        <Link to="/faq">FAQ</Link>
      </li>
      <li onClick={props.close}>
        <div>
          {getCookie("userId") === "" && (
            <Link className="signBtn" to="/signin">
              SIGN IN
            </Link>
          )}
          {getCookie("userId") !== "" && (
            <Link
              to="/dashboard/"
              onClick={() => {
                window.location.href = "/dashboard";
              }}
            >
              MY ACCOUNT
            </Link>
          )}

          {getCookie("userId") === "" && (
            <Link className="signupBtn" to="/signup">
              SIGN UP
            </Link>
          )}
          {getCookie("userId") !== "" && (
            <Link onClick={logout} to="/" className="signoutMob">
              Sign out
            </Link>
          )}
        </div>
      </li>
    </Ul>
  );
};

export default RightNav;
let styles = {
  image: {
    borderRadius: 0,
    width: 10,
    height: 6,
    marginLeft: 13,
    marginTop: 25
  }
};
