import React, { Component } from "react";


class PlanPage extends Component {
 

  render() {
    return (
      <div>
      
      </div>
    );
  }
}

  export default PlanPage
