export default {
    app: {
      auth: {},
      toasterConfig: {
        show: false,
        message: '',
        color: '',
      },
      showModal: false,
      breadcrumbsKey: 'home',
      profileImg : "",
      notificationCount : 0
    },
  };
  