import React, { Component } from "react";
import { Avatar, Container, Paper } from "@material-ui/core";
import { FlutterWaveButton } from "flutterwave-react-v3";
import Grid from "@material-ui/core/Grid";
import { getCookie, setCookie, deleteCookie } from "../../../../Utils/cookies";
import { flutterwaveKey } from "./../../../../Utils/domain";
import FormLabel from "@material-ui/core/FormLabel";
import { domain } from "../../../../Utils/domain";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateToasterConfig } from "../../../../redux/modules/app/actions";
import { Button } from "@material-ui/core";
import "./styles.scss";
import { getCookieConsentValue } from "react-cookie-consent";
import Test from "./test";
import MetaFile from "../../../../Pages/MetaFile";

// Card number: 5438 8980 1456 0229
//  cvv: 564
//  Expiry: 10/31
//  Pin: 3310
//  OTP: 12345

// Card number: 5531 8866 5214 2950
// cvv: 564
// Expiry: 09/32
// Pin: 3310
// OTP: 12345
var couponDiscount = 0;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      pin: "",
      state: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      lstStatus: "",
      lstTrans: "",
      lstTx_ref: "",
      finalAmount: "",
      templateAmount: "",
      orderNumber: "",
      documentName: "",
      showPopup: false,
      total: 0,
      coupon: "",
      applied: false,
      referralDiscount: 0,
      couponDiscount: 0,
      errorMsg: false,
      successMsg: false,
      couponApplied: false,
      errorString: "",
      subClicked: false,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }

  checkRefferal() {
    fetch(
      `${domain}/api/order/coupon-check?coupon=${
        this.state.coupon
      }&price=${getCookie("lanidigital_cost")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successCheckReffreal(response))
      .catch(error => this.errorDetails(error));
  }
  successCheckReffreal(res) {
    couponDiscount = getCookie("lanidigital_cost") - res.discount;
    if (res.valid) {
      if (this.state.coupon.length >= 6) {
        this.setState(
          {
            couponDiscount: getCookie("lanidigital_cost") - res.discount,
            referralDiscount: 0,
            successMsg: true,
            couponApplied: true
          },
          () => {
            setCookie("refDiscount", this.state.couponDiscount);
            setCookie("appliedCoupon", this.state.couponApplied);
            setCookie("coupon_object", res.coupon_object);
            setCookie("coupon_discount", res.discount);
            setCookie("coupon_is_percentage", res.is_percentage);
            setCookie("coupon_valid", res.valid);
            setCookie("coupon_value", res.value);
          }
        );
      } else {
        couponDiscount = getCookie("lanidigital_cost") - res.discount;
        this.setState(
          {
            couponDiscount: 0,
            referralDiscount: getCookie("lanidigital_cost") - res.discount,
            couponApplied: true
          },
          () => {
            setCookie("refDiscount", this.state.referralDiscount);
            setCookie("appliedCoupon", this.state.couponApplied);
            setCookie("coupon_object", res.coupon_object);
            setCookie("coupon_discount", res.discount);
            setCookie("coupon_is_percentage", res.is_percentage);
            setCookie("coupon_valid", res.valid);
            setCookie("coupon_value", res.value);
          }
        );
      }
    } else {
      this.setState({
        errorMsg: true
      });
    }
  }
  getOrderData() {
    let orderNumber = this.state.orderNumber;
    fetch(`${domain}/api/order/detail/${orderNumber}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successOrderDetails(response))
      .catch(error => this.errorDetails(error));
  }
  successOrderDetails(res) {
    this.setState(
      {
        finalAmount: res.amount
      },
      () => {
        setCookie("order_number", this.state.orderNumber);
        setCookie("docs_name", res.item);
        setCookie("filing_cost", res.filing_cost);
        setCookie("discount", res.discount);
        setCookie("lanidigital_cost", res.lanidigital_cost);
      }
    );
    getCookie("sub-clicked") === "false" && this.checkRefferal();
  }
  errorDetails(error) {}
  updateBackend = res => {};

  getProfileDetails() {
    let userId = getCookie("userId");
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successDetails(response))
      .catch(error => this.errorDetails(error));
  }
  successDetails = response => {
    this.setState({
      name:
        response.first_name + " " + response.last_name === " "
          ? response.username
          : response.first_name + " " + response.last_name,
      email: response.email,
      phone: response.phone,
      pin: response.pin,
      state: response.state,
      city: response.city,
      addressLine1: response.addressLine1,
      addressLine2: response.addressLine2
    });
  };

  setupCookies = () => {
    // setCookie('order_number',this.props.location.state===undefined ?  getCookie("order_number") :  this.props.location.state.order_number);
    // setCookie('order_amount',this.props.location.state===undefined ?  getCookie("order_amount") :  this.props.location.state.order_amount);
    // setCookie('docs_name',this.props.location.state===undefined ?  getCookie("docs_name") :  this.props.location.state.serviceName )

    this.setState(
      {
        total: parseInt(getCookie("order_amount") - getCookie("discount"))
      },
      () => setCookie("total", this.state.total)
    );

    //window.alert(getCookie("sub-clicked"))
    if (getCookie("sub-clicked") === "true") {
      deleteCookie("appliedCoupon");
      deleteCookie("coupon_object");
      deleteCookie("coupon_discount");
      deleteCookie("coupon_is_percentage");
      deleteCookie("coupon_valid");
      deleteCookie("coupon_value");
      deleteCookie("refDiscount");
    } else {
      this.checkRefferal();
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.location.state) {
      this.setState(
        {
          finalAmount:
            this.props.location.state === undefined
              ? getCookie("order_amount")
              : this.props.location.state.order_amount,
          templateAmount:
            this.props.location.state === undefined
              ? getCookie("order_amount")
              : this.props.location.state.lanidigital_cost,
          orderNumber:
            this.props.location.state === undefined
              ? getCookie("order_number")
              : this.props.location.state.order_number,
          documentName:
            this.props.location.state === undefined
              ? getCookie("docs_name")
              : this.props.location.state.serviceName
        },
        () => {
          this.setupCookies();
        }
      );
    } else {
      let url = this.props.location.search;
      let split = url.split("?order=");
      let order_number = split;

      this.setState(
        {
          orderNumber: order_number
        },
        () => this.getOrderData()
      );
    }

    this.getProfileDetails();

    //window.onbeforeunload = this.confirmExit;
    this.getMeta();
  };
  getMeta = () => {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
    };
    fetch(domain + `/api/home/tags/payment/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        this.getSucessMetaresponce(responseJson);
      })
      .catch(error => {
        this.getErrorMetaresponce(error);
      });
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  //  confirmExit()
  //     {
  //       return "show warning";
  //     }

  handleChange = (e, id) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  SuccessMsg = () => {
    this.props.updateToasterConfig({
      show: true,
      message: "Discount successfully applied.",
      color: "#D06900"
    });
    this.props.history.push(
      "/payment-status?status=successful&tx_ref=null&transaction_id=null"
    );
  };
  checkCoupon = () => {
    if (getCookie("sub-clicked") === "true") {
      this.setState({
        errorMsg: true,
        errorString: "Subscription Discount Applied! Coupon Can't Be Use"
      });
    } else if (this.state.coupon.length >= 6 && !this.state.applied) {
      this.checkRefferal();
      this.setState({
        applied: !this.state.applied,
        errorMsg: false,
        errorString: "Invalid Coupon"
      });
    } else if (!this.state.coupon.length) {
      this.setState({
        errorMsg: true,
        errorString: "Please Enter A Coupon Code"
      });
    } else if (this.state.coupon.length && this.state.applied) {
      this.setState(
        {
          errorMsg: false,
          applied: !this.state.applied,
          coupon: "",
          couponDiscount: 0,
          successMsg: false,
          couponApplied: false
        },
        () => {
          setCookie("appliedCoupon", this.state.couponApplied);
        }
      );
    } else {
      this.setState({
        errorMsg: true,
        applied: !this.state.applied,
        successMsg: false
      });
    }
  };
  calculateFinalAmount() {
    let total = getCookie("discount")
      ? getCookie("order_amount") - getCookie("discount")
      : getCookie("order_amount") - getCookie("refDiscount");
    return total;
  }

  //  config = {
  //         public_key: flutterwaveKey,
  //         tx_ref: Date.now(),
  //         amount:this.calculateFinalAmount(),
  //         currency: 'NGN',
  //         redirect_url:"/payment-status",
  //         payment_options: 'Debit/Credit Card',
  //         customer: {
  //           email: getCookie('email'),
  //           phonenumber: getCookie('phone'),
  //           name: getCookie('logedinUser'),
  //         },
  //         customizations: {
  //           title: 'LANIDIGITAL',
  //           description: getCookie('docs_name'),
  //           logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
  //         },
  //       };

  //        fwConfig = {
  //         ...this.config,
  //         text: 'Proceed',
  //         callback: (response) => {
  //             this.updateBackend();
  //         },
  //         onClose: () => {

  //         },

  //       };

  render() {
    return (
      <div>
        <Container>
        {this.state.meta_title !== "" &&  <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
    }

          <div className="payment">Payment</div>
          <div style={{ display: "flex" }}>
            <Avatar
              src={require("../../../../Assets/images/lock.png")}
              className="lock"
            />
            <div className="paymentCaption">
              All transactions are safe and secure
            </div>
          </div>
          <div className="paymentInfo">
            <Grid xs={12} sm={12} lg={8} md={8}>
              <Paper className="paper1">
                <div className="paper1Padding">
                  <div className="paper1Title">Billing Information</div>
                  <div className="paper1TextSaved">
                    Please Enter billing details below
                  </div>

                  <div className="paper1RemainingText"></div>
                  <FormLabel>Name*</FormLabel>
                  <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    value={this.state.name}
                    onChange={e => this.handleChange(e, "name")}
                  />
                  <FormLabel>Email*</FormLabel>
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    value={this.state.email}
                    onChange={e => this.handleChange(e, "email")}
                  />
                  <FormLabel>Phone*</FormLabel>
                  <input
                    type="number"
                    placeholder="Phone"
                    id="phone"
                    value={this.state.phone}
                    onChange={e => this.handleChange(e, "phone")}
                  />
                  <FormLabel>Address Line 1</FormLabel>
                  <input type="text" placeholder="Address Line 1" />
                  <FormLabel>Address Line 2</FormLabel>
                  <input type="text" placeholder="Address Line 2" />
                  <FormLabel>City*</FormLabel>
                  <input
                    type="city"
                    placeholder="city"
                    id="city"
                    value={this.state.city}
                    onChange={e => this.handleChange(e, "city")}
                  />
                  <FormLabel>State*</FormLabel>
                  <input
                    type="state"
                    placeholder="state"
                    id="state"
                    value={this.state.state}
                    onChange={e => this.handleChange(e, "state")}
                  />
                  <FormLabel>Enter Zip code*</FormLabel>
                  <input
                    type="number"
                    placeholder="Enter Zip code"
                    id="pin"
                    value={this.state.pin}
                    onChange={e => this.handleChange(e, "pin")}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4}>
              <Paper className="paper2">
                <div
                  style={{
                    paddingTop: 30,
                    paddingLeft: 40,
                    paddingBottom: 30,
                    paddingRight: 30
                  }}
                >
                  <div className="orderSum">Have Coupon Code?</div>
                  <input
                    className={
                      this.state.applied
                        ? "coupon-input-applied"
                        : "coupon-input"
                    }
                    placeholder="coupon code"
                    id="coupon"
                    value={this.state.coupon}
                    onChange={e => this.handleChange(e, "coupon")}
                  />
                  {this.state.errorMsg && (
                    <p className="error-msg">{this.state.errorString}</p>
                  )}
                  {this.state.successMsg && (
                    <p className="success-msg">Applied Successfully!</p>
                  )}
                  <button
                    className="apply-button"
                    id="applyCoupon"
                    onClick={() => this.checkCoupon()}
                  >
                    {this.state.applied ? "Remove" : "Apply"}
                  </button>
                </div>
              </Paper>
              <Paper className="paper2">
                <div
                  style={{
                    paddingTop: 30,
                    paddingLeft: 40,
                    paddingBottom: 30,
                    paddingRight: 30
                  }}
                >
                  <Grid xs={12} sm={12} lg={12} md={12}>
                    <div className="orderSum">Order Summary</div>
                    <div className="orderSummary">
                      <b style={{ width: "65%" }}>
                        {this.state.documentName || getCookie("docs_name")}
                      </b>
                      <b>
                        &#8358;
                        {this.state.templateAmount ||
                          getCookie("lanidigital_cost")}
                      </b>
                    </div>
                    {getCookie("filing_cost") > 0 && (
                      <div className="orderSummary">
                        <b>
                          {getCookie("filing_cost_text")
                            ? getCookie("filing_cost_text")
                            : "Filing Cost"}
                        </b>
                        <b> &#8358; {getCookie("filing_cost")}</b>
                      </div>
                    )}
                    {getCookie("discount") > 0 && (
                      <div className="orderSummary">
                        <b> Subscription Discount </b>
                        <b> - &#8358; {getCookie("discount")}</b>
                      </div>
                    )}
                    {this.state.referralDiscount > 0 && (
                      <div className="orderSummary">
                        <b> Referral Discount </b>
                        <b> - &#8358; {this.state.referralDiscount}</b>
                      </div>
                    )}
                    {this.state.couponDiscount > 0 && (
                      <div className="orderSummary">
                        <b> Coupon Discount </b>
                        <b> - &#8358; {this.state.couponDiscount}</b>
                      </div>
                    )}
                    <div
                      style={{
                        color: "#D06900",
                        fontWeight: 600,
                        paddingTop: 23,
                        fontSize: 14
                      }}
                    ></div>
                    <hr style={{ marginTop: 14 }} />
                    <div
                      style={{
                        color: "#141C49",
                        fontSize: 20,
                        fontFamily: "Raleway-Bold",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      Total :
                      <b>
                        &#8358;
                        {getCookie("discount")
                          ? (this.state.total =
                              getCookie("order_amount") - getCookie("discount"))
                          : (this.state.total =
                              getCookie("order_amount") -
                              this.state.referralDiscount -
                              this.state.couponDiscount)}
                      </b>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </div>
          <div></div>

          {this.state.total > 0 &&
            this.state.name &&
            this.state.email &&
            this.state.phone && (
              <div className="proceed-button">
                {/* <FlutterWaveButton {...this.fwConfig} /> */}
                <Test
                  total={
                    getCookie("discount")
                      ? (this.state.total =
                          getCookie("order_amount") - getCookie("discount"))
                      : (this.state.total =
                          getCookie("order_amount") -
                          this.state.referralDiscount -
                          this.state.couponDiscount)
                  }
                />
              </div>
            )}
          {this.state.total == 0 &&
            this.state.name &&
            this.state.email &&
            this.state.phone && (
              <div className="proceed-button">
                <button onClick={() => this.SuccessMsg()}>Proceed</button>
              </div>
            )}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig }
  )(index)
);

// let styles={
//     container:{
//         marginTop:200,
//         marginBottom:90,
//         marginLeft:150,
//         marginRight:150
//     }
// }
