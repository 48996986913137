import React, { Component } from "react";
import "./style.scss";
import Profile from "./../../../../Assets/images/profile-img.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import Edit_pencil from "./../../../../Assets/images/edit-pencil.svg";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Preloader from "./../../../../Components/Preloader";
import { updateProfileImg } from "./../../../../../src/redux/modules/app/actions";
import { Input } from "@material-ui/core";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

const formData = new FormData();
class index extends Component {
  state = {
    getInputs: false,
    username: "",
    gmail: "",
    number: "",
    address: "",
    addressline1: "",
    addressline2: "",
    stateName: "",
    city: "",
    pincode: "",
    profileimage: {},
    successResponseDetails: [],
    successBillingDetails: [],
    formData: {},
    preloader: false,
    fname: "",
    lname: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  saveProfile() {
    let userId = getCookie("userId");
    for (var value of formData.values()) {
    }
    formData.append("username", this.state.successResponseDetails.username);
    formData.append("email", this.state.gmail);
    formData.append("phone", this.state.number);
    formData.append("addressLine1", this.state.addressline1);
    formData.append("addressLine2", this.state.addressline2);
    formData.append("state", this.state.stateName);
    formData.append("city", this.state.city);
    formData.append("pincode", this.state.pincode);
    formData.append("normal_address", this.state.address);
    formData.append("first_name", this.state.fname);
    formData.append("last_name", this.state.lname);

    this.saveProfileDetails(formData);
    this.getProfileDetails();
  }
  billingAddress() {}
  saveProfileDetails(params) {
    this.setState({
      preloader: true
    });
    let token = getCookie("token");
    fetch(domain + "/api/auth/edit-profile/", {
      method: "POST",
      headers: {
        //"content-type": "multipart/form-data",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    if (response) {
      this.setState({ getInputs: false });
      window.location.reload();
    }
  };

  errorResponse = error => {};

  componentDidMount() {
    this.setState({
      preloader: true
    });
    this.props.updateBreadcrumbs("Profile");
    this.getProfileDetails();
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  getProfileDetails() {
    let userId = getCookie("userId");
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successDetails(response))
      .catch(error => this.errorDetails(error));
  }
  getBillingAddress() {
    let token = getCookie("token");
    fetch(domain + `/api/order/billingaddress/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successBilling(response))
      .catch(error => this.errorBilling(error));
  }

  successDetails = responseDetails => {
    // console.log(responseDetails)
    this.props.updateProfileImg(responseDetails.temp_profile_img);
    this.setState(
      { successResponseDetails: responseDetails, preloader: false },
      () => {
        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.username &&
          this.state.successResponseDetails.username !== ""
        ) {
          this.setState({
            username: this.state.successResponseDetails.username
          });
        }
        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.first_name &&
          this.state.successResponseDetails.first_name !== ""
        ) {
          this.setState({
            fname: this.state.successResponseDetails.first_name
          });
        }
        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.last_name &&
          this.state.successResponseDetails.last_name !== ""
        ) {
          this.setState({ lname: this.state.successResponseDetails.last_name });
        }

        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.email &&
          this.state.successResponseDetails.email !== ""
        ) {
          this.setState({ gmail: this.state.successResponseDetails.email });
        }
        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.phone &&
          this.state.successResponseDetails.phone !== ""
        ) {
          this.setState({ number: this.state.successResponseDetails.phone });
        }

        if (
          this.state.successResponseDetails &&
          this.state.successResponseDetails.status &&
          this.state.successResponseDetails.status !== ""
        ) {
          this.setState({ statuS: this.state.successResponseDetails.status });
        }

        this.setState({
          addressline1:
            this.state.successResponseDetails.addressLine1 !== undefined &&
            this.state.successResponseDetails.addressLine1,
          addressline2:
            this.state.successResponseDetails.addressLine2 !== undefined &&
            this.state.successResponseDetails.addressLine2,
          stateName:
            this.state.successResponseDetails.state !== undefined &&
            this.state.successResponseDetails.state,
          city:
            this.state.successResponseDetails.city !== undefined &&
            this.state.successResponseDetails.city,
          pincode:
            this.state.successResponseDetails.pincode !== undefined &&
            this.state.successResponseDetails.pincode,
          address:
            this.state.successResponseDetails.normal_address !== undefined &&
            this.state.successResponseDetails.normal_address
        });
      }
    );
  };
  loadFile = e => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
    formData.append("profileimage", e.target.files[0], e.target.files[0].name);

    this.setState({
      profileimage: formData
    });
  };

  successBilling = billingDetails => {
    this.setState({ successBillingDetails: billingDetails });
  };
  errorBilling = errorBilling => {};

  redirect = () => {
    this.props.history.push("/dashboard/subscription");
  };
  errorDetails = errorDetails => {};

  render() {
    return (
      <>
        <div className="userDetails">
        {this.state.meta_title !== "" &&  <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
    }
          <div className="userDetails__wrapper">
            <div className="userDetails__wrapper_heading">
              <p>My Profile</p>
              <div className="myProfil">
                <img src={Edit_pencil} style={{ height: 18, width: 18 }} />
                <div
                  className="editText"
                  onClick={() =>
                    this.setState({ getInputs: true, username: "" })
                  }
                >
                  Edit
                </div>
              </div>
            </div>
            {this.state.preloader ? (
              <Preloader />
            ) : (
              <Container>
                <div className="userDetails__wrapper_content">
                  <Grid xs={12} sm={12} lg={4} md={4}>
                    <div>
                      {this.state.getInputs === true ? (
                        <>
                          <p className="userDetails__wrapper_content_service">
                            {this.state.successResponseDetails.username}
                          </p>
                        </>
                      ) : (
                        <p className="userDetails__wrapper_content_service">
                          {this.state.successResponseDetails.username}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_content_block_left">
                      {this.state.getInputs === true ? (
                        <>
                          <p className="left-side emAil profile--image">
                            Choose Profile Image
                          </p>
                          <img
                            id="output"
                            src={
                              this.state.successResponseDetails.temp_profile_img
                            }
                            alt="profile"
                          />
                          <input
                            type="file"
                            id="file"
                            onChange={e => this.loadFile(e)}
                            style={{ display: "none" }}
                          />
                          <label for="file" className="upload-img">
                            Upload Image
                          </label>
                        </>
                      ) : (
                        <img
                          src={
                            this.state.successResponseDetails.temp_profile_img
                          }
                          alt="profile"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} lg={8} md={8}>
                    <div className="userDetails__wrapper_content_block_right">
                      {getCookie("group") !== "2" && (
                        <div className="userDetails__wrapper_section">
                          <p className="left-side">Role</p>
                          <p className="right-side">
                            {this.state.successResponseDetails !== undefined &&
                              this.state.successResponseDetails.group_name}
                          </p>
                        </div>
                      )}
                      <div className="userDetails__wrapper_section">
                        <p className="left-side emAil">First Name</p>
                        {this.state.getInputs === true ? (
                          <input
                            value={this.state.fname}
                            type="text"
                            className="inputText"
                            placeholder="Enter Your First Name"
                            onChange={e => {
                              this.setState({ fname: e.target.value });
                            }}
                          />
                        ) : (
                          <p className="right-side">
                            {this.state.successResponseDetails.first_name}
                          </p>
                        )}
                      </div>
                      <div className="userDetails__wrapper_section">
                        <p className="left-side emAil">Last Name</p>
                        {this.state.getInputs === true ? (
                          <input
                            value={this.state.lname}
                            type="text"
                            className="inputText"
                            placeholder="Enter Your Last Name"
                            onChange={e => {
                              this.setState({ lname: e.target.value });
                            }}
                          />
                        ) : (
                          <p className="right-side">
                            {this.state.successResponseDetails.last_name}
                          </p>
                        )}
                      </div>
                      <div className="userDetails__wrapper_section">
                        <p className="left-side emAil">Email ID</p>
                        {this.state.getInputs === true ? (
                          <p className="right-side eMailbox">
                            {this.state.successResponseDetails.email}
                          </p>
                        ) : (
                          <p className="right-side eMailbox">
                            {this.state.successResponseDetails.email}
                          </p>
                        )}
                      </div>
                      <div className="userDetails__wrapper_section">
                        <p className="left-side emAil">Phone number</p>
                        {this.state.getInputs === true ? (
                          <input
                            value={this.state.number}
                            type="text"
                            className="inputText"
                            placeholder="Enter The number"
                            onChange={e => {
                              if (e.target.value.length <= 11) {
                                this.setState(
                                  { number: e.target.value },
                                  () => {}
                                );
                              }
                            }}
                          />
                        ) : (
                          <p className="right-side">
                            {this.state.successResponseDetails.phone}
                          </p>
                        )}
                      </div>
                      <h3 className="left-side emAil">Billing Address</h3>
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.addressline1}
                          className="inputText"
                          type="text"
                          placeholder="Address Line 1"
                          onChange={e =>
                            this.setState({ addressline1: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.addressLine1}
                        </p>
                      )}
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.addressline2}
                          className="inputText"
                          type="text"
                          placeholder="Address Line 2"
                          style={{ marginLeft: 12 }}
                          onChange={e =>
                            this.setState({ addressline2: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.addressLine2}
                        </p>
                      )}
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.stateName}
                          className="inputText"
                          type="text"
                          placeholder="State"
                          style={{ marginTop: 12 }}
                          onChange={e =>
                            this.setState({ stateName: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.state}
                        </p>
                      )}
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.city}
                          className="inputText"
                          type="text"
                          placeholder="City"
                          style={{ marginLeft: 12 }}
                          onChange={e =>
                            this.setState({ city: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.city}
                        </p>
                      )}

                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.pincode}
                          className="inputText"
                          type="text"
                          placeholder="Pincode"
                          style={{ marginTop: 12 }}
                          onChange={e =>
                            this.setState({ pincode: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.pincode}
                        </p>
                      )}

                      <div className="userDetails__wrapper_section"></div>
                      <h3 className="left-side emAil">Address</h3>
                      {this.state.getInputs !== true ? (
                        <p className="right-side">
                          {this.state.successResponseDetails.normal_address}
                        </p>
                      ) : (
                        <>
                          <input
                            className="inputText"
                            value={this.state.address}
                            placeholder="Enter Address"
                            type="text"
                            onChange={e => {
                              this.setState({
                                address: e.target.value
                              });
                            }}
                          />
                          <div style={{ display: "flex", marginTop: 12 }}>
                            <input
                              type="checkbox"
                              style={{ borderRadius: 0, marginTop: 6 }}
                              name="copyAddress"
                              ref="copyAddress"
                              label="Same as shipping"
                              onChange={e => {
                                this.setState({
                                  address: `${this.state.addressline1 !==
                                    undefined &&
                                    this.state.addressline1}  ${this.state
                                    .addressline2 !== undefined &&
                                    this.state.addressline2} 
                            ${this.state.city !== undefined &&
                              this.state.city} ${this.state.stateName !==
                                    undefined && this.state.stateName} ${this
                                    .state.pincode !== undefined &&
                                    this.state.pincode}`
                                });
                              }}
                            />
                            {/* <Input name="addressBill" value={this.state.values.addressBill} onChange={this.handleChange.bind(this)} type="text" label="Address"  /> */}

                            <p className="right-side ">
                              Same as billing address
                            </p>
                          </div>
                        </>
                      )}
                      {/* <div className="userDetails__wrapper_section"></div>
                    <div className="userDetails__wrapper_section">
                      <p className="left-side emAil">Current Active Subscription</p>
                      <p className="view" onClick={() => {this.redirect()}}>View</p>
                      {/* <p className="right-side">{this.state.successResponseDetails.plan}</p> */}
                    </div>

                    {this.state.getInputs && (
                      <div style={{ marginTop: 26 }}>
                        <button
                          className="cancel"
                          onClick={() => this.setState({ getInputs: false })}
                        >
                          cancel
                        </button>
                        <button
                          className="save"
                          onClick={() => this.saveProfile()}
                        >
                          save
                        </button>
                      </div>
                    )}
                  </Grid>

                  <div className="userDetails__wrapper_content_block"></div>
                </div>
              </Container>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateProfileImg }
  )(index)
);
