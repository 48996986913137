import React, { Component } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { domain } from "../..//Utils/domain";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../redux/modules/app/actions";
import { updateToasterConfig } from '../../redux/modules/app/actions';
import { getCookie, deleteCookie } from "../../Utils/cookies";
import "./styles.scss";

class FormPasswordReset extends Component {
  state = {
    passChangeSuccess: false,
    passwordNew: "",
    passwordConfirm : "",
    passwordCurrent:""

  };
  componentDidMount() {
    this.props.updateBreadcrumbs("ResetPassword");
  }
  handleChange = e => {

    this.setState({
      [e.target.id] : e.target.value
    })

  }
  validation = () => {

    if(this.state.passwordCurrent && this.state.passwordConfirm && this.state.passwordNew){
      if(this.state.passwordNew == this.state.passwordConfirm){
          this.handleSubmit();
      }else{
        this.props.updateToasterConfig({
          show: true,
          message: "New Password mis-match",
          color: '#D06900',
          })
      }
      }

    else{
      this.props.updateToasterConfig({
        show: true,
        message: "Please fill all the inputs",
        color: '#D06900',
        })
    }
  }
  handleSubmit = () => {
    let data = {
      "old_password" : this.state.passwordCurrent,
      "new_password" : this.state.passwordNew
    }
    let token = getCookie("token");
    fetch(domain + "/api/auth/update-password/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => this.successResponse(response))
      .catch((error) => this.errorResponse(error));
  }

  successResponse = (response) => {
    
    if(response.old_password === "Wrong password."){
      this.props.updateToasterConfig({
        show: true,
        message: "Your Old password is wrong.",
        color: '#D06900',
        }) 
    }else{
      this.props.updateToasterConfig({
        show: true,
        message: "Password Success fully updated",
        color: '#D06900',
        }) 
        deleteCookie("notification-length");
        deleteCookie("phone");
        deleteCookie("logedinUser");
        deleteCookie("email");
        deleteCookie("userId");
        deleteCookie("token");
        deleteCookie("group");
        this.props.history.push("/signin");
      
    }
    
  };

  errorResponse = (error) => {
    this.props.updateToasterConfig({
      show: true,
      message: "Please enter a correct password",
      color: '#D06900',
      })
  };



  render() {
    return (


            <Paper className="Form Form--wrapper formContainer" elevation={10}>
                  <InputLabel
                    htmlFor="password-current"
                    className="passWordnew"
                  >Enter Old Password
                  </InputLabel>
                  <Input
                    className="inputFieldform"
                    id="passwordCurrent"
                    name="currentPass"
                    type="password"
                    value={this.state.passwordCurrent}
                    onChange={e => this.handleChange(e)}
                  />

                  <InputLabel
                    htmlFor="password-new"
                    className="passWordnew"

                  >
                      New Password
                  </InputLabel>
                  <Input
                    id="passwordNew"
                    className="inputFieldform"
                    name="newPass"
                    type="password"
                    value={this.state.passwordNew}
                    onChange={e => this.handleChange(e)}
                  />



                  <InputLabel
                    className="passWordnew"
                    htmlFor="password-confirm"

                  >
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="passwordConfirm"
                    className="inputFieldform"
                    name="confirmPass"
                    type="password"
                    value={this.state.passwordConfirm}
                    onChange={e => this.handleChange(e)}

                  />


                 <Button
                  className="saveBtn"
                  type="submit"
                  variant="raised"
                  color="primary"
                  onClick = {this.validation}

                >Save

                </Button>


            </Paper>
          );
        }}

export default withRouter(
  connect(
    (state) => ({
      // auth: state.app.auth,
    }),
    { updateToasterConfig ,
     updateBreadcrumbs }
  )(FormPasswordReset)
);
