import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import "./style.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "./../../../../redux/modules/app/actions";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { getCookie, setCookie } from "./../../../../Utils/cookies";
import { domain } from "./../../../../Utils/domain";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactStore: [],
      searchValue: null,
      chatData: [],
      responseOfUser: [],
      chatLogsData: [],
      selectValue: "recent",
      allOrdersData: []
    };
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Chats");
    this.getData();
    if (
      localStorage.getItem("allChatsData") === null ||
      localStorage.getItem("allChatsData") === undefined ||
      localStorage.getItem("allChatsData").length === 0
    ) {
      setTimeout(() => {
        this.state.totalItem !== null && this.getDataForSearch();
      }, 5000);
    } else {
      this.setState({
        allOrdersData: JSON.parse(localStorage.getItem("allChatsData"))
      });
    }
    window.scrollTo(0, 0);
  };
  getData = () => {
    fetch(domain + "/api/chat/chat_conversations_list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));
  };

  successResponseFetch = res => {
    var dataInput = [];
    for (var split in res.chatlog_conversations) {
      dataInput.push({
        id: res.chatlog_conversations[split].recipients[0].id,
        name: res.chatlog_conversations[split].recipients[0].name,
        sender: res.chatlog_conversations[split].messages[0].sender,
        senderName: res.chatlog_conversations[split].messages[0].senderName
      });
    }
    this.setState({ chatLogsData: dataInput, storeOrders: dataInput });
  };

  errorResponse = error => {
  };

  filterResult = (value, id) => {
    const currentClass = document.getElementsByClassName("filter");
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("filter-active");
    }
    const element = document.getElementById(value);
    element.classList.add("filter-active");

    if (value === "All") {
    }
  };

  getDataForSearch() {
    fetch(
      domain + `/adminlist_chat/?page=1&&page_size=${this.state.totalItem}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.searchSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  }
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allChatsData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        loader: false
      });
    }
  }
  performSearch = e => {
    if (e.length >= 3) {
      let filtered = this.state.allOrdersData.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });

      this.setState({
        chatLogsData: filtered
      });
    }
    if (e.length === 0) {
      this.setState({
        chatLogsData: this.state.chatStore
      });
    }
  };

  changeRefresh = () => {
    this.state.chatLogsData.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );
    this.setState({ refresh: true, orderData: true });
  };
  changeRefreshDate = () => {
    this.state.chatLogsData.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    this.setState({ refresh: true, orderData: false });
  };
  refreshMethod = () => {
    window.location.reload();
  };
  render() {
    if (this.state.selectValue === "id" && !this.state.orderData) {
      this.changeRefresh();
    }
    if (this.state.selectValue === "name" && this.state.orderData) {
      this.changeRefreshDate();
    }
    this.state.selectValue === "recent" &&
      this.state.refresh &&
      this.refreshMethod();
    return (
      <div className="customerCare">
        <div className="customerCare__wrapper">
          <div className="customerCare__wrapper_block">
            <div className="customerCare__wrapper_block_top">
              <div className="customerCare__wrapper_block_top_heading">
                <p>Chat Logs</p>
              </div>
              <div className="customerCare__wrapper_block_top_search">
                <input
                  onChange={event => this.performSearch(event.target.value)}
                  name="search"
                  placeholder="search..."
                />
                <span className="orders__wrapper_block_top_search_icon">
                  <FaSearch />
                </span>
              </div>
            </div>

            <ul className="customerCare__wrapper_block_top_filter">
              <li
                id="All"
                className=" filter filter-active"
                onClick={() => this.filterResult("All")}
              >
                All
              </li>
              <li
                id="Inprogress"
                className="filter"
                onClick={() => this.filterResult("Inprogress")}
              >
                Inprogress
              </li>
              <li
                id="Completed"
                className="filter"
                onClick={() => this.filterResult("Completed")}
              >
                Completed
              </li>
              <li className="filter mobileView">
                <span className="sortLabel">Sortby</span>
                <select
                  className="selectDropdown"
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option className="optionValue" value="recent">
                    Recent
                  </option>
                  <option className="optionValue" value="name">
                    Name
                  </option>
                  <option className="optionValue" value="id">
                    Id
                  </option>
                </select>
              </li>
            </ul>

            <div>
              <Grid xs={12} sm={12}>
                {this.state.chatLogsData !== null &&
                  this.state.chatLogsData !== undefined &&
                  this.state.chatLogsData.map(order => {
                    return (
                      <div className="mainchatlogdiv" key={order.id}>
                        <Grid
                          className="orderNo"
                          xs={12}
                          sm={12}
                          lg={12}
                          md={12}
                        >
                          <div className="chatLogcust">
                            <Grid className="chatlogFirstrow" xs={6} sm={6}>
                              <p>Date & Time</p>
                              <p>{order.id}</p>
                            </Grid>
                            <Grid className="chatlogFirstrow" xs={6} sm={6}>
                              <p>Customer Name</p>
                              <p>{order.name}</p>
                            </Grid>
                          </div>
                          <div className="chatLogsupport">
                            <Grid className="chatlogSrow" xs={6} sm={6}>
                              <p>Customer Email</p>
                              <p>{order.id}</p>
                            </Grid>
                            <Grid className="chatlogSrow" xs={6} sm={6}>
                              <p>Responder Name</p>
                              <p>CUSTOMERSUPPORT</p>
                            </Grid>
                          </div>
                          <div className="chatLogsupport">
                            <Grid className="chatlogSrow" xs={6} sm={6}>
                              <p>Status</p>
                              <p>{order.selected}</p>
                            </Grid>
                          </div>
                          <div className="routeAdminchatlog">
                            <Link
                              to={
                                "/dashboard/mobileView/chatLogAdminMobile/chatDetails/" +
                                order.id +
                                "/"
                              }
                            >
                              <li>
                                <span className="action">View</span>
                              </li>
                            </Link>
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
