import React ,{useEffect}from 'react'
import Login from './Login'
import useLocalStorage from '../../hooks/useLocalStorage';
import Dashboard from './Dashboard'
import { ContactsProvider } from '../../contexts/ContactsProvider'
import { ConversationsProvider } from '../../contexts/ConversationsProvider';
import { SocketProvider } from '../../contexts/SocketProvider';


function ChartComponent({performSearch,filterId,filterValue}) {
  const [id, setId] = useLocalStorage('id')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  if(localStorage.getItem("chatLogs-clone-id")===undefined) {
   localStorage.clear()
  }
  const dashboard = (
    <SocketProvider id={id} >
      <ContactsProvider>
        <ConversationsProvider id={id} performSearch={performSearch}>
          <Dashboard id={id} performSearch={performSearch} filterValue={filterValue} filterId={filterId}/>
        </ConversationsProvider>
      </ContactsProvider>
    </SocketProvider>
  )

  return (
    id ? dashboard : <Login onIdSubmit={setId} />
  )
}

export default ChartComponent;
