import React, { Component } from "react";
import Profile from "./../../../../Assets/images/profile-img.png";
import CustomOrders from "./../../Orders/customOrders";
import CustomOrdersMobile from "./../../Orders/CustomOrdersMobile";
import Back from "./../../../../Assets/icons/back.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { getCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import SubscriptionPlan from "./../../SubscriptionPlans/SubscriptionPlans";
import "./style.scss";

class index extends Component {
  state = {
    customerDetails: [],
    viewOrder: null,
    showOrder: false,
    showModal: false,
    timeState: null,
    subscriptionPlanId: null,
    notesState: null
  };

  componentDidMount() {
    this.props.updateBreadcrumbs("customers");
    let id = this.props.location.state.lawyerId;
    let token = getCookie("token");

    // fetch(`${domain}/api/subscription/detail/${id}`,{
    fetch(`${domain}/api/subscription/detail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    response.detail === "Not found."
      ? this.setState({ customerDetails: response.detail })
      : this.setState({
          customerDetails: response,
          subscriptionPlanId: response.plan
        });
  };

  errorResponse = error => {};
  viewOrderNumber = (e, item) => {
    this.setState({ viewOrder: item, showOrder: true });
  };
  AvailNowNumber = (e, data) => {
    this.props.history.push("/productDetail", { id: data });
  };
  bookNowLayer = () => {
    this.setState({ showModal: true });
  };
  addNotes = e => {
    this.setState({ notesState: e.target.value });
  };
  changeTime = e => {
    this.setState({ timeState: e.target.value });
  };
  acceptRequest = e => {
    this.setState({ showModal: false });
    setTimeout(() => {
      fetch(domain + "/api/subscription/activate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify({
          plan: parseInt(this.state.subscriptionPlanId),
          user: 11
        })
      })
        .then(response => response.json())
        .then(response => this.successResponseFetch(response))
        .catch(error => this.errorResponse(error));
    }, 100);
  };

  successResponseFetch = res => {
    const hourData = this.state.timeState.slice(0, 2);
    const minData = this.state.timeState.slice(3, 5);
    var data = {
      // "user":this.props.location.customerId,
      // "username":this.props.location.customerName,
      user: 11,
      username: "dheerajs24",
      // "note":this.state.notesState,
      subscription: res.user_subscription,
      duration_hr_requested: parseInt(hourData),
      duration_min_requested: parseInt(minData)
    };

    fetch(`${domain}/api/subscription/updateLawyerConsultationTime/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${getCookie("token")}`
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => this.successResponseAssignLawyer(response))
      .catch(error => this.errorResponse(error));
  };
  successResponseAssignLawyer = res => {};
  render() {
    var splicedDate =
      this.state.customerDetails.valid_till !== undefined &&
      this.state.customerDetails.valid_till.slice(0, 10);
    var present_date = new Date();
    var validateDay = new Date(splicedDate);
    var one_day = 1000 * 60 * 60 * 24;
    if (present_date.getMonth() == 11 && present_date.getDate() > 25)
      validateDay.setFullYear(validateDay.getFullYear() + 1);
    var Result =
      Math.round(validateDay.getTime() - present_date.getTime()) / one_day;
    var Final_Result = Result.toFixed(0);
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const futureDate = moment(currentDate).add(Final_Result, "days");
    const diff = moment.duration(futureDate.diff(currentDate));
    return (
      <>
        <div className="customerDetails">
          <div className="customerDetails__wrapper">
            <div className="customerDetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                {this.props.location.customerName} Subscription
              </p>
            </div>
            <Container>
              <div className="customerDetails__wrapper_content">
                <div className="customerDetails__wrapper_content_block">
                  {this.state.customerDetails === "Not found." ? (
                    <Grid xs={12} lg={6} md={6} sm={6}>
                      <p className="discountHeadplan">
                        No Subscription data found.
                      </p>
                    </Grid>
                  ) : (
                    <Grid xs={12} sm={12} md={6} lg={6}>
                      <div className="lawyerSubs">
                        {this.state.customerDetails.active ? (
                          <span className="activePlanTem">
                            CURRENT ACTIVE PLAN
                          </span>
                        ) : (
                          <span>NOT CURRENT ACTIVE PLAN</span>
                        )}
                        {this.state.customerDetails.plan === 1 && (
                          <span className="active--plan">BRONZE PLAN</span>
                        )}
                        {this.state.customerDetails.plan === 2 && (
                          <span className="active--plan">SILVER PLAN</span>
                        )}
                        {this.state.customerDetails.plan === 3 && (
                          <span className="active--plan">GOLD PLAN</span>
                        )}

                        <div className="recuRing">
                          Recurs in {diff.months()} Months {diff.days()} Days
                        </div>

                        <div className="remainingPlan">
                          {/* 20 templates (14/20 remaining) */}
                        </div>
                        {this.state.customerDetails.sub_usage !== undefined &&
                          this.state.customerDetails.sub_usage.map(
                            (item, index) => {
                              if (item.template_type === "TEMPLATE") {
                                return (
                                  <div
                                    key={index}
                                    className="servicesTemplatesC"
                                  >
                                    <div className="planName">
                                      {item.service_name}
                                    </div>
                                    {/* <div>{item.service_type}</div>
                                <div>{item.service_category}</div> */}

                                    {item.order_no !== null && (
                                      <button
                                        className="viewOrderT"
                                        onClick={e =>
                                          this.viewOrderNumber(
                                            e,
                                            item.service_name
                                          )
                                        }
                                      >
                                        View order
                                      </button>
                                    )}
                                    {this.state.showOrder &&
                                      item.service_name ===
                                        this.state.viewOrder && (
                                        <div className="servicesTemplatesNameAdd">
                                          Order Number:{item.order_no}
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    </Grid>
                  )}
                  <Grid className="rightSec" xs={12} lg={6} md={6} sm={12}>
                    <div className="discountHeadplan">
                      7% discount on 7 add-on templates (5/7 remaining)
                    </div>
                    {this.state.customerDetails.sub_usage !== undefined &&
                      this.state.customerDetails.sub_usage.map(
                        (item, index) => {
                          if (item.template_type === "ADDON") {
                            return (
                              <div key={index} className="mainDivflex">
                                <div className="servicePlan">
                                  {item.service_name}
                                </div>
                                {/* <div>{item.service_type}</div>
                                <div>{item.service_category}</div> */}

                                {item.order_no !== null && (
                                  <button
                                    className="viewOrderT"
                                    onClick={e =>
                                      this.viewOrderNumber(e, item.service_name)
                                    }
                                  >
                                    View order
                                  </button>
                                )}
                                {this.state.showOrder &&
                                  item.service_name ===
                                    this.state.viewOrder && (
                                    <div className="servicesTemplatesNameAdd">
                                      Order Number:{item.order_no}
                                    </div>
                                  )}
                              </div>
                            );
                          }
                        }
                      )}
                    <div className="discountH topDissec">
                      7% discount on 10 legal services (8/10 remaining)
                    </div>
                    {this.state.customerDetails.sub_usage !== undefined &&
                      this.state.customerDetails.sub_usage.map(
                        (item, index) => {
                          if (item.template_type === "LEGALSERVICE") {
                            return (
                              <div key={index} className="mainDivflex">
                                <div className="servicePlan">
                                  {item.service_name}
                                </div>
                                {/* <div>{item.service_type}</div>
                                <div>{item.service_category}</div> */}
                                {item.order_no !== null && (
                                  <button
                                    className="viewOrderT"
                                    onClick={e =>
                                      this.viewOrderNumber(e, item.service_name)
                                    }
                                  >
                                    View order
                                  </button>
                                )}
                                {this.state.showOrder &&
                                  item.service_name ===
                                    this.state.viewOrder && (
                                    <div className="servicesTemplatesNameAdd">
                                      Order Number:{item.order_no}
                                    </div>
                                  )}
                              </div>
                            );
                          }
                        }
                      )}
                    <div className=" topDissec">
                      <div className="flexDIv1">
                        <Grid xs={12} lg={12} md={12} sm={12}>
                          <div className="discountH-heading">
                            New consultation request
                          </div>
                          <div className="remainingTime">
                            <span className="consult--desc">
                              New consultation requested on
                            </span>
                            <span className="consult--time">
                              20th Nov. 2020, 5.30 PM for 30 min.
                            </span>
                          </div>
                        </Grid>
                        <Grid xs={12} lg={12} md={12} sm={12}>
                          <button
                            className="bookNow"
                            onClick={e => this.bookNowLayer(e)}
                          >
                            Accept Request
                          </button>
                        </Grid>
                      </div>
                    </div>

                    {this.state.showModal && (
                      <Modal
                        open={this.state.showModal}
                        onClose={e => this.setState({ showModal: false })}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div className="lawyer-subscription-popup">
                          <h1>Consultation Details</h1>
                          <div className="consult-time-wrapper">
                            <div className="consult-inner">
                              <p>Enter Time Taken</p>
                              <input
                                type="time"
                                value={this.state.timeState}
                                onChange={this.changeTime}
                              />
                            </div>
                            <div className="consult-inner">
                              <p>Notes</p>
                              <input
                                type="text"
                                value={this.state.notesState}
                                onChange={this.addNotes}
                              />
                            </div>
                          </div>
                          <button
                            onClick={e => this.setState({ showModal: false })}
                            className="btn-cancel"
                          >
                            cancel
                          </button>
                          <button
                            onClick={e => this.acceptRequest(e)}
                            className="btn-save"
                          >
                            Save
                          </button>
                        </div>
                      </Modal>
                    )}
                  </Grid>
                </div>
              </div>
              <SubscriptionPlan />
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
