import React, { Component } from 'react'
import './style.scss';
import { FaSearch } from "react-icons/fa";
import {Link} from 'react-router-dom';
//import { getFaqCategories } from '../../Website/WebServices/faqDetailAndSearch';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../redux/modules/app/actions';
import { domain, defaultHeaders } from '../../../../Utils/domain';
import { getCookie } from "../../../../Utils/cookies";


class index extends Component {

    state={
        qaData : []

    }

    componentDidMount(){
        this.props.updateBreadcrumbs('Faqs');
        this.getData();
    }

    getData(){
        let token = getCookie("token");
        fetch( domain + '/api/home/adminlist_homefaq/',{
            method  : "GET",
            headers: {
                "Content-Type": "application/json",
                "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                Authorization: `Token ${token}`,
              },
            })
     .then(response => response.json())
     .then((responseJson) => { this.successResponse(responseJson) })
     .catch((error) => {  this.errorResponse(error) })
    }
    successResponse=(response)=>{
         
        this.setState({ 
            qaData : response,
        
        })
       
    }

    errorResponse=(error)=>{

    }
    render() {
        return (
            <div className="subs-details">
            <div className="subs-details__wrapper">
                <div className="subs-details__wrapper_block">
                    <div className="subs-details__wrapper_block_top_heading">
                        <p>Edit FAQ's Que & Ans</p>
                    </div>
                   
                </div>
                <div className="wrapper-style">
                    
                    {
                        this.state.qaData && this.state.qaData.map(item => {
                            return (
                            <Link to={"/dashboard/faqs/que-ans/edit/" +item.id}>
                                <p className="subs-paln-name">{item.faq_title}</p>
                            </Link>
                            )
                        })
                    }
                </div>
                
             </div>
        </div>
        )
    }
}
export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      {updateBreadcrumbs },
    )(index),
  );