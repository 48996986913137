import React, { Component } from "react";
import "./style.scss";
import Profile from "./../../../../Assets/images/profile-img.png";
import Orders from "./../../Orders";
import Back from "./../../../../Assets/icons/back.png";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../../redux/modules/app/actions";
import { withRouter } from "react-router-dom";
import Edit_pencil from "../../../../Assets/images/edit-pencil.svg";
import { domain } from "../../../../Utils/domain";
import { getCookie } from "../../../../Utils/cookies";

class index extends Component {
  state = {
    lawyerProfile: "",
    uFname: "",
    uEmail: "",
    uMobile: "",
    uExpertise: "",
    cases: "",
    statuS: "",
    getInputs: false,
    gNname: "",
    userdetails: {}
  };

  saveProfile() {
    let userId = this.props.location.lawyerId;
    let params = {
      id: userId,
      first_name: this.state.uFname,
      last_name: this.state.uLname,
      email: this.state.uEmail,
      customerMobile: this.state.uMobile,
      expertise: this.state.uExpertise,
      cases: this.state.cases,
      joinedate: this.state.uJoined,
      status: this.state.statuS
    };
    this.saveProfileDetails(params);
    this.getLawyerDetails();
  }

  changePersona() {
    let ele = document.getElementById("persona");
    let id = ele.value;
    let params = {
      old_persona_name: this.state.userdetails.username,
      new_persona: id
    };
    let token = getCookie("token");
    fetch(domain + "/api/auth/change_persona/ ", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(response => this.personaSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  }
  personaSuccessResponse(res) {
    this.props.updateToasterConfig({
      show: true,
      message: "User Role Updated Successfully",
      color: "#D06900"
    });
    this.componentDidMount();
  }

  saveProfileDetails(params) {
    let token = getCookie("token");
    fetch(domain + "/api/auth/edit-profile/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    this.setState({ getInputs: false });
  };

  errorResponse = error => {};

  componentDidMount() {
    this.props.updateBreadcrumbs("users");
    this.getLawyerDetails();
  }
  getDate(date) {
    let str = date;
    return str.slice(0, 10);
  }

  getLawyerDetails() {
    let id = this.props.location.lawyerId;
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successDetails(response))
      .catch(error => this.errorDetails(error));
  }
  changeStatus(uname, status) {
    let token = getCookie("token");
    let data = {
      username: uname
    };
    if (status === "Deactivated") {
      fetch(domain + "/api/auth/activate2/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(response => this.activateSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/auth/deactivate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(response => this.deactivateSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }
  deactivateSuccessResponse(res) {
    this.props.updateToasterConfig({
      show: true,
      message: "User Deactivated Successfully",
      color: "#D06900"
    });
    this.componentDidMount();
  }
  activateSuccessResponse(res) {
    this.props.updateToasterConfig({
      show: true,
      message: "User Activated Successfully",
      color: "#D06900"
    });
    this.componentDidMount();
  }

  successDetails = response => {
    this.setState({ lawyerProfile: response, userdetails: response }, () => {
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.first_name &&
        this.state.lawyerProfile.first_name !== ""
      ) {
        this.setState({ uFname: this.state.lawyerProfile.first_name });
      }
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.last_name &&
        this.state.lawyerProfile.last_name !== ""
      ) {
        this.setState({ uLname: this.state.lawyerProfile.last_name });
      }

      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.group_name &&
        this.state.lawyerProfile.group_name !== ""
      ) {
        this.setState({ gNname: this.state.lawyerProfile.group_name });
      }

      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.email &&
        this.state.lawyerProfile.email !== ""
      ) {
        this.setState({ uEmail: this.state.lawyerProfile.email });
      }
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.phone &&
        this.state.lawyerProfile.phone !== ""
      ) {
        this.setState({ uMobile: this.state.lawyerProfile.phone });
      }
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.expertise &&
        this.state.lawyerProfile.expertise !== ""
      ) {
        this.setState({ uExpertise: this.state.lawyerProfile.expertise });
      }
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.date_joined &&
        this.state.lawyerProfile.date_joined !== ""
      ) {
        this.setState({ uJoined: this.state.lawyerProfile.date_joined });
      }
      if (
        this.state.lawyerProfile &&
        this.state.lawyerProfile.status &&
        this.state.lawyerProfile.status !== ""
      ) {
        this.setState({ statuS: this.state.lawyerProfile.status });
      }
    });
  };
  redirect = () => {
    this.props.history.push({
      pathname: "/dashboard/lawyers/lawyersSubscription/",
      state: {
        lawyerId: this.props.location.lawyerId
      }
    });
  };

  ChangeDateFormat = orderDate => {
    if (orderDate != "" && orderDate != null) {
      let format_date = new Date(orderDate);
      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let datestring =
        ("0" + format_date.getDate()).slice(-2) +
        " " +
        monthNames[format_date.getMonth()] +
        " " +
        format_date.getFullYear() +
        "   " +
        ("0" + format_date.getHours()).slice(-2) +
        ":" +
        ("0" + format_date.getMinutes()).slice(-2);
      format_date.toLocaleString().replace(/\//g, "-");
      return datestring;
    }
  };

  errorDetails = error => {};

  render() {
    return (
      <>
        <div className="lawyerDetails">
          <div className="lawyerDetails__wrapper">
            <div className="lawyerDetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                User Profile
              </p>
              {/* <div style={{ display: "flex", paddingLeft: 540, marginTop: 20 }}>
                <img src={Edit_pencil} style={{ height: 18, width: 18 }} />
                <div
                  className="editText"
                  onClick={() => this.setState({ getInputs: true })}
                >
                  Edit
                </div>
              </div> */}
            </div>
            <div className="lawyerDetails__wrapper_content">
              {/* <div className="lawyerDetails__wrapper_content_top">
                            <p className="lawyerDetails__wrapper_content_top_orderid">Order #{lawyerDetails.id}</p>
                        </div> */}

              <div className="lawyerDetails__wrapper_content_block">
                <div className="lawyerDetails__wrapper_content_block_left">
                  {this.state.getInputs === true ? (
                    <input
                      value={this.state.uFname}
                      className="inputText"
                      onChange={e => this.setState({ uFname: e.target.value })}
                    />
                  ) : (
                    <p className="lawyerDetails__wrapper_content_service">
                      {this.state.uFname}
                    </p>
                  )}

                  {this.state.getInputs === true ? (
                    <input
                      value={this.state.uLname}
                      className="inputText"
                      onChange={e => this.setState({ uLname: e.target.value })}
                    />
                  ) : (
                    <p className="lawyerDetails__wrapper_content_service">
                      {this.state.uLname}
                    </p>
                  )}
                  <img src={Profile} alt="profile" />
                </div>
                <div className="lawyerDetails__wrapper_content_block_right">
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Email ID</p>
                    {this.state.getInputs === true ? (
                      <input
                        value={this.state.uEmail}
                        className="inputText"
                        onChange={e =>
                          this.setState({ uEmail: e.target.value })
                        }
                      />
                    ) : (
                      <p className="right-side">{this.state.uEmail}</p>
                    )}
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Phone number</p>
                    {this.state.getInputs === true ? (
                      <input
                        value={this.state.uMobile}
                        className="inputText"
                        onChange={e =>
                          this.setState({ uMobile: e.target.value })
                        }
                      />
                    ) : (
                      <p className="right-side">{this.state.uMobile}</p>
                    )}
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Expertise In</p>
                    {this.state.getInputs === true ? (
                      <input
                        value={this.state.uExpertise}
                        className="inputText"
                        onChange={e =>
                          this.setState({ uExpertise: e.target.value })
                        }
                      />
                    ) : (
                      <p className="right-side">
                        {this.state.uExpertise ? this.state.uExpertise : "NA"}
                      </p>
                    )}
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Role</p>
                    <p className="right-side">
                      {this.state.gNname}
                      <span style={{ marginLeft: "10px" }}>
                        <select
                          id="persona"
                          onChange={() => this.changePersona()}
                        >
                          <option disabled selected>
                            Change Role
                          </option>
                          <option value="1">Admin</option>
                          <option value="3">Lawyer</option>
                          <option value="4">Finance</option>
                          <option value="5">Customer Support</option>
                        </select>
                      </span>
                    </p>
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Subscription Plan</p>
                    <p
                      className="right-side view__subscription"
                      onClick={() => this.redirect()}
                    >
                      View
                    </p>
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Verified</p>
                    <p className="right-side">
                      {this.state.statuS === "Inactive" ? "No" : "Yes"}
                    </p>
                  </div>

                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Created On</p>

                    <p className="right-side">
                      {this.state.uJoined != null
                        ? this.ChangeDateFormat(this.state.uJoined)
                        : " "}
                    </p>
                  </div>
                  <div className="lawyerDetails__wrapper_section">
                    <p className="left-side">Status</p>
                    <p
                      className="right-side save"
                      onClick={() =>
                        this.changeStatus(
                          this.state.userdetails.username,
                          this.state.userdetails.status
                        )
                      }
                    >
                      {this.state.userdetails.status}
                    </p>
                  </div>
                </div>
              </div>
              {this.state.getInputs && (
                <div style={{ marginTop: 26, marginLeft: "40%" }}>
                  <button
                    className="cancel"
                    onClick={() =>
                      this.setState({
                        getInputs: false,
                        uFname: this.state.uFname
                      })
                    }
                  >
                    cancel
                  </button>
                  <button className="save" onClick={() => this.saveProfile()}>
                    save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="lawyerDetails_orders-block">
          <Orders />
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
