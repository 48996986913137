import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

let config;
if (process.env.NODE_ENV === 'production') {
  config = function configureStore(history, initialState) {
    const store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunk, routerMiddleware(history))),
    );
    return store;
  };
} else {
  config = function configureStore(history, initialState) {
    const store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(
          thunk,
          routerMiddleware(history),
          createLogger(),
        ),
        typeof window !== 'undefined' && window.devToolsExtension
          ? window.devToolsExtension()
          : (fn) => fn,
      ),
    );
    return store;
  };
}

const configs = config;

export default configs;
