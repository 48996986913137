import React, { Component } from 'react';
import { getCookie } from "../../../../Utils/cookies";
import {ifameDomain} from "./../../../../Utils/domain";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../redux/modules/app/actions';
import Back from './../../../../Assets/icons/back.png';

let productId =  null
class index extends Component {

    componentDidMount(){
         productId = this.props.match.params.id;
    }
    render() {
        return (
            <div className="products">
                <div className="products__wrapper">
                    <div className="products__wrapper_block">
                        <div className="products__wrapper_block_top">
                            <div className="products__wrapper_block_top_heading">
                            <p>
                                <span><img  onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span>
                                Product details
                            </p>
                            </div>
                        </div>
                        <iframe src={ifameDomain+"/admin/services/service/"+productId +"/change/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="850" height="550"></iframe>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      {updateBreadcrumbs },
    )(index),
  );
