import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
//import httpRequest from '../../../Utils/httpRequest';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
// import { getLawyersList } from '../../Website/WebServices/LawyersList';
import { domain } from "../../../Utils/domain";
import { getCookie } from "../../../Utils/cookies";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import xlsExport from "../../../Components/ExcelExport/XlsExport";
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";
import DatePicker from "react-datepicker";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
import "./style.scss";

class index extends Component {
  state = {
    lawyersList: [],
    usersStore: [],
    activePage: 1,
    totalItem: null,
    loader: false,
    filterValue: [],
    allOrdersData: [],
    dynamicSelect: "All",
    allValue: "",
    dateFilterData: [],
    startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
    endDate: new Date(),
    startDateValue: "",
    endDateValue: "",
    dateFilter: false,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };
  componentDidMount() {
    this.props.updateBreadcrumbs("users");
    this.setState({
      loader: true
    });
    this.getData();
    // if (
    //   localStorage.getItem("allUsersData") === null ||
    //   localStorage.getItem("allUsersData") === undefined ||
    //   localStorage.getItem("allUsersData").length === 0
    // ) {
    //   setTimeout(() => {
    //     this.state.totalItem !== null && this.getDataForSearch();
    //   }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allUsersData"))
    //   });
    // }
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  // ?page=" + this.state.activePage,

  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  getData() {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/users/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/auth/users/?page=" + this.state.activePage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }

  successResponse = response => {
    console.log(response);
    this.setState(
      {
        totalItem: response.paginationTotal,
        lawyersList: response.results,
        usersStore: response.results,
        loader: false
      },
      () => {
        this.state.totalItem !== null && this.getDataForSearch();
      }
    );
  };
  getDataForSearch = () => {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/users/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/auth/users/?page=1&&page_size=1000", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      })
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    }
  };
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allUsersData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results,
        loader: false
      });
    }
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () =>
      this.filterResult(this.state.dynamicSelect)
    );
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  callApiOfSpecificGroup = valueFromFilter => {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/user_list_group/${valueFromFilter}/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    } else {
      fetch(
        domain +
          `/api/auth/user_list_group/${valueFromFilter}/?page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    }
  };
  successResponseSelected = response => {
    this.setState({
      totalItem: response.paginationTotal,
      lawyersList: response.results,
      usersStore: response.results,
      loader: false
    });
  };
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    console.log(this.state.dateFilter);
    this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        console.log(this.state.dateFilter);
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },
            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };
  errorResponseSelected = err => {};
  filterResult = value => {
    try {
      if (value === "All") {
        this.getData();
      }
      if (value === this.state.dynamicSelect) {
        this.setState({ dynamicSelect: value });
        const currentClass = document.getElementsByClassName("filter");

        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("filter-active");
        }
        const element = document.getElementById(value);
        element.classList.add("filter-active");

        if (value === "All") {
          this.setState({
            lawyersList: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.usersStore
          });
        } else {
          let filterValue = this.state.dateFilter
            ? this.state.dateFilterData.filter(
                item => item.group_name === value.toLowerCase()
              )
            : this.state.usersStore.filter(
                item => item.group_name === value.toLowerCase()
              );
          let exportOrder = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => {
                if (item.group_name === "customer support") {
                  return item.group_name.includes(value.toLowerCase());
                } else {
                  return item.group_name === value.toLowerCase();
                }
              })
            : this.state.allOrdersData.filter(item => {
                if (item.group_name === "customer support") {
                  return item.group_name.includes(value.toLowerCase());
                } else {
                  return item.group_name === value.toLowerCase();
                }
              });
          this.setState(
            {
              lawyersList: filterValue,
              exportOrder: exportOrder
            },
            () => {
              if (value.includes("support")) {
                this.callApiOfSpecificGroup(5);
              } else {
                var selectedArray = this.state.lawyersList.find(
                  x => x.group_name === value.toLowerCase()
                );
                selectedArray !== undefined &&
                  this.callApiOfSpecificGroup(selectedArray.groups);
              }
            }
          );
        }
      } else {
        this.setState({ dynamicSelect: value }, () => {
          this.getData();
        });
        setTimeout(() => {
          this.filterResult(this.state.dynamicSelect);
        }, 1000);
      }
    } catch (err) {}
  };

  errorResponse = error => {};
  performSearch = e => {
    try {
      if (e.length >= 3) {
        let filtered = this.state.allOrdersData.filter(entry => {
          return Object.values(entry).some(val => {
            if (
              typeof val === "string" &&
              val.toLowerCase().includes(e.toLowerCase())
            ) {
              return true;
            }
          });
        });

        this.setState({
          lawyersList: filtered
        });
      }
      if (e.length === 0) {
        this.setState({
          lawyersList: this.state.usersStore
        });
      }
    } catch (err) {}
  };
  exportexcel = e => {
    const xls = new xlsExport(
      this.state.dynamicSelect === "All" && !this.state.dateFilter
        ? this.state.allOrdersData
        : this.state.dynamicSelect === "All" && this.state.dateFilter
        ? this.state.dateFilterData
        : this.state.lawyersList,
      "lawyers"
    );
    xls.exportToXLS("export.xls");
  };
  usernameSort = () => {
    this.state.lawyersList.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
    this.state.usersStore.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
  };
  changeRefreshDate = () => {
    this.state.lawyersList.sort((a, b) => {
      return a.expertise > b.expertise ? 1 : b.expertise > a.expertise ? -1 : 0;
    });
    this.state.usersStore.sort((a, b) => {
      return a.expertise > b.expertise ? 1 : b.expertise > a.expertise ? -1 : 0;
    });
  };

  handleChangeAll = e => {
    this.setState(
      {
        allValue: e.target.value
      },
      () => {
        this.state.allValue === "All" && this.filterResult(this.state.allValue);
        this.state.allValue === "Lawyer" &&
          this.filterResult(this.state.allValue);
        this.state.allValue === "Finance" &&
          this.filterResult(this.state.allValue);
        this.state.allValue === "support" &&
          this.filterResult(this.state.allValue);
      }
    );
  };
  render() {
    this.state.selectValue === "name" && this.usernameSort();
    this.state.selectValue === "expertise" && this.changeRefreshDate();
    return (
      <>
        <div>
          <div className="users--mobileView">
          {this.state.meta_title !== "" &&  <MetaFile
              meta_title={this.state.meta_title}
              meta_description={this.state.meta_description}
              meta_keywords={this.state.meta_keywords}
              other_meta_tags={this.state.json_meta_tags}
            />
    }
            <ul className="orders__wrapper_block_top_filter">
              <li className="filter mobileView filter-active">
                <select
                  className="selectDropdown"
                  value={this.state.allValue}
                  onChange={e => {
                    this.handleChangeAll(e);
                  }}
                >
                  <option
                    id="All"
                    className=" filter filter-active"
                    value="All"
                  >
                    All
                  </option>
                  <option id="Lawyer" className="filter" value="Lawyer">
                    Lawyer
                  </option>
                  <option id="Finance" className="filter" value="Finance">
                    Finance
                  </option>
                  <option id="support" className="filter" value="support">
                    support
                  </option>
                </select>
              </li>
              <li className="filter mobileView">
                <select
                  className="selectDropdown"
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option className="optionValue" value="name">
                    Name
                  </option>
                  <option className="optionValue" value="expertise">
                    Expertise
                  </option>
                </select>
              </li>
            </ul>
            <div className="orders__wrapper_block_top_search">
              {getCookie("group") === "1" &&
                this.state.allOrdersData.length >= 1 && (
                  <span className="buttonExport">
                    <Button onClick={e => this.exportexcel(e)}>
                      Export Order Details
                    </Button>
                  </span>
                )}
              <Link to="/dashboard/user/create-user">
                <button className="button_create">
                  <span>+ </span>Create
                </button>
              </Link>
              {this.state.allOrdersData.length >= 1 && (
                <>
                  <input
                    onChange={event => this.performSearch(event.target.value)}
                    name="search"
                    placeholder="search..."
                  />
                  <span className="orders__wrapper_block_top_search_icon">
                    <FaSearch />
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="date_filter">
            <div className="date_input">
              <span
                style={{
                  color: this.state.dateFilter ? "#00A5E6" : "",
                  borderBottom: "1px"
                }}
              >
                Start Date
              </span>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <span>---</span>
            <div className="date_input">
              <span style={{ color: this.state.dateFilter ? "#00A5E6" : "" }}>
                End Date
              </span>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <button
              className={this.state.dateFilter ? "show_active_btn" : "show_btn"}
              onClick={e => this.dateFilter(e)}
            >
              {this.state.dateFilter ? "Clear" : "Show"}
            </button>
          </div>
          <Container className="orderContainer">
            {console.log(this.state.lawyersList)}

            <Grid xs={12} sm={12}>
              {this.state.lawyersList.map(lawyer => (
                <div className="ordeR" key={lawyer.id}>
                  <div className="orderData">
                    <Grid xs={6} sm={6}>
                      <div className="orderNo">
                        <p>Name</p>
                        <p> {lawyer.username}</p>
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6}>
                      <div className="datE">
                        <p>Phone</p>
                        <p>{lawyer.phone}</p>
                      </div>
                    </Grid>
                  </div>

                  <div className="emExpert">
                    <Grid xs={6} sm={6}>
                      <div className="statuS">
                        <p>Email</p>
                        <p>{lawyer.email}</p>
                      </div>
                    </Grid>

                    <Grid xs={6} sm={6}>
                      <div className="iteM">
                        <p>Expertise</p>
                        <p>{lawyer.expertise}</p>
                      </div>
                    </Grid>
                  </div>
                  <div className="payData">
                    <Grid xs={6} sm={6}>
                      <div className="amounT">
                        <p>No. of Cases</p>
                        <p>{lawyer.cases}</p>
                      </div>
                    </Grid>
                  </div>

                  <div className="linkData">
                    <Link
                      to={{
                        pathname: "/dashboard/user/users-detail",
                        lawyerId: lawyer.id
                      }}
                    >
                      <li>
                        <span className="action">View</span>
                      </li>
                    </Link>
                  </div>
                </div>
              ))}
            </Grid>
          </Container>
          {this.state.totalItem >= 10 && (
            <Pagination
              prevPageText="<"
              nextPageText=">"
              firstPageText=""
              lastPageText=""
              pageRangeDisplayed={3}
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalItem}
              onChange={this.handlePageChange.bind(this)}
            />
          )}
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
