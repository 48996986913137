import React from "react";
import Helmet from "react-helmet";
import { url } from "../../Utils/domain";

const MetaFile = props => {
  try {
    return (
      <>
        <Helmet>
          <title>{props.meta_title}</title>
          <meta name="title" content={props.meta_title} />
          <meta name="description" content={props.meta_description} />
          <meta name="keywords" content={props.meta_keywords} />
          <meta name="og:title" content={props.meta_title} />
          <meta name="og:description" content={props.meta_description} />
          <meta name="og:keywords" content={props.meta_keywords} />
          <meta name="og:image" content={props.meta_image} />
          <meta name="og:url" content={url} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={props.meta_title} />
          <meta name="twitter:description" content={props.meta_description} />
          <meta name="twitter:image" content={props.meta_image} />
          {props.other_meta_tags !== undefined &&
            props.other_meta_tags !== null &&
            props.other_meta_tags.length > 0 &&
            props.other_meta_tags.map((a, i) => {
              return <meta property={a.property} content={a.content} />;
            })}
        </Helmet>
      </>
    );
  } catch (err) {}
};

export default MetaFile;
