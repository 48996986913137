import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./style.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { deleteCookie } from "../../../Utils/cookies";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain } from "../../../Utils/domain";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";
import xlsExport from "../../../Components/ExcelExport/XlsExport";
import { Button } from "@material-ui/core";
import { getCookie, setCookie } from "../../../Utils/cookies";
import DatePicker from "react-datepicker";
import { updateToasterConfig } from "../../../../src/redux/modules/app/actions";
import "react-datepicker/dist/react-datepicker.css";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";

class index extends Component {
  state = {
    orders: [],
    filterValue: [],
    resultArr: {},
    showOrders: false,
    storeOrders: [],
    selectValue: "",
    activePage: 1,
    totalItem: null,
    loader: false,
    allOrdersData: [],
    dynamicSelect: "All",
    exportOrder: [],
    filteredObjectStatus: [
      "Payment Pending",
      "Payment Done",
      "Processing",
      "Completed"
    ],
    dateFilter: false,
    startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
    endDate: new Date(),
    startDateValue: "",
    endDateValue: "",
    dateFilterData: [],
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  //group id
  //   admin - 1
  // client - 2
  // cs -5
  // finance - 4
  // lawyer - 3
  componentDidMount() {
    this.setState({
      loader: true
    });
    this.props.updateBreadcrumbs("orders");
    this.getData();

    // if (
    //   localStorage.getItem("allOrderDetails") === null ||
    //   localStorage.getItem("allOrderDetails") === undefined ||
    //   localStorage.getItem("allOrderDetails").length === 0
    // ) {
    // setTimeout(() => {
    //   this.state.totalItem !== null && this.getDataForSearch();
    // }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allOrderDetails"))
    //   });
    // }
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getDataForSearch() {
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/order/list/?created_on__date__gte=` +
          this.state.startDateValue +
          "&created_on__date__lte=" +
          this.state.endDateValue +
          `&page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(
        domain + `/api/order/list/?page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }
  searchSuccessResponse(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allOrderDetails", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results
      });
    }
  }
  getData() {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          "/api/order/list/?created_on__date__gte=" +
          this.state.startDateValue +
          "&created_on__date__lte=" +
          this.state.endDateValue +
          "&page=" +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/order/list/?page=" + this.state.activePage +"&page_size=20", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }

  successResponse = response => {
    this.setState(
      {
        totalItem: response.paginationTotal,
        orders: response.results,
        storeOrders: response.results,
        loader: false
        // loader: this.state.totalItem===null ?true :false,
      },
      () => {
        if (this.state.orders && this.state.orders !== "") {
          this.setState({ showOrders: true }, () => {
            this.state.totalItem !== null && this.getDataForSearch();
          });
        }
      }
    );
  };
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },
            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };

  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  errorResponse = error => {};

  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () =>
      this.filterResult(this.state.dynamicSelect)
    );
  }
  callApiOfSpecificGroup = valueFromFilter => {
    if (this.state.dateFilter) {
      let token = getCookie("token");
      if (
        getCookie("group") === "2" ||
        getCookie("group") === "3" ||
        getCookie("group") === "4" ||
        getCookie("group") === "5"
      ) {
        fetch(
          domain +
            `/api/order/order_based_payment_client/${valueFromFilter}/?created_on__date__gte=` +
            this.state.startDateValue +
            "&created_on__date__lte=" +
            this.state.endDateValue +
            "&page=" +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      } else {
        fetch(
          domain +
            `/api/order/order_based_payment/${valueFromFilter}/?created_on__date__gte=` +
            this.state.startDateValue +
            "&created_on__date__lte=" +
            this.state.endDateValue +
            "&page=" +
            this.state.activePage,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      }
    } else {
      let token = getCookie("token");
      if (
        getCookie("group") === "2" ||
        getCookie("group") === "3" ||
        getCookie("group") === "4" ||
        getCookie("group") === "5"
      ) {
        fetch(
          domain +
            `/api/order/order_based_payment_client/${valueFromFilter}/?page=` +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      } else {
        fetch(
          domain +
            `/api/order/order_based_payment/${valueFromFilter}/?page=` +
            this.state.activePage,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          }
        )
          .then(response => response.json())
          .then(response => this.successResponseSelected(response))
          .catch(error => this.errorResponseSelected(error));
      }
    }
  };
  successResponseSelected = response => {
    this.setState({
      totalItem: response.paginationTotal,
      orders: response.results,
      storeOrders: response.results,
      loader: false
    });
  };
  errorResponseSelected = err => {};

  clearFilterForSearch = value => {
    const currentClass = document.getElementsByClassName("filter");
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i].classList.remove("filter-active");
      }
      const element = document.getElementById(value);
      element.classList.add("filter-active");
  }

  filterResult = (value, id) => {
    try {
      if (value === "All") {
        this.getData();
      }

      if (value === this.state.dynamicSelect) {
        this.setState({ dynamicSelect: value });

        const currentClass = document.getElementsByClassName("filter");

        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("filter-active");
        }
        const element = document.getElementById(value);
        element.classList.add("filter-active");

        if (value === "All") {
          this.setState({
            orders: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.storeOrders
          });
        } else {
          var filterValue = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => item.status === value)
            : this.state.storeOrders.filter(item => item.status === value);

          var exportFilter = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => item.status === value)
            : this.state.allOrdersData.filter(item => item.status === value);

          this.setState(
            {
              orders: filterValue,
              exportOrder: exportFilter
            },
            () => {
              var selectedArray = this.state.filteredObjectStatus.find(
                x => x === value
              );

              selectedArray !== undefined &&
                this.callApiOfSpecificGroup(selectedArray);
            }
          );
        }
      } else {
        this.setState({ dynamicSelect: value }, () => {
          this.getData();
        });
        setTimeout(() => {
          this.filterResult(this.state.dynamicSelect);
        }, 1000);
      }
    } catch (err) {}
  };
  performSearch = e => {


    try {
      this.setState({ dynamicSelect: "unknown" });
      if (e.length >= 3) {
        this.clearFilterForSearch("All");
        let filtered = this.state.allOrdersData.filter(entry => {
          return Object.values(entry).some(val => {
            if (
              typeof val === "string" &&
              val.toLowerCase().includes(e.toLowerCase())
            ) {
              return true;
            }
          });
        });

        this.setState({
          orders: filtered
        });
      }
      if (e.length === 0) {
        this.setState(
          {
            orders: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.storeOrders
          },
          () => {
            this.clearFilterForSearch("All");
            this.filterResult("All");
            
          }
        );
      }
    } catch (err) {}
  };

  logout = () => {
    deleteCookie("group");
    deleteCookie("token");
    deleteCookie("userId");
    deleteCookie("logedinUser");
    this.props.history.push("/");
  };

  updateTime = date => {
    let splitr = date;
    let etime = splitr.slice(11, 16);
    return etime;
  };

  ChangeDateFormat = orderDate => {
    let format_date = new Date(orderDate);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let datestring =
      ("0" + format_date.getDate()).slice(-2) +
      " " +
      monthNames[format_date.getMonth()] +
      " " +
      format_date.getFullYear();
    format_date.toLocaleString().replace(/\//g, "-");
    return datestring;
  };

  changeRefresh = () => {
    this.state.orders.sort((a, b) =>
      a.item > b.item ? 1 : b.item > a.item ? -1 : 0
    );
    this.state.storeOrders.sort((a, b) =>
      a.item > b.item ? 1 : b.item > a.item ? -1 : 0
    );
  };
  changeRefreshDate = () => {
    this.state.orders.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
  };

  refreshMethod = () => {
    this.state.orders.sort((a, b) => {
      return a.number > b.number ? 1 : b.number > a.number ? -1 : 0;
    });
    this.state.storeOrders.sort((a, b) => {
      return a.number > b.number ? 1 : b.number > a.number ? -1 : 0;
    });
  };
  exportexcel = e => {
    const xls = new xlsExport(
      this.state.dynamicSelect === "All" && !this.state.dateFilter
        ? this.state.allOrdersData
        : this.state.dynamicSelect === "All" && this.state.dateFilter
        ? this.state.dateFilterData
        : this.state.exportOrder,
      "Orders"
    );
    xls.exportToXLS("export.xls");
  };
  render() {
    console.log(this.state.dateFilterData);
    console.log(this.state.allOrdersData);
    // this.state.orders.detail === "Invalid token." && this.logout();
    this.state.selectValue === "item" && this.changeRefresh();

    this.state.selectValue === "date" && this.changeRefreshDate();

    this.state.selectValue === "number" && this.refreshMethod();

    return (
      <div className="orders">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="orders__wrapper">
          {this.state.loader ? (
            <Loader />
          ) : (
            <div className="orders__wrapper_block">
              <div className="orders__wrapper_block_top">
                <div className="orders__wrapper_block_top_heading">
                  {/* <span><img src={Back} alt="back button" ></img></span> */}
                  <p>Orders</p>
                </div>

                <div className="orders__wrapper_block_top_search">
                  {getCookie("group") === "1" &&
                    this.state.allOrdersData.length >= 1 && (
                      <span className="buttonExport">
                        <Button onClick={e => this.exportexcel(e)}>
                          Export Order Details
                        </Button>
                      </span>
                    )}
                  {this.state.allOrdersData.length >= 1 && (
                    <>
                      <input
                        onChange={event =>
                          this.performSearch(event.target.value)
                        }
                        name="search"
                        placeholder="Search..."
                      />
                      <span className="orders__wrapper_block_top_search_icon">
                        <FaSearch />
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="date_filter">
                <div className="date_input">
                  <span
                    style={{
                      color: this.state.dateFilter ? "#00a5e6" : "",
                      borderBottom: "1px"
                    }}
                  >
                    Start Date
                  </span>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <span>---</span>
                <div className="date_input">
                  <span
                    style={{ color: this.state.dateFilter ? "#00a5e6" : "" }}
                  >
                    End Date
                  </span>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <button
                  className={
                    this.state.dateFilter ? "show_active_btn" : "show_btn"
                  }
                  onClick={e => this.dateFilter(e)}
                >
                  {this.state.dateFilter ? "Clear" : "Show"}
                </button>
              </div>

              <ul className="orders__wrapper_block_top_filter">
                <li
                  id="All"
                  className=" filter filter-active"
                  onClick={() => this.filterResult("All")}
                >
                  All
                </li>
                <li
                  id="Completed"
                  className="filter"
                  onClick={() => this.filterResult("Completed")}
                >
                  Completed
                </li>
                <li
                  id="Payment Pending"
                  className="filter"
                  onClick={() => this.filterResult("Payment Pending")}
                >
                  Pending
                </li>
                <li
                  id="Payment Done"
                  className="filter"
                  onClick={() => this.filterResult("Payment Done")}
                >
                  Payment Done
                </li>
                <li className="filter mobileView">
                  <span className="sortLabel">Sortby</span>
                  <select
                    className="selectDropdown"
                    value={this.state.selectValue}
                    onChange={this.handleChange}
                  >
                    <option className="optionValue" value="item">
                      Item
                    </option>
                    <option className="optionValue" value="number">
                      Order No
                    </option>
                    <option className="optionValue" value="date">
                      Date
                    </option>
                  </select>
                </li>
              </ul>
              <TableContainer>
                <Table className="orderTable" aria-label="simple table">
                  <TableHead>
                    <TableRow className="orderNo">
                      <TableCell>Order No.</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      {getCookie("group") !== "2" && (
                        <TableCell>Name</TableCell>
                      )}
                      {getCookie("group") !== "2" && (
                        <TableCell>User Id</TableCell>
                      )}
                      {getCookie("group") !== "2" &&
                        getCookie("group") !== "3" && (
                          <TableCell>Lawyer Assigned</TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  {this.state.showOrders === true && (
                    <>
                      <TableBody>
                        {this.state.orders.length >= 1 ?
                          this.state.orders.map(order => {
                            return (
                              <TableRow key={order.number}>
                                <TableCell component="th" scope="row">
                                  {order.number}
                                </TableCell>

                                {/* <TableCell >{this.updateTime(order.date)}</TableCell> */}
                                <TableCell>
                                  {this.ChangeDateFormat(order.date)}
                                </TableCell>
                                <TableCell>{order.item}</TableCell>
                                <TableCell>{order.amount}</TableCell>
                                <TableCell>{order.status}</TableCell>
                                {getCookie("group") !== "2" && (
                                  <TableCell>
                                    {order.first_name === null &&
                                    order.last_name === null
                                      ? "NA"
                                      : order.first_name && order.last_name
                                      ? order.first_name + " " + order.last_name
                                      : "NA"}
                                  </TableCell>
                                )}
                                {getCookie("group") !== "2" && (
                                  <TableCell>
                                    {order.username ? order.username : "NA"}
                                  </TableCell>
                                )}
                                {getCookie("group") !== "2" &&
                                  getCookie("group") !== "3" && (
                                    <TableCell>
                                      {order.laywerAssigned
                                        ? order.laywerAssigned
                                        : "NA"}
                                    </TableCell>
                                  )}
                                <TableCell>
                                  <Link
                                    to={
                                      "/dashboard/order-details/" +
                                      order.number +
                                      "/" +
                                      order.item
                                    }
                                  >
                                    <li>
                                      <span className="action">View</span>
                                    </li>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          }) : <p class="order_Center" style={{textAlign:"center"}}>No orders found</p>}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
              {this.state.totalItem > 20 && <div className="pagination-box">
              <Pagination
                prevPageText="<"
                nextPageText=">"
                firstPageText=""
                lastPageText=""
                pageRangeDisplayed={5}
                activePage={this.state.activePage}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalItem}
                //pageRangeDisplayed={Math.ceil(this.state.totalItem/20)}
                onChange={this.handlePageChange.bind(this)}
              /><span className="total-page">Page No. {this.state.activePage} of {Math.ceil(this.state.totalItem/20)}</span>
              </div>
            }
            </div>
                        
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
