import React, { useEffect } from 'react'
import { getCookie } from '../../Utils/cookies'
import "./style.scss"
export default function Login({ onIdSubmit }) {

useEffect(()=>{
  if(getCookie("userId")===""||getCookie("userId")===null ||getCookie("userId")===undefined){
  onIdSubmit(JSON.parse(localStorage.getItem("uuidChat")))
  }
  else{
    localStorage.setItem("uuidChat", JSON.stringify(getCookie("userId")))
  onIdSubmit(getCookie("userId"))
  }
},[])
  return (
    <div>
     
    </div>
  )
}
