import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormPasswordReset from "./FormPasswordReset";
import "./styles.scss";
import { getMeta } from "../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../Pages/MetaFile";
function ResetPassword() {
  const [meta_title, setmeta_title] = useState("");
  const [meta_description, setmeta_description] = useState("");
  const [meta_keywords, setmeta_keywords] = useState("");
  const [other_meta_tags, seteother_meta_tags] = useState("");
  const [json_meta_tags, setjson_meta_tags] = useState("");
  useEffect(() => {
    getMeta(getSucessMetaresponce, getErrorMetaresponce);
  }, []);
  const getSucessMetaresponce = response => {
    setmeta_title(response.meta_title);
    setmeta_description(response.meta_description);
    setmeta_keywords(response.meta_keywords);
    seteother_meta_tags(response.other_meta_tags);
    setjson_meta_tags(response.json_meta_tags);
  };
  const getErrorMetaresponce = err => {};
  return (
    <div className="ResetContainer">
      {meta_title !== "" && <MetaFile
        meta_title={meta_title}
        meta_description={meta_description}
        meta_keywords={meta_keywords}
        other_meta_tags={json_meta_tags}
      />}
      <Container>
        <Grid xs={12} lg={12} md={12} sm={12}>
          <div className="resetHeading">
            <div className="titLeR">
              <Typography variant="title" className="topText">
                Reset Password
              </Typography>
            </div>
            <div className="subHeading">
              <span>
                Enter your current password as verification to reset your
                password
              </span>
            </div>
          </div>
          <FormPasswordReset />
        </Grid>
      </Container>
    </div>
  );
}
export default ResetPassword;
