import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
//import httpRequest from '../../../Utils/httpRequest';
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
// import { getLawyersList } from '../../Website/WebServices/LawyersList';
import { domain } from "../../../Utils/domain";
import { getCookie } from "../../../Utils/cookies";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination";
import Loader from "./../../../Components/Preloader";
import xlsExport from "../../../Components/ExcelExport/XlsExport";
import { Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
class index extends Component {
  state = {
    lawyersList: [],
    usersStore: [],
    activePage: 1,
    totalItem: null,
    loader: false,
    filterValue: [],
    allOrdersData: [],
    dynamicSelect: "All",
    dateFilterData: [],
    startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
    endDate: new Date(),
    startDateValue: "",
    endDateValue: "",
    dateFilter: false,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };
  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  componentDidMount() {
    this.props.updateBreadcrumbs("users");
    this.setState({
      loader: true
    });
    this.getData();
    // if (
    //   localStorage.getItem("allUsersData") === null ||
    //   localStorage.getItem("allUsersData") === undefined ||
    //   localStorage.getItem("allUsersData").length === 0
    // ) {
    // setTimeout(() => {
    //   this.state.totalItem !== null && this.getDataForSearch();
    // }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allUsersData"))
    //   });
    // }
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  // ?page=" + this.state.activePage,

  getData() {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/users/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage +"&page_size=20",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + `/api/auth/users/?page=` + this.state.activePage +"&page_size=20", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }

  successResponse = response => {
    console.log(response);
    this.setState(
      {
        totalItem: response.paginationTotal,
        lawyersList: response.results,
        usersStore: response.results,
        loader: false
      },
      () => {
        this.state.totalItem !== null && this.getDataForSearch();
      }
    );
  };
  getDataForSearch = () => {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/users/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(
        domain + `/api/auth/users/?page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponse(response))
        .catch(error => this.errorResponse(error));
    }
  };
  searchSuccessResponse(res) {
    console.log(res);
    if (res.results !== undefined) {
      localStorage.setItem("allUsersData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results,
        loader: false
      });
    }
  }
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () =>
      this.filterResult(this.state.dynamicSelect)
    );
  }
  handleChange = e => {
    this.setState({ selectValue: e.target.value });
  };
  callApiOfSpecificGroup = valueFromFilter => {
    let token = getCookie("token");
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/auth/user_list_group/${valueFromFilter}/?date_joined__date__gte=` +
          this.state.startDateValue +
          "&date_joined__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    } else {
      fetch(
        domain +
          `/api/auth/user_list_group/${valueFromFilter}/?page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponseSelected(response))
        .catch(error => this.errorResponseSelected(error));
    }
  };
  successResponseSelected = response => {
    this.setState({
      totalItem: response.paginationTotal,
      lawyersList: response.results,
      usersStore: response.results,
      loader: false
    });
  };
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    console.log(this.state.dateFilter);
    this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        console.log(this.state.dateFilter);
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },
            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };
  errorResponseSelected = err => {};
  filterResult = value => {
    try {
      if (value === "All") {
        this.getData();
      }
      if (value === this.state.dynamicSelect) {
        this.setState({ dynamicSelect: value });
        const currentClass = document.getElementsByClassName("filter");

        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.remove("filter-active");
        }
        const element = document.getElementById(value);
        element.classList.add("filter-active");

        if (value === "All") {
          this.setState({
            lawyersList: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.usersStore
          });
        } else {
          let filterValue = this.state.dateFilter
            ? this.state.dateFilterData.filter(
                item => item.group_name === value.toLowerCase()
              )
            : this.state.usersStore.filter(
                item => item.group_name === value.toLowerCase()
              );
          let exportOrder = this.state.dateFilter
            ? this.state.dateFilterData.filter(item => {
                if (item.group_name === "customer support") {
                  return item.group_name.includes(value.toLowerCase());
                } else {
                  return item.group_name === value.toLowerCase();
                }
              })
            : this.state.allOrdersData.filter(item => {
                if (item.group_name === "customer support") {
                  return item.group_name.includes(value.toLowerCase());
                } else {
                  return item.group_name === value.toLowerCase();
                }
              });
          this.setState(
            {
              lawyersList: filterValue,
              exportOrder: exportOrder
            },
            () => {
              if (value.includes("support")) {
                this.callApiOfSpecificGroup(5);
              } else {
                var selectedArray = this.state.lawyersList.find(
                  x => x.group_name === value.toLowerCase()
                );
                selectedArray !== undefined &&
                  this.callApiOfSpecificGroup(selectedArray.groups);
              }
            }
          );
        }
      } else {
        this.setState({ dynamicSelect: value }, () => {
          this.getData();
        });
        setTimeout(() => {
          this.filterResult(this.state.dynamicSelect);
        }, 1000);
      }
    } catch (err) {}
  };

  errorResponse = error => {};

  clearFilterForSearch = value => {
    const currentClass = document.getElementsByClassName("filter");
      for (let i = 0; i < currentClass.length; i++) {
        currentClass[i].classList.remove("filter-active");
      }
      const element = document.getElementById(value);
      element.classList.add("filter-active");
  }
  performSearch = e => {
    try {
      if (e.length >= 3) {
        this.clearFilterForSearch("All");
        let filtered = this.state.allOrdersData.filter(entry => {
          return Object.values(entry).some(val => {
            if (
              typeof val === "string" &&
              val.toLowerCase().includes(e.toLowerCase())
            ) {
              return true;
            }
          });
        });

        this.setState({
          lawyersList: filtered
        });
      }
      if (e.length === 0) {
        this.setState(
          {
            lawyersList: this.state.dateFilter
              ? this.state.dateFilterData
              : this.state.usersStore
          },
          () => {
            this.filterResult("All");
            this.clearFilterForSearch("All")
          }
        );
      }
    } catch (err) {}
  };
  exportexcel = e => {
    const xls = new xlsExport(
      this.state.dynamicSelect === "All" && !this.state.dateFilter
        ? this.state.allOrdersData
        : this.state.dynamicSelect === "All" && this.state.dateFilter
        ? this.state.dateFilterData
        : this.state.exportOrder,
      "lawyers"
    );
    xls.exportToXLS("export.xls");
  };
  usernameSort = () => {
    this.state.lawyersList.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
    this.state.usersStore.sort((a, b) => {
      return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
    });
  };
  changeRefreshDate = () => {
    this.state.lawyersList.sort((a, b) => {
      return a.expertise > b.expertise ? 1 : b.expertise > a.expertise ? -1 : 0;
    });
    this.state.usersStore.sort((a, b) => {
      return a.expertise > b.expertise ? 1 : b.expertise > a.expertise ? -1 : 0;
    });
  };

  render() {
    console.log(this.state.allOrdersData);
    console.log(this.state.dateFilterData);
    this.state.selectValue === "name" && this.usernameSort();
    this.state.selectValue === "expertise" && this.changeRefreshDate();
    return (
      <div className="lawyers">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="lawyers__wrapper">
          {this.state.loader ? (
            <Loader />
          ) : (
            <div className="lawyers__wrapper_block">
              <div className="lawyers__wrapper_block_top">
                <div className="lawyers__wrapper_block_top_heading">
                  {/* <span><img src={Back} alt="back button" ></img></span> */}
                  <p>All Users</p>
                </div>
                <div className="lawyers__wrapper_block_top_search">
                  <Link to="/dashboard/users/create-users">
                    <button className="button_create">
                      <span>+ </span>Create
                    </button>
                  </Link>
                  {getCookie("group") === "1" &&
                    this.state.allOrdersData.length >= 1 && (
                      <span className="buttonExport">
                        <Button onClick={e => this.exportexcel(e)}>
                          Export Users
                        </Button>
                      </span>
                    )}
                  {this.state.allOrdersData.length >= 1 && (
                    <>
                      <input
                        onChange={event =>
                          this.performSearch(event.target.value)
                        }
                        name="search"
                        placeholder="search..."
                      />
                      <span className="lawyers__wrapper_block_top_search_icon">
                        <FaSearch />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="date_filter">
                <div className="date_input">
                  <span
                    style={{
                      color: this.state.dateFilter ? "#00A5E6" : "",
                      borderBottom: "1px"
                    }}
                  >
                    Start Date
                  </span>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <span>---</span>
                <div className="date_input">
                  <span
                    style={{ color: this.state.dateFilter ? "#00A5E6" : "" }}
                  >
                    End Date
                  </span>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <button
                  className={
                    this.state.dateFilter ? "show_active_btn" : "show_btn"
                  }
                  onClick={e => this.dateFilter(e)}
                >
                  {this.state.dateFilter ? "Clear" : "Show"}
                </button>
              </div>
              <ul className="lawyers__wrapper_block_top_filter">
                <li
                  id="All"
                  className=" filter filter-active"
                  onClick={e => {
                    this.filterResult("All");
                  }}
                >
                  All
                </li>
                <li
                  id="Lawyer"
                  className="filter"
                  onClick={() => this.filterResult("Lawyer")}
                >
                  Lawyers
                </li>
                <li
                  id="Finance"
                  className="filter"
                  onClick={() => this.filterResult("Finance")}
                >
                  Finance
                </li>
                <li
                  id="support"
                  className="filter"
                  onClick={() => this.filterResult("support")}
                >
                  Customer Service
                </li>
                <li className="filter mobileView">
                  <span className="sortLabel">Sortby</span>
                  <select
                    className="selectDropdown"
                    value={this.state.selectValue}
                    onChange={this.handleChange}
                  >
                    <option className="optionValue" value="name">
                      Name
                    </option>
                    <option className="optionValue" value="expertise">
                      Expertise
                    </option>
                  </select>
                </li>
              </ul>
              <TableContainer component={Paper}>
                <Table className="lawyerTable" aria-label="simple table">
                  <TableHead className="lawyerTable_head">
                    <TableRow>
                      <TableCell>User ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Expertise</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="lawyerTable_head_body">
                    {this.state.lawyersList.map(lawyer => (
                      <TableRow key={lawyer.id}>
                        <TableCell component="th" scope="row">
                          {lawyer.username}
                        </TableCell>
                        <TableCell>
                          {lawyer.first_name || lawyer.last_name ? (
                            <div>
                              {lawyer.first_name}&nbsp;{lawyer.last_name}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </TableCell>
                        <TableCell>{lawyer.email}</TableCell>
                        <TableCell>
                          {lawyer.phone ? lawyer.phone : "NA"}
                        </TableCell>
                        <TableCell>
                          {lawyer.group_name ? lawyer.group_name : "NA"}
                        </TableCell>
                        <TableCell>
                          {lawyer.expertise ? lawyer.expertise : "NA"}
                        </TableCell>
                        <TableCell>{lawyer.status}</TableCell>

                        <TableCell className="lawyerView">
                          <Link
                            to={{
                              pathname: "/dashboard/users/users-details",
                              lawyerId: lawyer.id
                            }}
                          >
                            <li>
                              <span className="action">View</span>
                            </li>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.totalItem && <div className="pagination-box">
              <Pagination
                prevPageText="<"
                nextPageText=">"
                firstPageText=""
                lastPageText=""
                pageRangeDisplayed={5}
                activePage={this.state.activePage}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalItem}
                onChange={this.handlePageChange.bind(this)}
              />
                <span className="total-page">Page No. {this.state.activePage} of {Math.ceil(this.state.totalItem/20)}</span>
            </div>
            }
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
