import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from '../../../Utils/cookies';
import { domain } from '../../../Utils/domain';
var newSocket =null;
var createUUID=JSON.parse(localStorage.getItem("uuidChat"))


var newArray=[]

 

class MessageParser {
    constructor(actionProvider, state,props) {
    
      this.actionProvider = actionProvider;
      this.state = 
        state
    }

    getData=(msg,id)=>{
           
      newArray.push({id:id,message:msg})
  
 
     var MergeArray =newArray.concat(JSON.parse(localStorage.getItem('preveiousgenric-chats')))
      var filtered = MergeArray.filter(function (el) {
        return el != null;
      });
     
     
      localStorage.setItem("genricChat",JSON.stringify(filtered))


    }
 
    componentWillUnmount=()=>{
      newSocket.off('receive-chat')
    }


    parse = (message) => {
     
      if(createUUID=== null){
       createUUID =uuidv4()
       localStorage.setItem("uuidChat",JSON.stringify(createUUID))
      }
      
      newSocket = io(
        'https://legalchat-be.beta.webenza.net',
        { query: { message, id: createUUID } }
      )

      if (newSocket == null) return
      newSocket.emit('send-chat', { message,id:createUUID})

      this.getData(message, createUUID)

      newSocket.on('receive-chat', this.getData)
     

        const lowerCase = message.toLowerCase();
    
        if (
          lowerCase.includes("messageparser") ||
          lowerCase.includes("parse") ||
          lowerCase.includes("parser") ||
          lowerCase.includes("message parser")
        ) {
          return this.actionProvider.handleMessageParser();
        }
        return this.actionProvider.handleDefault();
      };
    }
    
    export default MessageParser;