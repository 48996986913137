import React, { Component } from "react";
import AdminRoutes from "./../../Routes/AdminRoutes";
import { getCookie } from "./../../Utils/cookies";
import { domain } from "./../../Utils/domain";

export default class index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (
      getCookie("userId") !== "" ||
      getCookie("userId") !== null ||
      getCookie("userId") !== undefined
    ) {
      localStorage.removeItem("chatLogs-id");
      localStorage.removeItem("uuidChat");
    }

    var data = localStorage.getItem("storeDb");
    if (data !== null) {
      setTimeout(() => {
        fetch(domain + "/api/chat/save_chat_conversations/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token"),
          },
          body: JSON.stringify({
            "chatlog_conversations": JSON.parse(data),
          }),
        })
          .then((response) => response.json())
          .then((response) => this.successResponseSaveChat(response))
          .catch((error) => this.errorResponseChat(error));
      }, 100);
    }

    setTimeout(() => {
      fetch(domain + "/api/chat/chat_conversations_list/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token"),
        },
      })
        .then((response) => response.json())
        .then((response) => this.successResponseFetch(response))
        .catch((error) => this.errorResponse(error));
    }, 1000);
  };
  successResponseSaveChat = (res) => {
    if (res) {
      localStorage.clear();
    }
  };
  errorResponse = (err) => {
  };

  successResponseFetch = (response) => {
    if (getCookie("userId") === "67") {
      localStorage.setItem(
        "storedData",
        JSON.stringify(response.chatlog_conversations)
      );
    }
    if (getCookie("userId") === "67") {
      for (var storeage in response.chatlog_conversations) {
        if (
          response.chatlog_conversations[storeage].recipients[0].id ===
          getCookie("userId")
        ) {
          localStorage.setItem(
            "storedData",
            JSON.stringify(response.chatlog_conversations[storeage])
          );
        }
      }
    }
  };

  errorResponseChat = (error) => {console.log(error)};
  render() {
    getCookie("logedinUser") === "" && window.location.replace("/home");
    return (
      <div>
        <AdminRoutes showNavber={this.props.showNavber} />
      </div>
    );
  }
}
