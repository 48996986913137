import React, { Component, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Orders from "./../Pages/Dashboard/Orders";
import Orderdetails from "./../Pages/Dashboard/Orders/OrderDetails";
import NotificationDetails from "./../Pages/Dashboard/Notifications/notificationDetails";
import Customer from "./../Pages/Dashboard/Customers";
import Lawyers from "./../Pages/Dashboard/lawyers";
import CustomerDetails from "./../Pages/Dashboard/Customers/customerDetails";
import CreateLawyers from "./../Pages/Dashboard/lawyers/CreateLawyers";
import LawyerDetails from "./../Pages/Dashboard/lawyers/LawyerDetails";
import Lawyer from "./../Pages/Dashboard/mobileLayers";
import CreateLawyer from "./../Pages/Dashboard/mobileLayers/CreateLawyers";
import LawyerDetail from "./../Pages/Dashboard/mobileLayers/LawyerDetails";
import Notification from "./../Pages/Dashboard/Notifications";
import PlanPage from "../Pages/Website/Planpage";
import FAQs from "./../Pages/Dashboard/Faqs";
import AddFAQs from "./../Pages/Dashboard/Faqs/addFAQs";
import FAQDetails from "./../Pages/Dashboard/Faqs/faqDetails";
import FAQQuaAns from "./../Pages/Dashboard/Faqs/faqQA";
import FAQEdit from "./../Pages/Dashboard/Faqs/faqQA/faqQAEdit";
import EditHome from "./../Pages/Dashboard/editHomepage";
import EditHomeContent from "./../Pages/Dashboard/editHomepage/EditHomeContent";
import EditSubs from "./../Pages/Dashboard/editSubscriptions";
import EditSubsPlan from "./../Pages/Dashboard/editSubscriptions/editPlan";
import AddProduct from "./../Pages/Dashboard/Products/AddProduct";
import Products from "./../Pages/Dashboard/Products";
import ProductDetails from "./../Pages/Dashboard/Products/Product-details";
import MobileProductDetails from "../Pages/Dashboard/mobileProducts/MobileProductDetails";
import Product from "./../Pages/Dashboard/mobileProducts";
import Chat from "./../Pages/Dashboard/Chat-logs";
import ChatLogsAdmin from "./../Pages/Dashboard/ChatLogsAdmin";
import Order from "./../Pages/Dashboard/MobileView/Order/Order";
import CustomerList from "./../Pages/Dashboard/MobileView/CustomerList/CustomerList";
import ChatDeatils from "../Pages/Dashboard/ChatLogsAdmin/chatDetails";
import mobChatAdminlog from "./../Pages/Dashboard/MobileView/chatLogAdminMobile";
import mobChatdetails from "./../Pages/Dashboard/MobileView/chatLogAdminMobile/chatDetails";
import mobbotAdminlog from "./../Pages/Dashboard/MobileView/botLogAdminMobile";
import mobbotdetails from "./../Pages/Dashboard/MobileView/botLogAdminMobile/botDetails";
//Clients links
import Profile from "./../Pages/Dashboard/Client/Profile";
import Subscription from "./../Pages/Dashboard/Client/Subscription";
import CustomerSubscriptionView from "./../Pages/Dashboard/Customers/CustomerSubscriptionView";
import LawyerSubscriptionView from "./../Pages/Dashboard/lawyers/lawyersSubscription";
import ResetPassword from "../Components/Resetpassword/ResetPassword";
import BotLogs from "../Pages/Dashboard/BotLogs";
import BotChatDetails from "../Pages/Dashboard/BotLogs/BotChatDetails";
import ProfilePage from "../Pages/Dashboard/ProfilePage";
import { getCookie } from "../Utils/cookies";

export default class Routes extends Component {
  render() {
    return (
      <div>
        {window.innerWidth > 768 ? (
          <Switch>
            <Route path="/dashboard" exact component={Orders} />
            <Route
              path="/dashboard/order-details/:id/:name"
              component={Orderdetails}
            />
            <Route
              path="/dashboard/notifications/notification-details/:id"
              component={NotificationDetails}
            />
            <Route path="/dashboard/orders" exact component={Orders} />
            <Route
              path="/dashboard/customers"
              exact
              component={
                getCookie("group") == 1 ||
                getCookie("group") == 3 ||
                getCookie("group") == 5 ||
                getCookie("group") == 4
                  ? Customer
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/customers/customer-details"
              exact
              component={
                getCookie("group") == 1 ||
                getCookie("group") == 3 ||
                getCookie("group") == 5 ||
                getCookie("group") == 4
                  ? CustomerDetails
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/users"
              exact
              component={getCookie("group") == 1 ? Lawyers : PlanPage}
            />
            <Route
              path="/dashboard/users/create-users"
              exact
              component={getCookie("group") == 1 ? CreateLawyers : PlanPage}
            />
            <Route
              path="/dashboard/users/users-details"
              exact
              component={getCookie("group") == 1 ? LawyerDetails : PlanPage}
            />
            <Route
              path="/dashboard/products/add-product"
              exact
              component={getCookie("group") == 1 ? AddProduct : PlanPage}
            />
            <Route
              path="/dashboard/product/product-details/:id/:name"
              exact
              component={getCookie("group") == 1 ? ProductDetails : PlanPage}
            />
            <Route
              path="/dashboard/products"
              exact
              component={getCookie("group") == 1 ? Products : PlanPage}
            />
            <Route path="/dashboard/chat-logs" exact component={Chat} />
            <Route
              path="/dashboard/chatLogsAdmin/chat-details/:id"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? ChatDeatils
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/chatLogsAdmin"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? ChatLogsAdmin
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/notifications"
              exact
              component={Notification}
            />
            <Route path="/dashboard/faqs" exact component={FAQs} />
            <Route path="/dashboard/faqs/add-faqs" exact component={AddFAQs} />
            <Route
              path="/dashboard/faq-Details/:id"
              exact
              component={FAQDetails}
            />
            <Route path="/dashboard/faqs/que-ans" exact component={FAQQuaAns} />
            <Route
              path="/dashboard/faqs/que-ans/edit/:id"
              exact
              component={FAQEdit}
            />

            <Route
              path="/dashboard/edit-home-page"
              exact
              component={EditHome}
            />
            <Route
              path="/dashboard/edit-home-page/content"
              exact
              component={EditHomeContent}
            />

            <Route
              path="/dashboard/edit-subscriptions"
              exact
              component={EditSubs}
            />
            <Route
              path="/dashboard/edit-subscriptions/:id"
              exact
              component={EditSubsPlan}
            />
            <Route
              path="/dashboard/customers/SubscriptionView"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 3
                  ? CustomerSubscriptionView
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/lawyers/lawyersSubscription"
              exact
              component={LawyerSubscriptionView}
            />
            <Route
              path="/dashboard/reset-password"
              exact
              component={ResetPassword}
            />
            {/* //Client Paths */}
            <Route
              path="/dashboard/profile"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? ProfilePage
                  : Profile
              }
            />
            <Route
              path="/dashboard/subscription"
              exact
              component={Subscription}
            />
            <Route
              path="/dashboard/BotLogs"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? BotLogs
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/BotLogs/BotChatDetails/:id"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? BotChatDetails
                  : PlanPage
              }
            />
          </Switch>
        ) : (
          <Switch>
            <Route path="/dashboard/" exact component={Order} />
            <Route path="/dashboard/order" exact component={Order} />
            <Route
              path="/dashboard/customer"
              exact
              component={
                getCookie("group") == 1 ||
                getCookie("group") == 5 ||
                getCookie("group") == 3 ||
                getCookie("group") == 4
                  ? CustomerList
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/notifications"
              exact
              component={Notification}
            />
            <Route path="/dashboard/chat-logs" exact component={Chat} />
            <Route
              path="/dashboard/mob-chatlogs"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? mobChatAdminlog
                  : PlanPage
              }
            />
            <Route path="/dashboard/profile" exact component={Profile} />
            <Route
              path="/dashboard/order-details/:id/:name"
              component={Orderdetails}
            />
            <Route
              path="/dashboard/customers/customer-details"
              exact
              component={
                getCookie("group") == 1 ||
                getCookie("group") == 5 ||
                getCookie("group") == 3 ||
                getCookie("group") == 4
                  ? CustomerDetails
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/user"
              exact
              component={getCookie("group") == 1 ? Lawyer : PlanPage}
            />
            <Route
              path="/dashboard/user/create-user"
              exact
              component={getCookie("group") == 1 ? CreateLawyer : PlanPage}
            />
            <Route
              path="/dashboard/user/users-detail"
              exact
              component={getCookie("group") == 1 ? LawyerDetail : PlanPage}
            />
            <Route
              path="/dashboard/products/add-product"
              exact
              component={getCookie("group") == 1 ? AddProduct : PlanPage}
            />
            <Route
              path="/dashboard/product"
              exact
              component={getCookie("group") == 1 ? Product : PlanPage}
            />
            <Route
              path="/dashboard/customers/SubscriptionView"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 3
                  ? CustomerSubscriptionView
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/lawyers/lawyersSubscription"
              exact
              component={LawyerSubscriptionView}
            />
            <Route
              path="/dashboard/mobileProducts/MobileProductDetails"
              exact
              component={
                getCookie("group") == 1 ? MobileProductDetails : PlanPage
              }
            />
            <Route
              path="/dashboard/notifications/notification-details/:id"
              component={NotificationDetails}
            />
            <Route
              path="/dashboard/mobileView/chatLogAdminMobile/chatDetails/:id"
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? mobChatdetails
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/notifications"
              exact
              component={Notification}
            />
            <Route path="/dashboard/faqs" exact component={FAQs} />
            <Route path="/dashboard/faq-Details" exact component={FAQDetails} />
            {/* //Client Paths */}
            <Route path="/dashboard/profile" exact component={Profile} />
            <Route
              path="/dashboard/subscription"
              exact
              component={Subscription}
            />
            <Route
              path="/dashboard/reset-password"
              exact
              component={ResetPassword}
            />
            <Route
              path="/dashboard/botlog"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? mobbotAdminlog
                  : PlanPage
              }
            />
            <Route
              path="/dashboard/botlog/botDetails/:id"
              exact
              component={
                getCookie("group") == 1 || getCookie("group") == 5
                  ? mobbotdetails
                  : PlanPage
              }
            />
          </Switch>
        )}
      </div>
    );
  }
}
