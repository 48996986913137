import React, { useState, useCallback, useEffect } from 'react'
import { getCookie } from '../../Utils/cookies';
import { useConversations } from '../../contexts/ConversationsProvider';
import { Avatar } from "@material-ui/core";
var mergearray=[]
export default function OpenConversation({prevMessage}) {
 
 
  const [text, setText] = useState('')
 
  const setRef = useCallback(node => {
    if (node) {
      node.scrollIntoView({ smooth: true })
    }
  }, [])
  const { sendMessage, selectedConversation } = useConversations([])
  const [dataUser,setDataUser]=useState([])
useEffect(()=>{
try{
  if(getCookie("userId")!=="67"){
     
    setDataUser(JSON.parse(localStorage.getItem("storedData")).messages)
    
   }
}catch(err){}
},[])

  function handleSubmit(e) {
    e.preventDefault()
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    sendMessage(
      selectedConversation.recipients.map(r => r.id),
      text
    )
    setText('')
  }


    mergearray = (dataUser!== undefined && dataUser.length>0)? prevMessage.concat(dataUser) :prevMessage
  
  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="flex-grow-1 overflow-auto">
        <div className="d-flex flex-column align-items-start justify-content-end px-3 scrollData" >
         
        {mergearray.map((itm,i)=>{
          const last = prevMessage.length - 1 === i
      return(
        <div
         ref={last ? setRef : null}
        key={i}
       className={`my-1 d-flex flex-column ${(itm.sender=== getCookie("userId")) ? 'backgroundWhite align-self-end align-items-end bodydiv' : ' bodydiv backgroundBlue align-items-start'}`}
      >
        <div
          className={`rounded px-2 py-1 ${(itm.sender=== getCookie("userId")) ? 'bg-primary text-white dataPadding paraDiv' : ' paraDiv dataPadding border '}`}
       >
{itm.text}
        </div>
        <div
        className={`text-muted small ${(itm.sender=== getCookie("userId")) ? 'text-right' : ''}`}
        >
           {itm.messageSentDateTime}
        </div>
        
       
      </div>
      )
    })}

          {selectedConversation.messages.map((message, index) => {
           
            const lastMessage = selectedConversation.messages.length - 1 === index
            return (
              <div
                ref={lastMessage ? setRef : null}
                key={index}
               className={`my-1 d-flex flex-column ${message.fromMe ? 'backgroundWhite align-self-end align-items-end bodydiv' : ' bodydiv backgroundBlue align-items-start'}`}
              >
                <div
                  className={`rounded px-2 py-1 ${message.fromMe ? 'bg-primary text-white dataPadding paraDiv' : ' paraDiv dataPadding border '}`}
               >
   {message.text}
                </div>

                <div
                className={`text-muted small ${message.fromMe ? 'text-right' : ''}`}
                >
                  {message.messageSentDateTime}

                </div>
               
              </div>

            )
          })}
        </div>
      </div>
      <form onSubmit={handleSubmit} className="bottomtext">

            <input
            className="textBoxNew"
             type="text"
              required
              value={text}
              placeholder="Type here .."
              onChange={e => setText(e.target.value)}
            />

              <button type="submit" className="createButton1"><Avatar
                src={require("./../../Assets/images/send.png")}
              /></button>


      </form>
    </div>
  )
}
