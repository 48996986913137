import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
  ListItem
} from "@material-ui/core";
import Carousel, { consts } from "react-elastic-carousel";
import { withStyles } from "@material-ui/core/styles";
import { getCookie, setCookie } from "../../../Utils/cookies";
import { domain } from "../../../Utils/domain";
import Box from "@material-ui/core/Box";
import {
  ServiceCategoriesAndLists,
  ServiceCategories,
  ServiceLists,
  ServiceProducts
} from "../WebServices/Services";
import { SearchApi } from "../WebServices/HomeApis";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Chatbot from "react-chatbot-kit";
import config from "./../../ChatBot/configs/chatbotConfig";
import MessageParser from "./../../ChatBot/chatbot/MessageParser";
import ActionProvider from "./../../ChatBot/chatbot/ActionProvider";
// import SMS from './../../../assets/icons/nav/headset w.png'
import "./styles.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateToasterConfig } from "../../../redux/modules/app/actions";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MetaFile from "../../MetaFile";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import "./styles.scss";
const YoutubeLink = lazy(() => import("./../../../Components/Youtube/youtube"));
const YoutbeIfram = lazy(() =>
  import("./../../../Components/Youtube/youtubeiframe")
);

const trackingId = "UA-198795830-2"; // UA-198795830-2  Google Analytics tracking ID
const tagManagerArgs = {
  gtmId: 'GTM-MT2Q5GF'  // GTM  tracking ID
}


const SelectService = lazy(() => import("./selectService"));
const SubscriptionPlan = lazy(() => import("./SubscriptionPlan"));
const Testimonials = lazy(() => import("./Testimonials"));
var loaderOpts;
var loader;
var settings = {
  slidesToShow: 3,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        arrows: true,
        slidesToShow: 1
      }
    }
  ]
};

const styles = {
  banner: {
    height: 850,
    width: "100%",
    borderRadius: 0
  },
  searchImageBackground: {
    backgroundColor: "#D06900",
    borderRadius: 0,
    width: 90
  },
  image: {
    borderRadius: 0,
    height: 24,
    width: 24
  },
  input: {
    flex: 1,
    marginLeft: 19,
    fontSize: 16,
    fontFamily: "Raleway"
  },
  modal: {
    top: 700,
    position: "absolute",
    // height: 320,
    height: 400,
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    borderRadius: 0,
    boxShadow: "0px 0px 22px #97979738"
  },
  modalTitle: {
    color: "#141C49",
    fontFamily: "Lora",
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: "medium",
    textAlign: "center",
    marginTop: 44,
    opacity: 1
  },
  title2: {
    color: "#141C49",
    fontFamily: "Lora",
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
    opacity: 1
  },
  description2: {
    color: "#585858",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Raleway",
    fontSize: 16,
    textAlign: "center",
    marginTop: 30,
    lineHeight: 1.4
  },
  cards: {
    marginTop: 62,
    backgroundColor: "white",
    borderRadius: 0,
    boxShadow: "0px 3px 35px #D4D4D429",
    padding: "30px"
  },
  cardsSpace: {
    width: "80%",
    margin: "0 auto"
  },
  cardsTitle: {
    fontFamily: "Lora",
    color: "#141C49",
    fontStyle: "normal",
    fontWeight: "medium",
    fontSize: 20,
    textAlign: "center"
  },
  cardImages: {
    height: 130,
    width: 130,
    // borderRadius:110,
    // backgroundColor:'#F6F9FC',
    marginTop: 35,
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardsCaption: {
    marginTop: 25,
    color: "#585858",
    fontFamily: "Raleway",
    fontWeight: "medium",
    fontStyle: "normal",
    fontSize: 15,
    textAlign: "center"
  },
  //  dropdown:{

  //  border:'0  #F6F9FC'
  //  },
  getstarted: {
    height: 60,
    width: 200,
    backgroundColor: "#D06900",
    textTransform: "initial",
    borderRadius: 0,
    marginLeft: "42%",
    marginRight: "42%",
    marginTop: 90,
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: 100,
    fontSize: 20,
    color: "white"
  },
  linkstyle: {
    fontSize: 20,
    color: "white",
    textDecoration: "none"
  },
  howWorks: {
    borderRadius: 0,
    paddingTop: "0px",
    paddingBottom: "90px",
    width: "100%"
  },
  paperTitle: {
    color: "#141C49",
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Lora",
    paddingTop: 60,
    textAlign: "center"
  },
  paperDescription: {
    fontSize: 16,
    fontFamily: "Raleway",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1.4,
    textAlign: "center"
  },
  video: {
    backgroundColor: "black",
    height: 400,
    width: "100%",
    marginTop: 60,
    borderRadius: 0
    //  marginBottom:100
  }
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services_type: "",
      categories: "Select",
      products: "Select",
      bannerDescription: "",
      choose_your_legal_template: "",
      choose_your_legal_template_description: "",
      how_to_use_platform: "",
      how_to_use_platform_description: "",
      subscription_description: "",
      subscription_head: "",
      help_title: "",
      help_discription: "",
      bannerTitle: "",
      test_discription: "",
      test_title: "",
      our_clients: "",
      sub_title: "",
      step1_title: "",
      step1_description: "",
      step2_title: "",
      step2_description: "",
      step3_title: "",
      step3_description: "",
      hideSignIn: false,
      ser_con_sug_com: "",
      ser_tit_sug_com: "",
      showList: false,
      searchListData: [],
      bool: false,
      categoriesData: [],
      productsLists: [],
      popularProducts: [],
      searchProductId: "",
      showChat: false,
      popular_title: "",
      personal_title: "",
      personal_img: "",
      business_title: "",
      business_img: "",
      json_meta_tags: "",
      url : ""
    };
    this.searchSuccessResponse = this.searchSuccessResponse.bind(this);
    this.successResponse = this.successResponse.bind(this);
    this.successLists = this.successLists.bind(this);
  }

  getsearchresult(ser_tit_sug_com) {
    let scsc = this.state.ser_con_sug_com;
    let stsc = ser_tit_sug_com;
    scsc = stsc;
    SearchApi(this.searchSuccessResponse, this.searchErrorResponse, scsc, stsc);
  }

  searchSuccessResponse(response) {
    if (response) {
      this.setState(
        {
          searchListData: response,
          showList: true
        },
        () => {
          this.state.searchListData.service_title_suggest__completion.map(
            (data, index) => {
              if (data.options.length === 0) {
                this.setState({ showList: false });
              }
            }
          );
        }
      );
    }
  }

  searchErrorResponse(error) {}

  myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <Avatar
          src={require("../../../Assets/images/right.png")}
          style={{ height: 50, width: 50, borderRadius: 0, marginTop: 25 }}
        />
      ) : (
        <Avatar
          src={require("../../../Assets/images/left.png")}
          style={{ height: 50, width: 50, borderRadius: 0, marginTop: 25 }}
        />
      );
    return (
      <div onClick={onClick} disabled={isEdge}>
        {pointer}
      </div>
    );
  }

  getServiceCategories(id) {
    const serviceTypeId = id;

    // ServiceCategories(this.successResponse, this.errorResponse,serviceTypeId );
    // ServiceLists(this.listsResponse,this.errorLists)
    ServiceCategoriesAndLists(
      this.successResponse,
      this.errorResponse,
      serviceTypeId
    );
  }

  successResponse(response) {
    this.setState({ categoriesData: response });
  }

  errorResponse(error) {}

  getProducts(categoryId) {
    ServiceProducts(this.successLists, this.errorLists, categoryId);
  }

  successLists(response) {
    this.setState({ productsLists: response });
  }

  componentDidMount = () => {
    this.props.location.state &&
      window.scrollTo(0, this.props.location.state.height);

      if(window.location.host === "lanidigital.com" || window.location.host === "www.lanidigital.com"){
        this.state.meta_title !== "" && setTimeout(function(){
          TagManager.initialize(tagManagerArgs)
          ReactGA.initialize(trackingId);
          //to report page view
          ReactGA.pageview(window.location.pathname)
          console.log("GA code triggered");
        },4000)
      }

    var data = localStorage.getItem("genricChat");
    if (data !== null) {
      setTimeout(() => {
        fetch(domain + "/api/chat/generic_conversations/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
          },
          body: JSON.stringify({
            generic_chat: JSON.parse(data)
          })
        })
          .then(response => response.json())
          .then(response => this.successResponseFetch(response))
          .catch(error => this.errorResponse(error));
      }, 100);
    }
    this.getData();
  };
  successResponseFetch = response => {};

  errorResponse = error => {};
  getcategory() {
    fetch(domain + "/api/services/types/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => this.successCategoryResponse(response))
      .catch(error => this.errorResponse(error));
  }
  successCategoryResponse(res) {
    // console.log(res)
    this.setState({
      personal_title: res[0].home_content,
      personal_img: res[0].service_type_image,
      business_title: res[1].home_content,
      business_img: res[1].service_type_image
    });
  }
  getTitles() {
    fetch(domain + "/api/home/homestatic/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => this.successTitleResponse(response))
      .catch(error => this.errorResponse(error));
  }
  successTitleResponse(response) {
    console.log(response);
    this.setState({
      bannerDescription: response[0].banner_description,
      bannerTitle: response[0].banner_title,
      choose_your_legal_template_description:
        response[0].choose_your_legal_template_description,
      choose_your_legal_template: response[0].choose_your_legal_template,
      help_title: response[0].how_the_platform_helps,
      help_discription: response[0].how_the_platform_helps_description,
      how_to_use_platform: response[0].how_to_use_platform,
      how_to_use_platform_description:
        response[0].how_to_use_platform_description,
      subscription_description: response[0].subscription_description,
      subscription_head: response[0].subscription_head,
      test_title: response[0].testimonials_head,
      test_discription: response[0].testimonials_description,
      our_clients: response[0].our_clients,
      customer_title: response[0].customer,
      customer_discription: response[0].customer_description,
      sub_title: response[0].discount_plans,
      popular_title: response[0].popular_products,
      step1_title: response[0].step1_title,
      step1_description: response[0].step1_description,
      step2_title: response[0].step2_title,
      step2_description: response[0].step2_description,
      step3_title: response[0].step3_title,
      step3_description: response[0].step3_description,
      utubVideo: response[0].video_link,
      homepageBanner: response[0].home_page_banner,
      meta_title: response[0].meta_title,
      meta_image : response[0].meta_image,
      meta_description: response[0].meta_description,
      meta_keywords: response[0].meta_keywords,
      other_meta_tags: response[0].other_meta_tags,
      json_meta_tags: response[0].json_meta_tags
    });
    this.getcategory();
  }

  getData() {
    this.getTitles();
    fetch(domain + "/api/services/popular_productslist/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => this.successPopResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successPopResponse = response => {
    this.setState({ popularProducts: response });
  };

  errorResponse = error => {};

  goToProductDetail() {
    if (this.state.ser_tit_sug_com === "") {
      this.props.history.push("/search");
    } else {
      this.state.searchListData.service_title_suggest__completion !==
        undefined &&
        this.state.searchListData.service_title_suggest__completion.map(
          (items, index) => {
            if (items.options.length === 0) {
              this.props.history.push("/search");
            } else if (items.options.length >= 1) {
              this.props.history.push("/productDetail/" +this.state.url);
            }
          }
        );
    }
  }

  getstartedHandler() {
  
    // "/productDetail/"+popularProducts.id +"/"+popularProducts.service_title.split(" ").join("-")
    if (
      this.state.services_type != "" &&
      this.state.categories != "Select" &&
      this.state.products != "Select"
    ) {
      this.props.history.push("/productDetail/" +this.state.products );
      document.getElementById("get--error").style.display = "none";
    } else {
      document.getElementById("get--error").style.display = "block";
    }
  }
  render() {
    return (
      <div>
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          meta_image={this.state.meta_image}
          other_meta_tags={this.state.json_meta_tags}
        />
    }

        {!getCookie("CookieConsent") && (
          <CookieConsent
            onAccept={() => {
              setCookie("lanidigital", "lani007");
            }}
            debug={true}
            buttonText="I accept cookies"
          >
            <p className="cookiePageH">Cookie Preferences</p>
            {/* <p className="cookiesBody">This website uses cookies to enhance the user experience.</p> */}
            <span className="cookiesBody">
              We use cookies to identify you as a User and make your user
              experience easier, customize our services, content and advertising
              and analyzing our traffic; help you ensure that your account
              security is not compromised, mitigate risk and prevent fraud; and
              to promote trust and safety on our website. By clicking the I
              ACCEPT button, you are consenting to our Cookie Policy.
            </span>
          </CookieConsent>
        )}
        <img
          className="banner--wrapper blurImged"
          src={this.state.homepageBanner}
          style={styles.banner}
        />
        <div className="bannerContent">
          {/*<h1>
            {this.state.bannerTitle}
             Easy to create Legal Forms,
            <br /> Templates, Documents and Contracts
            <br /> at your fingertips
          </h1>*/}
          <h1
            dangerouslySetInnerHTML={{
              __html: this.state.bannerTitle
            }}
          ></h1>
          <p>{this.state.bannerDescription}</p>
        </div>
        <Box component="search">
          <Paper className="paper ">
            <InputBase
              variant="outlined"
              placeholder="Search Legal Documents or Services.."
              style={styles.input}
              value={this.state.ser_tit_sug_com}
              onChange={e =>
                this.setState({ ser_tit_sug_com: e.target.value }, () =>
                  this.getsearchresult(this.state.ser_tit_sug_com)
                )
              }
            />
            <Divider className="divider" orientation="vertical" />
            <IconButton
              onClick={() => this.goToProductDetail()}
              style={styles.searchImageBackground}
            >
              <Avatar
                src={require("../../../Assets/images/search.png")}
                style={styles.image}
              />
            </IconButton>
          </Paper>
        </Box>
        {getCookie("userId") !== "67" && !this.state.showChat && (
          <button
            onClick={e => this.setState({ showChat: true })}
            className="buttonOfChat topStyle"
          >
            Chat
          </button>
        )}

        <div className={this.state.showChat ? "showData" : "hideData"}>
          <button
            onClick={e => this.setState({ showChat: false })}
            className="buttonOfChat bottomStyle"
          >
            X
          </button>
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageParser={MessageParser}
          />
          {localStorage.getItem("uuidChat") && (
            <Link
              to="/dashboard/userChat"
              key={JSON.parse(localStorage.getItem("uuidChat"))}
            >
              <button className="buttonOfChat bottomStyleChat">
                Chat with CustomerSupport
              </button>
            </Link>
          )}
        </div>
        {this.state.showList === true && (
          <div className="search--results--popup">
            {this.state.searchListData.service_title_suggest__completion.map(
              (data, index) => (
                <div key={index}>
                  {data.options.map((content, index) => (
                    <div>
                      <div
                        style={{ padding: 20 }}
                        onClick={() =>
                          this.setState({
                            ser_tit_sug_com: content._source.service_title,
                            url : content._source.slug,
                            showList: false,
                            searchProductId: content._source.id
                          })
                        }
                      >
                        <div
                          style={{
                            color: "#141C49",
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                            fontStyle: "normal",
                            fontSize: 14,
                            cursor: "pointer"
                          }}
                          key={index}
                        >
                          {content._source.service_title}
                        </div>
                        <div
                          style={{
                            color: "#707070",
                            lineHeight: 1.4,
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontSize: 14,
                            paddingTop: 8
                          }}
                        >
                          {content._source.service_content
                            .replace(/(<([^>]+)>)/gi, "")
                            .replace(/&nbsp;/g, "")}
                        </div>
                      </div>
                      <hr style={{ color: "#707070", marginTop: 10 }} />
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        )}
        <Paper className="popular--products--wrapper" style={styles.modal}>
          <div class="popular--heading" style={styles.modalTitle}>
            {this.state.popular_title}
          </div>
          <div className="product--content--wrapper">
            <Slider {...settings}>
              {this.state.popularProducts &&
                this.state.popularProducts.map((popularProducts, index) => {
                  return (
                    <div
                      className="product--inner--wrapper"
                      style={{ display: "flex", marginLeft: 15 }}
                    >
                      <div>
                        <Avatar
                          src={require("../../../Assets/images/form.png")}
                          style={{
                            borderRadius: 0,
                            height: 60,
                            width: 60,
                            marginRight: 14,
                            marginTop: 19
                          }}
                        />
                      </div>
                      <div
                        className="carouselData"
                        key={index}
                        onClick={() =>
                          this.props.history.push({
                            pathname:
                              "/productDetail/" +
                              popularProducts.slug,
                            state: {
                              id: popularProducts.id
                            }
                          })
                        }
                      >
                        <h2
                          className="sCategory"
                          dangerouslySetInnerHTML={{
                            __html: popularProducts.service_title
                          }}
                        ></h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: popularProducts.service_content
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </Paper>
        <div
          className="availService--main--wrapper"
          style={{ backgroundColor: "#F6F9FC" }}
        >
          <Container>
            <div className="availService--wrapper">
              <h1 style={styles.title2}>{this.state.how_to_use_platform}</h1>
              <div style={styles.description2}>
                {this.state.how_to_use_platform_description}
              </div>
            </div>
            <div style={styles.cardsSpace}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper style={styles.cards}>
                    <div className="card--heading" style={styles.cardsTitle}>
                      {this.state.step1_title}
                    </div>
                    <ListItem>
                      <Avatar
                        src={require("../../../Assets/images/servicetype.png")}
                        style={styles.cardImages}
                      />
                    </ListItem>
                    <div style={styles.cardsCaption}>
                      {this.state.step1_description}
                    </div>
                    <div>
                      <select
                        className="dropdown template--selection"
                        value={this.state.services_type}
                        onChange={e =>
                          this.setState({ services_type: e.target.value }, () =>
                            this.getServiceCategories(this.state.services_type)
                          )
                        }
                      >
                        <option value="Select" key="0">
                          Select
                        </option>
                        <option value="2" key="1">
                          Personal
                        </option>
                        <option value="3" key="2">
                          Business
                        </option>
                      </select>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper style={styles.cards}>
                    <div className="card--heading" style={styles.cardsTitle}>
                      {this.state.step2_title}
                    </div>
                    <ListItem>
                      <Avatar
                        src={require("../../../Assets/images/category.png")}
                        style={styles.cardImages}
                      />
                    </ListItem>
                    <div style={styles.cardsCaption}>
                      {this.state.step2_description}
                    </div>
                    <div>
                      <select
                        className="dropdown"
                        value={this.state.categories}
                        onChange={e =>
                          this.setState({ categories: e.target.value }, () =>
                            this.getProducts(this.state.categories)
                          )
                        }
                      >
                        <option value="Select">Select</option>
                        {this.state.categoriesData.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper style={styles.cards}>
                    <div className="card--heading" style={styles.cardsTitle}>
                      {this.state.step3_title}
                    </div>
                    <ListItem>
                      <Avatar
                        src={require("../../../Assets/images/product.png")}
                        style={styles.cardImages}
                      />
                    </ListItem>
                    <div style={styles.cardsCaption}>
                      {this.state.step3_description}
                    </div>
                    <div>
                      <select
                        className="dropdown"
                        value={this.state.products}
                        onChange={e =>
                          this.setState({ products: e.target.value })
                        }
                      >
                        <option value="Select">Select</option>
                        {this.state.productsLists.map((data, index) => (
                          <option value={data.slug} key={index}>
                            {data.service_title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
            <p
              id="get--error"
              style={{
                display: "none",
                textAlign: "center",
                color: "red",
                fontSize: "14px",
                fontFamily: "Raleway-Bold",
                marginTop: "30px",
                marginBottom: "-45px"
              }}
            >
              Please select above options to continue!
            </p>
            <Button
              style={styles.getstarted}
              onClick={() => this.getstartedHandler()}
            >
              Get Started
            </Button>
          </Container>
        </div>
        <Avatar
          src={require("../../../Assets/images/group.png")}
          style={{
            position: "absolute",
            top: 1875,
            borderRadius: 0,
            height: 355,
            width: 140
          }}
        />
        <Paper className="howitworks--wrapper" style={styles.howWorks}>
          <h1 style={styles.paperTitle}>{this.state.help_title}</h1>
          <div
            className="howitworks--desc"
            style={styles.paperDescription}
            dangerouslySetInnerHTML={{
              __html: this.state.help_discription
            }}
          ></div>
          {/* <p style={{ position:'absolute', }}>hello</p> */}
          {this.state.utubVideo && <Container>
            <Box>
              <Box justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={6} className="worksCol">
                  <div className="worksVideo">
                    <Suspense fallback={true}>
                      <YoutubeLink getData={e => this.getData(e)} />
                      <YoutbeIfram
                        style={styles.video}
                        src={
                          this.state.utubVideo +
                          "?showinfo=0&iv_load_policy=3&controls=0&rel=0"
                        }
                      
                      />
                    </Suspense>
                  </div>
                </Grid>
              </Box>
            </Box>
          </Container>
          }
          <Avatar
            src={require("../../../Assets/images/group2.png")}
            style={{
              position: "absolute",
              top: 2270,
              right: 0,
              borderRadius: 0,
              height: 230,
              width: 110
            }}
          />
        </Paper>
        <Suspense fallback={true}>
          <SelectService
            title={this.state.choose_your_legal_template}
            discription={this.state.choose_your_legal_template_description}
            personal_title={this.state.personal_title}
            business_title={this.state.business_title}
            personal_img={this.state.personal_img}
            business_img={this.state.business_img}
          />
        </Suspense>
        <Suspense fallback={true}>
          <SubscriptionPlan
            props={this.props}
            discription={this.state.subscription_description}
            title={this.state.subscription_head}
            client_title={this.state.our_clients}
            customer_title={this.state.customer_title}
            customer_discription={this.state.customer_discription}
            sub_title={this.state.sub_title}
          />
        </Suspense>
        <Suspense fallback={true}>
          <Testimonials
            title={this.state.test_title}
            discription={this.state.test_discription}
          />
        </Suspense>
        <div className="dots--group">
          <Avatar src={require("../../../Assets/images/blue-dots.png")} />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig }
  )(withStyles(styles)(Home))
);
