import React, { useState, useEffect } from "react";
import { Paper, Button, Avatar, withTheme } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { domain } from "./../../../Utils/domain";
import { getCookie, setCookie } from "../../../Utils/cookies";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateToasterConfig, updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { numberWithCommas } from "./../../Website/Form/currencyConvertor";
import "./styles.scss";


const SubscriptionPlan = props => {
  const [subscriptionCategory, setsubscriptionCategory] = useState([]);
  const [clients, setclients] = useState([]);
  const [templatesModal, setTemplateModal] = useState([]);
  const [templates, settemplates] = useState([]);
  const [addons, setaddons] = useState([]);
  const [legalservices, setlegalservices] = useState([]);
  const [showMonthlyPlan, setshowMonthlyPlan] = useState(true);
  const [showAnuallPlan, setshowAnuallPlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSinglePlan, setshowSinglePlan] = useState(false);
  const [saveCustomId, setCustomeId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [subscriptionDes, setsubscriptionDes] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.updateBreadcrumbs('Subscriptions');
    fetch(`${domain}/api/subscription/category/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => successResponseSub(response))
      .catch(error => errorResponse(error));
  }, []);
  function successResponseSub(response) {
    setsubscriptionCategory(response);
    var id = getCookie("userId");

    fetch(`${domain}/api/subscription/detail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => successResponseModal(response))
      .catch(error => errorResponse(error));
  }
  function successResponseModal(response) {
    setTemplateModal(response.sub_usage);
  }

  function successResponse(response) {
    setclients(response);
  }

  function errorResponse(error) {}

  function successResponseDes(response) {
    setsubscriptionDes(response);
  }

  function getActive(value) {
    const currentClass = document.getElementsByClassName("button");
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("button-active");
    }
    const element = document.getElementById(value);
    element.classList.add("button-active");

    if (value === "Anually") {
      setshowAnuallPlan(true);
      setshowMonthlyPlan(false);
      setshowSinglePlan(false);
    } else if (value === "Monthly") {
      setshowAnuallPlan(false);
      setshowMonthlyPlan(true);
      setshowSinglePlan(false);
    } else if (value === "Single") {
      setshowAnuallPlan(false);
      setshowMonthlyPlan(false);
      setshowSinglePlan(true);
    }
  }
  function activatePlan(e, id) {
    setCookie("subscriptionData", true);
    if (getCookie("userId")) {
      fetch(domain + "/api/subscription/activate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify({
          plan: id,
          user: getCookie("userId")
        })
      })
        .then(response => response.json())
        .then(response => successResponseFetch(response))
        .catch(error => errorResponse(error));
    } else {
      props.history.push({
        pathname: "/signin",
        state: {
          redirect: "/",
          height: "3700"
        }
      });
    }
  }

  function successResponseFetch(res) {
    if (res.message === "Please upgrade to the higher plans") {
      props.updateToasterConfig({
        show: true,
        message: res.message,
        color: "#D06900"
      });
    } else if (
      res.message === "You have already activated subscription plan."
    ) {
      props.updateToasterConfig({
        show: true,
        message: res.message,
        color: "#D06900"
      });
    } else if (res.message === "User is already have active subscription.") {
      props.updateToasterConfig({
        show: true,
        message: res.message,
        color: "#D06900"
      });
    } else {
      // setCookie("sub-plan" , res.plan);
      // setCookie("sub-amount", res.total_amount )
      // setCookie("sub-subscription", res.user_subscription)
      setCookie("sub-purchase", "Yes");
      setCookie("user_subscription", res.user_subscription);
      setCookie("plan", res.plan);
      setCookie("total_amount", res.total_amount);

      props.history.push("/PaymentSubscription/", {
        user_subscription: res.user_subscription,
        plan: res.plan,
        total_amount: res.total_amount
      });
    }
  }
  function errorResponse(err) {}

  function pushToTemplate(e, type, name) {
    if (type === "TEMPLATE") {
      settemplates(prevname => {
        return [...prevname, name];
      });
    }
    if (type === "ADDON") {
      setaddons(prevname => {
        return [...prevname, name];
      });
    }
    if (type === "LEGALSERVICE") {
      setlegalservices(prevname => {
        return [...prevname, name];
      });
    }
  }
  function removeActiveClass() {
    const Activecls = document.getElementsByClassName("plancards");
    for (let i = 0; i < Activecls.length; i++) {
      Activecls[i].classList.remove("active__show");
    }
  }
  function selectPlan(e, item) {
    setShowModal(true);
    setCustomeId(item);
  }

  return (
    <Container className="SubscriptionPlan--container">
      <div className="SubscriptionPlan--main--wrapper">
        <h1 style={styles.title}>All Available Subscriptions</h1>
        <div className="SubscriptionPlan--inner--wrapper">
          {/* {subscriptionCategory[0] !== undefined && (
            <div
              style={styles.midPaper}
              className="subscription--activat--plan"
            >
              <div className="marginClass">
                <div className="plantype">
                  {subscriptionCategory[0].subscription_category}
                </div>
                <div className="amount">
                  <div style={styles.price}>Try a document for free!</div>
                </div>
              </div>

              <div className="marginClassTwo">
                <div className="tikandcontent">
                  <Avatar
                    src={require("../../../Assets/images/tik.png")}
                    style={styles.tik}
                  />
                  <div className="features">Newsletter and legal updates</div>
                </div>
                <div className="tikandcontent">
                  <Avatar
                    src={require("../../../Assets/images/tik.png")}
                    style={styles.tik}
                  />
                  <div className="features">
                    Free trial – up to 1 free, pre-determined <br /> legal
                    template (typically one of the <br /> unpopular templates)
                  </div>
                </div>
              </div>
              <div className="marginClassThree">
                <Button
                  onClick={e =>
                    activatePlan(e, subscriptionCategory[0].plans[0].id)
                  }
                >
                  {getCookie("sub-plan") === "FREEMIUM PLAN" ? (
                    <span className="plan-activated">Activated</span>
                  ) : (
                    "Activate Now"
                  )}
                </Button>
              </div>
            </div>
          )} */}
          <Paper className="SubscriptionPlan--wrapper" style={styles.paper}>
            {/* <h3 style={styles.title}>{props.sub_title}</h3> */}
            {/*<div style={styles.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br />
                                             aliqua. Ut enim ad minim veniamLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor<br />
                                             incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia</div>*/}
            <div className="myButton" style={styles.buttoncenter}>
              <Button
                id="Monthly"
                className=" button button-active"
                onClick={() => getActive("Monthly")}
              >
                {subscriptionCategory[1] !== undefined &&
                  subscriptionCategory[1].subscription_category}&nbsp;Plans
              </Button>
              {/* <Button
                id="Anually"
                className="button"
                onClick={() => getActive("Anually")}
              >
                {subscriptionCategory[2] !== undefined &&
                  subscriptionCategory[2].subscription_category}

                Plan
              </Button> */}
              <Button
                id="Single"
                className="button"
                onClick={() => getActive("Single")}
              >
                {subscriptionCategory[3] !== undefined &&
                  subscriptionCategory[3].subscription_category}&nbsp;Plans
              </Button>
            </div>
            {showMonthlyPlan && (
              <div
                style={{
                  marginTop: 80,
                  display: "flex",
                  justifyContent: "space-evenly"
                }}
                className="avoidShakeHover"
              >
                <Grid container spacing={5}>
                  {subscriptionCategory[1] !== undefined &&
                    subscriptionCategory[1].plans.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                          <div
                            className={
                              index == 1
                                ? "plancards active__show"
                                : "plancards"
                            }
                            onMouseOver={removeActiveClass}
                          >
                            <div className="plantype">
                              {item.subscription_title}
                            </div>
                            <div className="amount">
                              <div style={styles.price}>
                                <span>&#8358; </span>
                                <span>{numberWithCommas(item.total_cost_with_IP)}</span>
                              </div>
                            </div>
                            <div
                              className="plancards-desc"
                              dangerouslySetInnerHTML={{
                                __html: item.subscription_description
                              }}
                            ></div>

                            <button
                              className="selectplan"
                              onClick={e => activatePlan(e, item.id)}
                            >
                              Activate Now
                            </button>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )}
            {showAnuallPlan && (
              <div
                style={{
                  marginTop: 80,
                  display: "flex",
                  justifyContent: "space-evenly"
                }}
                className="avoidShakeHover"
              >
                <Grid container spacing={5}>
                  {subscriptionCategory[2] !== undefined &&
                    subscriptionCategory[2].plans.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                          <div
                            className={
                              index == 0
                                ? "plancards active__show"
                                : "plancards"
                            }
                            onMouseOver={removeActiveClass}
                          >
                            <div className="plantype">
                              {item.subscription_title}
                            </div>
                            <div className="rq">
                              {item.subscription_title_description}
                            </div>
                            <div className="amount">
                              <div style={styles.price}>
                                <span>&#8358; </span>
                                <span>{numberWithCommas(item.total_cost_with_IP)}</span>
                              </div>
                            </div>
                            <div
                              className="plancards-desc"
                              dangerouslySetInnerHTML={{
                                __html: item.subscription_description
                              }}
                            ></div>

                            <button
                              className="selectplan"
                              onClick={handleOpen}
                              //onClick={(e) => selectPlan(e, item.id)}
                            >
                              Select Plan
                            </button>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div className="custom--subscription--popup">
                    <p className="custom--close" onClick={handleClose}>
                      x
                    </p>
                    <div className="custom--subscription--innrer">
                      <div className="custom--subscription--block">
                        <h1>Select Template</h1>
                        {templatesModal.map((item, index) => {
                          if (item.template_type === "TEMPLATE") {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  onClick={e =>
                                    pushToTemplate(
                                      e,
                                      item.template_type,
                                      item.service_name
                                    )
                                  }
                                />
                                <div>{item.service_name}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      {/* <div className="custom--subscription--block"> */}
                      {/* <h1>Select ADDON</h1>
                          {templatesModal.map((item, index) => {
                            if (item.template_type === "ADDON") {
                              return (
                                <div className="form-group" key={index}>
                                  <input
                                    type="checkbox"
                                    onClick={(e) =>
                                      pushToTemplate(e, item.template_type, item.service_name
                                      )
                                    }
                                  />
                                  <div>{item.service_name}</div>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div className="custom--subscription--block">
                          <h1>Select LEGALSERVICE</h1>
                          {templatesModal.map((item, index) => {
                            if (item.template_type === "LEGALSERVICE") {
                              return (
                                <div className="form-group" key={index}>
                                  <input
                                    type="checkbox"
                                    onClick={(e) =>
                                      pushToTemplate(
                                        e,
                                        item.template_type,
                                        item.service_name
                                      )
                                    }
                                  />
                                  <div>{item.service_name}</div>
                                </div>
                              );
                            }
                          })}
                        </div> */}
                    </div>
                    <button
                      className="selectplan"
                      onClick={e => activatePlan(e, saveCustomId)}
                    >
                      Activate Now
                    </button>
                  </div>
                </Modal>
              </div>
            )}
            {showSinglePlan && (
              <div
                style={{
                  marginTop: 80,
                  display: "flex",
                  justifyContent: "space-evenly"
                }}
                className="avoidShakeHover"
              >
                <Grid container spacing={5} className="business--subscription1">
                  {subscriptionCategory[3] !== undefined &&
                    subscriptionCategory[3].plans.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                          <div
                            className={
                              index == 0
                                ? "plancards active__show"
                                : "plancards"
                            }
                            onMouseOver={removeActiveClass}
                          >
                            <div className="plantype">
                              {item.subscription_title}
                            </div>
                            <div className="rq">
                              {item.subscription_title_description}
                            </div>
                            <div className="amount">
                              <div style={styles.price}>
                                <span>&#8358; </span>
                                <span>{numberWithCommas(item.total_cost_with_IP)}</span>
                              </div>
                            </div>
                            <div
                              className="plancards-desc"
                              dangerouslySetInnerHTML={{
                                __html: item.subscription_description
                              }}
                            ></div>
                            <button
                              className="selectplan"
                              onClick={e => activatePlan(e, item.id)}
                            >
                              Activate Now
                            </button>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )}
          </Paper>
        </div>
      </div>

      {showModal && (
        <div style={{ overflow: "scroll" }}>
          <h1>Select Template</h1>
          {templatesModal.map((item, index) => {
            if (item.template_type === "TEMPLATE") {
              return (
                <div key={index}>
                  <div>{item.service_name}</div>
                  <input
                    type="checkbox"
                    onClick={e =>
                      pushToTemplate(e, item.template_type, item.service_name)
                    }
                  />
                </div>
              );
            }
          })}
          {/* <h1>Select ADDON</h1>
          {templatesModal.map((item, index) => {
            if (item.template_type === "ADDON") {
              return (
                <div key={index}>
                  <div>{item.service_name}</div>
                  <input
                    type="checkbox"
                    onClick={(e) =>
                      pushToTemplate(e, item.template_type, item.service_name)
                    }
                  />
                </div>
              );
            }
          })}
          <h1>Select LEGALSERVICE</h1>
          {templatesModal.map((item, index) => {
            if (item.template_type === "LEGALSERVICE") {
              return (
                <div key={index}>
                  <div>{item.service_name}</div>
                  <input
                    type="checkbox"
                    onClick={(e) =>
                      pushToTemplate(e, item.template_type, item.service_name)
                    }
                  />
                </div>
              );
            }
          })} */}

          <button
            className="selectplan"
            onClick={e => activatePlan(e, saveCustomId)}
          >
            Active Now
          </button>
        </div>
      )}
    </Container>
  );
};

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig, updateBreadcrumbs }
  )(SubscriptionPlan)
);
let styles = {
  linktext: {
    textDecoration: "none",
    color: "white",
    fontSize: 20
  },
  tik: {
    height: 15,
    width: 16,
    borderRadius: 0
  },
  paper: {
    // backgroundColor: '#F9F9F9',
    backgroundColor: "white",
    borderRadius: 0,
    marginTop: 50,
    boxShadow: "none",
    padding: "80px 40px",
    border: "1px solid #141C4938"
  },
  midPaper: {
    backgroundColor: "white",
    borderRadius: 0,
    boxShadow: "none",
    margin: "50px 40px",
    marginBottom: "20px",
    display: "flex"
  },
  paperMain: {
    backgroundColor: "#F9F9F9",
    borderRadius: 0,
    marginTop: 420,
    boxShadow: "none",
    padding: "80px 40px"
  },
  title: {
    color: "#141C49",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Lora",
    fontSize: 40,
    textAlign: "center",
    marginBottom: 50,
    marginTop: 0
  },
  description: {
    marginTop: 38,
    textAlign: "center",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: 1.4
  },
  buttoncenter: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: 38
  },
  title2: {
    textAlign: "center",
    fontSize: 34,
    fontFamily: "Lora",
    color: "#4F4F4F",
    fontStyle: "normal",
    fontWeight: "normal"
  },
  description2: {
    marginTop: 17,
    lineHeight: 1.4,
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "center",
    padding: "0 40px"
  },
  button2: {
    marginRight: "42%",
    marginLeft: "42%",
    borderRadius: 0,
    color: "white",
    textTransform: "initial",
    height: 60,
    width: 200,
    backgroundColor: "#D06900",
    marginTop: 28,
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontStyle: "normal"
  },
  clientText: {
    marginTop: 80,
    fontSize: 40,
    fontWeight: "normal",
    fontFamily: "Lora",
    fontStyle: "normal",
    color: "#141C49",
    textAlign: "center"
  },
  clientData: {
    marginTop: 80,
    width: "70%",
    marginLeft: "15%",
    marginRight: "15%",
    backgroundColor: "#F9F9F9",
    height: 130
  },
  plantype: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontFamily: "Lora",
    fontStyle: "normal",
    fontSize: 12,
    color: "#D06900",
    marginLeft: "12%",
    marginTop: 30
  },
  price: {
    color: "#454751",
    fontFamily: "Raleway-Bold",
    fontWeight: "bold",
    fontSize: 25
  },
  month: {
    fontFamily: "Lora",
    fontSize: 12,
    color: "#454751",
    margin: 4
  },
  rq: {
    fontFamily: "Lora",
    fontSize: 14,
    color: "#454751",
    marginTop: 7,
    marginLeft: "12%"
  },
  selectplan: {
    textTransform: "initial",
    height: 60,
    width: 170,
    color: "#D06900",
    fontWeight: "bold",
    fontFamily: "Raleway",
    fontSize: 14,
    border: "1px solid #D06900",
    marginTop: 40,
    marginLeft: "19%",
    marginRight: "19%",
    borderRadius: 0
  },
  features: {
    color: "#454751",
    fontFamily: "Raleway",
    fontSize: 14,
    marginTop: 27,
    textAlign: "left",
    paddingLeft: "17%",
    paddingRight: "17%"
  },
  amount: {
    display: "flex",
    marginTop: 20
    // marginLeft: '12%',
  },
  round: {
    display: "grid"
  },
  plancards: {
    width: 270,
    height: 450,
    borderRadius: 0,
    boxShadow: "0px 3px 35px #1847A542"
  }
};
