import React, { useContext, useState } from 'react'
import useLocalStorage from '../hooks/useLocalStorage';
const ContactsContext = React.createContext()

export function useContacts() {
  return useContext(ContactsContext)
}

export function ContactsProvider({ children }) {

  const [contacts, setContacts] = useLocalStorage('contacts', [ ])
  const [keyValue,setKayValue]= useState(true)
  function createContact(id, name, status) {
    if(keyValue){
    setContacts(prevContacts => {
      return [...prevContacts, { id, name, status }]
    })
    setKayValue(false)
  }
   
  }

  return (
    <ContactsContext.Provider value={{ contacts, createContact }}>
      {children}
    </ContactsContext.Provider>
  )
}
