export let domain = "https://legalecomm.beta.webenza.net";
export let flutterwaveKey = "FLWPUBK_TEST-534c6e444e6441424c66c01c799504b8-X"; //default
export let ifameDomain = "https://legalecomm-stg-be.lanidigital.com";
export let url = "https://legalecomm-stg-fe.lanidigital.com";

if (window.location.host === "legalecomm-stg-fe.lanidigital.com") {
  domain = "https://legalecomm-stg-be.lanidigital.com"; // Stg
  ifameDomain = "https://legalecomm-stg-be.lanidigital.com";
  flutterwaveKey = "FLWPUBK_TEST-534c6e444e6441424c66c01c799504b8-X";
  url = "https://legalecomm-stg-fe.lanidigital.com";

} else if (window.location.host === "legalecomm-stg-fe.beta.webenza.net") {
  domain = "https://legalecomm-stg-be.lanidigital.com"; //Beta
  ifameDomain = "https://legalecomm-stg-fe.beta.webenza.net";
  flutterwaveKey = "FLWPUBK_TEST-534c6e444e6441424c66c01c799504b8-X";
  url = "https://legalecomm-stg-fe.beta.webenza.net";
  
} else if (window.location.host === "localhost:3000") {
  domain = "https://legalecomm-stg-be.lanidigital.com"; // Local
  ifameDomain = "https://legalecomm-stg-be.lanidigital.com";
  flutterwaveKey = "FLWPUBK_TEST-534c6e444e6441424c66c01c799504b8-X";
  url = "http://localhost:3000";
} else if (window.location.host === "www.lanidigital.com") {
  domain = "https://api.lanidigital.com"; // Prod
  ifameDomain = "https://api.lanidigital.com";
  flutterwaveKey = "FLWPUBK-289d7411420fad31f0174aa52fafae24-X"; //Prod key
  url = "https://www.lanidigital.com";
} else {
  domain = "https://api.lanidigital.com"; //default
  ifameDomain = "https://api.lanidigital.com";
  flutterwaveKey = "FLWPUBK-289d7411420fad31f0174aa52fafae24-X";
  url = "https://www.lanidigital.com";
}

export const defaultHeaders = {
  "Content-Type": "application/json",
  "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
  // "Access-Control-Allow-Origin": "*"
};
