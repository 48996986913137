import React, { useState, useEffect } from "react";
import Conversations from "./Conversations";
import OpenConversation from "./OpenConversation";
import { useConversations } from "../../contexts/ConversationsProvider";
import "./style.scss";
import { useContacts } from "../../contexts/ContactsProvider";
import { Avatar } from "@material-ui/core";
import { domain } from "../../Utils/domain";
import { getCookie } from "../../Utils/cookies";

export default function Sidebar({ id, performSearch, filterValue, filterId }) {
  const [prevMessage, setPrevMessage] = useState([]);
  const [messageLength, setMessageLength] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [idselected, setIdSelected] = useState([]);
  const [currentDataArray, setcurrentDataArray] = useState([]);
  const [showId, setShowId] = useState(null);
  const [showIdExecute, setShowIdExecute] = useState(0);
  const [showConversation, setShowConversation] = useState(false);

  const {
    selectedConversation,
    createConversation,
    clientName,
    conversations
  } = useConversations();
  const { createContact, contacts } = useContacts();
  const [prevStatus, setPrevStatus] = useState(null);
  useEffect(() => {
    var prevData = localStorage.getItem("storedData");
    setIdSelected(JSON.parse(prevData));

    if (contacts.length === 0 && getCookie("userId") !== "67") {
      createContact("67", "CUSTOMERSUPPORT", "Ongoing");
      createConversation(["67"]);
    }
  }, []);

  if (
    localStorage.getItem("chatLogs-id") === undefined ||
    localStorage.getItem("chatLogs-id") === null ||
    localStorage.getItem("chatLogs-id") === ""
  ) {
    localStorage.clear();
  }
  try {
    if (
      selectedConversation.recipients[0].id ===
      selectedConversation.recipients[0].name
    ) {
      createContact(clientName.id, clientName.name.toUpperCase());
    }
  } catch (err) {}
  try {
    if (getCookie("userId") === "67") {
      var MergeArray = conversations.concat(idselected);
      if (showId !== selectedConversation.recipients[0].id) {
        MergeArray !== null &&
          MergeArray.map(item => {
            if (
              item.recipients[0].id === selectedConversation.recipients[0].id
            ) {
              setPrevMessage(item.messages);
              setShowId(item.recipients[0].id);
              setPrevStatus(item.recipients[0].status);
            }
          });
      }
    }
  } catch (err) {}

  const MarkCompleted = (e, dataid, dataname) => {
    fetch(domain + "/api/chat/chat_conversations_status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify({
        id: JSON.parse(dataid)
      })
    })
      .then(response => response.json())
      .then(response => successResponseResponse(response))
      .catch(error => errorResponse(error, dataid, dataname));
  };
  const successResponse = res => {
    if (res) {
      MarkCompleted();
    }
  };
  const successResponseResponse = res => {
    if (res) {
      var fetchedData = localStorage.getItem("storedData");
      setIdSelected(JSON.parse(fetchedData));
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const errorResponse = (error, dataid, dataname) => {
    var object = [
      {
        recipients: [
          {
            id: dataid,
            name: dataname,
            status: "Completed"
          }
        ],
        messages: [],
        selected: true
      }
    ];

    fetch(domain + "/api/chat/save_chat_conversations/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      },
      body: JSON.stringify({
        chatlog_conversations: object
      })
    })
      .then(response => response.json())
      .then(response => successResponse(response))
      .catch(error => errorResponseRES(error));
  };
  const errorResponseRES = err => {};

  useEffect(() => {
    try {
      if (conversations.length > 0) {
        conversations.map((item, index) => {
          if (item.recipients[0].id !== selectedConversation.recipients[0].id) {
            setMessageLength(oldArray => [
              ...oldArray,
              { id: item.recipients[0].id, messageLength: item.messages.length }
            ]);
          }
        });
      }

      if (contacts.length === 0 && idselected !== null) {
        idselected.map((item, index) => {
          if (item.recipients[0].id !== "67") {
            createContact(
              item.recipients[0].id,
              item.recipients[0].name,
              item.recipients[0].status
            );
            createConversation([item.recipients[0].id]);
          }
        });
      }
    } catch (err) {}
  }, [idselected]);

  for (var i in messageLength) {
    if (messageLength[i].id === selectedConversation.recipients[0].id) {
      messageLength.splice(i, 1);
    }
  }
  var data = JSON.parse(localStorage.getItem("storedData"));

  return (
    <div className="chat_row_div_flex">
      <div className="contact_group_div">
        {!showConversation && (
          <div className="idClass scrollData cursor">
            <Conversations
              filterValue={filterValue}
              filterId={filterId}
              performSearch={performSearch}
              completed={completed}
              selectId={idselected}
              messageCame={messageLength}
              highLightId={selectedConversation}
            />
          </div>
        )}
        <div className="bottomButtons"></div>
        <div></div>
      </div>
      <div className="conversation_geoup_div">
        <div className="mainDiv">
          <span className="headingOfContact">
            <Avatar src={require("./../../Assets/images/chat-2.png")} />

            {selectedConversation !== undefined &&
              selectedConversation.recipients[0].name}
            <button
              onClick={e => window.location.reload()}
              className="createConversationButton"
            >
              View All Chats
            </button>
          </span>
          {selectedConversation !== undefined &&
            selectedConversation.recipients[0].id !== "67" &&
            prevStatus === "Ongoing" && (
              <span className="buttonData">
                <button
                  onClick={e =>
                    MarkCompleted(
                      e,
                      selectedConversation.recipients[0].id,
                      selectedConversation.recipients[0].name
                    )
                  }
                  className="createConversationButton"
                >
                  Mark as Completed
                </button>
              </span>
            )}

          {selectedConversation !== undefined &&
            selectedConversation.recipients[0].id !== "67" &&
            prevStatus === "Completed" && (
              <span className="buttonData">
                <button
                  onClick={e =>
                    MarkCompleted(
                      e,
                      selectedConversation.recipients[0].id,
                      selectedConversation.recipients[0].name
                    )
                  }
                  className="createConversationButton"
                >
                  Mark as Ongoing
                </button>
              </span>
            )}
        </div>
        <div className="mainDivConversation">
          {selectedConversation && (
            <OpenConversation
              prevMessage={prevMessage}
              selectedUser={selectedConversation.recipients[0].id}
            />
          )}
        </div>
      </div>
    </div>
  );
}
