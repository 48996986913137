import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain } from "../../../Utils/domain";

import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentsMethod: [],
    };
  }
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Payment");
    this.getData();
  };
  getData() {
    fetch(domain + "/api/home/payments/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
      },
    })
      .then((response) => response.json())
      .then((response) => this.successResponse(response))
      .catch((error) => this.errorResponse(error));
  }
  successResponse = (response) => {
    this.setState({ paymentsMethod: response });
  };

  errorResponse = (error) => {};

  render() {
    return (
      <div className="payments--static--page">
        <Container>
          <Grid className="contactMobile" item md={12} lg={12} sm={12} xs={12}>
            <div className="contact-breadcrumbs">
              <Breadcrumbs />
            </div>
          </Grid>
          <Grid className="static--page--content" item md={12} lg={12} sm={12} xs={12} >
            {this.state.paymentsMethod &&
              this.state.paymentsMethod.map((paymentsMethod) => (
                <div key={index} className="static--page--content--sec">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: paymentsMethod.page_description,
                    }}
                  ></div>
                </div>
              ))}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({}), { updateBreadcrumbs })(index)
);
