import { domain } from "./../../../Utils/domain";
import { LastVar } from "../../MetaFile/metaUrl";
const defaultHeaders = {
  "Content-Type": "application/json",
  "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
};

console.log(LastVar());
export const getMeta = (successResponse, errorResponse) => {
  fetch(domain + `/api/home/tags/${LastVar()}/`, {
    method: "GET",
    headers: defaultHeaders
  })
    .then(response => response.json())
    .then(responseJson => {
      console.log("Meta tags data success");
      successResponse(responseJson);
    })
    .catch(error => {
      errorResponse(error);
    });
};
