import React, { Component } from "react";
import Profile from "./../../../../Assets/images/profile-img.png";
import CustomOrders from "./../../Orders/customOrders";
import CustomOrdersMobile from "./../../Orders/CustomOrdersMobile";
import Back from "./../../../../Assets/icons/back.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../../../src/redux/modules/app/actions";
import { getCookie, setCookie } from "../../../../Utils/cookies";
import { domain } from "../../../../Utils/domain";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import SubscriptionPlan from "./../../SubscriptionPlans/SubscriptionPlans";
import "./style.scss";
import { getMeta } from "../../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../../Pages/MetaFile";

class index extends Component {
  state = {
    customerDetails: [],
    viewOrder: null,
    showOrder: false,
    showModal: false,
    dateState: null,
    timeState: null,
    subscriptionPlanId: null,
    bookBlock: false,
    duration: null,
    consultation: null,
    sub_id: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    other_meta_tags: "",
    json_meta_tags: ""
  };

  componentDidMount() {
    this.props.updateBreadcrumbs("customers");
    // let id = this.props.location.customerId;
    let id = getCookie("userId");
    let token = getCookie("token");

    // fetch(`${domain}/api/subscription/detail/${id}`,{
    fetch(`${domain}/api/subscription/detail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  successResponse = response => {
    response.detail === "Not found."
      ? this.setState({ customerDetails: response.detail }, () => {})
      : this.setState(
          {
            customerDetails: response,
            consultation: response.sub_consultation,
            subscriptionPlanId: response.plan,
            sub_id: response.id
          },
          () => {
            setCookie("sub-plan", response.plan_details.plan);
            this.bookBlock();
          }
        );
  };
  bookBlock = () => {
    if (this.state.subscriptionPlanId === 1) {
      this.setState({
        bookBlock: false
      });
    } else {
      this.setState({
        bookBlock: true
      });
    }
  };

  errorResponse = error => {};
  viewOrderNumber = (e, item) => {
    this.setState({ viewOrder: item, showOrder: true });
  };
  availNowNumber = (e, data) => {
    this.props.history.push("/productDetail/" + data);
  };
  bookNowLayer = () => {
    this.setState({
      showModal: true
    });
  };
  changeDate = e => {
    this.setState({
      dateState: e.target.value
    });
  };
  changeTime = e => {
    this.setState({
      timeState: e.target.value
    });
  };
  duration = e => {
    this.setState({
      duration: e.target.value
    });
  };

  bookLawyerNow = e => {
    this.setState({ showModal: false });
    setTimeout(() => {
      fetch(domain + "/api/subscription/activate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify({
          plan: parseInt(this.state.subscriptionPlanId),
          user: 11
        })
      })
        .then(response => response.json())
        .then(response => this.successResponseFetch(response))
        .catch(error => this.errorResponse(error));
    }, 100);
  };
  successResponseFetch = res => {
    const hourData = this.state.timeState.slice(0, 2);

    const minData = this.state.timeState.slice(3, 5);
    var data = {
      subscription: this.state.sub_id,
      scheduled_datetime:
        this.state.dateState + " " + this.state.timeState + ":" + "00",
      duration_hr_requested: 0,
      duration_min_requested: parseInt(this.state.duration)
    };

    setTimeout(() => {
      fetch(`${domain}/api/subscription/sub-consultation/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${getCookie("token")}`
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(response => this.successResponseBookLawyer(response))
        .catch(error => this.errorResponse(error));
    }, 1000);
  };
  successResponseBookLawyer = res => {
    if (res.message === undefined) {
      res.message =
        "Your request has been sent to Admin. Admin will update you soon.";
    }
    this.props.updateToasterConfig({
      show: true,
      message: res.message,
      color: "#D06900"
    });
    this.componentDidMount();
  };

  render() {
    //console.log(this.state.customerDetails);
    var splicedDate =
      this.state.customerDetails.valid_till !== undefined &&
      this.state.customerDetails.valid_till.slice(0, 10);
    var present_date = new Date();
    var validateDay = new Date(splicedDate);
    var one_day = 1000 * 60 * 60 * 24;
    if (present_date.getMonth() == 11 && present_date.getDate() > 25)
      validateDay.setFullYear(validateDay.getFullYear() + 1);
    var Result =
      Math.round(validateDay.getTime() - present_date.getTime()) / one_day;

    var Final_Result = Result.toFixed(0);
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const futureDate = moment(currentDate).add(Final_Result, "days");
    const diff = moment.duration(futureDate.diff(currentDate));
    return (
      <>
        <div className="customerDetails">
        {this.state.meta_title !== "" &&  <MetaFile
            meta_title={this.state.meta_title}
            meta_description={this.state.meta_description}
            meta_keywords={this.state.meta_keywords}
            other_meta_tags={this.state.json_meta_tags}
          />
    }
          <div className="customerDetails__wrapper">
            <div className="customerDetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                {this.props.location.customerName} Subscription
              </p>
            </div>
            <Container>
              <div className="customerDetails__wrapper_content">
                <div className="customerDetails__wrapper_content_block">
                  {this.state.customerDetails === "Not found." ? (
                    <Grid xs={12} lg={6} md={6} sm={6}>
                      <p className="discountHeadplan">
                        No Subscription data found.
                      </p>
                    </Grid>
                  ) : (
                    <Grid xs={12} lg={6} md={6} sm={6}>
                      <div className="coloumFlex">
                        {this.state.customerDetails.active ? (
                          <span className="activePlan">
                            CURRENT ACTIVE PLAN <br />
                            <b>
                              {" "}
                              {this.state.customerDetails.plan_details.plan}
                            </b>
                          </span>
                        ) : (
                          <span>NOT CURRENT ACTIVE PLAN</span>
                        )}

                        <div className="recuRing">
                          Recurs in {diff.months()} Months {diff.days()} Days
                        </div>

                        <div className="tempLatesH">
                          {/* 20 templates (14/20 remaining) */}
                        </div>
                        {this.state.customerDetails.sub_usage !== undefined &&
                          this.state.customerDetails.sub_usage.map(
                            (item, index) => {
                              if (item.template_type === "TEMPLATE") {
                                return (
                                  <div
                                    key={index}
                                    className="servicesTemplates"
                                  >
                                    <div className="servicesTemplatesName">
                                      {item.service_name}
                                    </div>
                                    {/* <div>{item.service_type}</div>
                                    <div>{item.service_category}</div> */}

                                    <button
                                      className="availNowBtn"
                                      onClick={e =>
                                        this.availNowNumber(e, item.slug)
                                      }
                                    >
                                      Access Now
                                    </button>

                                    {item.order_no !== null && item.availed && (
                                      <button
                                        className="viewOrderT"
                                        onClick={e =>
                                          this.viewOrderNumber(
                                            e,
                                            item.service_name
                                          )
                                        }
                                      >
                                        View order
                                      </button>
                                    )}
                                    {this.state.showOrder &&
                                      item.service_name ===
                                        this.state.viewOrder && (
                                        <div className="servicesTemplatesName">
                                          Order Number:{item.order_no}
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                            }
                          )}
                      </div>
                    </Grid>
                  )}
                  {this.state.bookBlock && (
                    <Grid xs={12} lg={6} md={6} sm={6} className="rightSec">
                      {this.state.consultation.length && (
                        <Grid>
                          <span className="activePlan">
                            Consultation Requests
                          </span>
                          {this.state.consultation.map(item => {
                            return (
                              <div>
                                <span>{item.consultatin_id} -</span>
                                <p>{item.last_consulted}</p>
                                <p>
                                  <b>Assigned Lawyer:</b>{" "}
                                  {item.lawyer_name
                                    ? item.lawyer_name
                                    : "Not Assigned Yet"}
                                </p>
                                <p>
                                  <b>Per Month Quota :</b>{" "}
                                  {item.legal_consultation_per_month}
                                </p>
                                <p>
                                  <b>Remaining Quota :</b>{" "}
                                  {item.remaining_total_quota}
                                </p>
                              </div>
                            );
                          })}
                        </Grid>
                      )}
                      <div className="discountH">
                        {/* 7% discount on 7 add-on templates (5/7 remaining) */}
                      </div>
                      {this.state.customerDetails.sub_usage !== undefined &&
                        this.state.customerDetails.sub_usage.map(
                          (item, index) => {
                            if (item.template_type === "ADDON") {
                              return (
                                <div
                                  key={index}
                                  className="servicesTemplatesAdd"
                                >
                                  <div className="servicesTemplatesNameAdd">
                                    {item.service_name}
                                  </div>
                                  <div>{item.service_type}</div>
                                  <div>{item.service_category}</div>

                                  <button
                                    className="availNowBtn"
                                    onClick={e =>
                                      this.availNowNumber(e, item.service_id)
                                    }
                                  >
                                    Create
                                  </button>
                                  {item.order_no !== null && item.availed && (
                                    <button
                                      className="viewOrderT"
                                      onClick={e =>
                                        this.viewOrderNumber(
                                          e,
                                          item.service_name
                                        )
                                      }
                                    >
                                      View order
                                    </button>
                                  )}
                                  {this.state.showOrder &&
                                    item.service_name ===
                                      this.state.viewOrder && (
                                      <div className="servicesTemplatesNameAdd">
                                        Order Number:{item.order_no}
                                      </div>
                                    )}
                                </div>
                              );
                            }
                          }
                        )}
                      <div className="discountH topDissec">
                        {/* 7% discount on 10 legal services (8/10 remaining) */}
                      </div>

                      {this.state.customerDetails.sub_usage !== undefined &&
                        this.state.customerDetails.sub_usage.map(
                          (item, index) => {
                            if (item.template_type === "LEGALSERVICE") {
                              return (
                                <div
                                  key={index}
                                  className="servicesTemplatesAdd"
                                >
                                  <div className="servicesTemplatesNameAdd">
                                    {item.service_name}
                                  </div>
                                  <div>{item.service_type}</div>
                                  <div>{item.service_category}</div>

                                  <button
                                    className="availNowBtn"
                                    onClick={e =>
                                      this.availNowNumber(e, item.slug)
                                    }
                                  >
                                    Access Now
                                  </button>

                                  {item.order_no !== null && item.availed && (
                                    <button
                                      className="viewOrderT"
                                      onClick={e =>
                                        this.viewOrderNumber(
                                          e,
                                          item.service_name
                                        )
                                      }
                                    >
                                      View order
                                    </button>
                                  )}
                                  {this.state.showOrder &&
                                    item.service_name ===
                                      this.state.viewOrder && (
                                      <div>Order Number:{item.order_no}</div>
                                    )}
                                </div>
                              );
                            }
                          }
                        )}
                      {/* <div className=" topDissec">
                      <div className="flexDIv">
                        <Grid xs={12} lg={8} md={8} sm={12}>
                          <div className="discountH">

                          </div>
                          <div className="remainingTime">
                            Create New Consultation Request
                          </div>
                        </Grid>
                        <Grid xs={12} lg={4} md={4} sm={12}>
                          <button onClick={this.bookNowLayer} className="bookNow">Book Now</button>
                        </Grid>
                      </div>
                     
                    </div> */}
                      {this.state.showModal && (
                        <Modal
                          open={this.state.showModal}
                          onClose={e => this.setState({ showModal: false })}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div className="lawyer-subscription-popup">
                            <h1>Request a Legal Consultation</h1>
                            <div className="consult-time-wrapper">
                              <div className="consult-inner">
                                <p>Schedule Date</p>
                                <input
                                  type="date"
                                  value={this.state.dateState}
                                  onChange={this.changeDate}
                                />
                              </div>
                              <div className="consult-inner">
                                <p>Schedule Time</p>
                                <input
                                  type="time"
                                  value={this.state.timeState}
                                  onChange={this.changeTime}
                                />
                              </div>
                            </div>
                            <div className="inner">
                              <p>Duration</p>
                              <input
                                type="number"
                                placeholder="30 mins"
                                value={this.state.duration}
                                onChange={this.duration}
                              />
                            </div>
                            <button
                              className="btn-cancel"
                              onClick={e => this.setState({ showModal: false })}
                            >
                              cancel
                            </button>
                            <button
                              className="btn-save"
                              onClick={e => this.bookLawyerNow(e)}
                            >
                              confirm and book
                            </button>
                          </div>
                        </Modal>
                      )}
                    </Grid>
                  )}
                </div>
              </div>
              <SubscriptionPlan />
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
