import React, { Component } from 'react'

export default class index extends Component {

    componentDidMount(){
         
        this.props.history.push({	
            pathname: "/signin",	
            state: {	
              redirect: "/dashboard/chat-logs",	
              	
            },	
          })	
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
