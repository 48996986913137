import React, { Component } from 'react';
import './style.scss';
import Back from './../../../../Assets/icons/back.png';
import { withRouter } from "react-router-dom";
import {domain} from '../../../../Utils/domain';
import { getCookie } from '../../../../Utils/cookies';

class index extends Component {

    state={
        personaId:'',
        expertiseArea:'',
        firstName:'',
        lastName:'',
        email:'',
        phoneNumber:'',
        password:'',
        confirmPassword:'',
        userId:''
    }

    creatingPersona(){

        let  groupsId    = this.state.personaId;
        let  firstname   = this.state.firstName;
        let  lastname    = this.state.lastName;
        let  gmail       = this.state.email;
        let  phoneNumber = this.state.phoneNumber;
        let  pwd         = this.state.password;
        let  expertiseIn = this.state.expertiseArea;
        let  userName    = this.state.userId;
        let token = getCookie("token");
        fetch( domain + "/api/auth/create-profile/",{
            method  : "POST",
            headers :  {
                            'Content-Type' : "application/json",
                            'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                            'Authorization': `Token ${token}`
                       },
            body    : JSON.stringify({
                "groups"     : groupsId,
                "username"   : userName,
                "first_name" : firstname,
                "last_name"  : lastname,
                "password"   : pwd,
                "phone"      : phoneNumber,
                "email"      : gmail,
                "expertise"  : expertiseIn
            }),


          })
          .then(response => response.json())
          .then( responsejson => this.successResponse(responsejson))
          .catch(error => this.errorResponse(error))
    }

    successResponse=(response)=>{
      
    }

    errorResponse=(error)=>{
        
    }

    render() {
        return (
            <div className="create-lawyer">
                <h1><span onClick={this.props.history.goBack}><img src={Back} alt="back button" ></img></span>Creating New User</h1>
                <div className="create-lawyer__wrapper">
                    <div className="create-lawyer__wrapper_block">
                        <div className="create-lawyer__wrapper_block_left">
                            <label>User Type</label>
                            <select id="Expertise" value={this.state.personaId} onChange={(e)=>this.setState({personaId:e.target.value},()=>console.log("persona_id",this.state.personaId))} className="select-input-type" name="Expertise">
                                <option value="">select</option>
                                <option value="3">Lawyer</option>
                                <option value="1">Admin</option>
                                <option value="4">Finance</option>
                                <option value="5">Customer Support</option>
                            </select>
                            <label>First Name</label>
                            <input type="text" placeholder="First Name"
                                   value={this.state.firstName}
                                   onChange={(e)=>this.setState({ firstName: e.target.value})}/>
                            <label>Email</label>
                            <input type="email" placeholder="Email"
                                   value={this.state.email}
                                   onChange={(e)=>this.setState({ email: e.target.value})}/>
                            <label>Password</label>
                            <input type="password" placeholder="Password"
                                   value={this.state.password}
                                   onChange={(e)=>this.setState({ password: e.target.value})}/>
                           
                        </div>
                        <div className="create-lawyer__wrapper_block_right">
                            <label>User Name</label>
                            <input type="text" placeholder="User Name"
                                   value={this.state.userId}
                                   onChange={(e)=>this.setState({ userId: e.target.value})}/>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name"
                                   value={this.state.lastName}
                                   onChange={(e)=>this.setState({ lastName: e.target.value})}/>
                            <label>Phone</label>
                            <input type="phone" placeholder="Phone"
                                   value={this.state.phoneNumber}
                                   onChange={(e)=>this.setState({ phoneNumber: e.target.value})}/>
                            <label>Confirm Password</label>
                            <input type="password" placeholder="Confirm Password"
                                   value={this.state.confirmPassword}
                                   onChange={(e)=>this.setState({ confirmPassword: e.target.value})}/>
                                    
                           {this.state.personaId==="3"&& <><label>Expertise</label><select id="Expertise" value={this.state.expertiseArea} onChange={(e)=>this.setState({expertiseArea:e.target.value})} className="select-input" name="Expertise">
                                <option value="">select</option>
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                            </select></>}
                        </div>
                    </div>
                    <button className="create-lawyer__wrapper_button_create">Generate Password</button>
                    <div className="create-lawyer__wrapper_button">
                        <button className="create-lawyer__wrapper_button_cancel">Cancel</button>
                        <button className="create-lawyer__wrapper_button_create" onClick={()=>this.creatingPersona()}>Create</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(index);
