import React from "react";
import { createChatBotMessage,createClientMessage } from "react-chatbot-kit";

const botName = "Customer Support";
const prevmessages =JSON.parse(localStorage.getItem("genricChat"))
localStorage.setItem("preveiousgenric-chats",JSON.stringify(prevmessages))
var onlyMessages =[]
for(var msg in prevmessages){
  onlyMessages.push(prevmessages[msg].message)
  }

const config =
onlyMessages.length!==0 ? {
  botName: botName,
  lang: "no",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
  initialMessages: [
    createClientMessage(onlyMessages.map((item,index)=>{
             return (item) +" , "
           })) ,
    createChatBotMessage(
      `Hi! I’m here to help you. How may I help you today.`
    ),
  ]
  
}:
{
  botName: botName,
  lang: "no",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
  initialMessages: [
   
    createChatBotMessage(
      `Hi! I’m here to help you. How may I help you today.`
    ),
  ]
  
}
export default config;