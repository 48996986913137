import React, { Component } from 'react';
import './style.scss';
import Profile from './../../../../Assets/images/profile-img.png';
import CustomOrders from './../../Orders/customOrders';
import CustomOrdersMobile from './../../Orders/CustomOrdersMobile';
import Back from './../../../../Assets/icons/back.png';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../redux/modules/app/actions';
import { getCookie } from '../../../../Utils/cookies';
import { domain } from '../../../../Utils/domain';
import { updateToasterConfig } from '../../../../redux/modules/app/actions';



class index extends Component {
    state={
        customerDetails:[]
    }

    componentDidMount(){
        this.props.updateBreadcrumbs('customers');
        let id = this.props.location.customerId;
        let token = getCookie("token");
        fetch(`${domain}/api/auth/view_profile/${id}`,{
                 method:"GET",
                 headers: {
                            'Content-Type' : "application/json",
                            'Api-Key'      : "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
                            'Authorization': `Token ${token}`
                          }
            })
        .then(response=>response.json())
        .then((response)=> this.successResponse(response))
        .catch(error=> this.errorResponse(error))
    }

    successResponse=(response)=>{
       
        this.setState({ customerDetails:response })
    }

    errorResponse=(error)=>{

    }
    redirect = ()=>{
        this.props.history.push({
            pathname : "/dashboard/customers/SubscriptionView",
            state : {
                customerId : this.props.location.customerId
            }
        });
    }
    changeStatus(uname, status){
        let token = getCookie("token");
        let data = {
          username : uname
        }
        if(status === "Deactivated"){
          fetch(domain + "/api/auth/activate2/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((response) => this.activateSuccessResponse(response))
            .catch((error) => this.errorResponse(error));
          
        }else{
          fetch(domain + "/api/auth/deactivate/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((response) => this.deactivateSuccessResponse(response))
            .catch((error) => this.errorResponse(error));
        }
    
      }
      deactivateSuccessResponse(res){
        this.props.updateToasterConfig({
          show: true,
          message: "User Deactivated Successfully",
          color: '#D06900',
        }) 
        this.componentDidMount();
      }
      activateSuccessResponse(res){
        this.props.updateToasterConfig({
          show: true,
          message: "User Activated Successfully",
          color: '#D06900',
        }) 
        this.componentDidMount();
      }


    render() {
        return (
            <>
            <div className="customerDetails">
                <div className="customerDetails__wrapper">
                    <div className="customerDetails__wrapper_heading">
                        <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span>Customer Profile</p>
                    </div>
                    <div className="customerDetails__wrapper_content">
                        {/*<div className="customerDetails__wrapper_content_top">
                            <p className="customerDetails__wrapper_content_top_orderid">ID Number{this.state.customerDetails.id}</p>
                            <p className="customerDetails__wrapper_content_top_status">{this.state.customerDetails.status}</p>
                        </div>
                        <p className="customerDetails__wrapper_content_service">{this.state.customerDetails.customerDetails!==undefined && this.state.customerDetails.customerDetails.customerName}</p>
                        */}
                        <div className="customerDetails__wrapper_content_block">
                            <div className="customerDetails__wrapper_content_block_left">
                                <img src={Profile} alt="profile" />
                            </div>
                            <div className="customerDetails__wrapper_content_block_right">
                            <div className="customerDetails__wrapper_section">
                                    <p className="left-side">Role</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.group_name}</p>
                                </div> 
                                <div className="customerDetails__wrapper_section">
                                    <p className="left-side">First Name</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.first_name }</p>
                                </div> 
                                <div className="customerDetails__wrapper_section">
                                    <p className="left-side">Last Name</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.last_name}</p>
                                </div>  
                                <div className="customerDetails__wrapper_section">
                                    <p className="left-side">User Id</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.username }</p>
                                </div>
                                <div className="customerDetails__wrapper_section">
                                    <p className="left-side">Email ID</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.email}</p>
                                </div>
                                <div className="customerDetails__wrapper_section">
                                    <p className="left-side">Phone number</p>
                                    <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.phone}</p>
                                </div>
                                <h3 className="left-side">Billing Address</h3>
                                <p className="right-side">{this.state.customerDetails!==undefined &&  this.state.customerDetails.first_name}</p>
                                <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.addressLine1}</p>
                                <p className="right-side">{this.state.customerDetails!==undefined && this.state.customerDetails.addressLine2}</p>
                                <p className="right-side"></p>
                                <div className="customerDetails__wrapper_section"></div>
                                    <h3 className="left-side">Address</h3>
                                    <p className="right-side">Same as billing address</p>
                                    <div className="customerDetails__wrapper_section"></div>
                                    <div className="customerDetails__wrapper_section">
                                      <p className="left-side mt-2">Current Active Subscription</p>
                                      <p className="right-side mt-2 hoverEff" onClick={() => this.redirect()}><a>Subscription Details</a></p>
                                    </div>
                                    <div className="lawyerDetails__wrapper_section">
                                        <p className="left-side">Status</p>
                                      {(getCookie("group")==="1" || getCookie("group")==="5" )?  <p className="right-side save"  onClick={()=> this.changeStatus(this.state.customerDetails.username,this.state.customerDetails.status)}>{this.state.customerDetails.status }</p>:
                                      <p className="right-side" >{this.state.customerDetails.status }</p>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 768  ?
                <div className="customerDetails_orders-block">
                    <CustomOrders userId = {this.props.location.customerId} />
                </div>
                :
                <div className="customerDetails_orders-block">
                <CustomOrdersMobile userId = {this.props.location.customerId} />
            </div>
    }
                </>
        )
    }
}

export default withRouter(
    connect(
      (state) => ({
        // auth: state.app.auth,
      }),
      { updateBreadcrumbs,updateToasterConfig }
    )(index)
  );