import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Back from "./../../../../Assets/icons/back.png";
import { domain } from "../../../../Utils/domain";
import { getCookie, setCookie } from "../../../../Utils/cookies";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination";
import MetaFile from "./../../../../Pages/MetaFile";
import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botLogDetail: [],
      activePage: 1,
      totalItem: null,
      chatId: null,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  componentDidMount = () => {
    this.getChatData();
    this.getMeta();
  };
  getMeta = () => {
    const defaultHeaders = {
      "Content-Type": "application/json",
      "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
    };
    fetch(domain + `/api/home/tags/chatbot-details/`, {
      method: "GET",
      headers: defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        this.getSucessMetaresponce(responseJson);
      })
      .catch(error => {
        this.getErrorMetaresponce(error);
      });
  };
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  seenMsg() {
    fetch(domain + "/api/chatbot/update_message_status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      },
      body: JSON.stringify({
        user_id: this.state.chatId,
        seen: true
      })
    })
      .then(response => response.json())
      .then(responsejson => this.seenSuccessResponse(responsejson))
      .catch(error => this.errorResponse(error));
  }
  seenSuccessResponse(res) {}
  getChatData = () => {
    fetch(
      domain +
        `/api/chatbot/chat_conversations_details/${this.props.match.params.id}/?page=` +
        this.state.activePage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        }
      }
    )
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));
  };
  successResponseFetch = response => {
    this.setState({
      botLogDetail: response.user_id,
      totalItem: response.paginationTotal,
      chatId: response.chatId
    });
    this.seenMsg();
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getChatData());
  }
  errorResponse = error => {};

  render() {
    return (
      <div>
        {this.state.meta_title !== "" && <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
        }
        <Container className="orderdetails__wrapper_content_block botLogDetailp">
          <Grid xs={12} sm={12} lg={12} md={12}>
            <div className="orderdetails__wrapper_heading">
              <p>
                <span>
                  <img
                    onClick={this.props.history.goBack}
                    src={Back}
                    alt="back button"
                  ></img>
                </span>
                Chat Details
                {/* <span className="userIDtext"> {botLogDetail.user_id}</span> */}
              </p>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="textBold">User Messages</TableCell>
                    <TableCell className="textBold">First Name</TableCell>
                    <TableCell className="textBold">Last Name</TableCell>
                    <TableCell className="textBold">Email ID</TableCell>
                    <TableCell className="textBold">Phone Number</TableCell>
                    <TableCell className="textBold">Date</TableCell>
                    <TableCell className="textBold">Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.botLogDetail &&
                    this.state.botLogDetail.map(botLogDetail => {
                      var dateTime = new Date(botLogDetail.created_on);
                      return (
                        <TableRow key={botLogDetail}>
                          <TableCell>
                            {botLogDetail.request &&
                            botLogDetail.request.replace(/(^"|"$)/g, "")
                              .length > 1
                              ? botLogDetail.request.replace(/(^"|"$)/g, "")
                              : "NA"}
                          </TableCell>
                          <TableCell>
                            {botLogDetail.first_name &&
                            botLogDetail.first_name.replace(/(^"|"$)/g, "")
                              .length > 1
                              ? botLogDetail.first_name.replace(/(^"|"$)/g, "")
                              : "NA"}
                          </TableCell>
                          <TableCell>
                            {botLogDetail.last_name &&
                            botLogDetail.last_name.replace(/(^"|"$)/g, "")
                              .length > 1
                              ? botLogDetail.last_name.replace(/(^"|"$)/g, "")
                              : "NA"}
                          </TableCell>
                          <TableCell>
                            {botLogDetail.email_id &&
                            botLogDetail.email_id.replace(/(^"|"$)/g, "")
                              .length > 1
                              ? botLogDetail.email_id.replace(/(^"|"$)/g, "")
                              : "NA"}
                          </TableCell>
                          <TableCell>
                            {botLogDetail.phone_number &&
                            botLogDetail.phone_number.replace(/(^"|"$)/g, "")
                              .length > 1
                              ? botLogDetail.phone_number.replace(
                                  /(^"|"$)/g,
                                  ""
                                )
                              : "NA"}
                          </TableCell>
                          <TableCell>{`${dateTime.getDate()}- ${dateTime.getMonth() +
                            1} - ${dateTime.getFullYear()}`}</TableCell>
                          <TableCell>{`${dateTime.getHours()}:${dateTime.getMinutes() +
                            1}:${dateTime.getSeconds()}`}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Container>
        <div>
          <Pagination
            prevPageText="<"
            nextPageText=">"
            firstPageText=""
            lastPageText=""
            pageRangeDisplayed={5}
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalItem}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(index);
