import React, { useState, useEffect } from "react";
import { Button, Avatar } from "@material-ui/core";
import Content from "./Content";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import { GetOtp, ApplyPasswordChange } from '../Website/WebServices/Auth'
import "./styles.scss";
import { defaultHeaders, domain } from "../../Utils/domain";
import { connect } from "react-redux";
import { updateToasterConfig } from "../../../src/redux/modules/app/actions";
import { getMeta } from "../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../Pages/MetaFile";

const ForgotPassword = props => {
  const [userid, setuserid] = useState("");
  const [otp, setotp] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [cfmNewPassword, setcfmNewPassword] = useState("");
  const [emptyuserid, setemptyuserid] = useState("");
  const [emptyotp, setemptyotp] = useState("");
  const [notValidUserId, setnotValidUserId] = useState("");
  const [notValidOtp, setnotValidOtp] = useState("");
  const [notReceivedOtp, setNotReceivedOtp] = useState(false);
  const [requestingOtp, setRequestingOtp] = useState(true);
  const [otpVerified, setOtpVerified] = useState(false);
  const [successResponseData, setsuccessResponseData] = useState("");
  const [passwordNotMatching, setPasswordNotMatching] = useState(false);
  const [continueButton, setContinueButton] = useState(true);
  const [otpSection, setOtpSection] = useState(false);
  const [meta_title, setmeta_title] = useState("");
  const [meta_description, setmeta_description] = useState("");
  const [meta_keywords, setmeta_keywords] = useState("");
  const [other_meta_tags, seteother_meta_tags] = useState("");
  const [json_meta_tags, setjson_meta_tags] = useState("");

  // !userid.includes('/^[0-9\b]+$/')
  // userid.length !== 10
  useEffect(() => {
    getMeta(getMetaResponse, getMetaError);
  }, []);
  const getMetaResponse = response => {
    setmeta_title(response.meta_title);
    setmeta_description(response.meta_description);
    setmeta_keywords(response.meta_keywords);
    seteother_meta_tags(response.other_meta_tags);
    setjson_meta_tags(response.json_meta_tags);
  };
  const getMetaError = error => {
    console.log(error);
  };
  function onOtpSubmit() {
    if (userid.includes("@") === false) {
      props.updateToasterConfig({
        show: true,
        message: "Can not use @ in Username ",
        color: "#D06900"
      });
    } else if (userid.length <= 5) {
      props.updateToasterConfig({
        show: true,
        message: "User Id should be more than 6 characters",
        color: "#D06900"
      });
    } else {
      requestOtp();
    }
  }
  function requestOtp() {
    axios
      .post(domain + "/api/auth/reset/", JSON.stringify({ username: userid }), {
        headers: defaultHeaders
      })
      .then(response => successResponse(response))
      .catch(error => errorResponse(error));
  }
  const successResponse = response => {
    setemptyuserid(response.data.user);
    setContinueButton(false);
    setOtpSection(true);
    props.updateToasterConfig({
      show: true,
      message: "We have sent you an email ",
      color: "#D06900"
    });
  };

  const errorResponse = error => {
    props.updateToasterConfig({
      show: true,
      message: "User does not exist",
      color: "#D06900"
    });
  };

  function submitNewPassword() {
    if (newPassword === cfmNewPassword) {
      // ApplyPasswordChange(passwordSuccessResponse,passwordErrorResponse,cfmNewPassword,successResponseData.user)
      fetch(domain + "/api/auth/apply-pass/", {
        method: "POST",
        headers: defaultHeaders,
        body: JSON.stringify({
          password: cfmNewPassword,
          otp: otp,
          id: emptyuserid
        })
      })
        .then(response => response.json())
        .then(responsejson => passwordSuccessResponse(responsejson))
        .catch(error => passwordErrorResponse(error));
    } else if (newPassword !== cfmNewPassword) {
      props.updateToasterConfig({
        show: true,
        message: "Password mismatched ",
        color: "#D06900"
      });
    }
  }
  const passwordErrorResponse = error => {
    props.updateToasterConfig({
      show: true,
      message: "Incorrect OTP ",
      color: "#D06900"
    });
  };

  function passwordSuccessResponse(response) {
    if (response.status === "Success") {
      props.updateToasterConfig({
        show: true,
        message: "Password has been updated",
        color: "#D06900"
      });
      props.history.push("/signin");
    } else if (response.status === "Incorrect OTP") {
      props.updateToasterConfig({
        show: true,
        message: "Incorrect OTP ",
        color: "#D06900"
      });
    }
  }

  return (
    <div className="Login--page forgot--password--page">
      {this.state.meta_title !== "" &&   <MetaFile
        meta_title={meta_title}
        meta_description={meta_description}
        meta_keywords={meta_keywords}
        other_meta_tags={json_meta_tags}
      />
  }
      <div className="login--main--wrapper">
        <Content />
        <div className="windowHeight">
          <div className="login--right--wrapper">
            <div className="singinStyles">Forgot Password</div>
            <div className="contentStyle">
              Enter your registered Username then the one-time password <br />{" "}
              will send to your registered email address, below.
            </div>
            {continueButton && (
              <div>
                {" "}
                <div className="useridtext textfont">Username or email*</div>
                <input
                  className="textField"
                  placeholder="Username"
                  variant="outlined"
                  value={userid}
                  onChange={e => setuserid(e.target.value)}
                />
                <div>
                  <Button
                    onClick={onOtpSubmit}
                    variant="contained"
                    style={{
                      marginTop: 32,
                      backgroundColor: "#D06900",
                      height: 56,
                      width: 184,
                      fontFamily: "Raleway",
                      textTransform: "inherit",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: 20,
                      borderRadius: 0
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}

            {otpSection && (
              <div>
                <div className="pwdfont textfont">OTP</div>
                <input
                  className="textField"
                  placeholder="Enter OTP"
                  variant="outlined"
                  value={otp}
                  onChange={e => setotp(e.target.value)}
                />
                <div className="errorstyle">Enter Otp from email</div>
                <div>
                  <div className="pwdfont textfont">New Password</div>
                  <input
                    className="textField"
                    type="password"
                    placeholder="Enter New Password"
                    variant="outlined"
                    value={newPassword}
                    onChange={e => setnewPassword(e.target.value)}
                  />

                  <div className="pwdfont textfont">Confirm New Password</div>
                  <input
                    className="textField"
                    type="password"
                    placeholder="Confirm New Password"
                    variant="outlined"
                    value={cfmNewPassword}
                    onChange={e => setcfmNewPassword(e.target.value)}
                  />

                  <div>
                    <Button
                      onClick={submitNewPassword}
                      variant="contained"
                      style={{
                        bottom: 20,
                        marginTop: 52,
                        backgroundColor: "#D06900",
                        height: 56,
                        width: 184,
                        fontFamily: "Raleway",
                        textTransform: "inherit",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: 20,
                        borderRadius: 0
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig }
  )(ForgotPassword)
);
