import React, { Component } from "react";
import "./style.scss";
import Profile from "./../../../Assets/images/profile-img.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import Edit_pencil from "./../../../Assets/images/edit-pencil.svg";
import { getCookie } from "../../../Utils/cookies";
import { domain } from "../../../Utils/domain";
import Preloader from "./../../../Components/Preloader";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { updateProfileImg } from "./../../../../src/redux/modules/app/actions";
import { Input } from "@material-ui/core";

const formData = new FormData();
class index extends Component {
  state = {
    getInputs: false,
    username: "",
    gmail: "",
    number: "",
    status: "",
    first_name: "",
    last_name: "",
    profileimage: {},
    successResponseDetails: [],
    successBillingDetails: [],
    formData: {},
    preloader : false
  };

  saveProfile() {
    this.setState({
      preloader : true
    })
    let userId = getCookie("userId");
    for (var value of formData.values()) {
    }
    formData.append("username", this.state.successResponseDetails.username);
    formData.append("email", this.state.gmail);
    formData.append("phone", this.state.number);
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);

    this.saveProfileDetails(formData);
    this.getProfileDetails();
  }

  saveProfileDetails(params) {
    let token = getCookie("token");
    fetch(domain + "/api/auth/edit-profile/", {
      method: "POST",
      headers: {
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => this.successResponse(response))
      .catch((error) => this.errorResponse(error));
  }

  successResponse = (response) => {
    if(response){
      this.setState({ getInputs: false });
      window.location.reload();
      }
  };

  errorResponse = (error) => {};

  componentDidMount() {
    this.setState({preloader : true})
    this.props.updateBreadcrumbs("Profile");
    this.getProfileDetails();
  }

  getProfileDetails() {
    let userId = getCookie("userId");
    let token = getCookie("token");
    fetch(domain + `/api/auth/view_profile/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => this.successDetails(response))
      .catch((error) => this.errorDetails(error));
  }

  successDetails = (responseDetails) => {
    this.props.updateProfileImg(responseDetails.temp_profile_img);
    this.setState({ successResponseDetails: responseDetails, preloader : false }, () => {
      if (
        this.state.successResponseDetails &&
        this.state.successResponseDetails.username &&
        this.state.successResponseDetails.username !== ""
      ) {
        this.setState({ username: this.state.successResponseDetails.username });
      }

      if (
        this.state.successResponseDetails &&
        this.state.successResponseDetails.email &&
        this.state.successResponseDetails.email !== ""
      ) {
        this.setState({ gmail: this.state.successResponseDetails.email });
      }
      if (
        this.state.successResponseDetails &&
        this.state.successResponseDetails.phone &&
        this.state.successResponseDetails.phone !== ""
      ) {
        this.setState({ number: this.state.successResponseDetails.phone });
      }

      this.setState({
        first_name:
          this.state.successResponseDetails.first_name !== undefined &&
          this.state.successResponseDetails.first_name,
        last_name:
          this.state.successResponseDetails.last_name !== undefined &&
          this.state.successResponseDetails.last_name,
        status:
          this.state.successResponseDetails.status !== undefined &&
          this.state.successResponseDetails.status,
      });
    });
  };
  loadFile = (e) => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
    formData.append("profileimage", e.target.files[0], e.target.files[0].name);

    this.setState({
      profileimage: formData,
    });
  };

  redirect = () => {
    this.props.history.push("/dashboard/subscription");
  };
  errorDetails = (errorDetails) => {};

  render() {
    return (
      <>
        <div className="userDetails">
          <div className="userDetails__wrapper">
            <div className="userDetails__wrapper_heading">
              <p>My Profile</p>
              <div className="myProfil">
                <img src={Edit_pencil} style={{ height: 18, width: 18 }} />
                <div
                  className="editText"
                  onClick={() =>
                    this.setState({ getInputs: true, username: "" })
                  }
                >
                  Edit
                </div>
              </div>
            </div>
            {this.state.preloader ?  <Preloader /> : <Container>
              <div className="userDetails__wrapper_content">
                <Grid xs={12} sm={12} lg={4} md={4}>
                  <div>
                    {this.state.getInputs === true ? (
                      <>
                        <p className="userDetails__wrapper_content_service">
                          {this.state.successResponseDetails.username}
                        </p>
                      </>
                    ) : (
                      <p className="userDetails__wrapper_content_service">
                        {this.state.successResponseDetails.username}
                      </p>
                    )}
                  </div>
                  <div className="userDetails__wrapper_content_block_left">
                    {this.state.getInputs === true ? (
                      <>
                        <p className="left-side emAil profile--image">
                          Choose Profile Image
                        </p>
                        <img
                          id="output"
                          src={
                            this.state.successResponseDetails.temp_profile_img
                          }
                          alt="profile"
                        />
                        <input
                          type="file"
                          id="file"
                          onChange={(e) => this.loadFile(e)}
                          style={{ display: "none" }}
                        />
                        <label for="file" className="upload-img">
                          Upload Image 
                        </label>
                      </>
                    ) : (
                      <img
                        src={this.state.successResponseDetails.temp_profile_img}
                        alt="profile"
                      />
                    )}
                  </div>
                </Grid>
                <Grid xs={12} sm={12} lg={8} md={8}>
                  <div className="userDetails__wrapper_content_block_right">
                  <div className="userDetails__wrapper_section">
                      <p className="left-side emAil">Role</p>
                      {this.state.getInputs === true ? (
                        <p className="right-side">
                          {this.state.successResponseDetails.group_name}
                        </p>
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.group_name}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_section">
                      <p className="left-side emAil">Email ID</p>
                      {this.state.getInputs === true ? (
                        <p className="right-side ">
                          {this.state.successResponseDetails.email}
                        </p>
                      ) : (
                        <p className="right-side ">
                          {this.state.successResponseDetails.email}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_section">
                      <p className="left-side emAil">Phone number</p>
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.number}
                          type="text"
                          className="inputText"
                          placeholder="Enter The number"
                          onChange={(e) => {
                         
                            if(e.target.value.length<=11){
                            this.setState({ number: e.target.value }, () => {
                            });
                          }
                          }}
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.phone}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_section fNameL">
                      <h3 className="left-side emAil">First name</h3>
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.first_name}
                          className="inputText"
                          type="text"
                          placeholder="Enter First Name"
                          onChange={(e) =>
                            this.setState({ first_name: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.first_name}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_section fNameL">
                      <h3 className="left-side emAil">Last name</h3>
                      {this.state.getInputs === true ? (
                        <input
                          value={this.state.last_name}
                          className="inputText"
                          type="text"
                          placeholder="Enter Last Name"
                          style={{ marginLeft: 12 }}
                          onChange={(e) =>
                            this.setState({ last_name: e.target.value })
                          }
                        />
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.last_name}
                        </p>
                      )}
                    </div>
                    <div className="userDetails__wrapper_section">
                      <p className="left-side emAil">Status</p>
                      {this.state.getInputs === true ? (
                        <p className="right-side">
                          {this.state.successResponseDetails.status}
                        </p>
                      ) : (
                        <p className="right-side">
                          {this.state.successResponseDetails.status}
                        </p>
                      )}
                    </div>

               
                  </div>

                  {this.state.getInputs && (
                    <div style={{ marginTop: 26 }}>
                      <button
                        className="cancel"
                        onClick={() => this.setState({ getInputs: false })}
                      >
                        cancel
                      </button>
                      <button
                        className="save"
                        onClick={() => this.saveProfile()}
                      >
                        save
                      </button>
                    </div>
                  )}
                </Grid>

                <div className="userDetails__wrapper_content_block"></div>
              </div>
            </Container>}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateProfileImg }
  )(index)
);
