import React, { Component, createContext } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Content from "./Content";
import "./styles.scss";
import { setCookie, getCookie } from "./../../Utils/cookies";
import { defaultHeaders, domain } from "../../Utils/domain";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import { updateToasterConfig } from "../../../src/redux/modules/app/actions";
import { updateProfileImg } from "./../../../src/redux/modules/app/actions";
import { updateNotificationCount } from "./../../../src/redux/modules/app/actions";
import { getMeta } from "../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../Pages/MetaFile";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      successResponseData: "",
      emptyUsername: false,
      emptyPassword: false,
      notValidName: false,
      //  hideSignIn : true,
      backgroundcolor: true,
      getNameInHeader: false,
      passIdToProfile: false,
      getNotification: []
    };
    // this.successResponse = this.successResponse.bind(this);
    // this.errorResponse = this.errorResponse.bind(this);
  }
  componentDidMount() {
    getMeta(this.getMetaResponse, this.getMetaError);
  }
  getMetaResponse = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getMetaError = error => {
    console.log(error);
  };
  submitLoginForm = () => {
    if (this.state.username === "" && this.state.password === "") {
      this.setState({
        emptyUsername: true,
        emptyPassword: true
      });
    } else if (this.state.username === "" && this.state.password !== "") {
      this.setState({
        emptyUsername: true,
        emptyPassword: false
      });
    } else if (this.state.username !== "" && this.state.password === "") {
      this.setState({
        emptyUsername: false,
        emptyPassword: true
      });
    } else if (this.state.username !== "" && this.state.password !== "") {
      this.setState({
        emptyUsername: false,
        emptyPassword: false
      });
      let username = this.state.username;
      if (username.includes("@")) {
        this.setState({ notValidName: true });
      } else {
        this.setState({ notValidName: false }, this.signIn());
      }
    }
  };

  signIn() {
    let data = {
      username: this.state.username,
      password: this.state.password
    };
    axios
      .post(domain + "/api/auth/active-login/", JSON.stringify(data), {
        headers: defaultHeaders
      })
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    this.props.updateProfileImg(response.data.user.temp_profile_img);
    setCookie("group", response.data.user.groups);
    setCookie("token", response.data.token);
    setCookie("userId", response.data.user.id);
    setCookie("email", response.data.user.email);
    setCookie("phone", response.data.user.phone);
    setCookie("logedinUser", this.state.username);
    //setCookie("sub-plan", "Silver Plan")
    this.getNotification();
    if (response.data.user.plan.length) {
      setCookie("sub-plan", response.data.user.plan[0].plan);
    }
    this.setState(
      {
        successResponseData: response
      },
      () => {
        this.props.location.state
          ? this.props.history.push({
              pathname: this.props.location.state.redirect,
              state: { height: this.props.location.state.height }
            })
          : this.props.history.push("/");
      }
    );
  };

  errorResponse = error => {
    //non_field_errors //status

    let msg = "Invalid Credentials";
    if (error.response) {
      if (
        error.response.data.status ===
        "Please activate your account with the email sent you !"
      ) {
        msg =
          "Please activate your account, a verification link sent on your registed email!";
      } else if (
        error.response.data.status === "Your account has been deactivated!"
      ) {
        msg = "Your account has been deactivated!";
      } else if (error.response.data) {
        if (
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors[0]
        ) {
          msg = error.response.data.non_field_errors[0];
        } else if (
          error.response.data.status ===
          "Please activate your account, a verification link sent on your registed email!"
        ) {
          msg = error.response.data.status;
        } else if (
          error.response.data.status &&
          error.response.data.status[0]
        ) {
          msg = error.response.data.status[0];
        }
      }

      // console.log(error.response);
      this.props.updateToasterConfig({
        show: true,
        message: msg,
        color: "#D06900"
      });
    }
  };
  getNotification = () => {
    fetch(domain + "/api/notification/list/?page=1&&page_size=10000000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.notiSuccessResponse(response))
      .catch(error => this.errorResponse(error));
  };
  notiSuccessResponse = res => {
    if (res.paginationTotal > 0) {
      var data = [];
      res.results.map((item, index) => {
        if (item.read === false) {
          data.push(item);
        }
      });
      this.props.updateNotificationCount(res.paginationTotal);
    }
  };

  render() {
    return (
      <div className="Login--page">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="login--main--wrapper">
          <Content
            redirect={
              this.props.location.state && this.props.location.state.redirect
            }
          />
          <div className="windowHeight">
            <div className="login--right--wrapper">
              <div className="singinStyles">Sign in</div>
              {/*<div className="contentStyle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor<br/>incididunt ut labore et</div>*/}
              <form>
                <div className="useridtext textfont">Username*</div>
                <input
                  className="textField"
                  placeholder="Username"
                  variant="outlined"
                  value={this.state.username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
                {this.state.emptyUsername === true ? (
                  <div className="errorstyle">Enter username</div>
                ) : (
                  ""
                )}
                {this.state.notValidName === true && (
                  <div className="errorstyle">
                    Username should not include '@'
                  </div>
                )}
                <div className="pwdfont textfont">Password*</div>
                <input
                  className="textField"
                  type="password"
                  placeholder="Enter your Password"
                  variant="outlined"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
                {this.state.emptyPassword === true ? (
                  <div className="errorstyle">Enter password</div>
                ) : (
                  ""
                )}
                <Link
                  to="/forgotpassword"
                  style={{ textDecoration: "none", color: "#D06900" }}
                >
                  <div className="fpwd textfont">Forgot Password?</div>
                </Link>
              </form>

              <Button
                onClick={this.submitLoginForm}
                variant="contained"
                style={styles.button}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig, updateProfileImg, updateNotificationCount }
  )(Login)
);

let styles = {
  button: {
    marginTop: 32,
    backgroundColor: "#D06900",
    height: 56,
    width: 184,
    fontFamily: "Raleway",
    textTransform: "inherit",
    fontWeight: "bold",
    color: "white",
    fontSize: 20,
    borderRadius: 0
  },
  circle: {
    borderRadius: 0,
    top: "37%",
    right: 0,
    position: "absolute",
    height: "63%",
    width: "30%"
  }
};
