import { domain } from "./../../../Utils/domain";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
};

export const getFaq = (successResponse, errorResponse) => {
  fetch(domain + "/api/home/faqcategory/", {
    method: "GET",
    headers: defaultHeaders
  })
    .then(response => response.json())
    .then(responseJson => {
      successResponse(responseJson);
    })
    .catch(error => {
      errorResponse(error);
    });
};

export const searchFaq = (successResponse, errorResponse, fcsc, ftsc) => {
  fetch(
    domain +
      `/search/faq/suggest/?faq_content_suggest__completion=${fcsc}&faq_title_suggest__completion=${ftsc}`,
    {
      method: "GET",
      headers: defaultHeaders
    }
  )
    .then(response => response.json())
    .then(responseJson => {
      successResponse(responseJson);
    })
    .catch(error => {
      errorResponse(error);
    });
};

export const faqDetail = (
  successDetailResponse,
  errorDetailResponse,
  searchedTextId
) => {
  fetch(domain + `/search/faq/?id=${searchedTextId}`, {
    method: "GET",
    headers: defaultHeaders
  })
    .then(response => response.json())
    .then(responseJson => {
      successDetailResponse(responseJson);
    })
    .catch(error => {
      errorDetailResponse(error);
    });
};

export const getFaqCategories = (successResponse, errorResponse) => {
  fetch(domain + "/api/auth/faq-category-list/", {
    method: "GET",
    headers: defaultHeaders
  })
    .then(response => response.json())
    .then(responseJson => {
      successResponse(responseJson);
    })
    .catch(error => {
      errorResponse(error);
    });
};
export const faqsdetailsPage = (successResponse, errorResponse, productId) => {
  fetch(domain + `/api/services/service/${productId}/faq/`, {
    method: "GET",
    headers: defaultHeaders
  })
    .then(response => response.json())
    .then(responseJson => {
      successResponse(responseJson);
    })
    .catch(error => {
      errorResponse(error);
    });
};
