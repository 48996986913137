import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { deleteCookie, getCookie, setCookie } from "../../../Utils/cookies";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import Preloader from "./../../../Components/Preloader";
import { domain } from "./../../../Utils/domain";
import { updateToasterConfig } from "../../../../src/redux/modules/app/actions";
import { LeftSideLoop } from "./leftSideLoop";
import { currencyConvertor, numberWithCommas } from "./currencyConvertor.js";
import axios from "axios";
import MetaFile from "./../../MetaFile";
import "./styles.scss";
import { FormatColorResetOutlined } from "@material-ui/icons";
var arrayOfCount = null;
var parser = new DOMParser();
var numberAdded;
class index extends Component {
  state = {
    formData: {},
    executeOnce: false,
    page: 1,
    inputs: {},
    maxpage: 0,
    dynamicClassState: null,
    agreement_id: "",
    error: null,
    heightOfPage: 0,
    loader: true,
    preView: false,
    formName: "",
    wrapper: null,
    countOfInput: null,
    stopNext: true,
    updatedDiv: true,
    reloadData: true,
    totalTestatorCount: 1,
    leftWrapper: null,
    storenameClass: [],
    storeAddressclass: [],
    textclassName: [],
    textclassAddress: [],
    prvious: false,
    finalPage: true,
    jointName: [],
    jointAddress: [],
    removedCount: 0,
    spanToMatch: null,
    reloadForCountStop: false,
    executeOnlyOnceFromLeft: true,
    righthandHtml: {},
    currentPathname: null,
    currentSearch: null,
    filingCost: false,
    meta_title: null,
    meta_description: null,
    meta_keywords: null,
    other_meta_tags: null,
    serviceId: "",
    errorCode: false,
    valueOfPage: 0
  };
  componentDidMount() {
    window.addEventListener('contextmenu', this.handleContextMenu);
    window.addEventListener('beforeunload',this.pageRefreshAlert); 


    window.scrollTo(0, 0);
    this.props.updateBreadcrumbs("form");
    let title = this.props.match.params.name;
    let space = " ";
    title = title.split("-").join(space);
    this.setState(
      {
        formName: title
      },
      () => this.getData()
    );
    if (getCookie("ref_id")) {
      this.preView();
    }
  }
  componentWillUnmount(){
    window.removeEventListener('contextmenu', this.handleContextMenu);
    window.removeEventListener('beforeunload', this.pageRefreshAlert);

  }
  handleContextMenu = (e) => {
    e.preventDefault();
}
pageRefreshAlert =(e) => {
  e.preventDefault();
    e.returnValue = "Data lost";
  alert("");
}
  preView = () => {
    fetch(`${domain}/api/order/agreement-detail/` + getCookie("ref_id"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        this.preViewSuccess(responseJson);
      })
      .catch(error => {
        this.errorResponse(error);
      });
  };
  preViewSuccess(res) {
    let data = JSON.parse(res.entireState);
    if (res.purchasedService_title === this.state.formName) {
      this.setState(data);
    }
    this.executeState();
  }
  executeState = () => {
    for (var counting = 1; counting <= this.state.maxpage + 1; counting++) {
      if (this.state[`right${counting}`] === undefined) {
        this.getData();
      }
    }
  };
  getDataPrev = () => {
    let url = window.location.pathname.split("/");
    if (url[2]) {
      let productId = url[2].toLowerCase();

      fetch(
        `${domain}/api/services/list/${productId}/form/?page=` +
          this.state.page,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          this.successResponse(responseJson);
        })
        .catch(error => {
          this.errorResponse(error);
        });
    } else {
      this.props.history.push("/personal");
    }
  };
  getData = () => {
    let url = window.location.pathname.split("/");
    if (url[2]) {
      let productId = url[2].toLowerCase();
      fetch(
        `${domain}/api/services/list/${productId}/form/?page=` +
          this.state.page,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          this.successResponse(responseJson);
        })
        .catch(error => {
          this.errorResponse(error);
        });
    } else {
      this.props.history.push("/personal");
    }
  };
  executeSelect = (namedata, valuedata) => {
    try {
      valuedata = this.setValue(namedata);
      let eledata = document.getElementById(namedata);
      eledata.innerHTML = this.setValue(namedata);
    } catch (err) {}
  };
  executeInput = (namedata, valuedata) => {
    if (this.state.page > 3 && this.state.finalPage) {
      this.setState({ finalPage: false });
      this.addeventsMethod();
    }
    try {
      valuedata = this.setValue(namedata);
      let eleinput = document.getElementById(namedata);
      eleinput.innerHTML = this.setValue(namedata);
    } catch (err) {}
  };
  alignValuesForRight = () => {
    var rightData = this.state.wrapper;
    try {
      for (var d = 1; d <= this.state.parentForClearLeft; d++) {
        if (this.state.checktheDiv.includes("AddToAlt")) {
        } else {
          var numbering = rightData.getElementById(
            `classOfInput_${this.state.dynamicClassState}_${d + 2}`
          ).children[0].innerHTML;
          var slicedNumber = numbering.slice(0, numbering.length - 1);
          if (slicedNumber.includes(d + 1) == false) {
            rightData.getElementById(
              `classOfInput_${this.state.dynamicClassState}_${d + 2}`
            ).children[0].innerHTML = `${slicedNumber} ${d + 1}`;
          }
        }
        this.setState({
          formData: rightData.documentElement.innerHTML,
          loader: false
        });
      }
    } catch (err) {}
    this.addeventsMethod();
    this.clickHandler();
  };
  clearAllValueFromLeft = () => {
    var rightData = this.state.wrapper;
    if (this.state.executeOnlyOnceFromLeft === true) {
      var leftData = this.state.leftWrapper;
      try {
        var mainParentDiv = leftData.getElementsByName(
          this.state.checktheDiv
        )[0].parentElement.parentElement.parentElement.parentElement;
        for (var k = 1; k <= this.state.parentForClearLeft; k++) {
          var addedData =
            mainParentDiv.children[k].children[0].children[0].innerHTML;
          if (mainParentDiv.children[k].childElementCount > 2) {
            if (addedData.includes(k + 1) == false) {
              LeftSideLoop(
                k,
                mainParentDiv.children[k],
                mainParentDiv.children[k].childElementCount,
                addedData
              );
            }
          } else {
            if (addedData.includes(k + 1) == false) {
              leftData.getElementsByName(
                this.state.checktheDiv
              )[0].parentElement.parentElement.parentElement.parentElement.children[
                k
              ].children[0].children[0].innerHTML = `${addedData.concat(
                k + 1
              )}?`;
            }
            // if (addedData.includes(k + 1) == false) {
            LeftSideLoop(
              k,
              mainParentDiv.children[k],
              mainParentDiv.children[k].childElementCount,
              addedData
            );

            // }
          }
          this.setState({
            inputs: leftData.documentElement.innerHTML
          });
          this.alignValuesForRight();
        }
        if (this.state.updatedDiv === true) {
          this.setState({ updatedDiv: false });
          try {
            for (var d = 1; d <= this.state.parentForClearLeft; d++) {
              var numbering = rightData.getElementById(
                `classOfInput_${this.state.dynamicClassState}_${d + 2}`
              ).children[0].innerHTML;
              var slicedNumber = numbering.slice(0, numbering.length - 1);
              if (slicedNumber.includes(d + 1) == false) {
                rightData.getElementById(
                  `classOfInput_${this.state.dynamicClassState}_${d + 2}`
                ).children[0].innerHTML = `${slicedNumber} ${d + 1}`;
              }
              this.setState({ formData: rightData.documentElement.innerHTML });
            }
          } catch (err) {}
          this.addeventsMethod();
          this.clickHandler();
        }
      } catch (err) {}
      if (this.state.reloadData === true) {
        setTimeout(() => {
          this.stopReload();
          this.clickHandler();
          this.addeventsMethod();
        }, 3000);
      }
    }
  };
  stopReload = () => {
    this.setState({ reloadData: false });
  };
  signatureError = () => {
    this.props.updateToasterConfig({
      show: true,
      message: "Upload only .PNG or .JPG File for this field. ",
      color: "#D06900",
      classStore: []
    });
  }
  addeventsMethod = (e, dataOfAdd) => {
    this.setState({ inputs: this.state.leftWrapper.documentElement.innerHTML });
    var rightData = this.state.wrapper;
    var leftData = this.state.leftWrapper;
    if (e !== null && e !== undefined) {
      if (
        e.target !== null &&
        e.target !== undefined &&
        e.target.tagName !== "SELECT"
      ) {
        if (this.state.heightOfPage !== 0) {
          let scrollDivright = document.getElementById("right-side");
          let scrollDivleft = document.getElementById("left-side");
          if (scrollDivright !== null && scrollDivleft !== null) {
            scrollDivright.scrollTop = this.state.heightOfPage;
            scrollDivleft.scrollTop = this.state.heightOfPage;
          }
        }
      }
    }
    try {
      let inputFields = document.getElementsByTagName("input");
      this.setState({
        righthandHtml: rightData.documentElement.innerHTML // we are setting value to sent to backend , complete chnaged html
      });
      var parentClassName;
      var nameSet = [];
      var addressSet = [];
      var noLastElement = [];
      for (let i = 0; i < inputFields.length; i++) {
        if (
          this.state.removedCount[0] >= 2 &&
          this.state.reloadForCountStop === false
        ) {
          parentClassName = inputFields[
            i
          ].parentElement.parentElement.parentElement.parentElement.getAttribute(
            "classname"
          );
          var newDivClassName = inputFields[
            i
          ].parentElement.parentElement.parentElement.getAttribute("classname");
          if (parentClassName !== null && newDivClassName !== null) {
            var totalParentElementCount =
              inputFields[i].parentElement.parentElement.parentElement
                .parentElement.childElementCount;
            var numberOfTotalInputFileds =
              inputFields[i].parentElement.parentElement.parentElement
                .childElementCount;
            for (
              var countOfAddMore = 1;
              countOfAddMore <= numberOfTotalInputFileds;
              countOfAddMore++
            ) {
              var prevOfSlicedData = inputFields[
                i
              ].parentElement.parentElement.children[0].innerHTML.slice(
                0,
                inputFields[i].parentElement.parentElement.children[0].innerHTML
                  .length - 4
              );
              var slicedData = inputFields[
                i
              ].parentElement.parentElement.children[0].innerHTML.slice(
                inputFields[i].parentElement.parentElement.children[0].innerHTML
                  .length - 10,
                inputFields[i].parentElement.parentElement.children[0].innerHTML
                  .length
              );
              var removedSliceData = this.state.removedCount;
              var r = /\d+/g;
              var m;
              var numbers = [1, 2, 3, 4, 5, 6];
              var RightInnerData = rightData.getElementById(inputFields[i].name)
                .parentElement.children[0].innerHTML;
              var numberOfCountInRight = RightInnerData.replace(
                / /g,
                ""
              ).replace(/\&nbsp;/g, "");
              while ((m = r.exec(slicedData)) != null) {
                if (parseInt(m[0]) > this.state.removedCount[0]) {
                  numbers.forEach(number => {
                    if (
                      number >= this.state.removedCount[0] &&
                      number <= totalParentElementCount
                    ) {
                      var entireValue =
                        numberOfCountInRight.length > 2
                          ? numberOfCountInRight.slice(
                              0,
                              numberOfCountInRight.length - 2
                            )
                          : null;
                      if (this.state.spanToMatch === parentClassName) {
                        if (inputFields[i].name.includes("AddToAlt")) {
                          var AddToAltEntireValue = rightData
                            .getElementById(inputFields[i].name)
                            .parentElement.children[0].innerHTML.slice(0, 4);
                          if (numberAdded !== parseInt(m[0])) {
                            rightData.getElementById(
                              inputFields[i].name
                            ).parentElement.children[0].innerHTML = `${parseFloat(
                              AddToAltEntireValue - 0.1
                            ).toFixed(1)}`;
                            numberAdded = parseInt(m[0]);
                          }
                        } else {
                          if (
                            rightData
                              .getElementById(inputFields[i].name)
                              .parentElement.children[0].innerHTML.includes(
                                parseInt(m[0])
                              )
                          ) {
                            rightData.getElementById(
                              inputFields[i].name
                            ).parentElement.children[0].innerHTML = `${
                              entireValue === null
                                ? `${parseInt(m[0]) - 1}.`
                                : `${entireValue}.${parseInt(m[0]) - 1}`
                            }`;
                          }
                        }
                        this.setState({
                          parentForClearLeft: totalParentElementCount,
                          checktheDiv: inputFields[i].name
                        });
                        leftData.getElementsByName(
                          inputFields[i].name
                        )[0].parentElement.parentElement.children[0].innerHTML = `${prevOfSlicedData}`;
                        this.setState({
                          formData: rightData.documentElement.innerHTML,
                          inputs: leftData.documentElement.innerHTML
                        });
                      }
                    }
                  });
                }
                setTimeout(() => {
                  try {
                    if (parseInt(m[0]) > totalParentElementCount) {
                      arrayOfCount = parseInt(m[0]);
                      if (arrayOfCount > totalParentElementCount) {
                        numbers.forEach(number => {
                          if (
                            number >= this.state.removedCount &&
                            number <= totalParentElementCount
                          ) {
                            var entireValue =
                              numberOfCountInRight.length > 2
                                ? numberOfCountInRight.slice(
                                    0,
                                    numberOfCountInRight.length - 2
                                  )
                                : null;
                            if (this.state.spanToMatch === parentClassName) {
                              if (inputFields[i].name.includes("AddToAlt")) {
                              } else {
                                if (
                                  rightData
                                    .getElementById(inputFields[i].name)
                                    .parentElement.children[0].innerHTML.includes(
                                      parseInt(m[0])
                                    )
                                ) {
                                  rightData.getElementById(
                                    inputFields[i].name
                                  ).parentElement.children[0].innerHTML = `${
                                    entireValue === null
                                      ? `${number + 1}.`
                                      : `${entireValue}.${number + 1}`
                                  }`;
                                }
                              }
                              leftData.getElementsByName(
                                inputFields[i].name
                              )[0].parentElement.parentElement.children[0].innerHTML = `${prevOfSlicedData}`;
                              this.setState({
                                formData: rightData.documentElement.innerHTML,
                                inputs: leftData.documentElement.innerHTML
                              });
                            }
                          }
                        });
                      }
                    }
                  } catch (err) {}
                }, 1000);
              }
            }
          }
        }
        if (inputFields[i].type === "date") {
          inputFields[i].setAttribute("max", "9999-12-31");
        }
        if (inputFields[i].name.includes("fullnameOne_rep99")) {
          this.state[inputFields[i].name] !== undefined &&
            nameSet.push(this.state[inputFields[i].name]);
          inputFields[i].addEventListener("blur", event => {
            this.addeventsMethod(event);
          });
        }
        if (inputFields[i].name.includes("addressOne_alphaNumeric_rep99")) {
          this.state[inputFields[i].name] !== undefined &&
            addressSet.push(this.state[inputFields[i].name]);
        }
        var uniquename = nameSet.filter((a, b, c) => c.indexOf(a) === b);
        this.setState({ jointName: uniquename });
        var uniqueaddress = addressSet.filter((a, b, c) => c.indexOf(a) === b);
        var arraySet = [];
        for (var k in uniquename) {
          arraySet.push(`${uniquename[k]} of ${uniqueaddress[k]}`);
        }
        var uniquevalues = arraySet.filter((a, b, c) => c.indexOf(a) === b);
        noLastElement = uniquename;
        var onlyLastElement = noLastElement.pop();
        var finalValueOfTitle =
          noLastElement.length > 0 ? noLastElement.join(",") : noLastElement[0];
        if (inputFields[i].name.includes("jointManyNames")) {
          inputFields[i].addEventListener(
            "input",
            this.setState({
              [inputFields[i].name]: `${
                finalValueOfTitle === undefined ? "" : finalValueOfTitle
              } And ${onlyLastElement === undefined ? "" : onlyLastElement}`
            })
          );
          inputFields[i].value = this.setValue(inputFields[i].name);
          let ele1 = document.getElementById(inputFields[i].name);
          ele1.innerHTML = this.setValue(inputFields[i].name);
        }
        if (inputFields[i].name.includes("nameAndAddress")) {
          inputFields[i].addEventListener(
            "input",
            this.setState({ [inputFields[i].name]: uniquevalues.join(",") })
          );
          inputFields[i].value = this.setValue(inputFields[i].name);
          let ele1 = document.getElementById(inputFields[i].name);
          ele1.innerHTML = this.setValue(inputFields[i].name);
        }
        for (var getCount = 1; getCount < 10; getCount++) {
          if (inputFields[i].name.includes(`get${getCount}00`)) {
            var classData = inputFields[i].name.slice(
              7,
              inputFields[i].name.length
            );
            inputFields[i].addEventListener(
              "input",
              this.setState({ [inputFields[i].name]: this.state[classData] })
            );
            inputFields[i].value = this.setValue(inputFields[i].name);
            let ele1 = document.getElementById(inputFields[i].name);
            ele1.innerHTML = this.setValue(inputFields[i].name);
          }
          if (inputFields[i].name.includes(`post${getCount}00`)) {
            var classData = inputFields[i].name.slice(
              8,
              inputFields[i].name.length
            );
            inputFields[i].addEventListener(
              "input",
              this.setState({ [inputFields[i].name]: this.state[classData] })
            );
            inputFields[i].value = this.setValue(inputFields[i].name);
            let ele1 = document.getElementById(inputFields[i].name);
            ele1.innerHTML = this.setValue(inputFields[i].name);
          }
        }
        inputFields[i].addEventListener("input", this.handleChange);
        inputFields[i].addEventListener("keyup", this.KeyDownHandler);
        inputFields[i].addEventListener("blur", this.BlurHandler);
        inputFields[i].addEventListener("blur", event => {
          try {
            if (event.target.name.includes("Price100")) {
              var parts = event.target.value.toString().split(".");
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              var inputfields = document.getElementsByTagName("input");
              for (let i = 0; i < inputfields.length; i++) {
                if (inputfields[i].name === event.target.name) {
                  inputfields[i].addEventListener(
                    "blur",
                    this.setState({ [inputfields[i].name]: parts.join(".") })
                  );
                  inputfields[i].value = this.setValue(inputfields[i].name);
                  let ele1 = document.getElementById(inputfields[i].name);
                  ele1.innerHTML = this.setValue(inputfields[i].name);
                }
              }
            }
            if (event.target.type === "email") {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (
                event.target.value === undefined ||
                event.target.value === "" ||
                event.target.value.length < 10 ||
                !re.test(String(event.target.value).toLowerCase())
              ) {
                this.props.updateToasterConfig({
                  show: true,
                  message: "please enter valid Email Id",
                  color: "#D06900",
                  classStore: []
                });
                this.handleChange(event);
              }
            }
          } catch (err) {}
        });
        setTimeout(() => {
          try {
            if (
              inputFields[i] !== undefined &&
              inputFields[i].value !== undefined &&
              inputFields[i].name !== undefined
            ) {
              inputFields[i].value = this.setValue(inputFields[i].name);
              this.executeInput(inputFields[i].name, inputFields[i].value);
            }
          } catch (err) {}
        }, 1000);
      }
      setTimeout(() => {
        if (this.state.removedCount > 0) {
          this.clearAllValueFromLeft();
        }
        this.setState({ reloadForCountStop: false });
      }, 3000);
      let selectFields = document.getElementsByTagName("select");
      for (let j = 0; j <= selectFields.length; j++) {
        this.setState({
          [selectFields[j].name]:
            selectFields[j].value === "select" ? "" : selectFields[j].value
        });
        selectFields[j].addEventListener("input", this.handleChange);
        if (
          selectFields[j].value !== undefined ||
          selectFields[j].value.length !== 0
        ) {
          setTimeout(() => {
            if (
              selectFields[j] !== undefined &&
              selectFields[j].name !== undefined &&
              selectFields[j].value !== undefined
            ) {
              this.executeSelect(selectFields[j].name, selectFields[j].value);
            }
          }, 1000);
        }
      }
    } catch (err) {}
    if (dataOfAdd !== undefined && dataOfAdd.includes("delteImage")) {
      this.deleteImageApiCall(dataOfAdd);
    }
  };
  deleteImageApiCall = e => {
    var slicedDataFromApi = e.slice(11, e.length);
    fetch(
      `${domain}/api/order/delete_upload_agreement/${
        this.state[`numId_${slicedDataFromApi}`]
      }`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
        }
      }
    )
      .then(response => response.json())
      .then(response => this.deleteResponseImage(e, response))
      .catch(error => this.errorResponse(error));
  };
  deleteResponseImage = (e, res) => {
    var slicedDataFromApi = e.slice(11, e.length);
    this.setState({ loader: false, [`numId_${slicedDataFromApi}`]: "" });
    this.addeventsMethod();
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: "Document Deleted successfully .",
        color: "#D06900"
      });
    }, 100);
  };
  setValue(name) {
    try {
      let inputName = this.state[name];
      if (inputName == undefined) {
        inputName = " ";
      } else {
        this.setState({
          preView: false
        });
        let ele = document.getElementById(name);
        ele.innerHTML = this.state[name];
        if (inputName === undefined) {
          inputName = " ";
        }
      }
      return inputName;
    } catch (err) {}
  }
  validateInput() {
    let inputFields = document.getElementsByTagName("input");
    for (let i = 0; i < inputFields.length; i++) {
      if (inputFields[i].value === "") {
        alert("fill all input field");
      }
    }
  }
  showMsg = () => {
    this.props.updateToasterConfig({
      show: true,
      message:
        "You may also fill in your details after you download this template post payment!",
      color: "#00A5E6",
      classStore: []
    });
  };
  successResponse = res => {
    this.setState(
      {
        formName: res.results[0].service_title,
        serviceId: res.results[0].service_id,
        filingCost: res.results[0].filing_cost,
        meta_title: res.results[0].meta_title,
        meta_description: res.results[0].meta_description,
        meta_keywords: res.results[0].meta_keywords,
        other_meta_tags: res.results[0].json_meta_tags
      },
      () => {
        this.state.filingCost <= 0 && setTimeout(this.showMsg, 4000);
      }
    );

    // if we have data filled in  one page then it will take from this.state[`right${counting}`] class or else from api original data.
    var pageCount = 20;
    for (var counting = 1; counting <= pageCount; counting++) {
      if (res.paginationCurrent === counting) {
        this.setState(
          {
            formData:
              this.state[`right${counting}`] !== undefined
                ? this.state[`right${counting}`]
                : res.results[0].data,
            inputs:
              this.state[`left${counting}`] !== undefined
                ? this.state[`left${counting}`]
                : res.results[0].input_content_data,
            maxpage: res.paginationTotal,
            page: res.paginationCurrent,
            wrapper: parser.parseFromString(
              this.state[`right${counting}`] !== undefined
                ? this.state[`right${counting}`]
                : res.results[0].data,
              "text/html"
            ),
            leftWrapper: parser.parseFromString(
              this.state[`left${counting}`] !== undefined
                ? this.state[`left${counting}`]
                : res.results[0].input_content_data,
              "text/html"
            )
          },
          () => {
            var allInputs = this.state.leftWrapper.getElementsByTagName(
              "input"
            );
            for (var k = 0; k <= allInputs.length; k++) {
              if (allInputs[k] !== undefined && allInputs[k].type === "date") {
                var rightDateFields = this.state.wrapper.getElementById(
                  `hideDateFromDox_${allInputs[k].name}`
                );
                if (rightDateFields !== null) {
                  if (rightDateFields.id.includes("reverse100")) {
                  } else {
                    rightDateFields.setAttribute(
                      "id",
                      `${rightDateFields.id.slice(
                        16,
                        rightDateFields.id.length
                      )}`
                    );
                  }
                }
              }
            }

            //  window.history.pushState(``, "", `${this.props.match.params.name.replace(/[0-9]/g, '')}${this.state.page}`);
            this.setState({
              formData: this.state.wrapper.documentElement.innerHTML,
              righthandHtml: this.state.leftWrapper.documentElement.innerHTML,
              loader: false
            });
            this.alignValuesForRight();
          }
        );
        let inputFields = document.getElementsByTagName("input");
        for (let i = 0; i < inputFields.length; i++) {
          inputFields[i].addEventListener("input", this.handleChange);
        }
        this.addeventsMethod();
        window.scrollTo(0, 0);
      }
    }
  };
  errorResponse = error => {
    this.setState({
      loader: false
    });
  };
  scrollIfNeeded = (element, container) => {
    if (!element) {
      return false;
    } else {
      if (element.id.includes("tableData")) {
        if (element.offsetTop < container.scrollTop) {
          container.scrollTop = element.closest("tr").offsetTop;
        } else {
          const offsetBottom =
            element.closest("tr").offsetTop +
            element.closest("tr").offsetHeight;
          const scrollBottom = container.scrollTop + container.offsetHeight;
          if (offsetBottom > scrollBottom) {
            container.scrollTop = offsetBottom - container.offsetHeight;
          }
        }
      } else if (element.id.includes("SmallTable")) {
        container.scrollTop =
          window.innerHeight +
          element.closest("tr").offsetHeight -
          element.closest("tr").offsetHeight / 2 -
          100;
      } else {
        if (element.offsetTop < container.scrollTop) {
          container.scrollTop = element.offsetTop;
        } else {
          const offsetBottom = element.offsetTop + element.offsetHeight;
          const scrollBottom = container.scrollTop + container.offsetHeight;
          if (offsetBottom > scrollBottom) {
            container.scrollTop = offsetBottom - container.offsetHeight;
          }
        }
      }
    }
  };
  KeyDownHandler = event => {
    let targetBgElement = document.getElementById(event.target.name);
    if (targetBgElement) {
      targetBgElement.style.background = "yellow";
      targetBgElement.style.minWidth = "65px";
    }
  };
  BlurHandler = event => {
    let targetRElement = document.getElementById(event.target.name);
    if (targetRElement) {
      setTimeout(function() {
        targetRElement.style.removeProperty("background");
        targetRElement.style.removeProperty("minWidth");
      }, 100);
    }
  };
  uploadDocs(e, newdata, nameOfStatE) {
    newdata.append("agreement", this.state.agreement_id);
    newdata.append("extra_data", this.state.page);
    axios
      .post(domain + "/api/order/upload_agreement/", newdata, {
        headers: {
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
        }
      })
      .then(response => {
        this.setState(
          {
            [nameOfStatE]: response.data.uploaded_doc,
            [`numId_${nameOfStatE}`]: response.data.id,
            agreement_id: response.data.agreement
          },
          () => {}
        );
        var image = this.state.wrapper.getElementById(`${nameOfStatE}`);
        if (nameOfStatE.includes("file100")) {
        } else {
          image.setAttribute("src", response.data.uploaded_doc);
          image.innerHTML = "";
          image.setAttribute("value", response.data.uploaded_doc);
          image.setAttribute("style", "width:200px");
        }
        this.setState({
          formData: this.state.wrapper.documentElement.innerHTML,
          loader: false
        });
        this.addeventsMethod(e);
        setTimeout(() => {
          this.props.updateToasterConfig({
            show: true,
            message: "Document added successfully.",
            color: "#D06900"
          });
        }, 3000);
      })
      .catch(error => this.errorResponse(error));
  }
  error(error) {
    this.props.updateToasterConfig({
      show: true,
      message: "Something went wrong please update image once again.",
      color: "#D06900"
    });
  }
  handleChange = evt => {
    evt.preventDefault();
    let scrollDiv = document.getElementById("right-side");
    let elmnt = " ";
    elmnt = document.getElementById(evt.target.name);
    if (evt.target.type == "date") {
      var d = new Date(evt.target.value);
      var year = d.getFullYear();
      elmnt = document.getElementById("reverse100_" + evt.target.name);
      if (elmnt == null) {
        elmnt = document.getElementById(evt.target.name);
      }
    }
    if (elmnt) {
      if (evt.target.type == "file") {
        elmnt = elmnt.parentElement;
      }
      this.scrollIfNeeded(elmnt, scrollDiv);
      elmnt.style.background = "yellow";
    }
    if (elmnt) {
      setTimeout(function() {
        elmnt.style.removeProperty("background");
      }, 800);
    }
    if (evt.target.tagName === "SELECT") {
      var leftData = this.state.leftWrapper;
      var rightData = this.state.wrapper;
      if (evt.target.value === "individual") {
        var leftDivData = leftData.getElementsByName(
          `showSelect_${evt.target.value}`
        );
        var ChildDataDiv = rightData.getElementById(
          `showSelect_${evt.target.value}`
        );
        var leftDivDataCOR = leftData.getElementsByName(`showSelect_corporate`);
        var ChildDataDivCOR = rightData.getElementById(`showSelect_corporate`);
        leftDivData[0].setAttribute("style", "display:block");
        ChildDataDiv.setAttribute("style", "display:block");
        leftDivDataCOR[0].setAttribute("style", "display:none");
        ChildDataDivCOR.setAttribute("style", "display:none");
        this.setState({ formData: rightData.documentElement.innerHTML });
      } else if (evt.target.value === "corporate") {
        var leftDivData = leftData.getElementsByName(
          `showSelect_${evt.target.value}`
        );
        var ChildDataDiv = rightData.getElementById(
          `showSelect_${evt.target.value}`
        );
        var leftDivDataCOR = leftData.getElementsByName(
          `showSelect_individual`
        );
        var ChildDataDivCOR = rightData.getElementById(`showSelect_individual`);
        leftDivData[0].setAttribute("style", "display:block");
        ChildDataDiv.setAttribute("style", "display:block");
        leftDivDataCOR[0].setAttribute("style", "display:none");
        ChildDataDivCOR.setAttribute("style", "display:none");
        this.setState({ formData: rightData.documentElement.innerHTML });
      } else {
        var empltyvalue = "";
        if (evt.target.value.includes("select")) {
          this.setState({ [evt.target.name]: "" });
          var inputfields = document.getElementsByTagName("input");
          for (let i = 0; i < inputfields.length; i++) {
            if (
              inputfields[i].name.slice(9, inputfields[i].name.length) ===
              evt.target.name
            ) {
              if (inputfields[i].name.includes("samename")) {
                inputfields[i].addEventListener(
                  "input",
                  this.setState({
                    [inputfields[i].name]: ""
                  })
                );
                inputfields[i].value = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              }
            }
          }
        } else if (evt.target.value.includes("Select")) {
          this.setState({ [evt.target.name]: "" });
          var inputfields = document.getElementsByTagName("input");
          for (let i = 0; i < inputfields.length; i++) {
            if (
              inputfields[i].name.slice(9, inputfields[i].name.length) ===
              evt.target.name
            ) {
              if (inputfields[i].name.includes("samename")) {
                inputfields[i].addEventListener(
                  "input",
                  this.setState({
                    [inputfields[i].name]: ""
                  })
                );
                inputfields[i].value = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              }
            }
          }
        } else {
          this.setState({
            [evt.target.name]: evt.target.value
          });
          var inputfields = document.getElementsByTagName("input");
          for (let i = 0; i < inputfields.length; i++) {
            if (
              inputfields[i].name.slice(9, inputfields[i].name.length) ===
              evt.target.name
            ) {
              if (inputfields[i].name.includes("samename")) {
                inputfields[i].addEventListener(
                  "input",
                  this.setState({
                    [inputfields[i].name]: evt.target.value
                  })
                );
                inputfields[i].value = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              }
            }
          }
        }
      }
    }
    if (evt.target.type == "date") {
      var d = new Date(evt.target.value);
      var year = d.getFullYear();
      elmnt = document.getElementById("reverse100_" + evt.target.name);
      if (elmnt == null) {
        elmnt = document.getElementById(evt.target.name);
      }
    }

    if (evt.target.type === "file") {
      if((evt.target.name).indexOf("imginsert") !== -1){
        let imgUrl = (evt.target.value).split(".");
        if( imgUrl[1].toUpperCase() === "JPG" || imgUrl[1].toUpperCase() === "PNG" ){
          try {
            const formData = new FormData();
            var leftData = this.state.leftWrapper;
            var rightData = this.state.wrapper;
            formData.append(
              "uploaded_doc",
              evt.target.files[0],
              evt.target.files[0].name
            );
            var inputField = evt.target;
            var nameOfTheInputField = evt.target.name;
            var storeLeftOfDeleteButton = `<button name="delete_file" classname="delete_file">Delete</button>`;
            var storeRightOfImage = `<button name="crossFile" classname="crossFile" id="crossFile" style="padding:5px 5px !important;text-align: center;position:absolute;top:0px;right:0px;margin-right:0px !important;margin-top:0px !important">x</button>`;
            var leftButtonTraget = leftData.getElementsByName(
              nameOfTheInputField
            )[0];
            if (evt.target.name.includes("file100")) {
            } else {
              var rightImageTraget = rightData.getElementById(
                `${nameOfTheInputField}`
              );
              rightImageTraget.innerHTML = "";
              var closestrightTagForAppend =
                evt.target.parentElement.parentElement.parentElement
                  .childElementCount > 1
                  ? rightImageTraget.closest("div")
                  : rightImageTraget.closest("div");
              closestrightTagForAppend.insertAdjacentHTML(
                "beforeend",
                storeRightOfImage
              );
            }
            leftButtonTraget.setAttribute("style", "display:none");
            var closestleftTagForAppend = leftButtonTraget.closest("div");
            closestleftTagForAppend.insertAdjacentHTML(
              "beforeend",
              storeLeftOfDeleteButton
            );
            var newLeftContent = this.state.leftWrapper.getElementsByName(
              evt.target.name
            )[0];
            newLeftContent.insertAdjacentHTML(
              "afterend",
              `<div name="addPngText">${evt.target.files[0].name}</div>`
            );
            this.setState({
              heightOfPage:
                window.innerHeight +
                evt.target.parentElement.parentElement.offsetHeight +
                200,
              formData: this.state.wrapper.documentElement.innerHTML,
              inputs: this.state.leftWrapper.documentElement.innerHTML
            });
            this.uploadDocs(evt, formData, evt.target.name);
            this.setState(
              {
                formData: rightData.documentElement.innerHTML,
                inputs: leftData.documentElement.innerHTML
              },
              () => {
                this.setState({ loader: true });
              }
            );
          } catch (err) {}
        }else{
          this.signatureError()
        }
      
      }else{
        try {
          const formData = new FormData();
          var leftData = this.state.leftWrapper;
          var rightData = this.state.wrapper;
          formData.append(
            "uploaded_doc",
            evt.target.files[0],
            evt.target.files[0].name
          );
          var inputField = evt.target;
          var nameOfTheInputField = evt.target.name;
          var storeLeftOfDeleteButton = `<button name="delete_file" classname="delete_file">Delete</button>`;
          var storeRightOfImage = `<button name="crossFile" classname="crossFile" id="crossFile" style="padding:5px 5px !important;text-align: center;position:absolute;top:0px;right:0px;margin-right:0px !important;margin-top:0px !important">x</button>`;
          var leftButtonTraget = leftData.getElementsByName(
            nameOfTheInputField
          )[0];
          if (evt.target.name.includes("file100")) {
          } else {
            var rightImageTraget = rightData.getElementById(
              `${nameOfTheInputField}`
            );
            rightImageTraget.innerHTML = "";
            var closestrightTagForAppend =
              evt.target.parentElement.parentElement.parentElement
                .childElementCount > 1
                ? rightImageTraget.closest("div")
                : rightImageTraget.closest("div");
            closestrightTagForAppend.insertAdjacentHTML(
              "beforeend",
              storeRightOfImage
            );
          }
          leftButtonTraget.setAttribute("style", "display:none");
          var closestleftTagForAppend = leftButtonTraget.closest("div");
          closestleftTagForAppend.insertAdjacentHTML(
            "beforeend",
            storeLeftOfDeleteButton
          );
          var newLeftContent = this.state.leftWrapper.getElementsByName(
            evt.target.name
          )[0];
          newLeftContent.insertAdjacentHTML(
            "afterend",
            `<div name="addPngText">${evt.target.files[0].name}</div>`
          );
          this.setState({
            heightOfPage:
              window.innerHeight +
              evt.target.parentElement.parentElement.offsetHeight +
              200,
            formData: this.state.wrapper.documentElement.innerHTML,
            inputs: this.state.leftWrapper.documentElement.innerHTML
          });
          this.uploadDocs(evt, formData, evt.target.name);
          this.setState(
            {
              formData: rightData.documentElement.innerHTML,
              inputs: leftData.documentElement.innerHTML
            },
            () => {
              this.setState({ loader: true });
            }
          );
        } catch (err) {}
      }
      
    }
    try {
      for (var loopOfgetPost = 0; loopOfgetPost < 10; loopOfgetPost++) {
        if (
          evt.target.name.includes(`post${loopOfgetPost}00`) ||
          evt.target.name.includes(`get${loopOfgetPost}00`)
        ) {
          var inputfields = document.getElementsByTagName("input");
          var postDatavar =
            evt.target.name.includes(`post${loopOfgetPost}00`) &&
            evt.target.name.slice(8, evt.target.name.length);
          var getDatavar =
            evt.target.name.includes(`get${loopOfgetPost}00`) &&
            evt.target.name.slice(7, evt.target.name.length);
          for (let i = 0; i <= inputfields.length; i++) {
            if (inputfields[i].name.includes(postDatavar)) {
              inputfields[i].addEventListener(
                "input",
                this.setState({
                  [inputfields[i].name]: evt.target.value,
                  [postDatavar]: evt.target.value
                })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
            if (inputfields[i].name.includes(getDatavar)) {
              inputfields[i].addEventListener(
                "input",
                this.setState({
                  [inputfields[i].name]: evt.target.value,
                  [getDatavar]: evt.target.value
                })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
          }
        }
      }

      var newaddData = evt.target.value;

      if (evt.target.type === "text") {
        var aplhaNumericValue = evt.target
          .getAttribute("classname")
          .search("alphaNumeric");
        if (evt.target.name.includes("Price100")) {
        } else {
          if (aplhaNumericValue > 1) {
            this.setState({
              [evt.target.name]: newaddData
            });
          } else {
            this.setState({
              [evt.target.name]: newaddData
            });
          }
        }
        this.setState({
          [evt.target.name]: newaddData
        });
      }
      if (evt.target.name.includes("loadData")) {
        this.addeventsMethod(evt);
      }
      if (evt.target.type === "checkbox") {
        var rightData = this.state.wrapper;
        var leftData = this.state.leftWrapper;
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i <= inputfields.length; i++) {
          if (
            inputfields[i].type === "checkbox" &&
            inputfields[i].name === evt.target.name
          ) {
            var leftEle = leftData.getElementsByName(`${inputfields[i].name}`);

            var elementOfRight = rightData.getElementById(
              `hideDataOfCheckbox_${inputfields[i].name}`
            );

            if (elementOfRight === null) {
              if (inputfields[i].checked !== false) {
                leftEle[0].setAttribute("checked", true);
                inputfields[i].addEventListener(
                  "input",
                  this.setState({ [inputfields[i].name]: inputfields[i].name })
                );
                inputfields[i].name = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              } else {
                leftEle[0].removeAttribute("checked");
                inputfields[i].addEventListener(
                  "input",
                  this.setState({ [inputfields[i].name]: "" })
                );
                inputfields[i].value = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              }
            }
            if (elementOfRight.tagName === "SPAN") {
              if (inputfields[i].checked !== false) {
                rightData
                  .getElementById(`hideDataOfCheckbox_${inputfields[i].name}`)
                  .setAttribute("style", "display: flex;flex-direction: row");

                inputfields[i].addEventListener(
                  "input",
                  this.setState({ [inputfields[i].name]: inputfields[i].name })
                );
                inputfields[i].name = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              } else {
                rightData
                  .getElementById(`hideDataOfCheckbox_${inputfields[i].name}`)
                  .setAttribute("style", "display:none");

                inputfields[i].addEventListener(
                  "input",
                  this.setState({ [inputfields[i].name]: "" })
                );
                inputfields[i].value = this.setValue(inputfields[i].name);
                let ele1 = document.getElementById(inputfields[i].name);
                ele1.innerHTML = this.setValue(inputfields[i].name);
              }
              this.setState(
                {
                  formData: rightData.documentElement.innerHTML,
                  inputs: leftData.documentElement.innerHTML,
                  heightOfPage:
                    window.innerHeight +
                    evt.target.parentElement.parentElement.offsetHeight +
                    1000
                },
                () => {
                  // console.log(this.state.heightOfPage);
                  let scrollDivright = document.getElementById("right-side");
                  if (scrollDivright !== null) {
                    scrollDivright.scrollTop = this.state.heightOfPage;
                  }
                }
              );
            }
            this.addeventsMethod();
          }
        }
        this.addeventsMethod();
      }
      if (evt.target.name.includes("samename")) {
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i < inputfields.length; i++) {
          if (inputfields[i].name.includes("samename")) {
            inputfields[i].addEventListener(
              "input",
              this.setState({ [inputfields[i].name]: evt.target.value })
            );
            inputfields[i].value = this.setValue(inputfields[i].name);
            let ele1 = document.getElementById(inputfields[i].name);
            ele1.innerHTML = this.setValue(inputfields[i].name);
          }
        }
      }
      if (evt.target.name.includes("extraname")) {
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i < inputfields.length; i++) {
          if (inputfields[i].name.includes("extraname")) {
            inputfields[i].addEventListener(
              "input",
              this.setState({
                [inputfields[i].name]: evt.target.value
              })
            );
            inputfields[i].value = this.setValue(inputfields[i].name);
            let ele1 = document.getElementById(inputfields[i].name);
            ele1.innerHTML = this.setValue(inputfields[i].name);
          }
        }
      }
      if (evt.target.name.includes("supername")) {
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i < inputfields.length; i++) {
          if (inputfields[i].name.includes("supername")) {
            inputfields[i].addEventListener(
              "input",
              this.setState({
                [inputfields[i].name]: evt.target.value
              })
            );
            inputfields[i].value = this.setValue(inputfields[i].name);
            let ele1 = document.getElementById(inputfields[i].name);
            ele1.innerHTML = this.setValue(inputfields[i].name);
          }
        }
      }
      if (evt.target.type === "date") {
        var d = new Date(evt.target.value);
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var newaddData = evt.target.value;
        var rightDate = `${day < 9 ? `0${day}` : day}-${
          month < 9 ? `0${parseInt(month + 1)}` : parseInt(month + 1)
        }-${year}`;
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i < inputfields.length; i++) {
          if (
            inputfields[i].name.includes("reverse100") &&
            inputfields[i].name.includes(evt.target.name)
          ) {
            inputfields[i].addEventListener(
              "input",
              this.setState({ [inputfields[i].name]: rightDate })
            );
            inputfields[i].value = this.setValue(inputfields[i].name);
            let ele1 = document.getElementById(inputfields[i].name);
            ele1.innerHTML = this.setValue(inputfields[i].name);
          }
        }
      } else if (evt.target.name.includes("Price100")) {
        let value = numberWithCommas(evt.target.value);
        let words = currencyConvertor(value);
        var selectedPriceClass = evt.target.name;
        var inputfields = document.getElementsByTagName("input");
        for (let i = 0; i < inputfields.length; i++) {
          if (inputfields[i].name.includes("amountInWords")) {
            var slicedClass = inputfields[i].name.slice(
              14,
              inputfields[i].name.length
            );
            if (selectedPriceClass.includes(slicedClass)) {
              inputfields[i].addEventListener(
                "input",
                this.setState({ [inputfields[i].name]: words })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
          }
        }
      }
      let ele = document.getElementById(evt.target.name);
      var newid = ele.parentNode.getAttribute("id");
      var ParentEleClass =
        newid === null || newid === undefined
          ? evt.target.parentElement.parentElement.children[1].getAttribute(
              evt.target.name
            )
          : newid;
      if (!ele) {
        return false;
      }
      ele.innerHTML = newaddData;
      if (evt.target.type === "date") {
        var input = evt.target.value;
        var selectDate = evt.target.name;
        this.setState({
          [evt.target.name]: newaddData
        });
        var d = new Date(input);
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var j = day % 10;
        var k = day % 100;
        var finaldateDay = day;
        if (j === 1 && k !== 11) {
          finaldateDay = day + "st";
        } else if (j === 2 && k !== 12) {
          finaldateDay = day + "nd";
        } else if (j === 3 && k !== 13) {
          finaldateDay = day + "rd";
        } else {
          finaldateDay = day + "th";
        }
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        var dateClassname = evt.target.parentElement.children[1].getAttribute(
          "classname"
        );
        if (dateClassname.includes("dateGroup")) {
          var inputfields = document.getElementsByTagName("input");
          for (let i = 0; i < inputfields.length; i++) {
            if (inputfields[i].name === "yearOf") {
              inputfields[i].addEventListener(
                "input",
                this.setState({ yearOf: year })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
            if (inputfields[i].name === "monthOf") {
              inputfields[i].addEventListener(
                "input",
                this.setState({ monthOf: months[month] })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
            if (inputfields[i].name === "dateOf") {
              inputfields[i].addEventListener(
                "input",
                this.setState({ dateOf: finaldateDay })
              );
              inputfields[i].value = this.setValue(inputfields[i].name);
              let ele1 = document.getElementById(inputfields[i].name);
              ele1.innerHTML = this.setValue(inputfields[i].name);
            }
          }
        }
      }
      if (evt.target.type === "number") {
        this.setState({
          [evt.target.name]: newaddData
        });
      }
      if (evt.target.type === "url") {
        this.setState({
          [evt.target.name]: newaddData
        });
      }
      if (evt.target.type === "tel") {
        this.setState({
          [evt.target.name]: newaddData
        });
      }
      if (evt.target.type === "time") {
        this.setState({
          [evt.target.name]: newaddData
        });
      }
      if (evt.target.type === "email") {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({
          [evt.target.name]: newaddData
        });
      }
    } catch (err) {}
  };
  submitForm = () => {
    let StateData = {};
    Object.assign(StateData, this.state);
    delete StateData.page;
    delete StateData.maxpage;
    delete StateData.agreement_id;
    delete StateData.preView;
    delete StateData.error;
    delete StateData.formName;
    delete StateData.countOfInput;
    delete StateData.stopNext;
    delete StateData.totalTestatorCount;
    delete StateData.storenameClass;
    delete StateData.storeAddressclass;
    delete StateData.textclassName;
    delete StateData.textclassAddress;
    delete StateData.datatobackend;
    delete StateData.finalPage;
    delete StateData.jointName;
    delete StateData.jointAddress;
    delete StateData.stepUniqueOne;
    delete StateData.removedCount;
    delete StateData.righthandHtml;
    delete StateData.prvious;
    for (var count = 0; count <= 20; count++) {
      if (count > this.state.maxpage) {
        delete StateData[`left${count}`];
        delete StateData[`right${count}`];
      }
    }
    var dataObjectToBackend = {
      leftside: StateData,
      rightside: this.state.righthandHtml
    };
    let token = getCookie("token");

    fetch(domain + "/api/order/agreement-create/", {
      method: "POST",
      headers:
        token !== undefined && token !== null && token.length > 0
          ? {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
              Authorization: `Token ${token}`
            }
          : {
              "Content-Type": "application/json",
              "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
            },
      body: JSON.stringify({
        agreement_id: this.state.agreement_id,
        purchasedService: this.state.serviceId,
        page_no: JSON.stringify(this.state.page),
        field_data: JSON.stringify(dataObjectToBackend),
        entireState: JSON.stringify(StateData)
      })
    })
      .then(response => response.json())
      .then(responsejson => this.formSuccessResponse(responsejson))
      .catch(error => this.errorResponse(error));
  };
  errorResponse = err => {
    console.log(err);
      this.setState(
        {
          errorCode: true,
          valueOfPage: 0
        },
        () => {
          this.excuteFinalMethod();
        }
      );
};
  formSuccessResponse = response => {
    if (response.code === "not_created") {
      this.setState(
        {
          errorCode: true,
          valueOfPage: 0
        },
        () => {
          this.excuteFinalMethod();
        }
      );
    } else if (response.code === "previous_not_saved") {
      this.setState(
        {
          errorCode: true,
          valueOfPage: this.state.page - 1
        },
        () => {
          this.excuteFinalMethod();
        }
      );
    } else if (response.code === "page_not_saved") {
      this.setState(
        {
          errorCode: true,
          valueOfPage: this.state.page
        },
        () => {
          this.excuteFinalMethod();
        }
      );
    } else {
      this.setState(
        {
          agreement_id: response.agreement_id,
          errorCode: false,
          valueOfPage: 0
        },
        () => {
          this.excuteFinalMethod();
        }
      );

      if (
        response.detail === "Invalid token header. No credentials provided."
      ) {
        this.props.history.push({
          pathname: "/signin",
          state: {
            redirect: "/paymentDetails",
            agreement_id: response.agreement_id
          }
        });
      }

      if (response.status === "Completed") {
        setCookie("sub-purchase", "No");
        setCookie("order_amount", response.amount);
        setCookie("order_number", response.order_number);
        setCookie("docs_name", response.purchasedService);
        setCookie("ref_id", response.agreement_id);
        setCookie("filing_cost", response.filing_cost);
        setCookie("filing_cost_text", response.filing_cost_text);
        setCookie("lanidigital_cost", response.lanidigital_cost);
      }
      if (response.status === "Completed") {
        !getCookie("userId")
          ? this.props.history.push({
              pathname: "/signin",
              state: {
                redirect: "/paymentDetails/?order=" + response.order_number,
                agreement_id: response.agreement_id
              }
            })
          : this.props.history.push({
              pathname: "/paymentDetails/?order=" + response.order_number,
              state: {
                order_number: response.order_number,
                order_amount: response.amount,
                agreement_id: response.agreement_id,
                purchasedService_id: this.state.serviceId,
                serviceName: response.purchasedService,
                lanidigital_cost: response.lanidigital_cost
              }
            });
      }
    }
  };
  executeNext = () => {
    this.setState({ stopNext: true, executeOnce: false });
    if (!this.state.stopNext) {
      setCookie("subscriptionData", false);
      setCookie("user_subscription", null);
      var rightData = this.state.wrapper;
      var leftData = this.state.leftWrapper;
      this.setState({
        formData: rightData.documentElement.innerHTML,
        inputs: leftData.documentElement.innerHTML
      });
      var countInNext = 20;
      var allInputs = leftData.getElementsByTagName("input");
      for (var k = 0; k <= allInputs.length; k++) {
        if (allInputs[k] !== undefined && allInputs[k].type === "date") {
          var rightDateFields = rightData.getElementById(allInputs[k].name);
          if (rightDateFields !== null) {
            if (rightDateFields.id.includes("reverse100")) {
            } else {
              rightDateFields.setAttribute(
                "id",
                `hideDateFromDox_${rightDateFields.id}`
              );
            }
          }
        }
      }
      this.setState({
        formData: rightData.documentElement.innerHTML,
        righthandHtml: rightData.documentElement.innerHTML
      });
      for (var nextCount = 1; nextCount <= countInNext; nextCount++) {
        if (this.state.page === nextCount) {
          this.setState(
            {
              [`left${nextCount}`]: leftData.documentElement.innerHTML,
              [`right${nextCount}`]: rightData.documentElement.innerHTML
            },
            () => {
              this.submitForm();
            }
          );
        }
      }
    }
  };
  excuteFinalMethod = () => {
    if (this.state.maxpage > this.state.page) {
      this.setState(
        {
          page: this.state.errorCode
            ? this.state.valueOfPage
            : this.state.page + 1,
          prvious: this.state.errorCode ? true : false
        },
        () => {
          this.getData();
        }
      );
    }
    this.setState({
      loader: false
    });
    // this.submitForm();
  };
  handleClickNext = () => {
    this.setState({ stopNext: false, loader: true });
    for (var obj in this.state) {
      if (typeof this.state[obj] === "object" && this.state[obj] !== null) {
        if (this.state[obj].valid === false) {
          this.setState({ stopNext: true });
        }
      }
    }
  };
  handleClickPrev = () => {
    var rightData = this.state.wrapper;
    var leftData = this.state.leftWrapper;
    var allInputs = leftData.getElementsByTagName("input");
    for (var k = 0; k <= allInputs.length; k++) {
      if (allInputs[k] !== undefined && allInputs[k].type === "date") {
        var rightDateFields = rightData.getElementById(
          `hideDateFromDox_${allInputs[k].name}`
        );
        if (rightDateFields !== null) {
          if (rightDateFields.id.includes("reverse100")) {
          } else {
            rightDateFields.setAttribute(
              "id",
              `${rightDateFields.id.slice(16, rightDateFields.id.length)}`
            );
          }
        }
      }
    }
    this.setState({
      formData: rightData.documentElement.innerHTML,
      righthandHtml: rightData.documentElement.innerHTML
    });
    var countInNext = 20;
    for (var nextCount = 1; nextCount <= countInNext; nextCount++) {
      if (this.state.page === nextCount) {
        this.setState({
          [`left${nextCount}`]: leftData.documentElement.innerHTML,
          [`right${nextCount}`]: rightData.documentElement.innerHTML
        });
      }
    }
    this.setState(
      {
        loader: this.state.page > 4 ? false : true,
        page: this.state.page - 1,
        preView: true,
        prvious: true,
        totalTestatorCount: this.state.totalTestatorCount,
        executeOnce: false
      },
      () => {
        this.getDataPrev();
      }
    );
  };
  handleSubscriptionCheck = () => {
    let token = getCookie("token");
    fetch(
      `${domain}/api/order/subscription-check/?service=${this.state.serviceId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      }
    )
      .then(response => response.json())
      .then(response => this.formSuccessResponseService(response))
      .catch(error => this.errorResponse(error));
  };
  errorResponse = err => {
    console.log(err);
  };
  formSuccessResponseService = res => {
    this.props.updateToasterConfig({
      show: true,
      message: res.message,
      color: "#D06900",
      classStore: []
    });
    setCookie("sub-clicked", "true");
    setCookie("discount", res.discount);
    setCookie("msg", res.message);
    setCookie("id_of_service_usage", res.id_of_service_usage);
    setCookie("template_type", res.template_type);
    this.handleClickNext();
    // this.props.history.push({
    //   pathname: "/paymentDetails"})
  };

  clickrightHandler = e => {
    try {
      var rightData = this.state.wrapper;
      var leftData = this.state.leftWrapper;
      if (e.target.id === "crossFile") {
        var rightDataToDelete =
          e.target.parentElement.children[0].getAttribute("id") === null
            ? e.target.parentElement.children[1].getAttribute("id")
            : e.target.parentElement.children[0].getAttribute("id");
        var spanToDelete = leftData.getElementsByName(rightDataToDelete)[0];
        spanToDelete.setAttribute("style", "display:block");
        var leftDlete = spanToDelete.parentElement.children[2];
        var divToDelete = rightData.getElementById(rightDataToDelete);
        spanToDelete.addEventListener(
          "input",
          this.setState({ [spanToDelete.name]: "" })
        );
        var contentData =
          spanToDelete.parentElement.childElementCount === 3
            ? spanToDelete.parentElement.parentElement.lastElementChild
                .children[1]
            : spanToDelete.parentElement.lastElementChild;
        contentData.remove();
        var crossMarkOfImage =
          divToDelete.parentElement.children[1].getAttribute("id") ===
          "crossFile"
            ? divToDelete.parentElement.children[1]
            : divToDelete.parentElement.children[2];
        crossMarkOfImage.remove();
        contentData.remove();
        divToDelete.removeAttribute("src");
        leftDlete.remove();
        divToDelete.innerHTML = "";
        divToDelete.value = "";
        this.setState({
          heightOfPage:
            window.innerHeight +
            e.target.parentElement.parentElement.offsetHeight +
            200,
          formData: rightData.documentElement.innerHTML,
          inputs: leftData.documentElement.innerHTML
        });
        setTimeout(() => {
          this.addeventsMethod(e, `delteImage_${rightDataToDelete}`);
        }, 100);
        setTimeout(() => {
          this.setState({ loader: true });
        }, 200);
      }
    } catch (err) {}
  };
  clickHandler = e => {
    var rightData = this.state.wrapper;
    var leftData = this.state.leftWrapper;
    this.setState({
      removedCount: 0,
      spanToMatch: "",
      updatedDiv: true,
      executeOnlyOnceFromLeft: false
    });
    if (this.state.removedCount !== 0) {
    } else {
      try {
        var ChildData = rightData.getElementById(
          e.target.parentElement.children[0].children[0].children[0].children[1].firstElementChild.getAttribute(
            "classname"
          )
        );
        if (
          this.state.page === 4 &&
          ChildData.getAttribute("id").slice(0, 11) === "fullnameOne_rep99"
        ) {
          var newChild = ChildData.closest("div");
        } else {
          var newChild =
            ChildData.closest("p").closest("li") === null
              ? ChildData.closest("p").closest("div")
              : ChildData.closest("p").closest("li");
        }
        if (
          e.target.parentNode.children[0].children[0].childElementCount >= 2
        ) {
          var newChild = ChildData.closest("div");
        } else {
          var newChild =
            ChildData.closest("p").closest("li") === null
              ? ChildData.closest("p").closest("div")
              : ChildData.closest("p").closest("li");
        }
      } catch (err) {}
      var data = null;
      var idValue = uuidv4();
      try {
        data =
          e.target.parentNode.children[0].childElementCount +
          idValue.slice(0, 3);
        if (e.target.name === "delete_file") {
          var rightDataToDelete =
            e.target.parentElement.parentElement.parentElement
              .childElementCount > 1
              ? e.target.parentElement.children[0].getAttribute("classname")
              : e.target.parentElement.children[1].getAttribute("classname");
          var leftDataToDelete = e.target.getAttribute("name");
          if (rightDataToDelete.includes("file100")) {
          } else {
            var divToDelete = rightData.getElementById(`${rightDataToDelete}`);
            var crossMarkOfImage =
              divToDelete.parentElement.children[1].getAttribute("id") ===
              "crossFile"
                ? divToDelete.parentElement.children[1]
                : divToDelete.parentElement.children[2];
            crossMarkOfImage.remove();
            divToDelete.removeAttribute("src");
            divToDelete.innerHTML = "";
            divToDelete.value = "";
          }
          var spanToDelete = leftData.getElementsByName(rightDataToDelete)[0];
          var contentData =
            spanToDelete.parentElement.childElementCount === 3
              ? spanToDelete.parentElement.parentElement.lastElementChild
                  .children[1]
              : spanToDelete.parentElement.lastElementChild;
          contentData.remove();
          spanToDelete.setAttribute("style", "display:block");
          var removeProperDelete =
            spanToDelete.parentElement.childElementCount === 3
              ? spanToDelete.parentElement.children[2]
              : spanToDelete.parentElement.lastElementChild;
          removeProperDelete.remove();
          this.setState({
            heightOfPage:
              window.innerHeight +
              e.target.parentElement.parentElement.offsetHeight +
              200,
            formData: rightData.documentElement.innerHTML,
            inputs: leftData.documentElement.innerHTML
          });
          this.addeventsMethod(e, `delteImage_${rightDataToDelete}`);
          setTimeout(() => {
            this.setState({ loader: true });
            spanToDelete.addEventListener(
              "input",
              this.setState({ [spanToDelete.name]: "" })
            );
          }, 200);
        }
        if (e.target.tagName === "BUTTON") {
          setTimeout(() => {
            if (this.state.page > 0) {
              this.addeventsMethod();
            }
          }, 1000);
          var lastcode = e.target.parentElement.getAttribute("classname");
          if (leftData.body.children[0].childElementCount >= 1) {
            for (
              var j = 0;
              j < leftData.body.children[0].childElementCount;
              j++
            ) {
              if (
                leftData.body.children[0].children[j].getAttribute(
                  "classname"
                ) === lastcode
              ) {
                var repaetitClass =
                  leftData.body.children[0].children[j].children[0];
              }
            }
          }
          var appendedDiv = e.target.parentElement.children[0];
          var newClassForDate = leftData.body.children[0];
          var parentDivClass = e.target.parentElement.children[0].children[0].getAttribute(
            "classname"
          );
          let el = e.target.getAttribute("classname");
          if (el === "removeDiv") {
            this.setState({ executeOnlyOnceFromLeft: true });
            var regex = /\d+/g;
            if (appendedDiv.parentNode.childElementCount >= 3) {
              var selectedTextBox = appendedDiv.parentNode.getAttribute(
                "classname"
              );
              this.setState({
                spanToMatch: e.target.parentElement.parentElement.getAttribute(
                  "classname"
                ),
                removedCount: regex.exec(
                  appendedDiv.children[0].innerHTML.slice(
                    appendedDiv.children[0].innerHTML.length - 10,
                    appendedDiv.children[0].innerHTML.length
                  )
                )
              });

              var spanToDelete = leftData.getElementsByName(selectedTextBox)[0];
              var divToDelete = rightData.getElementById(selectedTextBox);
              spanToDelete.remove();
              divToDelete.remove();

              this.addeventsMethod(e);
            } else if (appendedDiv.parentNode.childElementCount === 3) {
              var selectedTextBox = appendedDiv.parentNode.getAttribute(
                "classname"
              );
              this.setState({
                spanToMatch: e.target.parentElement.parentElement.getAttribute(
                  "classname"
                ),
                removedCount: regex.exec(
                  appendedDiv.children[0].innerHTML.slice(
                    appendedDiv.children[0].innerHTML.length - 10,
                    appendedDiv.children[0].innerHTML.length
                  )
                )
              });
              if (
                leftData.getElementsByName(selectedTextBox)[0] === undefined
              ) {
                var spanToDelete = document.getElementsByName(
                  selectedTextBox
                )[0];
                this.setState({ reloadForCountStop: true });
              } else {
                var spanToDelete = leftData.getElementsByName(
                  selectedTextBox
                )[0];
                this.setState({ reloadForCountStop: false });
              }
              var divToDelete = rightData.getElementById(selectedTextBox);
              spanToDelete.remove();
              divToDelete.remove();
              this.addeventsMethod(e);
            } else {
              var selectedTextBox = appendedDiv.children[1].firstElementChild.getAttribute(
                "classname"
              );
              var selectedTextBoxTwo = appendedDiv.parentNode.getAttribute(
                "classname"
              );
              this.setState({
                reloadData: true,
                spanToMatch: e.target.parentElement.parentElement.getAttribute(
                  "classname"
                ),
                removedCount: regex.exec(
                  appendedDiv.children[0].innerHTML.slice(
                    appendedDiv.children[0].innerHTML.length - 10,
                    appendedDiv.children[0].innerHTML.length
                  )
                )
              });
              var divToDelete = rightData.getElementById(selectedTextBoxTwo);
              if (e.target.name.includes("to100")) {
                var toExtra = rightData.getElementById("toClass");
                toExtra.remove();
              }
              var spanToDelete = leftData.getElementsByName(selectedTextBox)[0]
                .parentElement.parentElement.parentElement;
              var divToDeleteTwo = appendedDiv.parentNode.getAttribute(
                "classname"
              );
              divToDelete.remove();
              spanToDelete.remove();
              this.addeventsMethod(e);
            }
            let btn = document.getElementsByClassName(`${el}`);
            appendedDiv.parentNode.remove();
            this.setState({
              formData: rightData.documentElement.innerHTML,
              inputs: leftData.documentElement.innerHTML
            });
            if (this.state.page === 1) {
              this.setState({
                totalTestatorCount: this.state.totalTestatorCount - 1
              });
            }
            for (
              var o = 0;
              o <= appendedDiv.parentNode.childElementCount;
              o++
            ) {
              if (
                appendedDiv.parentNode.children[o].children[1].children[0]
                  .type === "file"
              ) {
                this.deleteImageApiCall(
                  `delteImage_${appendedDiv.parentNode.children[o].children[1].children[0].name}`
                );
              }
            }
            btn.addEventListener("click", this.addeventsMethod(e));
          } else {
            var count = e.target.parentNode.children[0].childElementCount + 1;
            var childCount =
              e.target.parentNode.children[0].children[0].childElementCount;
            var divForDateleft = [];
            var storeDivleft = [];
            var storeDivright = [];

            for (var loop = 0; loop < childCount; loop++) {
              if (
                e.target.parentElement.children[0].children[0].children[loop]
                  .children[1].firstElementChild.type === "date"
              ) {
                divForDateleft.push(`<div style="display:none">  <input type="${
                  e.target.parentElement.children[0].children[0].children[loop]
                    .children[1].firstElementChild.type
                }" value=""
                name="reverse100_${e.target.parentElement.children[0].children[0].children[
                  loop
                ].children[1].firstElementChild.getAttribute(
                  "classname"
                )}_${data}"
                classname="reverse100_${e.target.parentElement.children[0].children[0].children[
                  loop
                ].children[1].firstElementChild.getAttribute(
                  "classname"
                )}_${data}"
                /></div>`);
              }

              var classOfInput =
                e.target.parentElement.children[0].children[0].children[
                  loop
                ].children[1].firstElementChild.getAttribute("placeholder") !==
                null
                  ? e.target.parentElement.children[0].children[0].children[
                      loop
                    ].children[1].firstElementChild.getAttribute("placeholder")
                  : null;
              this.setState({ dynamicClassState: classOfInput });
              var newStyleClass = e.target.parentElement.children[0].children[0].children[
                loop
              ].children[1].firstElementChild.getAttribute("classname");
              var styleOfSpan = rightData.getElementById(newStyleClass);
              storeDivleft.push(`<div classname="${e.target.parentElement.children[0].children[0].children[
                loop
              ].getAttribute("classname")}_${data}">
    <div classname="${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[0].getAttribute("classname")}_${data}">
    ${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[0].innerHTML.slice(
      0,
      e.target.parentElement.children[0].children[0].children[loop].children[0]
        .innerHTML.length - 3
    )}
    ${
      e.target.parentElement.children[0].children[0].children[
        loop
      ].children[0].innerHTML.includes("Testator ")
        ? count + 1
        : e.target.parentNode.children[0].childElementCount + 1
    }?
    </div>
    <div classname="${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[1].getAttribute("classname")}_${data}">
    <input type="${
      e.target.parentElement.children[0].children[0].children[loop].children[1]
        .firstElementChild.type
    }" value=""
    name="${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[1].firstElementChild.getAttribute("classname")}_${data}"
    classname="${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[1].firstElementChild.getAttribute("classname")}_${data}"
    />
    </div>
    </div>`);
              if (
                e.target.parentElement.children[0].children[0].children[
                  loop
                ].children[1].firstElementChild
                  .getAttribute("classname")
                  .includes("file100")
              ) {
              } else {
                storeDivright.push(`${
                  e.target.parentElement.children[0].children[0].children[
                    loop
                  ].children[1].firstElementChild
                    .getAttribute("classname")
                    .includes("corporation100")
                    ? `<span>b. Corporations:<span>`
                    : ""
                }
    <${styleOfSpan.parentNode.tagName.toLowerCase()}
    style="${styleOfSpan.parentNode.getAttribute("style")}">
    ${
      e.target.parentElement.children[0].children[0].children[
        loop
      ].children[1].firstElementChild
        .getAttribute("classname")
        .includes("num100")
        ? `<span>${count}.</span>`
        : ""
    }
    ${
      e.target.parentElement.children[0].children[0].children[
        loop
      ].children[1].firstElementChild.getAttribute("title") !== null
        ? `<span>${e.target.parentElement.children[0].children[0].children[
            loop
          ].children[1].firstElementChild.getAttribute("title")}  &nbsp;</span>`
        : " "
    }
    ${
      e.target.parentElement.children[0].children[0].children[
        loop
      ].children[1].firstElementChild
        .getAttribute("name")
        .includes("AddToAlt")
        ? e.target.parentElement.children[0].children[0].children[
            loop
          ].children[1].firstElementChild.getAttribute("alt") !== null
          ? `<span>${(
              parseFloat(
                e.target.parentElement.children[0].children[0].children[
                  loop
                ].children[1].firstElementChild.getAttribute("alt")
              ) + parseFloat(`0.${count - 1}`)
            ).toFixed(1)} &nbsp  </span>`
          : " "
        : e.target.parentElement.children[0].children[0].children[
            loop
          ].children[1].firstElementChild.getAttribute("alt") !== null
        ? `<span>${e.target.parentElement.children[0].children[0].children[
            loop
          ].children[1].firstElementChild.getAttribute(
            "alt"
          )}${count} &nbsp  </span>`
        : " "
    }
    <${styleOfSpan.tagName.toLowerCase()}
    style="${styleOfSpan.getAttribute("style")}"
    id="${e.target.parentElement.children[0].children[0].children[
      loop
    ].children[1].firstElementChild.getAttribute("classname")}_${data}">

     </${styleOfSpan.tagName.toLowerCase()}>

     ${
       e.target.parentElement.children[0].children[0].children[loop].children[1]
         .firstElementChild.type === "date"
         ? `
      <span  id="reverse100_${e.target.parentElement.children[0].children[0].children[
        loop
      ].children[1].firstElementChild.getAttribute(
        "classname"
      )}_${data}">  </span>`
         : ""
     }


     ${
       e.target.parentElement.children[0].children[0].children[
         loop
       ].children[1].firstElementChild
         .getAttribute("classname")
         .includes("to100")
         ? `<span id="toClass"> to &nbsp </span>`
         : ""
     }
   

     </${styleOfSpan.parentNode.tagName.toLowerCase()}>`);
              }
            }
            newChild.insertAdjacentHTML(
              "beforeend",
              `<span id="${parentDivClass}_${data}" style="${
                parentDivClass.includes("toData")
                  ? "display:flex;"
                  : "display:block"
              }" >${
                classOfInput !== null
                  ? `<p id="classOfInput_${classOfInput}_${count}"style="margin-top:10pt;  margin-bottom:0pt; text-align:left; line-height:150%; font-size:10pt"><span>${classOfInput}&nbsp${count}</span><span>: </span></p>`
                  : ``
              }${storeDivright.join(" ")} </span>`
            );
            var newDiv = `<div className="${parentDivClass}_${data}" name="${parentDivClass}_${data}"> ${storeDivleft.join(
              " "
            )}
    <button classname="removeDiv">remove</button></div>`;

            this.setState({ formData: rightData.documentElement.innerHTML });
            if (this.state.page === 1) {
              this.setState({
                totalTestatorCount: this.state.totalTestatorCount + 1
              });
            }
            if (
              `${e.target.parentElement.children[0].children[0].children[0].children[1].getAttribute(
                "classname"
              )}_${data}`.search("name") > -1
            ) {
              this.setState({
                storenameClass: [
                  ...this.state.storenameClass,
                  `${e.target.parentElement.children[0].children[0].children[0].children[1].getAttribute(
                    "classname"
                  )}_${data}`
                ],
                textclassName: [
                  ...this.state.textclassName,
                  `${e.target.parentElement.children[0].children[0].children[0].children[1].firstElementChild.getAttribute(
                    "classname"
                  )}`
                ]
              });
            }
            appendedDiv.insertAdjacentHTML("beforeend", newDiv);
            appendedDiv.parentElement.parentElement.insertAdjacentHTML(
              "beforeend",
              divForDateleft[0] !== undefined ? divForDateleft[0] : ""
            );
            newClassForDate.insertAdjacentHTML(
              "beforeend",
              divForDateleft[0] !== undefined ? divForDateleft[0] : ""
            );
            repaetitClass.insertAdjacentHTML("beforeend", newDiv);
            let btn = document.getElementsByClassName(`${el}`);
            btn.addEventListener("click", this.addeventsMethod(e));
          }
        } else if (e.target.tagName === "SELECT") {
          if (e.target.className.length === 0) {
            this.setState({ [e.target.name]: "" }, () => {
              setTimeout(() => {
                this.addeventsMethod();
              }, 1000);
            });
          }

          if (e.target.value.includes("select")) {
            this.setState({ [e.target.name]: "" });
            this.addeventsMethod(e);
          } else if (e.target.value.includes("Select")) {
            this.setState({ [e.target.name]: "" });
            this.addeventsMethod(e);
          } else {
            for (
              var obj = 0;
              obj <
              leftData.body.children[0].getElementsByTagName("SELECT").length;
              obj++
            ) {
              if (
                leftData.body.children[0]
                  .getElementsByTagName("SELECT")
                  [obj].getAttribute("classname") === e.target.name
              ) {
                for (
                  var k = 0;
                  k <=
                  leftData.body.children[0].getElementsByTagName("SELECT")[obj]
                    .childElementCount;
                  k++
                ) {
                  leftData.body.children[0]
                    .getElementsByTagName("SELECT")
                    [obj].children[k].removeAttribute("selected", "false");
                  if (
                    leftData.body.children[0]
                      .getElementsByTagName("SELECT")
                      [obj].children[k].innerHTML.includes(e.target.value)
                  ) {
                    leftData.body.children[0]
                      .getElementsByTagName("SELECT")
                      [obj].children[k].setAttribute("selected", "true");

                    if (e.target.value === "no") {
                      var addedDiv = leftData.getElementsByName(
                        e.target.parentNode.parentNode.children[1].getAttribute(
                          "className"
                        )
                      );

                      var ChildData = rightData.getElementById(
                        e.target.parentNode.parentNode.children[1].getAttribute(
                          "className"
                        )
                      );

                      addedDiv[0].setAttribute("style", "display:none");

                      ChildData.setAttribute("style", "display:none");

                      this.setState(
                        {
                          formData: rightData.documentElement.innerHTML,
                          inputs: leftData.documentElement.innerHTML
                        },
                        () => {
                          this.addeventsMethod(e);
                        }
                      );
                    }

                    if (e.target.value === "yes") {
                      var addedDiv = leftData.getElementsByName(
                        e.target.parentNode.parentNode.children[1].getAttribute(
                          "className"
                        )
                      );

                      var ChildData = rightData.getElementById(
                        e.target.parentNode.parentNode.children[1].getAttribute(
                          "className"
                        )
                      );

                      addedDiv[0].setAttribute("style", "display:block");

                      if (
                        ChildData.getAttribute("name") === "yesOrNoFlexClass"
                      ) {
                        ChildData.setAttribute("style", "display:flex");
                      } else {
                        ChildData.setAttribute("style", "display:block");
                      }

                      this.setState(
                        {
                          formData: rightData.documentElement.innerHTML,
                          inputs: leftData.documentElement.innerHTML
                        },
                        () => {
                          this.addeventsMethod(e);
                        }
                      );
                    }
                  }
                }
              }
              this.addeventsMethod(e);
            }
            try {
              let Select1 = document.getElementsByName("shares")[0].options
                .selectedIndex;
              let Select2 = document.getElementsByName("sharesone")[0].options
                .selectedIndex;
              let Select3 = document.getElementsByName("opinion")[0].options
                .selectedIndex;
              let Select4 = document.getElementsByName("selectYesorNo")[0]
                .options.selectedIndex;
              if (Select1 == 1 && Select2 == 1 && Select3 == 1) {
                setTimeout(function() {
                  try {
                    document.getElementsByClassName(
                      "hideSel"
                    )[0].style.display = "none";
                  } catch (err) {}
                }, 1000);
              } else {
                setTimeout(function() {
                  try {
                    document.getElementsByClassName(
                      "hideSel"
                    )[0].style.display = "block";
                  } catch (err) {}
                }, 1000);
              }
              if (Select4 == 1) {
                setTimeout(function() {
                  try {
                    document.getElementsByClassName(
                      "selectYesorNo"
                    )[0].style.display = "none";
                  } catch (err) {}
                }, 1000);
              } else {
                setTimeout(function() {
                  try {
                    document.getElementsByClassName(
                      "selectYesorNo"
                    )[0].style.display = "block";
                  } catch (err) {}
                }, 1000);
              }
            } catch (err) {}
            this.setState({ inputs: leftData.documentElement.innerHTML });
            // this.addeventsMethod(e);
          }

          // }
        } else if (e.target.type === "radio") {
          if (e.target.id.includes("no")) {
            var leftSideRemove = leftData.getElementsByName(
              `remove100_${e.target.name}`
            )[0];
            leftSideRemove.setAttribute("style", "display:none");
            var ChildData = rightData.getElementById(
              `remove100_${e.target.name}`
            );
            ChildData.setAttribute("style", "display:none");
            this.setState({
              inputs: leftData.documentElement.innerHTML,
              formData: rightData.documentElement.innerHTML
            });
            this.addeventsMethod(e);
          }
          if (e.target.id.includes("yes")) {
            var leftSideRemove = leftData.getElementsByName(
              `remove100_${e.target.name}`
            )[0];
            leftSideRemove.setAttribute("style", "display:block");
            var ChildData = rightData.getElementById(
              `remove100_${e.target.name}`
            );
            ChildData.setAttribute(
              "style",
              "display:block;margin-top: 25px;font-weight:600;padding-left: 30px;"
            );
            this.setState({
              inputs: leftData.documentElement.innerHTML,
              formData: rightData.documentElement.innerHTML
            });
            this.addeventsMethod(e);
          }
        } else {
          e.stoppropagation();
          this.state.count.pop();
        }
      } catch (err) {}
    }
  };

  render() {
    try {
      !this.state.stopNext && this.executeNext();
      // window.onpopstate = function(event) {

      // };
    } catch (err) {}
    return (
      <div className="form">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.other_meta_tags}
        />
    }
        {this.state.loader ? (
          <Preloader />
        ) : (
          <div className="form__wrapper">
            {this.state.error ? (
              <p className="thank-you">
                "Work is in progress Please check some other Agreements. Thank
                You!"
              </p>
            ) : (
              <div>
                <div className="form__wrapper_heading">
                  <div className="form__wrapper_heading_title">
                    <h1>{this.state.formName.replace(/[0-9]/g, "")}</h1>
                  </div>
                  <div className="form__wrapper_heading_progress">
                    <span className="progress">Progress</span>
                    <progress
                      id="file"
                      value={this.state.page - 1}
                      max={this.state.maxpage}
                    ></progress>
                  </div>
                </div>
                <div className="agreement-view">
                  <div className="left-side" id="left-side">
                    <div
                      onClick={this.clickHandler}
                      dangerouslySetInnerHTML={{ __html: this.state.inputs }}
                    ></div>
                    {this.state.page >= 2 && (
                      <button onClick={this.handleClickPrev}>Prev</button>
                    )}
                    {this.state.page === this.state.maxpage &&
                      getCookie("sub-plan") &&
                      !(getCookie("sub-plan") === "FREEMIUM PLAN") && (
                        <button onClick={() => this.handleSubscriptionCheck()}>
                          Download with +{getCookie("sub-plan")}
                        </button>
                      )}
                    <button
                      onClick={() => {
                        setCookie("sub-clicked", "false");
                        deleteCookie("discount");
                        deleteCookie("refDiscount");
                        this.handleClickNext();
                      }}
                    >
                      {this.state.page === this.state.maxpage
                        ? "Pay Now"
                        : "Next"}
                    </button>
                  </div>
                  <div
                    className="right-side"
                    id="right-side"
                    onClick={this.clickrightHandler}
                    dangerouslySetInnerHTML={{ __html: this.state.formData }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(
  connect(state => ({}), { updateBreadcrumbs, updateToasterConfig })(index)
);
