import React, { Component } from "react";
import { getCookie, setCookie, deleteCookie } from "../../../../Utils/cookies";
import { Button } from "@material-ui/core";
import { domain } from "./../../../../Utils/domain";
import "./style.scss";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstStatus: "",
      lstTrans: "",
      lstTx_ref: "",
      finalAmount: "",
      orderNumber: "",
      documentName: ""
    };
  }
  componentDidMount() {
    this.updateStatus();
  }
  updateStatus() {
    let url = this.props.location.search;
    let split = url.split("&");
    let status = split[0].split("status=");
    let trans = split[2].split("transaction_id=");
    let tx_ref = split[1].split("tx_ref=");

    this.setState(
      {
        lstTrans: trans[1],
        lstStatus: status[1],
        lstTx_ref: tx_ref[1],
        finalAmount: getCookie("order_amount"),
        orderNumber: getCookie("order_number")
      },
      () => this.updateBackend()
    );

    setCookie("trans", trans[1]);
    //   setLstTrans(trans[1]);
    setCookie("status", status[1]);
    //   setLstStatus(status[1]);

    setCookie("tx_ref", tx_ref[1]);
    //   setLstTx_ref(tx_ref[1]);
  }
  updateBackend() {
    if (getCookie("sub-purchase") === "Yes") {
      let data = {
        user_subscription:
          getCookie("user_subscription") !== null
            ? getCookie("user_subscription")
            : null,
        flw_ref: this.state.lstTx_ref,
        tx_ref: this.state.lstTx_ref,
        transaction_id: this.state.lstTrans,
        status: this.state.lstStatus,
        amount: getCookie("total_amount"),
        response_object: "null",
        payment_method: "Debit Card/ Credit Card"
      };
      fetch(domain + "/api/subscription/sub-payment/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(responsejson => this.orderSuccessResponse(responsejson))
        .catch(error => this.errorResponse(error));
    } else {
      let data = {
        flw_ref: this.state.lstTx_ref,
        tx_ref: this.state.lstTx_ref,
        transaction_id: this.state.lstTrans,
        status: this.state.lstStatus,
        amount: this.state.finalAmount,
        response_object: "null",
        payment_method: "Debit Card",
        discount_applied: getCookie("discount") ? getCookie("discount") : 0,
        order_no: this.state.orderNumber,
        subscription_applied: getCookie("discount") ? "True" : "False",
        subscription_object: {
          discount: getCookie("discount") ? getCookie("discount") : 0,
          message: getCookie("msg") ? getCookie("msg") : null,
          id_of_service_usage: getCookie("id_of_service_usage")
            ? getCookie("id_of_service_usage")
            : null,
          template_type: getCookie("template_type")
            ? getCookie("template_type")
            : null
        },
        coupon_applied: getCookie("appliedCoupon") ? "True" : "False",
        couponobject: {
          coupon_object: getCookie("coupon_object"),
          discount: getCookie("coupon_discount"),
          is_percentage: getCookie("coupon_is_percentage"),
          valid: getCookie("coupon_valid"),
          value: getCookie("coupon_value")
        }
      };

      //Google Inhance Code 

      var dataLayer  = window.dataLayer || [];
      dataLayer.push({

        'event': 'transaction',

        'ecommerce': {

        'purchase': {

        'actionField': {

          'id':this.state.orderNumber,        // Order Number. Required          

          'affiliation': 'Online Store',

          'revenue': this.state.finalAmount,   // Total transaction value (incl. tax and shipping)

          'coupon': getCookie("coupon_value")

        }
        }
        }
        })



      JSON.stringify(data);
      fetch(domain + "/api/order/payment-create/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: "Token " + getCookie("token")
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(responsejson => this.orderSuccessResponse(responsejson))
        .catch(error => this.errorResponse(error));
    }
  }

  orderSuccessResponse(responsejson) {
    deleteCookie("sub-amount");
    // deleteCookie("sub-plan");
    deleteCookie("sub-subscription");
  }

  errorResponse(error) {}
  displayPopup() {
    deleteCookie("sub-purchase");
    deleteCookie("order_number");
    deleteCookie("order_amount");
    deleteCookie("trans");
    deleteCookie("status");
    deleteCookie("tx_ref");
    deleteCookie("docs_name");
    deleteCookie("id_of_service_usage");
    deleteCookie("template_type");
    deleteCookie("msg");
    deleteCookie("discount");
    deleteCookie("total");
    deleteCookie("subscriptionData");
    deleteCookie("user_subscription");
    deleteCookie("ref_id");
    deleteCookie("filing_cost");
    deleteCookie("filing_cost_text");
    deleteCookie("lanidigital_cost");
    deleteCookie("appliedCoupon");
    deleteCookie("coupon_object");
    deleteCookie("coupon_discount");
    deleteCookie("coupon_is_percentage");
    deleteCookie("coupon_valid");
    deleteCookie("coupon_value");
    deleteCookie("sub-clicked");
    this.props.history.push("/dashboard");
  }

  render() {
    return (
      <div>
        <div className="status-div">
          <div className="status-text">
            <p>
              Thank You! For your order. You can check order details from the
              dashboard's orders section.
            </p>
            <h5>Payment Status : {this.state.lstStatus} </h5>
            <Button
              className="status-button"
              onClick={() => this.displayPopup()}
            >
              Check Your Order Details
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
