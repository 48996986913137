import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain } from "../../../Utils/domain";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import MetaFile from "../../MetaFile";
import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: []
    };
  }
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Contact");
    this.getData();
  };
  getData() {
    fetch(domain + "/api/home/contactus_static/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    this.setState({ contactDetails: response });
  };

  errorResponse = error => {};
  render() {
    return (
      <div className="topContact">
        <Grid className="contactMobile" item md={12} lg={12} sm={12} xs={12}>
          <div className="contact-breadcrumbs">
            <Breadcrumbs />
          </div>
          <div className="contactusLeft">Contact Us</div>
        </Grid>

        <Grid className="fulldetailsContact" container spacing={2}>
          <Grid className="fullLocations" item sm={12} lg={4} md={4} xs={12}>
            <Paper className="ourLocation">
              <div className="locationText">Our Locations</div>
            </Paper>
          </Grid>
          <Grid
            className="addressdeatilsBox"
            item
            md={8}
            lg={8}
            xs={12}
            sm={12}
          >
            <Paper className="contactRight">
              {this.state.contactDetails &&
                this.state.contactDetails.map(contactDetails => (
                  <div key={index}>
                    {this.state.meta_title !== "" &&  <MetaFile
                      meta_title={contactDetails.meta_title}
                      meta_description={contactDetails.meta_description}
                      meta_keywords={contactDetails.meta_keywords}
                      meta_image={contactDetails.meta_image}
                      other_meta_tags={contactDetails.json_meta_tags}
                    />
                }
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contactDetails.page_description
                      }}
                    ></div>
                  </div>
                ))}
            </Paper>
          </Grid>

          <div style={{ position: "absolute", top: 320, display: "flex" }}>
            <div style={{ marginLeft: 200 }}></div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
