import React, { Component } from 'react';
import { getCookie } from "../../../../Utils/cookies";
import {ifameDomain} from './../../../../Utils/domain';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../redux/modules/app/actions';
import Back from './../../../../Assets/icons/back.png';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

let productId =  null
class index extends Component {
  componentDidMount(){
    productId = this.props.match.params.id;
}
render() {
 
    return (
      <div>
      <div className="orders__wrapper_block_top_search">  
      </div>
        <Container className="orderContainer products--Container">
          <Grid xs={12} sm={12}>
          <div className="ordeR" >
                <div className="orderData">
                <span>
                <img
                  onClick={this.props.history.goBack}
                  src={Back}
                  alt="back button"
                ></img>
              </span>
                    <h1>  
                               Product details
                           </h1>
                </div>
                <div className="orderData payData">
              
                  <Grid xs={6} sm={6}>
                    <div className="iteM">
                    <iframe src={ifameDomain+"/admin/services/service/"+productId +"/change/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="300" height="500"></iframe>
                    </div>
                  </Grid>
                </div>
  
               
              </div>
                            
                          
                   

                          
          </Grid>
        </Container>
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      // auth: state.app.auth,
    }),
    {updateBreadcrumbs },
  )(index),
);
