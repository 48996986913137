import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { domain } from "../../../Utils/domain";
import MetaFile from "../../MetaFile";
import "./styles.scss";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsConditions: []
    };
  }
  componentDidMount = () => {
    this.props.updateBreadcrumbs("Terms");
    this.getData();
  };
  getData() {
    fetch(domain + "/api/home/tnc/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(response => this.successResponse(response))
      .catch(error => this.errorResponse(error));
  }

  successResponse = response => {
    this.setState({ termsConditions: response });
  };

  errorResponse = error => {};

  render() {
    return (
      <div className="payments--static--page">
        <Container>
          <Grid className="contactMobile" item md={12} lg={12} sm={12} xs={12}>
            <div className="contact-breadcrumbs">
              <Breadcrumbs />
            </div>
          </Grid>
          <Grid
            className="static--page--content"
            item
            md={12}
            lg={12}
            sm={12}
            xs={12}
          >
            {this.state.termsConditions &&
              this.state.termsConditions.map(termsConditions => (
                <div className="static--page--content--sec">
                   {this.state.meta_title !== "" &&  <MetaFile
                    meta_title={termsConditions.meta_title}
                    meta_description={termsConditions.meta_description}
                    meta_keywords={termsConditions.meta_keywords}
                    meta_image={termsConditions.meta_image}
                    other_meta_tags={termsConditions.json_meta_tags}
                  />
              }
                  <div
                    dangerouslySetInnerHTML={{
                      __html: termsConditions.page_description
                    }}
                  ></div>
                </div>
              ))}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withRouter(connect(state => ({}), { updateBreadcrumbs })(index));
