import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import Content from "./Content";
import "./styles.scss";
import { defaultHeaders, domain } from "../../Utils/domain";
import { setCookie, getCookie } from "./../../Utils/cookies";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateToasterConfig } from "../../../src/redux/modules/app/actions";
import { getMeta } from "../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../Pages/MetaFile";
import { Helmet } from 'react-helmet';

const Signup = props => {
  const [username, setusername] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [redirect, setRedirect] = useState("/");
  const [popup, setPopup] = useState(false);
  const [cfmPassword, setcfmPassword] = useState("");
  const [referral, setReferral] = useState("");
  const [meta_title, setmeta_title] = useState("");
  const [meta_description, setmeta_description] = useState("");
  const [meta_keywords, setmeta_keywords] = useState("");
  const [other_meta_tags, seteother_meta_tags] = useState("");
  const [json_meta_tags, setjson_meta_tags] = useState("");
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    var searchParams1 = searchParams.has("referral");
    if (searchParams1) {
      let data = searchParams.getAll("referral");
      setReferral(data[0].toUpperCase());
    }
    getMeta(getMetaResponse, getMetaError);
  }, []);

  const getMetaResponse = response => {
    setmeta_title(response.meta_title);
    setmeta_description(response.meta_description);
    setmeta_keywords(response.meta_keywords);
    seteother_meta_tags(response.other_meta_tags);
    setjson_meta_tags(response.json_meta_tags);
  };
  const getMetaError = error => {
    console.log(error);
  };
  let redirectUrl = "/";
  if (
    props.location.state !== undefined &&
    props.location.state.redirect !== undefined
  ) {
    redirectUrl = props.location.state.redirect;
  } else {
    redirectUrl = "/";
  }

  function validateForm() {
    if (
      username &&
      email &&
      phone &&
      password &&
      cfmPassword &&
      fname &&
      lname
    ) {
      if (username.includes("@") === true) {
        props.updateToasterConfig({
          show: true,
          message: "Can not use @ in Username ",
          color: "#D06900"
        });
      } else if ((password === cfmPassword) === false) {
        props.updateToasterConfig({
          show: true,
          message: "Password is not matching",
          color: "#D06900"
        });
      } else if (phone.length <= 9 || phone.length >= 13) {
        props.updateToasterConfig({
          show: true,
          message: "Please enter a valid Phone number",
          color: "#D06900"
        });
      } else if (referral && referral.length <= 6) {
        props.updateToasterConfig({
          show: true,
          message: "Please enter a valid Referral Code",
          color: "#D06900"
        });
      } else {
        submitRegistrationForm(
          username,
          email,
          phone,
          password,
          referral,
          fname,
          lname
        );
      }
    } else {
      props.updateToasterConfig({
        show: true,
        message: "Please fill all the input fields",
        color: "#D06900"
      });
    }
  }
  function submitRegistrationForm(
    username,
    email,
    phone,
    password,
    referral,
    fname,
    lname
  ) {
    let data = {
      first_name: fname,
      last_name: lname,
      username: username,
      password: password,
      email: email,
      phone: phone,
      groups: 2,
      referral: referral,
      lastUrl: redirectUrl,
      activationUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/email-verification"
    };
    axios
      .post(domain + "/api/auth/emailregister/", JSON.stringify(data), {
        headers: defaultHeaders
      })
      .then(response => successResponse(response))
      .catch(error => errorResponse(error));
  }
  const successResponse = response => {
    setPopup(true);
  };

  const errorResponse = error => {
    let msg = "Internal Server error Please try after some time";
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.email && error.response.data.email[0]) {
          msg = error.response.data.email[0];
        } else if (
          error.response.data.username &&
          error.response.data.username[0]
        ) {
          msg = error.response.data.username[0];
        } else if (error.response.data.referral) {
          msg = error.response.data.referral[0];
        }
      }
    }

    props.updateToasterConfig({
      show: true,
      message: msg,
      color: "#D06900"
    });
  };
  const closePopup = () => {
    setPopup(false);
    window.location.reload(false);
  };

  return (
    <div className="Login--page signup--page">
      <Helmet>
      <script>{`gtag('event', 'conversion', {'send_to': 'AW-353797034/K9FBCI2Y6e4CEKqH2qgB'});`}</script>
      </Helmet>
      {meta_title !== "" &&  <MetaFile
        meta_title={meta_title}
        meta_description={meta_description}
        meta_keywords={meta_keywords}
        other_meta_tags={json_meta_tags}
      />
  }
      <div className="login--main--wrapper">
        <Content />
        <div className="windowHeight">
          <div className="login--right--wrapper">
            <div className="singinStyles">Sign Up</div>
            <div className="contentStyle">
              To create an account, please enter the requested details
              <br /> below and click 'sign up'
            </div>
            <form>
              <div className="useridtext textfont">First Name*</div>
              <input
                className="textField"
                placeholder="First Name"
                variant="outlined"
                value={fname}
                onChange={e => setFname(e.target.value)}
              />
              <div className="useridtext textfont">Last Name*</div>
              <input
                className="textField"
                placeholder="Last Name"
                variant="outlined"
                value={lname}
                onChange={e => setLname(e.target.value)}
              />
              <div className="useridtext textfont">User Name*</div>
              <input
                className="textField"
                placeholder="Username"
                variant="outlined"
                value={username}
                onChange={e => setusername(e.target.value)}
              />

              <div className="pwdfont textfont">Email*</div>
              <input
                className="textField"
                placeholder="email@email.com"
                variant="outlined"
                value={email}
                onChange={e => setemail(e.target.value)}
              />
              <div className="pwdfont textfont">Phone*</div>
              <input
                className="textField"
                placeholder="Phone number"
                variant="outlined"
                value={phone}
                onChange={e => setphone(e.target.value)}
              />

              <div className="pwdfont textfont">Password*</div>
              <input
                className="textField"
                type="password"
                placeholder="Enter Password"
                variant="outlined"
                value={password}
                onChange={e => setpassword(e.target.value)}
              />

              <div className="pwdfont textfont">Confirm Password*</div>
              <input
                className="textField"
                type="password"
                placeholder="Confirm Password"
                variant="outlined"
                value={cfmPassword}
                onChange={e => setcfmPassword(e.target.value)}
              />

              <div className="pwdfont textfont">Have Referral code?</div>
              <input
                className="textField"
                type="text"
                placeholder="Enter Code (Optional)"
                variant="outlined"
                value={referral}
                onChange={e => {
                  setReferral(e.target.value.toUpperCase());
                }}
              />
            </form>

            <Button
              variant="contained"
              onClick={validateForm}
              style={{
                bottom: 20,
                marginTop: 52,
                backgroundColor: "#D06900",
                height: 56,
                width: 184,
                fontFamily: "Raleway",
                textTransform: "inherit",
                fontWeight: "bold",
                color: "white",
                fontSize: 20,
                borderRadius: 0
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
      {popup && (
        <div className="email-msg">
          <div className="msg-box">
            <p>
              We have sent you a verification email. To login into your account
              please verify your email address
            </p>
            <button className="email-msg-close" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth
    }),
    { updateToasterConfig }
  )(Signup)
);
