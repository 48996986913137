import {domain} from './../../../Utils/domain';

const defaultHeaders = {
    'Content-Type' : "application/json",
    'Api-Key': 'sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79'
   }
//    https://legalecomm.beta.webenza.in
export const TestimonialsApi = ( successResponse, errorResponse ) => {
        
            fetch(domain +'/api/home/testimonials/',{
                   method  : "GET", 
                   headers : defaultHeaders,        
                  })
            .then(response => response.json())
            .then((responseJson) => { successResponse(responseJson) })
            .catch((error) => {  errorResponse(error) })
 }


 export const SearchApi = ( successResponse, errorResponse,scsc,stsc ) => {
        
    fetch( `${domain}/search/service/suggest/?service_content_suggest__completion=${scsc}&service_title_suggest__completion=${stsc}`,{
           method  : "GET", 
           headers : defaultHeaders,        
          })
    .then(response => response.json())
    .then((responseJson) => { successResponse(responseJson) })
    .catch((error) => {  errorResponse(error) })
}


export const GetOurClients = ( successResponse, errorResponse ) => {
    fetch(domain +'/api/home/ourclients/',{
           method  : "GET", 
           headers : defaultHeaders,        
          })
    .then(response => response.json())
    .then((responseJson) => { successResponse(responseJson) })
    .catch((error) => {  errorResponse(error) })
}

export const contactHome = ( successResponse, errorResponse ) => {
    fetch(domain +'/api/home/homestatic',{
           method  : "GET", 
           headers : defaultHeaders,        
          })
    .then(response => response.json())
    .then((responseJson) => { successResponse(responseJson) })
    .catch((error) => {  errorResponse(error) })
}

// export const popularProducts = ( successResponse, errorResponse ) => {
//     fetch('https://legalecomm.beta.webenza.net/api/services/popular_productslist/',{
//            method  : "GET", 
//            headers : defaultHeaders,        
//           })
//     .then(response => response.json())
//     .then((responseJson) => { successResponse(responseJson) })
//     .catch((error) => {  errorResponse(error) })
// }