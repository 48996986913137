import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import Back from "./../../../Assets/icons/back.png";
import "./style.scss";
import xlsExport from "../../../Components/ExcelExport/XlsExport";
import { Button } from "@material-ui/core";
import { domain } from "../../../Utils/domain";
import { getCookie } from "../../../Utils/cookies";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsList: [],
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: "",
      productStore: "",
      activePage: 1,
      totalItem: null,
      allOrdersData: [],
      dynamicSelect: "All",
      dateFilter: false,
      startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
      endDate: new Date(),
      startDateValue: "",
      endDateValue: "",
      dateFilterData: []
    };
  }
  componentDidMount() {
    this.props.updateBreadcrumbs("Products");
    this.setState({
      loader: true
    });
    this.getData();
    // if (
    //   localStorage.getItem("allProductsData") === null ||
    //   localStorage.getItem("allProductsData") === undefined ||
    //   localStorage.getItem("allProductsData").length === 0
    // ) {
    //   setTimeout(() => {
    //     this.state.totalItem !== null && this.getDataForSearch();
    //   }, 5000);
    // } else {
    //   this.setState({
    //     allOrdersData: JSON.parse(localStorage.getItem("allProductsData"))
    //   });
    // }
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => this.getData());
  }
  getData() {
    let token = getCookie("token");

    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/services/adminlist/?created_on__date__gte=` +
          this.state.startDateValue +
          "&created_on__date__lte=" +
          this.state.endDateValue +
          `&page=` +
          this.state.activePage,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          }
        }
      )
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    } else {
      fetch(domain + "/api/services/adminlist/?page=" + this.state.activePage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
          Authorization: `Token ${token}`
        }
      })
        .then(response => response.json())
        .then(response => this.successResponse(response))
        .catch(error => this.errorResponse(error));
    }
  }
  getDataForSearch = () => {
    if (this.state.dateFilter) {
      fetch(
        domain +
          `/api/services/adminlist/?created_on__date__gte=` +
          this.state.startDateValue +
          "&created_on__date__lte=" +
          this.state.endDateValue +
          `&page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponsepro(response))
        .catch(error => this.errorResponsepro(error));
    } else {
      fetch(
        domain +
          `/api/services/adminlist/?page=1&&page_size=${this.state.totalItem}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: "Token " + getCookie("token")
          }
        }
      )
        .then(response => response.json())
        .then(response => this.searchSuccessResponsepro(response))
        .catch(error => this.errorResponsepro(error));
    }
  };
  searchSuccessResponsepro(res) {
    if (res.results !== undefined) {
      localStorage.setItem("allProductsData", JSON.stringify(res.results));
      this.setState({
        allOrdersData: res.results,
        dateFilterData: res.results,
        loader: false
      });
    }
  }
  errorResponsepro = err => {};
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };
  dateFilter = () => {
    console.log(this.state.dateFilter);
    // this.filterResult("All");
    this.setState(
      {
        dateFilter: !this.state.dateFilter
      },
      () => {
        if (this.state.dateFilter) {
          let a = this.convert(this.state.startDate);
          let b = this.convert(this.state.endDate);
          let c = this.convert(new Date());
          if (b <= c) {
            this.setState(
              {
                startDateValue: a,
                endDateValue: b
              },
              () => {
                if (this.state.dynamicSelect === "All") {
                  this.getDataForSearch();
                  this.getData();
                } else {
                  this.callApiOfSpecificGroup(this.state.dynamicSelect);
                }
              }
            );
          } else {
            this.props.updateToasterConfig({
              show: true,
              message: "End Date value can't be greater than today's date",
              color: "#D06900"
            });
          }
        } else {
          this.setState(
            {
              dateFilterData: [],
              startDate: new Date(2021, 0, 1, 10, 33, 30, 0),
              endDate: new Date(),
              startDateValue: "",
              endDateValue: ""
            },
            () => {
              this.getDataForSearch();
              this.getData();
            }
          );
        }
      }
    );
  };

  convert = str => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  exportexcel = e => {
    const xls = new xlsExport(
      this.state.dynamicSelect === "All" && !this.state.dateFilter
        ? this.state.allOrdersData
        : this.state.dynamicSelect === "All" && this.state.dateFilter
        ? this.state.dateFilterData
        : this.state.productsList,
      "Products"
    );
    xls.exportToXLS("export.xls");
  };
  successResponse = response => {
    this.setState(
      {
        productsList: response.results,
        productStore: response.results,
        totalItem: response.paginationTotal,
        loader: false
      },
      () => {
        this.state.totalItem !== null && this.getDataForSearch();
      }
    );
  };
  errorResponse = err => {};
  performSearch = e => {
    if (e.length >= 3) {
      let filtered = this.state.allOrdersData.filter(entry => {
        return Object.values(entry).some(val => {
          if (
            typeof val === "string" &&
            val.toLowerCase().includes(e.toLowerCase())
          ) {
            return true;
          }
        });
      });

      this.setState({
        productsList: filtered,
        dynamicSelect: null
      });
    }
    if (e.length === 0) {
      this.setState({
        productsList: this.state.productStore,
        dynamicSelect: "All"
      });
    }
  };
  updateTime = date => {
    let splitr = date;
    let etime = splitr.slice(0, 10);
    return etime
      .split("-")
      .reverse()
      .join("-");
  };

  render() {
    return (
      <div>
         {this.state.meta_title !== "" && <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="orders__wrapper_block_top_search">
          {getCookie("group") === "1" && this.state.allOrdersData.length >= 1 && (
            <span className="buttonExport">
              <Button onClick={e => this.exportexcel(e)}>
                Export Products
              </Button>
            </span>
          )}
          {this.state.allOrdersData.length >= 1 && (
            <>
              <input
                onChange={event => this.performSearch(event.target.value)}
                name="search"
                placeholder="search..."
              />
              <span className="orders__wrapper_block_top_search_icon">
                <FaSearch />
              </span>
            </>
          )}
        </div>
        <div>
          {/* Date Button start */}
          <div className="date_filter">
            <div className="date_input">
              <span
                style={{
                  color: this.state.dateFilter ? "#00A5E6" : "",
                  borderBottom: "1px"
                }}
              >
                Start Date
              </span>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <span>---</span>
            <div className="date_input">
              <span style={{ color: this.state.dateFilter ? "#00A5E6" : "" }}>
                End Date
              </span>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <button
              className={this.state.dateFilter ? "show_active_btn" : "show_btn"}
              onClick={e => this.dateFilter(e)}
            >
              {this.state.dateFilter ? "Clear" : "Show"}
            </button>
          </div>

          {/* Date button end  */}
        </div>

        <Container className="orderContainer products--Container">
          <Grid xs={12} sm={12}>
            {this.state.productsList !== undefined &&
              this.state.productsList.map(product => (
                <div className="ordeR" key={product.id}>
                  <div className="orderData">
                    <Grid xs={6} sm={6}>
                      <div className="orderNo">
                        <p>Name</p>

                        <p> {product.service_title}</p>
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6}>
                      <div className="datE">
                        <p>Type</p>
                        {/* <p>   {order.date}</p> */}
                        <p>{product.service_type}</p>
                      </div>
                    </Grid>
                  </div>
                  <div className="orderData payData">
                    <Grid xs={6} sm={6}>
                      <div className="statuS">
                        <p>Category</p>
                        <p>{product.service_category}</p>
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6}>
                      <div className="iteM">
                        <p>Added</p>
                        <p>{this.updateTime(product.content_published_date)}</p>
                      </div>
                    </Grid>
                  </div>
                  <div className="payData">
                    <Grid xs={6} sm={6}>
                      <div className="amounT">
                        <p>Edited</p>
                        <p>NA</p>
                      </div>
                    </Grid>
                    <Grid xs={6} sm={6}>
                      <div className="amounT">
                        <p>Status</p>
                        <p>{product.status}</p>
                      </div>
                    </Grid>
                  </div>
                  <div className="linkData">
                    <Link to="/dashboard/mobileProducts/MobileProductDetails">
                      <li>
                        <span className="action">View</span>
                      </li>
                    </Link>
                  </div>
                </div>
              ))}
          </Grid>
        </Container>
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);
