import ChatComponent from "../../../Components/ChatComponent";

import React, { Component } from "react";
import { FaSearch } from "react-icons/fa";

import "./style.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getCookie, setCookie } from "../../../Utils/cookies";
import { domain, ifameDomain } from "../../../Utils/domain";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { updateToasterConfig } from "../../../redux/modules/app/actions";
import { getMeta } from "../../../Pages/Website/WebServices/metaTags";
import MetaFile from "../../../Pages/MetaFile";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactStore: [],
      searchValue: null,
      chatData: [],
      responseOfUser: [],
      filterValue: null,
      filterId: null,
      online: false,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }
  componentDidMount() {
    this.getData();
    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};

  getData() {
    fetch(domain + "/api/auth/customer_support_active/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
        Authorization: "Token " + getCookie("token")
      }
    })
      .then(response => response.json())
      .then(response => this.successResponseFetch(response))
      .catch(error => this.errorResponse(error));
  }

  successResponseFetch = res => {
    this.setState({
      online: res.active
    });
  };

  errorResponse = err => {};
  filterResult = (value, id) => {
    const currentClass = document.getElementsByClassName("filter");

    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("filter-active");
    }
    const element = document.getElementById(value);
    element.classList.add("filter-active");

    this.setState({ filterId: id, filterValue: value });
  };

  makeOnline() {
    this.setState(
      {
        online: !this.state.online
      },
      () => {
        let params = {
          username: getCookie("logedinUser"),
          active: this.state.online
        };
        let token = getCookie("token");
        fetch(domain + "/api/auth/customer_support_active/ ", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79",
            Authorization: `Token ${token}`
          },
          body: JSON.stringify(params)
        })
          .then(response => response.json())
          .then(response => this.onlineSuccessResponse(response))
          .catch(error => this.errorResponse(error));
      }
    );
  }
  onlineSuccessResponse(res) {
    this.props.updateToasterConfig({
      show: true,
      message: "Status Updated Successfully",
      color: "#D06900"
    });
    window.location.reload();
  }

  render() {
    return (
      <div className="customerCare">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <div className="customerCare__wrapper">
          <div className="customerCare__wrapper_block">
            <div className="customerCare__wrapper_block_top">
              <div className="customerCare__wrapper_block_top_heading">
                <p>Customer Care</p>
              </div>
              <div className="customerCare__wrapper_block_top_search">
                {/* <input onChange={(event) => this.setState({searchValue: event.target.value})} name="search" placeholder="search..."  /><span className="customerCare__wrapper_block_top_search_icon"><FaSearch /></span> */}
              </div>
            </div>
            {getCookie("group") == 5 && (
              <h3 style={{ color: this.state.online ? "green" : "red" }}>
                {this.state.online
                  ? "Click to make yourself offline"
                  : "Click to make yourself online"}{" "}
                <input
                  type="checkbox"
                  className="online-checkbox"
                  checked={this.state.online}
                  onClick={() => this.makeOnline()}
                ></input>
              </h3>
            )}
            <div className="chatSupportS">
              {getCookie("group") == 2 && (
                <h4 className="noteSection">
                  Note: Working hours for Customer Support is 9am - 6pm.
                  Requests received after 6pm would be dealt with before 10am
                  the next day.
                </h4>
              )}
              <iframe
                src={ifameDomain + "/dialogs/?auth=" + getCookie("token")}
                title="description"
              ></iframe>
            </div>

            {/* <ul className="customerCare__wrapper_block_top_filter">
                                <li id="All" className=" filter filter-active" onClick={() => this.filterResult('All') }>All</li>
                                <li id="Ongoing" className="filter" onClick={() => this.filterResult('Ongoing')}>Ongoing</li>
                                <li id="Completed" className="filter" onClick={() => this.filterResult('Completed')}>Completed</li>
                               
                            </ul> */}

            {/* <div className="customerCare__wrapper_block_orderlist">
                           
                           <ChatComponent performSearch={this.state.searchValue} filterId={this.state.filterId} filterValue={this.state.filterValue}/>

                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs, updateToasterConfig }
  )(index)
);
