import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Back from "./../../../../Assets/icons/back.png";
import { getCookie, setCookie } from "../../../../Utils/cookies";
import {ifameDomain} from "./../../../../Utils/domain";
import Container from "@material-ui/core/Container";

import "./style.scss";
let id = null;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatLogDetail: [],
    };
  }
  componentDidMount = () => {
     
    id = this.props.match.params.id;
  };

  render() {
    return (
      <div className="orderdetails">
           <div className="orderdetails__wrapper">
               <div className="orderdetails__wrapper_heading">
                   <p><span><img onClick={this.props.history.goBack} src={Back} alt="back button" ></img></span> Chat Log Details</p>
               </div>
              <Container className="orderdetails__wrapper_content_block chatDetailsp" >
                
                <div style={{paddingTop:"50px"}}>
                    <iframe src={ifameDomain+"/admin/django_private_chat/dialog/"+id+"/change/?_to_field=id&_popup=1&auth="+getCookie('token') } title="description" width="850" height="550"></iframe>
                </div>
              </Container>
              </div>
              </div>
    );
  }
}
export default withRouter(index);
