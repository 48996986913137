import React, { useEffect, useState, Component } from "react";
import {
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
  Button
} from "@material-ui/core";
import { getFaq } from "../WebServices/faqDetailAndSearch";
import { getMeta } from "../WebServices/metaTags";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { searchFaq, faqDetail } from "../WebServices/faqDetailAndSearch";
import "./styles.scss";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { domain } from "./../../../Utils/domain";
import MetaFile from "../../MetaFile";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      passId: "",
      bool: false,
      cheveronUp: false,
      questionColor: false,
      faq_tit_sug_com: "",
      faq_con_sug_com: "",
      searchListData: [],
      showList: false,
      faq_content: true,
      showSearchedContent: false,
      searchedContentDetail: [],
      activeIndex: 0,
      displayId: null,
      searchProductId: "",
      FaqlistPerId: [],
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      meta_image : "",
      other_meta_tags: "",
      json_meta_tags: ""
    };
  }

  componentDidMount() {
    getFaq(this.successResponse, this.errorResponse);
    this.getFaqListPerId(11);
    this.props.updateBreadcrumbs("Faq");

    getMeta(this.getSucessMetaresponce, this.getErrorMetaresponce);
  }
  getSucessMetaresponce = response => {
    this.setState({
      meta_title: response.meta_title,
      meta_description: response.meta_description,
      meta_keywords: response.meta_keywords,
      meta_image:response.meta_image,
      other_meta_tags: response.other_meta_tags,
      json_meta_tags: response.json_meta_tags
    });
  };
  getErrorMetaresponce = err => {};
  getFaqListPerId(id) {
    fetch(`${domain}/api/home/faq_qa/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "sEaxEzKR.b0knrBuHSTMt77xxQWJ9n01Ik7pNWM79"
      }
    })
      .then(response => response.json())
      .then(responseJson => this.listSuccessResponse(responseJson))
      .catch(error => this.listErrorResponse(error));
  }

  listSuccessResponse = response => {
    this.setState({ FaqlistPerId: response });
  };

  listErrorResponse = error => {};

  getsearchresult(faq_tit_sug_com) {
    let fcsc = this.state.faq_con_sug_com;
    let ftsc = faq_tit_sug_com;
    fcsc = ftsc;
    searchFaq(this.searchSuccessResponse, this.searchErrorResponse, fcsc, ftsc);
  }

  searchSuccessResponse = response => {
    this.setState(
      {
        showList: true,
        searchListData: response
      },
      () => {
        if (this.state.showList === true) {
          this.state.searchListData.faq_title_suggest__completion.map(
            (data, index) => {
              if (data.options.length === 0) {
                this.setState({ showList: false });
              }
            }
          );
        }
      }
    );
  };

  searchErrorResponse = error => {};

  successResponse = response => {
    console.log(response);
    this.setState({ categories: response });
  };

  errorResponse = error => {};

  getFaqList(id, index) {
    this.setState({ passId: id, activeIndex: index }, () =>
      this.getFaqListPerId(this.state.passId)
    );
  }

  changeBool(e, data) {
    if (this.state.bool === false && this.state.questionColor == false) {
      this.setState({
        bool: true,
        questionColor: true,
        cheveronUp: true,
        displayId: data
      });
    } else if (this.state.bool === true && this.state.questionColor === true) {
      this.setState({
        bool: false,
        questionColor: false,
        cheveronUp: false,
        displayId: data
      });
    }
  }

  getSearchDetail() {
    // setfaqContent(false);
    // setShowSearchedContent(true);
    this.setState(
      {
        faq_content: false,
        showSearchedContent: true
      },
      () =>
        faqDetail(
          this.successDetailResponse,
          this.errorDetailResponse,
          this.state.searchProductId
        )
    );
  }

  successDetailResponse = response => {
    this.setState({
      searchedContentDetail: response
    });
  };

  errorDetailResponse = error => {};

  render() {
    return (
      <div className="faq--page">
         {this.state.meta_title !== "" &&  <MetaFile
          meta_title={this.state.meta_title}
          meta_description={this.state.meta_description}
          meta_keywords={this.state.meta_keywords}
          meta_image={this.state.meta_image}
          other_meta_tags={this.state.json_meta_tags}
        />
    }
        <Avatar
          className="banner--image"
          src={require("../../../Assets/images/f_a_q1.png")}
        />
        <div className="faq--breadcrumbs">
          <Breadcrumbs />
        </div>
        <div className="banner--content">
          <h1>Frequently Asked Questions</h1>
          <p>Find the answers to our frequently asked questions here.</p>
        </div>
        <Paper className="banner--search">
          <InputBase
            variant="outlined"
            placeholder="Enter a search term..."
            style={styles.input}
            value={this.state.faq_tit_sug_com}
            onChange={e =>
              this.setState({ faq_tit_sug_com: e.target.value }, () =>
                this.getsearchresult(this.state.faq_tit_sug_com)
              )
            }
          />
          <Divider style={styles.divider} orientation="vertical" />
          <IconButton
            onClick={() => this.getSearchDetail()}
            style={styles.searchImageBackground}
          >
            <Avatar
              src={require("../../../Assets/images/search.png")}
              style={styles.image}
            />
          </IconButton>
        </Paper>
        {this.state.showList === true && (
          <div className="search--results--popup">
            {this.state.searchListData.faq_title_suggest__completion.map(
              (data, index) => (
                <div key={index}>
                  {data.options.map((content, index) => (
                    <div>
                      <div style={{ paddingTop: 12, paddingLeft: 20 }}>
                        <div
                          style={{
                            color: "#141C49",
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                            fontStyle: "normal",
                            fontSize: 14
                          }}
                          onClick={() =>
                            this.setState({
                              showList: false,
                              faq_tit_sug_com: content._source.faq_category,
                              searchProductId: content._source.id
                            })
                          }
                          key={index}
                        >
                          {content._source.faq_category}
                        </div>
                        <div
                          style={{
                            color: "#707070",
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                            fontStyle: "normal",
                            fontSize: 14,
                            paddingTop: 8
                          }}
                        >
                          {content._source.faq_title}
                        </div>
                      </div>
                      <hr style={{ color: "gray", marginTop: 10 }} />
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        )}
        {this.state.faq_content && (
          <div className="faq--section--wrapper">
            <Container>
              <div className="faqlist">
                <div className="faq--left">
                  {this.state.categories.map((data, index) => (
                    <div key={index}>
                      <div
                        onClick={() => this.getFaqList(data.id, index)}
                        className={
                          this.state.activeIndex === index
                            ? "sideTab activeItem"
                            : "sideTab"
                        }
                      >
                        {data.faq_category_name}
                      </div>
                    </div>
                  ))}
                </div>

                {
                  <div className="faq--right">
                    <div>
                      {this.state.FaqlistPerId.map((faq, index) => (
                        <div>
                          {
                            <div className="faq--right--question">
                              <div
                                className="faq--right--question--wrapper"
                                onClick={e => this.changeBool(e, faq.id)}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  paddingLeft: 25,
                                  paddingRight: 25,
                                  paddingTop: 25,
                                  paddingBottom:
                                    (this.state.questionColor === true &&
                                      this.state.displayId === faq.id) === false
                                      ? 25
                                      : 0
                                }}
                              >
                                <div
                                  className="faq--right--question--wrapper--inner"
                                  style={{
                                    fontFamily: "Raleway",
                                    color:
                                      this.state.questionColor === true &&
                                      this.state.displayId === faq.id
                                        ? "#00A5E6"
                                        : "#141C49",
                                    fontSize: 16,
                                    fontWeight:
                                      this.state.questionColor === true &&
                                      this.state.displayId === faq.id
                                        ? 600
                                        : "normal"
                                  }}
                                >
                                  {faq.faq_title}
                                </div>
                                {this.state.cheveronUp === true &&
                                this.state.displayId === faq.id ? (
                                  <Avatar
                                    src={require("../../../Assets/images/up.png")}
                                    style={{
                                      height: 25,
                                      width: 25,
                                      borderRadius: 12
                                    }}
                                    id={faq.id}
                                    onClick={e => this.changeBool(e, faq.id)}
                                  />
                                ) : (
                                  <Avatar
                                    src={require("../../../Assets/images/down.png")}
                                    style={{
                                      height: 25,
                                      width: 25,
                                      borderRadius: 12
                                    }}
                                    id={faq.id}
                                    onClick={e => this.changeBool(e, faq.id)}
                                  />
                                )}
                              </div>
                              {this.state.bool === true &&
                                this.state.displayId === faq.id && (
                                  <div
                                    key={faq.id}
                                    style={{
                                      lineHeight: 1.5,
                                      color: "#585858",
                                      fontFamily: "Raleway",
                                      fontSize: 14,
                                      paddingLeft: 25,
                                      paddingRight: 25,
                                      marginTop: 15,
                                      paddingBottom:
                                        this.state.questionColor === true &&
                                        this.state.displayId === faq.id
                                          ? 35
                                          : 0
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: faq.faq_description
                                    }}
                                  ></div>
                                )}
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                }
              </div>
            </Container>
          </div>
        )}
        {this.state.showSearchedContent && (
          <div className="faq--section--wrapper">
            <Container>
              <div>
                {this.state.searchedContentDetail.map((data, index) => (
                  <div className="faqlist">
                    <div className="faq--left">
                      <div className="sideTab">{data.faq_category}</div>
                    </div>
                    <div className="faq--right">
                      <div className="faq--right--question">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: 45,
                            paddingRight: 38,
                            paddingTop: 35,
                            paddingBottom:
                              this.state.questionColor === false &&
                              this.state.displayId === data.id
                                ? 35
                                : 30
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "Raleway",
                              color:
                                this.state.questionColor === true &&
                                this.state.displayId === data.id
                                  ? "#00A5E6"
                                  : "#141C49",
                              fontSize: 16,
                              fontWeight:
                                this.state.questionColor === true &&
                                this.state.displayId === data.id
                                  ? 600
                                  : "normal"
                            }}
                          >
                            {data.faq_title}
                          </div>
                          {this.state.cheveronUp === true &&
                          this.state.displayId === data.id ? (
                            <Avatar
                              src={require("../../../Assets/images/up.png")}
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 12
                              }}
                              onClick={e => this.changeBool(e, data.id)}
                            />
                          ) : (
                            <Avatar
                              src={require("../../../Assets/images/down.png")}
                              style={{
                                height: 25,
                                width: 25,
                                borderRadius: 12
                              }}
                              onClick={e => this.changeBool(e, data.id)}
                            />
                          )}
                        </div>
                        {this.state.bool === true &&
                          this.state.displayId === data.id && (
                            <div
                              style={{
                                lineHeight: 1.4,
                                color: "#585858",
                                fontFamily: "Raleway",
                                fontSize: 14,
                                paddingLeft: 45,
                                paddingRight: 38,
                                marginTop: 22,
                                paddingBottom:
                                  this.state.questionColor === true &&
                                  this.state.displayId === data.id
                                    ? 35
                                    : 0
                              }}
                            >
                              {data.faq_description}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      // auth: state.app.auth,
    }),
    { updateBreadcrumbs }
  )(index)
);

let styles = {
  banner: {
    height: 600,
    width: "100%",
    borderRadius: 0
  },
  h1_title: {
    fontFamily: "Lora",
    fontWeight: "normal",
    fontStyle: "normal",
    position: "absolute",
    top: 150,
    color: "white",
    fontSize: 50,
    marginLeft: "22%",
    marginRight: "22%"
  },
  h4_title: {
    fontFamily: "Raleway",
    fontWeight: "normal",
    fontStyle: "normal",
    position: "absolute",
    top: 226,
    color: "white",
    fontSize: 20,
    marginLeft: "27%",
    marginRight: "27%"
  },
  paper: {
    display: "flex",
    width: 600,
    height: 60,
    backgroundColor: "white",
    position: "absolute",
    top: 340,
    marginLeft: "25%",
    marginRight: "25%",
    borderRadius: 0
  },
  input: {
    flex: 1,
    marginLeft: 19,
    fontSize: 16,
    fontFamily: "Raleway"
  },
  divider: {
    height: 60
  },
  image: {
    borderRadius: 0,
    height: 24,
    width: 24
  },
  searchImageBackground: {
    backgroundColor: "#D06900",
    borderRadius: 0,
    width: 90
  },
  categoryName: {
    height: 40,
    width: 250,
    textAlign: "center",
    borderRadius: 0,
    borderBottom: "1px solid #ECECEC",
    paddingTop: 22,
    fontFamily: "Raleway",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 16
  }
};
