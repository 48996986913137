import {
    UPDATE_AUTH,
    UPDATE_MODAL,
    UPDATE_TOASTER_CONFIG,
    UPDATE_BREADCRUMBS,
    UPDATE_PROFILE_Image,
    NOTIFICATION_COUNT 
    
  } from './types';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case UPDATE_AUTH:
        return {
          ...state,
          auth: action.response,
        };
      case UPDATE_MODAL:
        return {
          ...state,
          showModal: action.response,
        };
      case UPDATE_TOASTER_CONFIG:
        return {
          ...state,
          toasterConfig: { ...action.response },
        };
      case UPDATE_BREADCRUMBS:
        return {
          ...state,
          breadcrumbsKey: action.response,
        };
        case UPDATE_PROFILE_Image:
        return {
          ...state,
          profileImg: action.response,
        };
        case NOTIFICATION_COUNT :
          return {
            ...state,
            notificationCount: action.response,
          };
        
      default: {
        return state;
      }
    }
  };
  