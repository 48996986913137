import React,{useEffect, useState} from 'react'
import { ListGroup } from 'react-bootstrap'
import { Avatar } from "@material-ui/core";
import { useConversations } from '../../contexts/ConversationsProvider';
import "./style.scss"
import { getCookie } from '../../Utils/cookies';

export default function Conversations({ selectId, messageCame, performSearch,filterValue,filterId}) {

  const { conversations, selectConversationIndex } = useConversations()

  const [ conStore, setconStore ] = useState([...conversations])
  const [ store, setStore ] = useState(null)



  useEffect(()=>{
    try{
    if ( performSearch !== null && performSearch.length >= 3) {
      let filtered = conStore.filter((entry) =>
          typeof entry.recipients[0].name === "string" && entry.recipients[0].name.toLowerCase().includes(performSearch)   
      );
      setStore(filtered)  
  }
  if( performSearch!== null && performSearch.length===0){
    setStore(null)  
  }
}catch(err){}
  },[performSearch])


  useEffect(()=>{
    try{
if(filterValue!== null){
          if(filterValue === 'All'){
            setStore(null)  
          }
          else{
            let filter = selectId.filter(item => item.recipients[0].status === filterValue);
            setStore(filter)          
          }
}
    }catch(err){}

  },[filterValue,filterId])

useEffect(()=>{
  selectConversationIndex(0)
},[])




  return (
    <>
    
    {store!==null  ?  <ListGroup variant="flush" >
      {store.map((conversation, index) => 
       {
      
        
           return(
            <div className="chat--wrapper" key={index}>
               
            <Avatar
              src={require("./../../Assets/images/chat-1.png")}
            />
            <div className="chat--row">
            <ListGroup.Item
            className="buttonIntoText"
              key={index}
              action
              onClick={() => selectConversationIndex(index)}
              active={conversation.selected}
            >
              {/* {conversation.recipients.map(r => r.name).join(', ')} */}
              {conversation.recipients[0].name}
            </ListGroup.Item>
           
      {messageCame.map((item,index)=>{
       if(conversation.recipients[0]!== undefined &&conversation.messages[0] !== undefined ) 
        if(item.id=== conversation.recipients[0].id){
        if(item.messageLength < conversation.messages.length){
         return <span className="boldGreen"> . </span>
        }  
      }
     })}


{/* {getCookie("userId")==="67" && selectId!== null && selectId.map((item,index)=>{
      if(item.recipients[0].id=== conversation.recipients[0].id){
          return  (
          <>  
          {item.recipients[0].status==="Ongoing" && 
          <span className="ongoingClass">{item.recipients[0].status}</span>}
        {item.recipients[0].status==="Completed" && 
          <span className="completedClass">{item.recipients[0].status}</span>}
           </>)
      }
        })
    } */}
   
{getCookie("userId")==="67" && 
        conversation.recipients[0].status==="Ongoing" && 
  <span className="ongoingClass">{conversation.recipients[0].status}</span> }
        { getCookie("userId")==="67" && conversation.recipients[0].status==="Completed" && 
          <span className="completedClass">{conversation.recipients[0].status}</span>}
          


                 </div>
             </div>
           )
         
       }
      )}
    </ListGroup>  :
    <ListGroup variant="flush" >
      {conversations.map((conversation, index) => (
       <div className="chat--wrapper">
       <Avatar
         src={require("./../../Assets/images/chat-1.png")}
       />
       <div className="chat--row">
        <ListGroup.Item
        className="buttonIntoText"
          key={index}
          action
          onClick={() => selectConversationIndex(index)}
          active={conversation.selected}
        >
          {conversation.recipients[0].name}
        </ListGroup.Item>


{messageCame.map((item,index)=>{
   if(conversation.recipients[0]!== undefined &&conversation.messages[0] !== undefined ) 
    if(item.id=== conversation.recipients[0].id){
    if(item.messageLength < conversation.messages.length){
     return <span className="boldGreen"> . </span>
    }  
  }
 })}


{getCookie("userId")==="67" && 
        conversation.recipients[0].status==="Ongoing" && 
  <span className="ongoingClass">{conversation.recipients[0].status}</span> }
        { getCookie("userId")==="67" && conversation.recipients[0].status==="Completed" && 
          <span className="completedClass">{conversation.recipients[0].status}</span>}
   
    
         </div>
         </div>
      ))}
    </ListGroup>}
    </>
  )

}
